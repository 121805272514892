export const getConversationsWithUpdatedLatestMessage = (
  state,
  newMessages
) => {
  return state.conversations.map(
    item =>
      item.id === state.currentConversation.id
        ? {
            ...item,
            latest_message: {
              ...newMessages[0],
              is_seen: true
            }
          }
        : item
  );
};
