import * as Sentry from '@sentry/browser';

import {
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE_VERSION
} from 'global-constants';

export const initSentry = () => {
  if (SENTRY_ENVIRONMENT) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      release: SENTRY_RELEASE_VERSION
    });
  }
};

export const setSentryUser = ({ userData: { email, id } }) =>
  Sentry.setUser({
    id,
    email
  });
