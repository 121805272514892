import { put, takeLatest } from 'redux-saga/effects';

import { makeRequest } from 'shared/sdk';
import { closeModal } from 'shared/components/Modals/reducer';
import { MODALS } from 'global-constants';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'InviteTeamMembers/';

const INVITE_TEAM_MEMBERS = `${actionPrefix}INVITE_TEAM_MEMBERS`;
const SUCCESS_INVITE_TEAM_MEMBERS = `${actionPrefix}SUCCESS_INVITE_TEAM_MEMBERS`;
const FAIL_INVITE_TEAM_MEMBERS = `${actionPrefix}FAIL_INVITE_TEAM_MEMBERS`;
const RESET_ERRORS = `${actionPrefix}RESET_ERRORS`;

export const inviteTeamMembers = ({ emails, teamType }) => ({
  type: INVITE_TEAM_MEMBERS,
  payload: {
    emails,
    teamType
  }
});

const successInviteTeamMembers = data => ({
  type: SUCCESS_INVITE_TEAM_MEMBERS,
  payload: data
});

const failInviteTeamMembers = errors => ({
  type: FAIL_INVITE_TEAM_MEMBERS,
  payload: errors
});

export const resetErrors = () => ({
  type: RESET_ERRORS
});

const initialState = {
  invitationErrors: null
};

export const inviteTeamMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAIL_INVITE_TEAM_MEMBERS:
      state['invitationErrors'] = action.payload;
      break;

    case RESET_ERRORS:
      state['invitationErrors'] = null;
      break;

    default:
      return state;
  }
  return state;
};

function* inviteTeamMembersWorker(action) {
  const { emails, teamType } = action.payload;

  const response = yield makeRequest(teamType.api, {
    requestBody: { emails }
  });

  if (response.success) {
    const notificationContent = `Great! ${
      emails.length
    } invites are now on their way to your connections`;

    yield* [
      put(successInviteTeamMembers(response.data)),
      put(closeModal(MODALS.INVITE_TEAM_MEMBERS)),
      put(openNotificationBar(notificationContent))
    ];
  } else yield put(failInviteTeamMembers(response.errors));
}

export function* inviteTeamMembersSaga() {
  yield takeLatest(INVITE_TEAM_MEMBERS, inviteTeamMembersWorker);
}
