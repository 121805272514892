import _ from 'lodash';
import { put, takeLatest, select } from 'redux-saga/effects';
import history from 'config/history';

import { parseQueryString } from 'shared/utils/url';

import {
  FILTERS,
  AUTHOR,
  FILTERS_OF_ORDER_BY_TYPE
} from 'shared/components/Filters/constants';
import { fetchAuthors } from 'shared/components/Filters/ducks';

import {
  loadInitialFilters,
  changeFilterService,
  clearFilterService,
  clearAllFiltersService,
  getNewParams
} from './utils';
import * as selectors from './selectors';

const INIT_FILTERS = 'filters/INIT_FILTERS';
const SET_INITIAL_FILTERS = 'filters/SET_INITIAL_FILTERS';

const REQUEST_CHANGE_FILTERS = 'filters/REQUEST_CHANGE_FILTERS';
const CHANGE_FILTERS = 'filters/CHANGE_FILTERS';

const REQUEST_CLEAR_FILTER = 'filters/REQUEST_CLEAR_FILTER';
const CLEAR_FILTER = 'filters/CLEAR_FILTER';

const REQUEST_CLEAR_ALL_FILTERS = 'filters/REQUEST_CLEAR_ALL_FILTERS';
const CLEAR_ALL_FILTERS = 'filters/CLEAR_ALL_FILTERS';

const FETCH_FILTERED_DATA = 'filters/FETCH_FILTERED_DATA';

export const initFilters = ({ pageName, fetchData = null }) => ({
  type: INIT_FILTERS,
  payload: { pageName, fetchData }
});

const setInitialFilters = pageName => ({
  type: SET_INITIAL_FILTERS,
  payload: { pageName }
});

export const requestChangeFilters = (
  pageInfo,
  filterName,
  value,
  additionalFilterOptions = {}
) => ({
  type: REQUEST_CHANGE_FILTERS,
  payload: { pageInfo, filterName, value, additionalFilterOptions }
});

export const requestClearFilter = (pageInfo, filterName) => ({
  type: REQUEST_CLEAR_FILTER,
  payload: { pageInfo, filterName }
});

export const requestClearAllFilters = pageInfo => ({
  type: REQUEST_CLEAR_ALL_FILTERS,
  payload: { pageInfo }
});

const changeFilters = (pageName, filterName, value) => ({
  type: CHANGE_FILTERS,
  payload: { pageName, filterName, value }
});

const clearFilter = (pageName, filterName) => ({
  type: CLEAR_FILTER,
  payload: { pageName, filterName }
});

const clearAllFilters = pageName => ({
  type: CLEAR_ALL_FILTERS,
  payload: { pageName }
});

export const fetchFilteredData = ({ pageName, fetchData = null }) => ({
  type: FETCH_FILTERED_DATA,
  payload: { fetchData, pageName }
});

export const manageFiltersReducer = (state, action) => {
  const pageName = _.get(action, 'payload.pageName');

  switch (action.type) {
    case SET_INITIAL_FILTERS:
      state[pageName] = {
        filters: loadInitialFilters(FILTERS[pageName])
      };
      break;

    case CHANGE_FILTERS:
      const { filterName, value } = action.payload;
      state[pageName] = {
        ...state[pageName],
        filters: changeFilterService(state[pageName].filters, filterName, value)
      };
      break;

    case CLEAR_FILTER:
      const filter = action.payload.filterName;
      state[pageName] = {
        ...state[pageName],
        filters: clearFilterService(state[pageName].filters, filter)
      };
      break;

    case CLEAR_ALL_FILTERS:
      state[pageName] = {
        ...state[pageName],
        filters: clearAllFiltersService(state[pageName].filters)
      };
      break;

    default:
      return state;
  }
};

function* initFiltersWorker(action) {
  const { pageName, fetchData } = action.payload;

  yield put(setInitialFilters(pageName));
  let filters = [];

  const paramsObj = parseQueryString(history.location.search);
  const initialFilters = yield select(selectors.getFilters, pageName);

  if (history.location.search !== '') {
    let keyParams = Object.keys(paramsObj);

    yield* keyParams.map(keyParam =>
      put(changeFilters(pageName, keyParam, paramsObj[keyParam]))
    );

    filters = yield select(selectors.getFilters, pageName);

    // We need to load initial companies because fetch companies after type something
    const authorFilter = _.find(filters, { name: AUTHOR.name });
    if (authorFilter && !_.isEmpty(authorFilter.value))
      yield put(fetchAuthors({ id: { value: authorFilter.value } }));
  }

  for (const filter of FILTERS_OF_ORDER_BY_TYPE) {
    // initialising default value if not in params
    const orderByFilter = _.find(initialFilters, filter);

    if (_.isNil(paramsObj[filter.name]) && !_.isNil(orderByFilter)) {
      yield put(
        changeFilters(pageName, filter.name, orderByFilter.defaultOption.value)
      );
    }
  }

  filters = yield select(selectors.getFilters, pageName);
  history.replace({ search: getNewParams(filters) });

  if (!_.isNil(fetchData)) {
    yield put(fetchData(filters));
  }
}

function* requestChangeFiltersWorker(action) {
  const {
    pageInfo: { pageName, fetchData },
    filterName,
    value,
    additionalFilterOptions
  } = action.payload;
  let filters = yield select(selectors.getFilters, pageName);

  yield put(changeFilters(pageName, filterName, value));

  filters = yield select(selectors.getFilters, pageName);
  let newFilter = _.find(filters, { name: filterName });

  newFilter = { ...newFilter, ...additionalFilterOptions };

  if (newFilter.isInstant) {
    if (!_.isNil(fetchData)) {
      yield put(fetchData(filters));
    }

    history.replace({ search: getNewParams(filters) });
  }
}

function* requestClearFilterWorker(action) {
  const {
    pageInfo: { pageName, fetchData },
    filterName
  } = action.payload;

  yield put(clearFilter(pageName, filterName));

  let filters = yield select(selectors.getFilters, pageName);

  if (!_.isNil(fetchData)) {
    yield put(fetchData(filters));
  }
  history.replace({ search: getNewParams(filters) });
}

function* requestClearAllFiltersWorker(action) {
  const {
    pageInfo: { pageName, fetchData }
  } = action.payload;

  yield put(clearAllFilters(pageName));

  let filters = yield select(selectors.getFilters, pageName);

  if (!_.isNil(fetchData)) {
    yield put(fetchData(filters));
  }

  history.replace({ search: getNewParams(filters) });
}

function* fetchFilteredDataWorker(action) {
  const { fetchData, pageName } = action.payload;

  if (!_.isNil(fetchData)) {
    const filters = yield select(selectors.getFilters, pageName);

    history.replace({ search: getNewParams(filters) });

    yield put(fetchData(filters));
  }
}

export function* filtersWatcher() {
  yield takeLatest(INIT_FILTERS, initFiltersWorker);
  yield takeLatest(REQUEST_CHANGE_FILTERS, requestChangeFiltersWorker);
  yield takeLatest(REQUEST_CLEAR_FILTER, requestClearFilterWorker);
  yield takeLatest(REQUEST_CLEAR_ALL_FILTERS, requestClearAllFiltersWorker);
  yield takeLatest(FETCH_FILTERED_DATA, fetchFilteredDataWorker);
}
