import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { startSubmit, stopSubmit } from 'redux-form';

const actionPrefix = 'ResetPassword/';

export const RESET_PASSWORD = `${actionPrefix}RESET_PASSWORD`;
export const SUCCESS_RESET_PASSWORD = `${actionPrefix}SUCCESS_RESET_PASSWORD`;

export const resetPassword = (formName, data) => {
  return {
    type: RESET_PASSWORD,
    formName,
    payload: { data }
  };
};

const successResetPassword = () => {
  return {
    type: SUCCESS_RESET_PASSWORD
  };
};

const initialState = {
  resetPasswordSuccess: false
};

export const resetPasswordReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SUCCESS_RESET_PASSWORD:
      newState = {
        resetPasswordSuccess: true
      };
      break;

    default:
      return state;
  }

  return Object.assign({}, state, newState);
};

function* resetPasswordWorker(action) {
  yield put(startSubmit('ResetPasswordForm'));
  const { data } = action.payload;

  const response = yield makeRequest(api.resetPassword, {
    requestBody: data
  });

  if (response.success) {
    yield put(successResetPassword());
  } else {
    const errors = {
      _error: [response.errors.token]
    };
    yield put(stopSubmit('ResetPasswordForm', errors));
  }
}

export function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPasswordWorker);
}
