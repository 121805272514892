import { put, takeLatest } from 'redux-saga/effects';
import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Tender/';

const FETCH_TENDER = `${actionPrefix}FETCH_TENDER`;
const SUCCESS_FETCH_TENDER = `${actionPrefix}SUCCESS_FETCH_TENDER`;
const FAIL_FETCH_TENDER = `${actionPrefix}FAIL_FETCH_TENDER`;

const initialState = {
  isFetching: true,
  tender: {}
};

export const fetchTender = tenderId => ({
  type: FETCH_TENDER,
  payload: { tenderId }
});

const successFetchTender = payload => ({
  type: SUCCESS_FETCH_TENDER,
  payload
});

const failFetchTender = payload => ({
  type: FAIL_FETCH_TENDER,
  payload
});

export const tenderReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case FETCH_TENDER:
      newState['isFetching'] = true;
      break;
    case SUCCESS_FETCH_TENDER:
      newState['tender'] = action.payload;
      newState['isFetching'] = false;
      break;
    default:
      return newState;
  }

  return newState;
};

function* fetchTenderWorker(action) {
  const response = yield getResponseOr404(api.fetchTender, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFetchTender(response.data));
  else {
    yield put(failFetchTender(response.errors));
  }
}

export function* tenderSaga() {
  yield takeLatest(FETCH_TENDER, fetchTenderWorker);
}
