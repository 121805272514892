import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { buildFiltersFromObj } from 'shared/utils/filters';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'Tenders/';
export const FETCH_TENDERS = `${actionPrefix}FETCH_TENDERS`;
export const SUCCESS_FETCH_TENDERS = `${actionPrefix}SUCCESS_FETCH_TENDERS`;
export const FAIL_FETCH_TENDERS = `${actionPrefix}FAIL_FETCH_TENDERS`;

export const FETCH_NEXT_TENDERS = `${actionPrefix}FETCH_NEXT_TENDERS`;
export const SUCCESS_FETCH_NEXT_TENDERS = `${actionPrefix}SUCCESS_FETCH_NEXT_TENDERS`;
export const FAIL_FETCH_NEXT_TENDERS = `${actionPrefix}FAIL_FETCH_NEXT_TENDERS`;

export const fetchTenders = (filters = {}) => ({
  type: FETCH_TENDERS,
  payload: {
    filters
  }
});

const successFetchTenders = payload => ({
  type: SUCCESS_FETCH_TENDERS,
  payload
});

const failFetchTenders = payload => ({
  type: FAIL_FETCH_TENDERS,
  payload
});

export const fetchNextTenders = nextUrl => ({
  type: FETCH_NEXT_TENDERS,
  payload: {
    nextUrl
  }
});

const successFetchNextTenders = payload => ({
  type: SUCCESS_FETCH_NEXT_TENDERS,
  payload
});

const failFetchNextTenders = payload => ({
  type: FAIL_FETCH_NEXT_TENDERS,
  payload
});

export const listTendersReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TENDERS:
      state['tenders'] = [];
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_TENDERS:
      state['isFetching'] = false;
      state['tenders'] = action.payload.results;
      state['nextUrl'] = action.payload.next;
      break;

    case FETCH_NEXT_TENDERS:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_NEXT_TENDERS:
      state['isFetching'] = false;
      state['tenders'] = addToList(state.tenders, action.payload.results);
      state['nextUrl'] = action.payload.next;
      break;

    default:
      return state;
  }
};

function* fetchTendersWorker(action) {
  const response = yield makeRequest(api.tendersList, {
    requestData: {
      params: {
        ...buildFiltersFromObj(action.payload.filters),
        limit: 20,
        offset: 0
      }
    }
  });

  if (response.success) yield put(successFetchTenders(response.data));
  else yield put(failFetchTenders(response.errors));
}

function* fetchNextTendersWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.payload.nextUrl
  });

  if (response.success) yield put(successFetchNextTenders(response.data));
  else yield put(failFetchNextTenders(response.errors));
}

export function* tendersWatcher() {
  yield takeLatest(FETCH_TENDERS, fetchTendersWorker);
  yield takeLatest(FETCH_NEXT_TENDERS, fetchNextTendersWorker);
}
