import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { uploadFile } from 'shared/utils/fileUpload';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'Company/';

const FETCH_COUNTRIES = `${actionPrefix}FETCH_COUNTRIES`;
const SUCCESS_FETCH_COUNTRIES = `${actionPrefix}SUCCESS_FETCH_COUNTRIES`;
const FAIL_FETCH_COUNTRIES = `${actionPrefix}FAIL_FETCH_COUNTRIES`;

const SUBMIT_SUGGEST_EDIT = `${actionPrefix}SUBMIT_SUGGEST_EDIT`;
const FAIL_SUBMIT_SUGGEST_EDIT = `${actionPrefix}FAIL_SUBMIT_SUGGEST_EDIT`;
const SUCCESS_SUBMIT_SUGGEST_EDIT = `${actionPrefix}SUCCESS_SUBMIT_SUGGEST_EDIT`;

export const submitSuggestEdit = (
  formName,
  slug,
  payload,
  extraActions = []
) => ({
  type: SUBMIT_SUGGEST_EDIT,
  formName,
  slug,
  payload,
  extraActions
});

const failSubmitSuggestEdit = payload => ({
  type: FAIL_SUBMIT_SUGGEST_EDIT,
  payload
});

const successSubmitSuggestEdit = payload => ({
  type: SUCCESS_SUBMIT_SUGGEST_EDIT,
  payload
});

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES
});

const failFetchCountries = payload => ({
  type: FAIL_FETCH_COUNTRIES,
  payload
});

const successFetchCountries = payload => ({
  type: SUCCESS_FETCH_COUNTRIES,
  payload
});

export const suggestEditReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COUNTRIES:
      newState['countries'] = action.payload.results;
      break;

    default:
      break;
  }
};

function* suggestEditWorker(action) {
  let data = action.payload;
  const logoArr = _.get(action.payload, 'logo', null);

  if (!_.isNil(logoArr) && Array.isArray(logoArr)) {
    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signVendorLogo);

    data = Object.assign({}, action.payload, { logo: logoUrl });
  }

  const response = yield makeRequest(api.companySuggestEdit, {
    lookupData: { slug: action.slug },
    requestBody: data
  });

  if (response.success) {
    yield put(successSubmitSuggestEdit(response.data));
    yield* action.extraActions.map(extraAction =>
      put(extraAction(response.data))
    );
  } else {
    yield put(failSubmitSuggestEdit(response.errors));

    return response.errors;
  }
}

function* fetchCountriesWorker(action) {
  const response = yield makeRequest(api.countriesList, {
    requestData: {
      params: {
        limit: 500,
        offset: 0,
        ordering: 1
      }
    }
  });

  if (response.success) yield put(successFetchCountries(response.data));
  else yield put(failFetchCountries(response.errors));
}

export function* suggestEditSaga() {
  yield takeLatest(FETCH_COUNTRIES, fetchCountriesWorker);
  yield takeLatest(SUBMIT_SUGGEST_EDIT, formSubmitWorker(suggestEditWorker));
}
