import _ from 'lodash';

import { conversationsReducer, conversationsWatcher } from './conversations';
import { messagesReducer, messagesWatcher } from './messages';
import { notificationsReducer, notificationsWatcher } from './notifications';
import {
  userInformationReducer,
  userInformationWatcher
} from './userInformation';

const actionPrefix = 'Messaging/';

const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const resetState = () => ({
  type: RESET_STATE
});

const initialState = {
  conversations: [],
  currentConversationId: null,
  currentMessages: [],
  writtenMessages: [],
  conversationHasNewMessages: false,
  lastFetchedMessageId: null,
  pollCurrentConversationLatestMessages: false,
  loadingMessages: null,
  loadingPreviousMessages: false,
  loadingConversations: true,
  globalNotifier: {
    pollUnseenMessages: false,
    unseenMessages: [],
    unseenMessageCount: 0
  },
  pollMarkSeen: false,
  fetchingCurrentUserInformation: false,
  currentUserInformation: { profile: {}, vendor: {} },
  nextConversationsUrl: null,
  previousMessagesUrl: null,
  loadingNextConversations: false
};

export const messagingReducer = (state = _.cloneDeep(initialState), action) => {
  let newState = { ...state };

  conversationsReducer(newState, action);
  messagesReducer(newState, action);
  notificationsReducer(newState, action);
  userInformationReducer(newState, action);

  switch (action.type) {
    case RESET_STATE:
      newState = _.cloneDeep(initialState);
      break;

    default:
      break;
  }

  return newState;
};

export const messagingSagas = [
  conversationsWatcher,
  messagesWatcher,
  notificationsWatcher,
  userInformationWatcher
];

export * from './conversations';
export * from './messages';
export * from './notifications';
export * from './userInformation';
