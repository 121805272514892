import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { fetchMe } from 'shared/HOCs/FetchInitials/ducks';

const actionPrefix = 'ConnectButton/';

const TOGGLE_CONNECTION = `${actionPrefix}TOGGLE_CONNECTION`;
const SUCCESS_TOGGLE_CONNECTION = `${actionPrefix}SUCCESS_TOGGLE_CONNECTION`;

export const toggleConnection = ({ userToConnectId, extra }) => ({
  type: TOGGLE_CONNECTION,
  payload: { userToConnectId, extra }
});

const successToggleConnection = () => ({
  type: SUCCESS_TOGGLE_CONNECTION
});

const initialState = {
  isPerformingConnectOperation: false
};

export const connectButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CONNECTION:
      state['isPerformingConnectOperation'] = true;
      break;

    case SUCCESS_TOGGLE_CONNECTION:
      state['isPerformingConnectOperation'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* toggleConnectionWorker(action) {
  const { userToConnectId, extra } = action.payload;

  const response = yield makeRequest(api.toggleConnection, {
    lookupData: { userToConnectId }
  });

  if (response.success) {
    yield* [put(successToggleConnection()), put(fetchMe())];

    extra.map(extraAction => extraAction(response.data));
  }
}

export function* connectButtonSaga(action) {
  yield takeLatest(TOGGLE_CONNECTION, toggleConnectionWorker);
}
