import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Dashboard/RankDifference/';

const FETCH_RANK_DIFFERENCE = `${actionPrefix}FETCH_RANK_DIFFERENCE`;
const SUCCESS_FETCH_RANK_DIFFERENCE = `${actionPrefix}SUCCESS_FETCH_RANK_DIFFERENCE`;
const FAIL_FETCH_RANK_DIFFERENCE = `${actionPrefix}FAIL_FETCH_RANK_DIFFERENCE`;

export const fetchRankDifference = ({ userId }) => ({
  type: FETCH_RANK_DIFFERENCE,
  payload: { userId }
});

const successFetchRankDifference = ({ data }) => ({
  type: SUCCESS_FETCH_RANK_DIFFERENCE,
  payload: { data }
});

const failFetchRankDifference = ({ errors }) => ({
  type: FAIL_FETCH_RANK_DIFFERENCE,
  payload: { errors }
});

const initialState = {
  rankDifferenceData: [],
  isFetchingRankDifference: false
};

export const rankDifferenceWidgetReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case FETCH_RANK_DIFFERENCE:
      newState['isFetchingRankDifference'] = true;
      break;

    case SUCCESS_FETCH_RANK_DIFFERENCE:
      newState['isFetchingRankDifference'] = false;
      newState['rankDifferenceData'] = action.payload.data;
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchRankDifferenceWorker(action) {
  const { userId } = action.payload;

  const response = yield makeRequest(api.rankDifferenceList, {
    lookupData: { userId }
  });

  if (response.success) yield put(successFetchRankDifference(response));
  else yield put(failFetchRankDifference(response));
}

export function* rankDifferenceWidgetSaga() {
  yield takeLatest(FETCH_RANK_DIFFERENCE, fetchRankDifferenceWorker);
}
