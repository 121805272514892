import _ from 'lodash';
import { push } from 'react-router-redux';
import { put, takeLatest } from 'redux-saga/effects';
import { arrayMove } from 'react-sortable-hoc';

import { substituteUrl } from 'shared/utils/substituteUrl';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { addToList, removeAtIndex } from 'shared/utils/reducerUtils';

import { EDIT_COMPANY_URL } from 'global-constants';
import { api, makeRequest, getResponseOr404 } from 'shared/sdk';
import {
  uploadFile,
  multipleFileUploadWorkerFactory,
  asGenericUploadHook,
  toImages
} from 'shared/utils/fileUpload';

import { successFetchMe } from 'shared/HOCs/FetchInitials/ducks/index.js';

const actionPrefix = 'EditCompany/';

export const FETCH_COMPANY = `${actionPrefix}FETCH_COMPANY`;
export const SUCCESS_FETCH_COMPANY = `${actionPrefix}SUCCESS_FETCH_COMPANY`;
export const FAIL_FETCH_COMPANY = `${actionPrefix}FAIL_FETCH_COMPANY`;

const UPDATE_COMPANY = `${actionPrefix}UPDATE_COMPANY`;
const SUCCESS_UPDATE_COMPANY = `${actionPrefix}SUCCESS_UPDATE_COMPANY`;
const FAIL_UPDATE_COMPANY = `${actionPrefix}FAIL_UPDATE_COMPANY`;

const START_VENDOR_IMAGES_UPLOAD = `${actionPrefix}START_VENDOR_IMAGE_UPLOAD`;
const SUCCESS_VENDOR_IMAGES_UPLOAD = `${actionPrefix}SUCCESS_VENDOR_IMAGE_UPLOAD`;
const FAIL_VENDOR_IMAGES_UPLOAD = `${actionPrefix}FAIL_VENDOR_IMAGE_UPLOAD`;

const REMOVE_VENDOR_IMAGE = `${actionPrefix}REMOVE_VENDOR_IMAGE`;
const MOVE_VENDOR_IMAGE = `${actionPrefix}MOVE_VENDOR_IMAGE`;

const FETCH_UPLOADED_VENDOR_PROFILE_VIDEO = `${actionPrefix}FETCH_UPLOADED_VENDOR_PROFILE_VIDEO`;
const SUCCESS_FETCH_UPLOADED_VENDOR_PROFILE_VIDEO = `${actionPrefix}SUCCESS_FETCH_UPLOADED_VENDOR_PROFILE_VIDEO`;

const DELETE_VENDOR_PROFILE_VIDEO = `${actionPrefix}DELETE_VENDOR_PROFILE_VIDEO`;

export const fetchCompany = ({ slug }) => ({
  type: FETCH_COMPANY,
  payload: { slug }
});

const successFetchCompany = payload => ({
  type: SUCCESS_FETCH_COMPANY,
  payload
});

const failFetchCompany = data => ({
  type: FAIL_FETCH_COMPANY,
  data
});

export const updateCompany = (formName, slug, payload, extraActions = []) => ({
  type: UPDATE_COMPANY,
  formName,
  slug,
  payload,
  extraActions
});

const successUpdateCompany = payload => ({
  type: SUCCESS_UPDATE_COMPANY,
  payload
});

const failUpdateCompany = payload => ({
  type: FAIL_UPDATE_COMPANY,
  payload
});

export const startVendorImagesUpload = (id, payload) => ({
  type: START_VENDOR_IMAGES_UPLOAD,
  id,
  payload
});

const successVendorImagesUpload = payload => ({
  type: SUCCESS_VENDOR_IMAGES_UPLOAD,
  payload
});

const failVendorImagesUpload = payload => ({
  type: FAIL_VENDOR_IMAGES_UPLOAD,
  payload
});

export const removeVendorImage = payload => ({
  type: REMOVE_VENDOR_IMAGE,
  payload
});

export const moveVendorImage = payload => ({
  type: MOVE_VENDOR_IMAGE,
  payload
});

export const fetchUploadedVendorProfileVideo = ({ identifier }) => ({
  type: FETCH_UPLOADED_VENDOR_PROFILE_VIDEO,
  payload: { identifier }
});

export const successFetchUploadedVendorProfileVideo = payload => ({
  type: SUCCESS_FETCH_UPLOADED_VENDOR_PROFILE_VIDEO,
  payload
});

export const deleteVendorProfileVideo = () => ({
  type: DELETE_VENDOR_PROFILE_VIDEO
});

export const updateHeadlinesReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COMPANY:
      newState['company'] = action.payload;
      newState['companyImages'] = _.get(action.payload, 'images', []);
      newState['questions'] = _.sortBy(action.payload.questions, x => x.order);
      newState['profileVideo'] = _.get(action.payload, 'profile_video', null);
      break;

    case SUCCESS_VENDOR_IMAGES_UPLOAD:
      newState['companyImages'] = addToList(
        newState.companyImages,
        toImages(action.payload.files)
      );
      break;

    case REMOVE_VENDOR_IMAGE:
      newState['companyImages'] = removeAtIndex(
        newState['companyImages'],
        action.payload.index
      );
      break;

    case MOVE_VENDOR_IMAGE:
      newState['companyImages'] = arrayMove(
        newState['companyImages'],
        action.payload.oldIndex,
        action.payload.newIndex
      );
      break;

    case SUCCESS_FETCH_UPLOADED_VENDOR_PROFILE_VIDEO:
      newState['profileVideo'] = action.payload;
      break;

    case DELETE_VENDOR_PROFILE_VIDEO:
      newState['profileVideo'] = null;
      break;

    default:
      break;
  }
};

export function* fetchCompanyWorker(action) {
  const response = yield getResponseOr404(api.companiesDetail, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFetchCompany(response.data));
  else yield put(failFetchCompany(response.data));
}

function* updateHeadlinesWorker(action) {
  let data = action.payload;
  const logoArr = _.get(action.payload, 'logo', null);

  if (!_.isNil(logoArr) && Array.isArray(logoArr)) {
    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signVendorLogo);

    data = Object.assign({}, action.payload, { logo: logoUrl });
  }

  const updateResponse = yield makeRequest(api.updateCompany, {
    lookupData: { slug: action.slug },
    requestBody: data
  });

  if (updateResponse.success) {
    yield put(successUpdateCompany(updateResponse.data));
    if (action.slug !== updateResponse.data['slug']) {
      const meResponse = yield makeRequest(api.getMe);

      if (meResponse.success) yield put(successFetchMe(meResponse.data));

      yield put(
        push(
          substituteUrl(EDIT_COMPANY_URL, {
            companySlug: updateResponse.data['slug']
          })
        )
      );
    }
    yield* action.extraActions.map(action => put(action(updateResponse.data)));
  } else {
    yield put(failUpdateCompany(updateResponse.errors));
    return updateResponse.errors;
  }
}

export function* fetchUploadedVendorProfileVideoWorker(action) {
  const response = yield makeRequest(api.vendorProfileVideo, {
    lookupData: { identifier: action.payload.identifier }
  });

  if (response.success) {
    yield put(successFetchUploadedVendorProfileVideo(response.data));
  }
}

export function* updateHeadlinesSaga() {
  yield takeLatest(UPDATE_COMPANY, formSubmitWorker(updateHeadlinesWorker));
  yield takeLatest(FETCH_COMPANY, fetchCompanyWorker);
  yield takeLatest(
    START_VENDOR_IMAGES_UPLOAD,
    multipleFileUploadWorkerFactory(
      api.signVendorImage,
      asGenericUploadHook(successVendorImagesUpload),
      asGenericUploadHook(failVendorImagesUpload)
    )
  );
  yield takeLatest(
    FETCH_UPLOADED_VENDOR_PROFILE_VIDEO,
    fetchUploadedVendorProfileVideoWorker
  );
}
