import _ from 'lodash';

import { stringifyParams } from 'shared/utils/url';

export const loadInitialFilters = filters =>
  filters.map(filter => ({
    ...filter,
    isOpen: false,
    value: getDefaultValue(filter)
  }));

export const getArrayValue = newValue =>
  _.isArray(newValue)
    ? newValue.map(value => getIntOrStringValue(value))
    : [getIntOrStringValue(newValue)];

export const getIntOrStringValue = newValue =>
  _.isNaN(parseInt(newValue)) ? newValue : parseInt(newValue);

export const getNewValue = (filter, newValue) => {
  if (filter.select && filter.multiple) return getArrayValue(newValue);
  if (filter.select && !filter.multiple) return getIntOrStringValue(newValue);
  if (!filter.select && filter.multiple)
    return _.xor(filter.value, getArrayValue(newValue));
  if (!filter.select && !filter.multiple)
    return newValue !== filter.value ? getIntOrStringValue(newValue) : null;
};

export const changeFilterService = (filters, filterName, newValue) =>
  filters.map(filter => {
    return filter.name === filterName
      ? { ...filter, value: getNewValue(filter, newValue), isOpen: true }
      : filter;
  });

export const getDefaultValue = filter => {
  if (_.has(filter, 'defaultValue')) {
    return filter.defaultValue;
  }
  return filter.multiple ? [] : null;
};

export const clearFilterService = (filters, filterName) =>
  filters.map(
    filter =>
      filter.name === filterName
        ? { ...filter, value: getDefaultValue(filter) }
        : filter
  );

export const clearAllFiltersService = filters =>
  filters.map(filter => ({
    ...filter,
    // isOpen: filter.isOpen,
    value: getDefaultValue(filter)
  }));

export const getNewParams = filters => {
  let params = {};
  _.forEach(filters, filter => {
    if (
      !_.isNil(filter.value) &&
      filter.value !== '' &&
      filter.value !== false
    ) {
      params[filter.name] = filter.value;
    }
  });

  return stringifyParams(params);
};

export const isEmptyValue = value => {
  if (_.isArray(value)) {
    return _.isEmpty(value);
  }

  return _.isNil(value) || value === '';
};

export const getNonEmptyFilterNames = filters =>
  _.map(_.filter(filters, f => !isEmptyValue(f.value)), f => f.name);

export const getActiveFilterCount = filters =>
  getNonEmptyFilterNames(filters).length;
