import { removeAtIndex } from 'shared/utils/reducerUtils';

const actionPrefix = 'CreateCompany/';

const SAVE_PRODUCT_DATA = `${actionPrefix}SAVE_PRODUCT_DATA`;

const SET_EDITING_PRODUCT = `${actionPrefix}SET_EDITING_PRODUCT`;
const SAVE_EDITING_PRODUCT = `${actionPrefix}SAVE_EDITING_PRODUCT`;

const DELETE_PRODUCT = `${actionPrefix}DELETE_PRODUCT`;

export const saveProductData = payload => ({
  type: SAVE_PRODUCT_DATA,
  payload
});

export const setEditingProduct = productIndex => ({
  type: SET_EDITING_PRODUCT,
  payload: { productIndex }
});

export const saveEditingProduct = payload => ({
  type: SAVE_EDITING_PRODUCT,
  payload
});

export const deleteProduct = () => ({
  type: DELETE_PRODUCT
});

export const productsReducer = (newState, action) => {
  switch (action.type) {
    case SAVE_PRODUCT_DATA:
      newState['companyProductsData'] = [
        ...newState.companyProductsData,
        action.payload
      ];
      break;

    case SET_EDITING_PRODUCT:
      newState['editingProductIndex'] = action.payload.productIndex;
      break;

    case SAVE_EDITING_PRODUCT:
      let productsData = [...newState.companyProductsData];
      productsData[newState.editingProductIndex] = action.payload;

      newState['companyProductsData'] = productsData;
      break;

    case DELETE_PRODUCT:
      newState['companyProductsData'] = removeAtIndex(
        newState.companyProductsData,
        newState.editingProductIndex
      );
      break;

    default:
      break;
  }
};
