import { listEventsReducer, eventsWatcher } from './list';
import { previewEventsReducer, previewEventsWatcher } from './previewEvents';

const DELETE_STATE = 'Events/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  events: [],
  nextUrl: null,
  isFetching: true,
  previewEvents: []
};

export const eventsReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  listEventsReducer(initialState, newState, action);
  previewEventsReducer(newState, action);

  return newState;
};

export const eventsSaga = [eventsWatcher, previewEventsWatcher];
export * from './list';
export * from './previewEvents';
