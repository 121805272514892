import { serviceProviderReducer, serviceProviderSaga } from './serviceProvider';

import {
  similarServiceProvidersReducer,
  similarServiceProvidersSaga
} from './similarServiceProviders';
import { teamMembersReducer, teamMembersWatcher } from './teamMembers';

const DELETE_STATE = 'ServiceProvider/Detail/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  company: {},
  countries: [],
  isFetching: true,
  similarServiceProviders: [],
  fetchingTeamMembers: false,
  team: []
};

export const detailServiceProvidersReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === DELETE_STATE) newState = initialState;

  serviceProviderReducer(newState, action);
  similarServiceProvidersReducer(newState, action);
  teamMembersReducer(newState, action);

  return newState;
};

export const serviceProviderSagas = [
  serviceProviderSaga,
  similarServiceProvidersSaga,
  teamMembersWatcher
];

export * from './serviceProvider';
export * from './similarServiceProviders';
export * from './teamMembers';
