import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { MODALS } from 'global-constants';
import { openModal, isModalOpen } from 'shared/components/Modals/reducer';

import { initPushService, triggerPrompt, requestPermission } from './pushUtils';

import { requestDismissNotifications } from './ducks';

import PermissionPrompt from './PermissionPrompt';

class PushProvider extends React.Component {
  state = {
    modalClosed: false,
    initialized: false
  };

  closeModal = (callBack = () => {}) => {
    this.setState({ modalClosed: true }, callBack());
  };

  dismissNotifications = () => {
    this.props.requestDismissNotifications();
  };

  componentDidMount() {
    this.setState({ initialized: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.initialized && this.state.initialized)
      setTimeout(() => {
        initPushService(this.props.user);

        // Display the prompt only when it hasn't been dismissed
        // either for the current session (controlled by `this.state.modalClosed`)
        // or forever (controlled by `user.dismissed_notifications`)

        if (!this.state.modalClosed)
          if (
            !_.isNil(this.props.user) &&
            this.props.user.dismissed_notifications
          )
            return;

        triggerPrompt(() => {
          this.props.openModal({
            modalName: MODALS.NOTIFICATION_PERMISSION_PROMPT
          });
        });
      }, 44000);
  }

  render() {
    return this.props.isOpenPermissionPrompt ? (
      <PermissionPrompt
        user={this.props.user}
        onClose={this.closeModal}
        dismissNotifications={this.dismissNotifications}
        requestPermission={requestPermission}
      />
    ) : null;
  }
}

const mapStateToProps = state => ({
  user: state.me.user,
  isOpenPermissionPrompt: isModalOpen(
    state,
    MODALS.NOTIFICATION_PERMISSION_PROMPT
  )
});

const mapDispatchToProps = {
  openModal,
  requestDismissNotifications
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PushProvider);
