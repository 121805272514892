import _ from 'lodash';

const injectScript = ({
  url,
  source,
  identifier,
  clearElement = null,
  injectionTarget = 'body',
  type = 'text/javascript',
  useUrl
}) => {
  const existingInjection = document.getElementById(identifier);

  if (!_.isNil(existingInjection)) existingInjection.remove();

  if (!_.isNil(clearElement)) {
    const toClear = document.getElementById(clearElement);

    if (!_.isNil(toClear)) toClear.innerHTML = null;
  }

  let injection = document.createElement('script');
  injection.setAttribute('id', identifier);
  injection.setAttribute('type', type);

  if (useUrl) {
    injection.src = url;
  } else {
    injection.innerHTML = source;
  }

  document.getElementsByTagName(injectionTarget)[0].appendChild(injection);

  return injection;
};

export const injectScriptByUrl = ({
  url,
  identifier,
  clearElement = null,
  injectionTarget = 'body',
  type = 'text/javascript'
}) => {
  return injectScript({
    url,
    identifier,
    clearElement,
    injectionTarget,
    type,
    useUrl: true
  });
};

export const injectScriptBySource = ({
  source,
  identifier,
  clearElement = null,
  injectionTarget = 'body',
  type = 'text/javascript'
}) => {
  return injectScript({
    source,
    identifier,
    clearElement,
    injectionTarget,
    type,
    useUrl: false
  });
};
