import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { fetchMe } from 'shared/HOCs/FetchInitials/ducks/index.js';

const actionPrefix = 'Notifications/Seen/';

const MARK_AS_SEEN = `${actionPrefix}MARK_AS_SEEN`;

export const markAsSeen = ({ ids }) => ({
  type: MARK_AS_SEEN,
  payload: { ids }
});

function* markAsSeenWorker(action) {
  const { ids } = action.payload;

  const response = yield makeRequest(api.markNotificationsAsSeen, {
    requestBody: { notification_ids: ids }
  });

  if (response.success) {
    yield put(fetchMe());
  }
}

export function* markAsSeenSaga() {
  yield takeLatest(MARK_AS_SEEN, markAsSeenWorker);
}
