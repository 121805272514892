import { combineReducers } from 'redux';

import {
  detailCompaniesReducer,
  companySagas as detailSagas
} from './Detail/ducks';
import {
  acceptInvitationReducer,
  acceptInvitationSaga
} from './AcceptInvitation/ducks';
import {
  confirmClaimRequestReducer,
  confirmClaimRequestSaga
} from './ClaimRequest/Confirm/ducks';
import { editCompanyReducer, editCompanySagas } from './Edit/ducks';
import { listCompaniesReducer, companiesSagas } from './List/ducks';
import { createCompanyReducer, createCompanySaga } from './Create/ducks';
import { editProductReducer, editProductSaga } from './Product/Edit/ducks';

export const companyReducer = combineReducers({
  edit: editCompanyReducer,
  list: listCompaniesReducer,
  create: createCompanyReducer,
  detail: detailCompaniesReducer,
  acceptInvitation: acceptInvitationReducer,
  product: combineReducers({
    edit: editProductReducer
  }),
  claimRequest: confirmClaimRequestReducer
});

export const companySagas = [
  editProductSaga,
  acceptInvitationSaga,
  confirmClaimRequestSaga,
  ...detailSagas,
  ...companiesSagas,
  ...editCompanySagas,
  ...createCompanySaga
];
