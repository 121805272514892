import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'PostSignupModal/GetCompany';

const GET_COMPANY = `${actionPrefix}/GET_COMPANY`;
const SUCCESS_GET_COMPANY = `${actionPrefix}/SUCCESS_GET_COMPANY`;
const FAIL_GET_COMPANY = `${actionPrefix}/FAIL_GET_COMPANY`;

const initialState = {
  detectedCompany: null,
  isFetchingCompany: false
};

export const getCompany = () => ({ type: GET_COMPANY });

const successGetCompany = ({ data }) => ({
  type: SUCCESS_GET_COMPANY,
  payload: data
});

const failGetCompany = ({ errors }) => ({
  type: FAIL_GET_COMPANY,
  payload: errors
});

export const postSignUpModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY:
      state['isFetchingCompany'] = true;
      break;
    case SUCCESS_GET_COMPANY:
      state['isFetchingCompany'] = false;
      state['detectedCompany'] = action.payload;
      break;
    default:
      return state;
  }

  return state;
};

export function* getCompanyWorker(action) {
  const response = yield makeRequest(api.getCompanyByEmail);

  if (response.success) {
    yield put(successGetCompany(response));
  } else {
    yield put(failGetCompany(response));
  }
}

export function* postSignUpModalSaga() {
  yield takeLatest(GET_COMPANY, getCompanyWorker);
}
