import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'ForgotPassword/';

export const REQUEST_FORGOT_PASSWORD = `${actionPrefix}REQUEST_FORGOT_PASSWORD`;
export const SUCCESS_RESET_EMAIL_SENT = `${actionPrefix}SUCCESS_RESET_EMAIL_SENT`;

export const requestForgotPassword = (formName, data) => {
  return {
    type: REQUEST_FORGOT_PASSWORD,
    formName,
    payload: { data }
  };
};

const successForgotPassword = () => {
  return {
    type: SUCCESS_RESET_EMAIL_SENT
  };
};

const initialState = {
  emailSent: false
};

export const forgotPasswordReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SUCCESS_RESET_EMAIL_SENT:
      newState = {
        emailSent: true
      };
      break;

    default:
      return state;
  }

  return Object.assign({}, state, newState);
};

function* forgotPasswordWorker(action) {
  const { data } = action.payload;

  const response = yield makeRequest(api.sendResetPasswordEmail, {
    requestBody: data
  });

  if (response.success) {
    yield put(successForgotPassword());
  } else {
    return response.errors;
  }
}

export function* forgotPasswordSaga() {
  yield takeLatest(
    REQUEST_FORGOT_PASSWORD,
    formSubmitWorker(forgotPasswordWorker)
  );
}
