import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { buildFilters } from 'shared/utils/filters';

import {
  handleSuccessFetchCompanies,
  handleSuccessFetchNextCompanies
} from './utils';

const actionPrefix = 'Companies/List/';
const FETCH_COMPANIES_AND_PREFERENTIAL_COMPANIES = `${actionPrefix}FETCH_COMPANIES_AND_PREFERENTIAL_COMPANIES`;

const FETCH_COMPANIES = `${actionPrefix}FETCH_COMPANIES`;
const SUCCESS_FETCH_COMPANIES = `${actionPrefix}SUCCESS_FETCH_COMPANIES`;
const FAIL_FETCH_COMPANIES = `${actionPrefix}FAIL_FETCH_COMPANIES`;

const SUCCESS_FETCH_NEXT_COMPANIES = `${actionPrefix}SUCCESS_FETCH_NEXT_COMPANIES`;
const FETCH_NEXT_COMPANIES = `${actionPrefix}FETCH_NEXT_COMPANIES`;

const FETCH_PREFERENTIAL_COMPANIES = `${actionPrefix}FETCH_PREFERENTIAL_COMPANIES`;
const SUCCESS_FETCH_PREFERENTIAL_COMPANIES = `${actionPrefix}SUCCESS_FETCH_PREFERENTIAL_COMPANIES`;
const FAIL_FETCH_PREFERENTIAL_COMPANIES = `${actionPrefix}FAIL_FETCH_PREFERENTIAL_COMPANIES`;

const TRACK_VENDOR_IMPRESSIONS = `Companies/List/TRACK_VENDOR_IMPRESSIONS`;
const SUCCESS_TRACK_VENDOR_IMPRESSIONS = `Companies/List/SUCCESS_TRACK_VENDOR_IMPRESSIONS`;
const FAIL_TRACK_VENDOR_IMPRESSIONS = `Companies/List/FAIL_TRACK_VENDOR_IMPRESSIONS`;

export const fetchCompaniesAndPreferentialCompanies = (filters = []) => ({
  type: FETCH_COMPANIES_AND_PREFERENTIAL_COMPANIES,
  payload: {
    filters
  }
});

export const fetchCompanies = (params = {}) => ({
  type: FETCH_COMPANIES,
  payload: {
    params
  }
});

const successFetchCompanies = payload => ({
  type: SUCCESS_FETCH_COMPANIES,
  payload
});

const failFetchCompanies = payload => ({
  type: FAIL_FETCH_COMPANIES,
  payload
});

export const fetchNextCompanies = url => ({
  type: FETCH_NEXT_COMPANIES,
  url
});

const successFetchNextCompanies = payload => ({
  type: SUCCESS_FETCH_NEXT_COMPANIES,
  payload
});

const fetchPreferentialCompanies = (params = {}) => ({
  type: FETCH_PREFERENTIAL_COMPANIES,
  payload: { params }
});

const successFetchPreferentialCompanies = payload => ({
  type: SUCCESS_FETCH_PREFERENTIAL_COMPANIES,
  payload
});

export const failFetchPreferentialCompanies = payload => ({
  type: FAIL_FETCH_PREFERENTIAL_COMPANIES,
  payload
});

const trackVendorImpressions = ({ vendorIds, params, isPreferential }) => ({
  type: TRACK_VENDOR_IMPRESSIONS,
  payload: { vendorIds, params, isPreferential }
});

const successTrackVendorImpressions = data => ({
  type: SUCCESS_TRACK_VENDOR_IMPRESSIONS,
  payload: { data }
});

const failTrackVendorImpressions = errors => ({
  type: FAIL_TRACK_VENDOR_IMPRESSIONS,
  payload: { errors }
});

export const companiesReducer = (initialState, state, action) => {
  let handlerResult;

  switch (action.type) {
    case SUCCESS_FETCH_NEXT_COMPANIES:
      handlerResult = handleSuccessFetchNextCompanies({
        currentPreferentialCompanies: state.preferentialCompanies,
        currentListCompanies: state.companies,
        nextCompanies: action.payload.results
      });
      state.allCompanies = handlerResult.allCompanies;
      state.companies = handlerResult.listCompanies;
      state.preferentialCompanies = handlerResult.preferentialCompanies;
      state.nextUrl = action.payload.next;
      state.isFetching = false;

      break;
    case FETCH_COMPANIES:
    case FETCH_NEXT_COMPANIES:
      state['isFetching'] = true;
      break;
    case SUCCESS_FETCH_COMPANIES:
      handlerResult = handleSuccessFetchCompanies({
        preferentialCompanies: state.preferentialCompanies,
        listCompanies: action.payload.results
      });

      state.preferentialCompanies = handlerResult.preferentialCompanies;
      state.companies = handlerResult.listCompanies;
      state.allCompanies = handlerResult.allCompanies;
      state.companyCount = action.payload.count;
      state.nextUrl = action.payload.next;
      state.isFetching = false;

      break;

    case FETCH_PREFERENTIAL_COMPANIES:
      state['isFetchingPreferentialCompany'] = true;
      break;

    case SUCCESS_FETCH_PREFERENTIAL_COMPANIES:
      handlerResult = handleSuccessFetchCompanies({
        preferentialCompanies: action.payload.results,
        listCompanies: state.companies
      });

      state.companies = handlerResult.listCompanies;
      state.allCompanies = handlerResult.allCompanies;
      state.preferentialCompanies = handlerResult.preferentialCompanies;
      state.isFetchingPreferentialCompany = false;
      break;

    default:
      return state;
  }
};

function* fetchCompaniesWorker(action) {
  const { params } = action.payload;

  const response = yield makeRequest(api.companiesList, {
    requestData: { params }
  });

  if (response.success) {
    const { results } = response.data;

    const vendorIds = _.map(results, result => result.id);

    const hasFilters = !_.isEmpty(_.omit(params, ['ordering']));

    if (hasFilters && !_.isEmpty(vendorIds)) {
      yield put(
        trackVendorImpressions({ vendorIds, params, isPreferential: false })
      );
    }

    yield put(successFetchCompanies(response.data));
  } else {
    yield put(failFetchCompanies(response.errors));
  }
}

function* fetchPreferentialCompaniesWorker(action) {
  const { params } = action.payload;
  const preferentialVendorResponse = yield makeRequest(
    api.getPreferentialVendor,
    {
      requestData: { params }
    }
  );

  if (preferentialVendorResponse.success) {
    const { results } = preferentialVendorResponse.data;

    const vendorIds = _.map(results, result => result.id);

    const hasFilters = !_.isEmpty(_.omit(params, ['ordering']));

    if (hasFilters && !_.isEmpty(vendorIds)) {
      yield put(
        trackVendorImpressions({ vendorIds, params, isPreferential: true })
      );
    }

    yield put(
      successFetchPreferentialCompanies(preferentialVendorResponse.data)
    );
  } else {
    yield put(
      failFetchPreferentialCompanies(preferentialVendorResponse.errors)
    );
  }
}

function* fetchCompaniesAndPreferentialCompaniesWorker(action) {
  const builtFilters = buildFilters(action.payload.filters);
  let params = builtFilters;
  yield* [put(fetchCompanies(params)), put(fetchPreferentialCompanies(params))];
}

function* fetchNextCompaniesWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.url
  });

  if (response.success) {
    yield put(successFetchNextCompanies(response.data));
  } else yield put(failFetchCompanies(response.errors));
}

function* trackVendorImpressionsWorker(action) {
  const { vendorIds, params, isPreferential } = action.payload;

  const response = yield makeRequest(api.trackVendorImpressions, {
    requestData: { params },
    requestBody: { vendor_ids: vendorIds, is_preferential: isPreferential }
  });

  if (response.success) {
    yield put(successTrackVendorImpressions(response.data));
  } else {
    yield put(failTrackVendorImpressions(response.errors));
  }
}

export function* companiesWatcher() {
  yield takeLatest(
    FETCH_COMPANIES_AND_PREFERENTIAL_COMPANIES,
    fetchCompaniesAndPreferentialCompaniesWorker
  );
  yield takeLatest(FETCH_COMPANIES, fetchCompaniesWorker);
  yield takeLatest(
    FETCH_PREFERENTIAL_COMPANIES,
    fetchPreferentialCompaniesWorker
  );
  yield takeLatest(TRACK_VENDOR_IMPRESSIONS, trackVendorImpressionsWorker);

  yield takeLatest(FETCH_NEXT_COMPANIES, fetchNextCompaniesWorker);
}
