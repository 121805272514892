import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

export const TEAM_MEMBER_PREVIEW_COUNT = 3;

const actionPrefix = 'Job/Detail/Team/';

const FETCH_TEAM_PREVIEW = `${actionPrefix}FETCH_TEAM_PREVIEW`;
const SUCCESS_FETCH_TEAM_PREVIEW = `${actionPrefix}SUCCESS_FETCH_TEAM_PREVIEW`;

const FETCH_TEAM = `${actionPrefix}FETCH_TEAM`;
const SUCCESS_FETCH_TEAM = `${actionPrefix}SUCCESS_FETCH_TEAM`;

export const fetchTeamPreview = ({ companyId }) => ({
  type: FETCH_TEAM_PREVIEW,
  payload: { companyId }
});

const successFetchTeamPreview = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_TEAM_PREVIEW,
  payload: { results, next }
});

export const fetchTeam = ({ companyId, teamNextUrl }) => ({
  type: FETCH_TEAM,
  payload: { companyId, teamNextUrl }
});

const successFetchTeam = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_TEAM,
  payload: { results, next }
});

export const teamReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TEAM_PREVIEW:
      state['team']['isFetchingPreview'] = true;
      break;

    case SUCCESS_FETCH_TEAM_PREVIEW:
      state['team']['isFetchingPreview'] = false;
      state['team']['preview'] = action.payload.results;
      state['team']['hasMoreThanPreview'] = !_.isNil(action.payload.next);
      break;

    case FETCH_TEAM:
      state['team']['isFetching'] = true;
      break;

    case SUCCESS_FETCH_TEAM:
      state['team']['isFetching'] = false;
      state['team']['team'] = addToList(
        state.team.team,
        action.payload.results
      );
      state['team']['teamNextUrl'] = action.payload.next;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchTeamPreviewWorker(action) {
  const { companyId } = action.payload;

  const response = yield makeRequest(api.usersList, {
    requestData: {
      params: { vendor: companyId, limit: TEAM_MEMBER_PREVIEW_COUNT }
    }
  });

  if (response.success) {
    yield put(successFetchTeamPreview(response));
  }
}

function* fetchTeamWorker(action) {
  const { companyId, teamNextUrl } = action.payload;

  let response;

  if (_.isNil(teamNextUrl)) {
    response = yield makeRequest(api.usersList, {
      requestData: {
        params: { vendor: companyId, limit: 10 }
      }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: teamNextUrl
    });
  }

  if (response.success) {
    yield put(successFetchTeam(response));
  }
}

export function* teamSaga() {
  yield takeLatest(FETCH_TEAM_PREVIEW, fetchTeamPreviewWorker);
  yield takeLatest(FETCH_TEAM, fetchTeamWorker);
}
