import React from 'react';
import { Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import { substituteUrl } from 'shared/utils/substituteUrl';

import Loader from 'shared/components/Loader';

import {
  STYLEGUIDE_URL,
  DASHBOARD_URL,
  DASHBOARD_POST_DETAIL_URL,
  COMPANIES_URL,
  COMPANY_DETAIL_URL,
  SERVICE_PROVIDER_DETAIL_URL,
  SOLUTIONS_URL,
  HOME_URL,
  RESOURCES_URL,
  RESOURCE_DETAIL_URL,
  EDIT_COMPANY_URL,
  LINKEDIN_LOGIN_URL,
  EDIT_PRODUCT_URL,
  STATIC_PAGE_URL,
  SOLUTION_DETAIL_URL,
  INFLUENCERS_URL,
  EVENTS_URL,
  EDIT_PROFILE_URL,
  INFLUENCER_PREVIEW_URL,
  EVENT_DETAIL_URL,
  CREATE_COMPANY_URL,
  ACCEPT_INVITATION_URL,
  ACCEPT_SERVICE_PROVIDER_INVITATION_URL,
  CONFIRM_CLAIM_REQUEST_URL,
  CONFIRM_SERVICE_PROVIDER_CLAIM_REQUEST_URL,
  RESET_PASSWORD_URL,
  PAGE_NOT_FOUND_URL,
  GLOBAL_SEARCH_URL,
  ANALYTICS_URL,
  POST_EVENT_URL,
  CREATE_RESOURCE_PROPOSAL_URL,
  PREMIUM_FEATURES_URL,
  MESSAGING_URL,
  ENTERPRISE_PRODUCTS_URL,
  SERVICE_PROVIDERS_URL,
  CREATE_SERVICE_PROVIDER_URL,
  EDIT_SERVICE_PROVIDER_URL,
  SEND_PUSH_NOTIFICATION_URL,
  CREATE_TENDER_URL,
  TENDER_URL,
  RESPOND_TO_TENDER_URL,
  EDIT_TENDER_URL,
  TENDERS_LIST_URL,
  EDIT_EVENT_URL,
  ACCEPT_SPEAKER_INVITATION_URL,
  TENDER_RESPONSE_URL,
  TENDER_OVERVIEW_URL,
  INVESTORS_URL,
  INVESTOR_DETAIL_URL,
  CLOSED_TENDER_DETAIL_URL,
  TENDER_PARTNER_CREATE_TENDER_URL,
  TENDER_PARTNER_AVAILABLE_TENDERS_URL,
  TENDER_PARTNER_RESPOND_TO_TENDER_URL,
  CONFIRM_INVESTOR_CLAIM_REQUEST_URL,
  CONNECT_VIDEOS_URL,
  CONNECT_VIDEO_DETAIL_URL,
  NEWS_URL,
  CONNECT_VIDEO_ALTERNATIVE_URL,
  CONNECT_SPEAKER_STATS_URL,
  CONNECT_VIDEO_STATS_URL,
  INFLUENCER_FAQ_URL,
  PROPERTY_JOURNEY_URL,
  PROPTECH_JOURNEY_URL,
  NOTIFICATIONS_URL,
  INFLUENCER_ID_TO_SLUG_REDIRECT_URL,
  CREATE_JOB_URL,
  EDIT_JOB_URL,
  JOB_DETAIL_URL,
  JOB_LIST_URL,
  COMPANY_PREDEFINED_SEARCH_URL,
  CASE_STUDIES_URL,
  CONNECT_LOGIN_URL,
  CONNECT_SIGNUP_URL,
  MODALS,
  KNOWLEDGEBASE_URL
} from 'global-constants';

const INVALID_DOMAINS = [
  'localhost',
  'staging.unissu.com',
  'www.staging.unissu.com',
  'unissu.com',
  'www.unissu.com'
];

const Styleguide = Loadable({
  loader: () => import('pages/Styleguide'),
  loading: Loader
});

const Dashboard = Loadable({
  loader: () => import('pages/Dashboard'),
  loading: Loader
});

const Companies = Loadable({
  loader: () => import('pages/Company/List'),
  loading: Loader
});

const Company = Loadable({
  loader: () => import('pages/Company/Detail'),
  loading: Loader
});

const ServiceProvider = Loadable({
  loader: () => import('pages/ServiceProvider/Detail'),
  loading: Loader
});

const Solutions = Loadable({
  loader: () => import('pages/Solution/List'),
  loading: Loader
});

const Solution = Loadable({
  loader: () => import('pages/Solution/Detail'),
  loading: Loader
});

const Home = Loadable({
  loader: () => import('pages/Home'),
  loading: Loader
});

const PropertyJourney = Loadable({
  loader: () => import('pages/Home/subpages/PropertyJourney'),
  loading: Loader
});

const PropTechJourney = Loadable({
  loader: () => import('pages/Home/subpages/PropTechJourney'),
  loading: Loader
});

const ResourceList = Loadable({
  loader: () => import('pages/Resource/List'),
  loading: Loader
});

const ResourceDetail = Loadable({
  loader: () => import('pages/Resource/Detail'),
  loading: Loader
});

const ConnectVideoList = Loadable({
  loader: () => import('pages/ConnectVideo/List'),
  loading: Loader
});

const ConnectVideoDetail = Loadable({
  loader: () => import('pages/ConnectVideo/Detail'),
  loading: Loader
});

const ConnectSpeakerStats = Loadable({
  loader: () => import('pages/ConnectSpeakerStats'),
  loading: Loader
});

const ConnectVideoStats = Loadable({
  loader: () => import('pages/ConnectVideoStats'),
  loading: Loader
});

const ConnectLogin = Loadable({
  loader: () => import('pages/ConnectLogin'),
  loading: Loader
});

const EditCompany = Loadable({
  loader: () => import('pages/Company/Edit'),
  loading: Loader
});

const LinkedInLogin = Loadable({
  loader: () => import('pages/LinkedInLogin'),
  loading: Loader
});

const EditProduct = Loadable({
  loader: () => import('pages/Company/Product/Edit'),
  loading: Loader
});

const StaticPage = Loadable({
  loader: () => import('pages/StaticPage'),
  loading: Loader
});

const EditProfile = Loadable({
  loader: () => import('pages/EditProfile'),
  loading: Loader
});

const Events = Loadable({
  loader: () => import('pages/Event/List'),
  loading: Loader
});

const InfluencerDetail = Loadable({
  loader: () => import('pages/Influencer/Detail'),
  loading: Loader
});

const EventDetail = Loadable({
  loader: () => import('pages/Event/Detail'),
  loading: Loader
});

const CreateCompany = Loadable({
  loader: () => import('pages/Company/Create'),
  loading: Loader
});

const InfluencersList = Loadable({
  loader: () => import('pages/Influencer/List'),
  loading: Loader
});

const AcceptInvitation = Loadable({
  loader: () => import('pages/Company/AcceptInvitation'),
  loading: Loader
});

const AcceptServiceProviderInvitation = Loadable({
  loader: () => import('pages/ServiceProvider/AcceptInvitation'),
  loading: Loader
});

const ConfirmClaimRequest = Loadable({
  loader: () => import('pages/Company/ClaimRequest/Confirm'),
  loading: Loader
});

const ConfirmServiceProviderClaimRequest = Loadable({
  loader: () => import('pages/ServiceProvider/ClaimRequest/Confirm'),
  loading: Loader
});

const ResetPassword = Loadable({
  loader: () => import('pages/ResetPassword'),
  loading: Loader
});

const PageNotFound = Loadable({
  loader: () => import('pages/PageNotFound'),
  loading: Loader
});

const GlobalSearch = Loadable({
  loader: () => import('pages/GlobalSearch'),
  loading: Loader
});

const Analytics = Loadable({
  loader: () => import('pages/Analytics'),
  loading: Loader
});

const PostEvent = Loadable({
  loader: () => import('pages/Event/Create'),
  loading: Loader
});

const CreateResourceProposal = Loadable({
  loader: () => import('pages/Resource/Create'),
  loading: Loader
});

const PremiumFeatures = Loadable({
  loader: () => import('pages/PremiumFeatures'),
  loading: Loader
});

const Messaging = Loadable({
  loader: () => import('pages/Messaging'),
  loading: Loader
});

const EnterpriseProducts = Loadable({
  loader: () => import('pages/EnterpriseProducts'),
  loading: Loader
});

const ServiceProviders = Loadable({
  loader: () => import('pages/ServiceProvider/List'),
  loading: Loader
});

const CreateServiceProvider = Loadable({
  loader: () => import('pages/ServiceProvider/Create'),
  loading: Loader
});

const EditServiceProvider = Loadable({
  loader: () => import('pages/ServiceProvider/Edit'),
  loading: Loader
});

const SendNotification = Loadable({
  loader: () => import('pages/SendNotification'),
  loading: Loader
});

const CreateTender = Loadable({
  loader: () => import('pages/Tender/Create'),
  loading: Loader
});

const Tender = Loadable({
  loader: () => import('pages/Tender/Detail'),
  loading: Loader
});

const EditTender = Loadable({
  loader: () => import('pages/Tender/Edit'),
  loading: Loader
});

const TendersList = Loadable({
  loader: () => import('pages/Tender/List'),
  loading: Loader
});

const TenderCreateResponse = Loadable({
  loader: () => import('pages/Tender/CreateResponse'),
  loading: Loader
});

const EditEvent = Loadable({
  loader: () => import('pages/Event/Edit'),
  loading: Loader
});

const AcceptSpeakerInvitation = Loadable({
  loader: () => import('pages/AcceptSpeakerInvitation'),
  loading: Loader
});

const TenderResponse = Loadable({
  loader: () => import('pages/TenderResponse/Detail'),
  loading: Loader
});

const ClosedTenderDetail = Loadable({
  loader: () => import('pages/Tender/ClosedTenderDetail'),
  loading: Loader
});

const TenderOverview = Loadable({
  loader: () => import('pages/TenderOverview'),
  loading: Loader
});

const TenderPartnerCreateTender = Loadable({
  loader: () => import('pages/TenderPartner/CreateTender'),
  loading: Loader
});

const TenderPartnerAvailableTenders = Loadable({
  loader: () => import('pages/TenderPartner/AvailableTenders'),
  loading: Loader
});

const TenderPartnerRespondToTender = Loadable({
  loader: () => import('pages/TenderPartner/Respond'),
  loading: Loader
});

const InvestorList = Loadable({
  loader: () => import('pages/Investor/List'),
  loading: Loader
});

const InvestorDetail = Loadable({
  loader: () => import('pages/Investor/Detail'),
  loading: Loader
});

const InvestorConfirmClaimRequest = Loadable({
  loader: () => import('pages/Investor/ClaimRequest/Confirm'),
  loading: Loader
});

const News = Loadable({
  loader: () => import('pages/News'),
  loading: Loader
});

const KnowledgeBase = Loadable({
  loader: () => import('pages/KnowledgeBase'),
  loading: Loader
});

const InfluencerFAQ = Loadable({
  loader: () => import('pages/InfluencerFAQ'),
  loading: Loader
});

const Notifications = Loadable({
  loader: () => import('pages/Notifications'),
  loading: Loader
});

const InfluencerIdToSlugRedirect = Loadable({
  loader: () => import('pages/InfluencerIdToSlugRedirect'),
  loading: Loader
});

const CreateAJob = Loadable({
  loader: () => import('pages/Jobs/Form'),
  loading: Loader
});

const EditAJob = Loadable({
  loader: () => import('pages/Jobs/Form'),
  loading: Loader
});

const JobDetail = Loadable({
  loader: () => import('pages/Jobs/Detail'),
  loading: Loader
});

const JobList = Loadable({
  loader: () => import('pages/Jobs/List'),
  loading: Loader
});

const CompanyPredefinedSearch = Loadable({
  loader: () => import('pages/Company/PredefinedSearch'),
  loading: Loader
});

const CaseStudies = Loadable({
  loader: () => import('pages/CaseStudies'),
  loading: Loader
});

function routes() {
  const domain = window.location.hostname;

  if (INVALID_DOMAINS.includes(domain)) {
    return (
      <Switch>
        <Route exact path={STYLEGUIDE_URL} component={Styleguide} />
        <Route exact path={DASHBOARD_URL} component={Dashboard} />
        <Route
          exact
          path={DASHBOARD_POST_DETAIL_URL}
          render={props => (
            <Dashboard key={props.match.params.postId} {...props} />
          )}
        />
        <Route exact path={COMPANIES_URL} component={Companies} />
        <Route
          exact
          path={COMPANY_DETAIL_URL}
          render={props => (
            <Company key={props.match.params.companySlug} {...props} />
          )}
        />
        <Route
          exact
          path={COMPANY_PREDEFINED_SEARCH_URL}
          render={props => (
            <CompanyPredefinedSearch
              key={props.match.params.predefinedSearchSlug}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={SERVICE_PROVIDER_DETAIL_URL}
          component={ServiceProvider}
        />
        <Route exact path={SOLUTIONS_URL} component={Solutions} />
        <Route
          exact
          path={SOLUTION_DETAIL_URL}
          render={props => (
            <Solution key={props.match.params.solutionSlug} {...props} />
          )}
        />
        <Route exact path={HOME_URL} component={Home} />
        <Route exact path={PROPERTY_JOURNEY_URL} component={PropertyJourney} />
        <Route exact path={PROPTECH_JOURNEY_URL} component={PropTechJourney} />
        <Route exact path={RESOURCES_URL} component={ResourceList} />
        <Route
          exact
          path={RESOURCE_DETAIL_URL}
          render={props => (
            <ResourceDetail key={props.match.params.resourceSlug} {...props} />
          )}
        />
        <Route exact path={CONNECT_VIDEOS_URL} component={ConnectVideoList} />
        <Route
          exact
          path={CONNECT_VIDEO_DETAIL_URL}
          render={props => (
            <ConnectVideoDetail
              key={props.match.params.connectVideoSlug}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={CONNECT_VIDEO_ALTERNATIVE_URL}
          render={props => (
            <Redirect
              to={substituteUrl(CONNECT_VIDEO_DETAIL_URL, props.match.params)}
            />
          )}
        />
        <Route
          exact
          path={CONNECT_SPEAKER_STATS_URL}
          component={ConnectSpeakerStats}
        />
        <Route
          exact
          path={CONNECT_VIDEO_STATS_URL}
          component={ConnectVideoStats}
        />
        <Route
          exact
          path={CONNECT_LOGIN_URL}
          render={props => <ConnectLogin modalName={MODALS.LOGIN} {...props} />}
        />
        <Route
          exact
          path={CONNECT_SIGNUP_URL}
          component={props => (
            <ConnectLogin modalName={MODALS.SIGNUP} {...props} />
          )}
        />
        <Route path={KNOWLEDGEBASE_URL} component={KnowledgeBase} />
        <Route
          exact
          path={EDIT_COMPANY_URL}
          render={props => {
            return (
              <EditCompany key={props.match.params.companySlug} {...props} />
            );
          }}
        />
        <Route exact path={LINKEDIN_LOGIN_URL} component={LinkedInLogin} />
        <Route exact path={EDIT_PRODUCT_URL} component={EditProduct} />
        <Route
          exact
          path={INFLUENCER_ID_TO_SLUG_REDIRECT_URL}
          render={props => (
            <InfluencerIdToSlugRedirect
              key={props.match.params.influencerId}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={INFLUENCER_PREVIEW_URL}
          render={props => (
            <InfluencerDetail
              key={props.match.params.influencerSlug}
              {...props}
            />
          )}
        />
        <Route exact path={INFLUENCERS_URL} component={InfluencersList} />
        <Route exact path={EDIT_EVENT_URL} component={EditEvent} />
        <Route
          exact
          path={EVENT_DETAIL_URL}
          render={props => (
            <EventDetail key={props.match.params.eventSlug} {...props} />
          )}
        />
        <Route exact path={CREATE_COMPANY_URL} component={CreateCompany} />
        <Route exact path={EVENTS_URL} component={Events} />
        <Route exact path={EDIT_PROFILE_URL} component={EditProfile} />
        <Route
          exact
          path={ACCEPT_INVITATION_URL}
          component={AcceptInvitation}
        />
        <Route
          exact
          path={ACCEPT_SERVICE_PROVIDER_INVITATION_URL}
          component={AcceptServiceProviderInvitation}
        />
        <Route
          exact
          path={ACCEPT_SPEAKER_INVITATION_URL}
          component={AcceptSpeakerInvitation}
        />
        <Route
          exact
          path={CONFIRM_CLAIM_REQUEST_URL}
          component={ConfirmClaimRequest}
        />
        <Route
          exact
          path={CONFIRM_SERVICE_PROVIDER_CLAIM_REQUEST_URL}
          component={ConfirmServiceProviderClaimRequest}
        />
        <Route exact path={RESET_PASSWORD_URL} component={ResetPassword} />
        <Route exact path={PAGE_NOT_FOUND_URL} component={PageNotFound} />
        <Route exact path={GLOBAL_SEARCH_URL} component={GlobalSearch} />
        <Route exact path={ANALYTICS_URL} component={Analytics} />
        <Route exact path={MESSAGING_URL} component={Messaging} />
        <Route exact path={POST_EVENT_URL} component={PostEvent} />
        <Route
          exact
          path={CREATE_RESOURCE_PROPOSAL_URL}
          component={CreateResourceProposal}
        />
        <Route exact path={PREMIUM_FEATURES_URL} component={PremiumFeatures} />
        <Route
          exact
          path={ENTERPRISE_PRODUCTS_URL}
          component={EnterpriseProducts}
        />
        <Route
          exact
          path={SERVICE_PROVIDERS_URL}
          component={ServiceProviders}
        />
        <Route
          exact
          path={CREATE_SERVICE_PROVIDER_URL}
          component={CreateServiceProvider}
        />
        <Route
          exact
          path={EDIT_SERVICE_PROVIDER_URL}
          component={EditServiceProvider}
        />
        <Route
          exact
          path={SEND_PUSH_NOTIFICATION_URL}
          component={SendNotification}
        />
        <Route
          exact
          path={CREATE_TENDER_URL}
          render={props => {
            return (
              <CreateTender key={props.match.params.activeTab} {...props} />
            );
          }}
        />
        <Route exact path={TENDER_URL} component={Tender} />
        <Route
          exact
          path={CLOSED_TENDER_DETAIL_URL}
          render={props => {
            return (
              <ClosedTenderDetail
                key={props.match.params.tenderId}
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path={TENDERS_LIST_URL}
          render={props => {
            return (
              <TendersList key={props.match.params.activeTab} {...props} />
            );
          }}
        />
        <Route
          exact
          path={RESPOND_TO_TENDER_URL}
          component={TenderCreateResponse}
        />
        <Route exact path={EDIT_TENDER_URL} component={EditTender} />
        <Route exact path={TENDER_RESPONSE_URL} component={TenderResponse} />
        <Route exact path={TENDER_OVERVIEW_URL} component={TenderOverview} />
        <Route exact path={INVESTORS_URL} component={InvestorList} />
        <Route
          exact
          path={INVESTOR_DETAIL_URL}
          render={props => {
            return (
              <InvestorDetail
                key={props.match.params.investorSlug}
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path={CONFIRM_INVESTOR_CLAIM_REQUEST_URL}
          component={InvestorConfirmClaimRequest}
        />
        <Route exact path={NEWS_URL} component={News} />
        <Route exact path={INFLUENCER_FAQ_URL} component={InfluencerFAQ} />
        <Route exact path={NOTIFICATIONS_URL} component={Notifications} />
        <Route exact path={CREATE_JOB_URL} component={CreateAJob} />
        <Route
          exact
          path={EDIT_JOB_URL}
          render={props => (
            <EditAJob
              key={props.match.params.jobSlug}
              {...props}
              isEdit={true}
            />
          )}
        />
        <Route
          exact
          path={JOB_DETAIL_URL}
          render={props => (
            <JobDetail key={props.match.params.jobSlug} {...props} />
          )}
        />
        <Route exact path={JOB_LIST_URL} component={JobList} />
        <Route exact path={CASE_STUDIES_URL} component={CaseStudies} />

        {/* Must be at the bottom */}
        <Route
          exact
          path={STATIC_PAGE_URL}
          render={props => (
            <StaticPage key={props.match.params.pageSlug} {...props} />
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route
          exact
          path={TENDER_PARTNER_CREATE_TENDER_URL}
          component={TenderPartnerCreateTender}
        />

        <Route
          exact
          path={TENDER_PARTNER_AVAILABLE_TENDERS_URL}
          render={props => {
            return (
              <TenderPartnerAvailableTenders
                key={props.match.params.activeTab}
                {...props}
              />
            );
          }}
        />

        <Route
          exact
          path={TENDER_PARTNER_RESPOND_TO_TENDER_URL}
          component={TenderPartnerRespondToTender}
        />

        <Route exact path="" component={TenderPartnerCreateTender} />

        {/* Must be at the bottom */}
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default routes;
