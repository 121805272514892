import {
  companiesReducer,
  featuredCompaniesWatcher
} from './featuredCompanies';
import { resourcesReducer, resourcesSaga } from './resources';
import { influencersReducer, influencersSaga } from './influencers';
import { bookATourSaga } from './bookATour';

const initialState = {
  companies: [],
  fetchingCompanies: false,
  resources: [],
  influencers: [],
  fetchingInfluencers: false,
  fetchingResources: false
};

export const homePageReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  companiesReducer(newState, action);
  resourcesReducer(newState, action);
  influencersReducer(newState, action);

  return newState;
};

export const homeSagas = [
  featuredCompaniesWatcher,
  resourcesSaga,
  influencersSaga,
  bookATourSaga
];

export * from './featuredCompanies';
export * from './resources';
export * from './influencers';
export * from './bookATour';
