import { combineReducers } from 'redux';

import {
  tenderResponseReducer as tenderResponseDetailReducer,
  tenderResponseSaga as tenderResponseDetailSaga
} from './Detail/ducks';

export const tenderResponseReducer = combineReducers({
  detail: tenderResponseDetailReducer
});

export const tenderResponseSagas = [...tenderResponseDetailSaga];
