import { put, takeLatest, select } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

import { getServiceProviderContactData } from './selectors';
const actionPrefix = 'EditServiceProvider/';

const UPDATE_CONTACT = `${actionPrefix}UPDATE_CONTACT`;
const FAIL_UPDATE_CONTACT = `${actionPrefix}FAIL_UPDATE_CONTACT`;
const SUCCESS_UPDATE_CONTACT = `${actionPrefix}SUCCESS_UPDATE_CONTACT`;

export const updateContact = (formName, slug) => ({
  type: UPDATE_CONTACT,
  formName,
  slug
});

const failUpdateContact = payload => ({
  type: FAIL_UPDATE_CONTACT,
  payload
});

const successUpdateContact = payload => ({
  type: SUCCESS_UPDATE_CONTACT,
  payload
});

function* updateContactWorker(action) {
  const serviceProviderContactData = yield select(
    getServiceProviderContactData
  );
  const response = yield makeRequest(api.updateServiceProviderContact, {
    lookupData: { slug: action.slug },
    requestBody: serviceProviderContactData
  });

  if (response.success) {
    yield put(successUpdateContact(response.data));
    yield put(openNotificationBar('Contact data was updated successfully'));
  } else {
    yield put(failUpdateContact(response.errors));

    return response.errors;
  }
}

export function* updateContactSaga() {
  yield takeLatest(UPDATE_CONTACT, formSubmitWorker(updateContactWorker));
}
