import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Solution/Detail/';

const LIST_CTAS = `${actionPrefix}LIST_CTAS`;
const SUCCESS_LIST_CTAS = `${actionPrefix}SUCCESS_LIST_CTAS`;

const FETCH_SOLUTION = `${actionPrefix}FETCH_SOLUTION`;
const SUCCESS_FETCH_SOLUTION = `${actionPrefix}SUCCESS_FETCH_SOLUTION`;
const FAIL_FETCH_SOLUTION = `${actionPrefix}FAIL_FETCH_SOLUTION`;

const FOLLOW_SOLUTION = `${actionPrefix}FOLLOW_SOLUTION`;
const SUCCESS_FOLLOW_SOLUTION = `${actionPrefix}SUCCESS_FOLLOW_SOLUTION`;
const FAIL_FOLLOW_SOLUTION = `${actionPrefix}FAIL_FOLLOW_SOLUTION`;

const UNFOLLOW_SOLUTION = `${actionPrefix}UNFOLLOW_SOLUTION`;
const SUCCESS_UNFOLLOW_SOLUTION = `${actionPrefix}SUCCESS_UNFOLLOW_SOLUTION`;
const FAIL_UNFOLLOW_SOLUTION = `${actionPrefix}FAIL_UNFOLLOW_SOLUTION`;

const DELETE_STATE = 'Solution/Detail/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

export const listCTAs = extra => ({
  type: LIST_CTAS,
  extra
});

const successListCTAs = payload => ({
  type: SUCCESS_LIST_CTAS,
  payload
});

export const fetchSolution = (slug, extra = []) => ({
  type: FETCH_SOLUTION,
  payload: {
    slug
  },
  extra
});

const successFetchSolutionData = payload => ({
  type: SUCCESS_FETCH_SOLUTION,
  payload
});

const failFetchSolutionData = payload => ({
  type: FAIL_FETCH_SOLUTION,
  payload
});

export const followSolution = (slug, extra = []) => ({
  type: FOLLOW_SOLUTION,
  payload: {
    slug
  },
  extra
});

const successFollowSolution = () => ({
  type: SUCCESS_FOLLOW_SOLUTION
});

const failFollowSolution = payload => ({
  type: FAIL_FOLLOW_SOLUTION,
  payload
});

export const unFollowSolution = (slug, extra = []) => ({
  type: UNFOLLOW_SOLUTION,
  payload: {
    slug
  },
  extra
});

const successUnFollowSolution = () => ({
  type: SUCCESS_UNFOLLOW_SOLUTION
});

const failUnFollowSolution = payload => ({
  type: FAIL_UNFOLLOW_SOLUTION,
  payload
});

const initialState = {
  solution: {},
  isFetching: true,
  CTAs: []
};

export const solutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_STATE:
      return { ...initialState };

    case SUCCESS_FETCH_SOLUTION:
      return {
        ...state,
        solution: action.payload,
        isFetching: false
      };

    case FOLLOW_SOLUTION:
      return {
        ...state,
        performingFollowOperation: true
      };

    case UNFOLLOW_SOLUTION:
      return {
        ...state,
        performingFollowOperation: true
      };

    case SUCCESS_FOLLOW_SOLUTION:
      return {
        ...state,
        solution: { ...state.solution, followed: true },
        performingFollowOperation: false
      };

    case SUCCESS_UNFOLLOW_SOLUTION:
      return {
        ...state,
        solution: { ...state.solution, followed: false },
        performingFollowOperation: false
      };

    case SUCCESS_LIST_CTAS:
      return {
        ...state,
        CTAs: action.payload
      };

    default:
      return state;
  }
};

function* fetchSolutionWorker(action) {
  const lookupData = {
    slug: action.payload.slug
  };

  const response = yield getResponseOr404(api.solutionDetail, { lookupData });

  if (response.success) {
    yield put(successFetchSolutionData(response.data));
    yield* [...action.extra.map(action => action())];
  } else yield put(failFetchSolutionData(response.errors));
}

function* followSolutionWorker(action) {
  const lookupData = {
    slug: action.payload.slug
  };
  const response = yield makeRequest(api.followSolution, { lookupData });

  if (response.success)
    yield* [
      put(successFollowSolution()),
      ...action.extra.map(action => action())
    ];
  else yield put(failFollowSolution(response.errors));
}

function* unfollowSolutionWorker(action) {
  const lookupData = { slug: action.payload.slug };
  const response = yield makeRequest(api.unfollowSolution, { lookupData });

  if (response.success)
    yield* [
      put(successUnFollowSolution()),
      ...action.extra.map(action => action())
    ];
  else yield put(failUnFollowSolution(response.errors));
}

function* fetchCTAs(action) {
  const response = yield makeRequest(api.CTAsList);

  if (response.success) {
    yield put(successListCTAs(response.data));
    yield* [...action.extra.map(action => action())];
  }
}

export function* solutionSaga() {
  yield takeLatest(LIST_CTAS, fetchCTAs);
  yield takeLatest(FETCH_SOLUTION, fetchSolutionWorker);
  yield takeLatest(FOLLOW_SOLUTION, followSolutionWorker);
  yield takeLatest(UNFOLLOW_SOLUTION, unfollowSolutionWorker);
}
