import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { buildFilters } from 'shared/utils/filters';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

import { prepareLanguageOptions } from './utils';

const actionPrefix = 'News/';

const FETCH_NEWS = `${actionPrefix}FETCH_NEWS`;
const SUCCESS_FETCH_NEWS = `${actionPrefix}SUCCESS_FETCH_NEWS`;
const FAIL_FETCH_NEWS = `${actionPrefix}FAIL_FETCH_NEWS`;

const TOGGLE_NEWS_ITEM = `${actionPrefix}TOGGLE_NEWS_ITEM`;
const SUCCESS_TOGGLE_NEWS_ITEM = `${actionPrefix}SUCCESS_TOGGLE_NEWS_ITEM`;
const FAIL_TOGGLE_NEWS_ITEM = `${actionPrefix}FAIL_TOGGLE_NEWS_ITEM`;

const FETCH_NEWS_LANGUAGES = `${actionPrefix}FETCH_NEWS_LANGUAGES`;
const SUCCESS_FETCH_NEWS_LANGUAGES = `${actionPrefix}SUCCESS_FETCH_NEWS_LANGUAGES`;
const FAIL_FETCH_NEWS_LANGUAGES = `${actionPrefix}FAIL_FETCH_NEWS_LANGUAGES`;

const ADD_NEWS = `${actionPrefix}ADD_NEWS`;

const PAGE_SIZE = 30;
const FEATURED_NEWS_COUNT = 4;

const initialState = {
  news: [],
  featuredNews: [],
  newsCount: 0,
  isFetchingNews: false,
  languages: []
};

export const fetchNews = (filters, { currentPage = 1 }) => ({
  type: FETCH_NEWS,
  payload: { filters, currentPage }
});

const successFetchNews = ({ data: { results, count } }) => ({
  type: SUCCESS_FETCH_NEWS,
  payload: { results, count }
});

const failFetchNews = ({ errors }) => ({
  type: FAIL_FETCH_NEWS,
  payload: { errors }
});

export const toggleNewsItem = ({ newsItemId }) => ({
  type: TOGGLE_NEWS_ITEM,
  payload: {
    newsItemId
  }
});

const successToggleNewsItem = ({ newsItemId }) => ({
  type: SUCCESS_TOGGLE_NEWS_ITEM,
  payload: {
    newsItemId
  }
});

const failToggleNewsItem = ({ errors }) => ({
  type: FAIL_TOGGLE_NEWS_ITEM,
  payload: {
    errors
  }
});

export const fetchNewsLanguages = () => ({
  type: FETCH_NEWS_LANGUAGES
});

const successFetchNewsLanguages = ({ data: { results } }) => ({
  type: SUCCESS_FETCH_NEWS_LANGUAGES,
  payload: { results }
});

const failFetchNewsLanguages = ({ errors }) => ({
  type: FAIL_FETCH_NEWS_LANGUAGES,
  payload: { errors }
});

export const addNewsItem = ({ data, formName, extraActions }) => ({
  type: ADD_NEWS,
  formName,
  payload: {
    data,
    extraActions
  }
});

export const newsReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case FETCH_NEWS:
      newState['news'] = [];
      newState['featuredNews'] = [];
      newState['isFetchingNews'] = true;
      break;

    case SUCCESS_FETCH_NEWS:
      const { results, count } = action.payload;

      newState['isFetchingNews'] = false;
      newState['pageCount'] = Math.ceil(count / PAGE_SIZE);

      newState['news'] = _.slice(results, FEATURED_NEWS_COUNT);
      newState['featuredNews'] = _.take(results, FEATURED_NEWS_COUNT);
      break;

    case SUCCESS_TOGGLE_NEWS_ITEM:
      newState['news'] = newState['news'].filter(
        newsItem => newsItem.id !== action.payload.newsItemId
      );
      newState['featuredNews'] = newState['featuredNews'].filter(
        newsItem => newsItem.id !== action.payload.newsItemId
      );
      break;

    case SUCCESS_FETCH_NEWS_LANGUAGES:
      const languages = prepareLanguageOptions({
        languages: action.payload.results
      });

      newState['languages'] = languages;
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchNewsWorker(action) {
  const { filters, currentPage } = action.payload;

  const builtFilters = buildFilters(filters);

  const params = {
    ...builtFilters,
    limit: PAGE_SIZE,
    offset: PAGE_SIZE * (currentPage - 1)
  };

  const response = yield makeRequest(api.newsList, {
    requestData: { params }
  });

  if (response.success) {
    yield put(successFetchNews(response));
  } else {
    yield put(failFetchNews(response));
  }
}

function* toggleNewsItemWorker(action) {
  const { newsItemId } = action.payload;

  const response = yield makeRequest(api.newsItemToggle, {
    lookupData: { newsItemId }
  });

  if (response.success) {
    yield put(successToggleNewsItem({ newsItemId }));
  } else {
    yield put(failToggleNewsItem(response));
  }
}

function* fetchNewsLanguagesWorker(action) {
  const response = yield makeRequest(api.newsLanguageList);

  if (response.success) {
    yield put(successFetchNewsLanguages(response));
  } else {
    yield put(failFetchNewsLanguages(response));
  }
}

function* addNewsItemWorker(action) {
  const { data, extraActions } = action.payload;

  const response = yield makeRequest(api.addProptechNews, {
    requestBody: data
  });

  if (response.success) {
    const notificationContent = 'News will be available shortly.';

    extraActions.map(action => action());
    yield put(openNotificationBar(notificationContent));
  } else {
    return response.errors;
  }
}

export function* newsSaga() {
  yield takeLatest(FETCH_NEWS, fetchNewsWorker);
  yield takeLatest(TOGGLE_NEWS_ITEM, toggleNewsItemWorker);
  yield takeLatest(FETCH_NEWS_LANGUAGES, fetchNewsLanguagesWorker);
  yield takeLatest(ADD_NEWS, formSubmitWorker(addNewsItemWorker));
}
