import _ from 'lodash';

export const getEmptyMessagingTitle = missingFollowedInfluencersCount => {
  if (missingFollowedInfluencersCount === 0)
    return 'Here are some people you follow, why not start a conversation?';
  else if (missingFollowedInfluencersCount === 6)
    return 'Why not start a conversation with some of our top influencers?';

  return 'Why not start a conversation with someone? Here are some suggestions';
};

export const addLatestMessageToConversations = ({
  conversations,
  latestMessages
}) =>
  conversations.map(conversation => ({
    ...conversation,
    ..._.find(latestMessages, message => message.id === conversation.id)
  }));
