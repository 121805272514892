import { getFormValues } from 'redux-form';

import { SERVICE_PROVIDER_CASE_STUDY_FORM } from 'shared/components/ServiceProviderForm/ducks';

export const getServiceProviderCaseStudyData = state => {
  const formContactData = getFormValues(SERVICE_PROVIDER_CASE_STUDY_FORM)(
    state
  );

  return { ...formContactData };
};
