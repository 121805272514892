import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'StaticPage/';

const FETCH_STATIC_PAGE = `${actionPrefix}FETCH_STATIC_PAGE`;
const SUCCESS_FETCH_STATIC_PAGE = `${actionPrefix}SUCCESS_FETCH_STATIC_PAGE`;
const FAIL_FETCH_STATIC_PAGE = `${actionPrefix}FAIL_FETCH_STATIC_PAGE`;

export const fetchStaticPage = ({ pageSlug }) => ({
  type: FETCH_STATIC_PAGE,
  payload: {
    pageSlug
  }
});

const successFetchStaticPage = payload => ({
  type: SUCCESS_FETCH_STATIC_PAGE,
  payload
});

const failFetchStaticPage = payload => ({
  type: FAIL_FETCH_STATIC_PAGE,
  payload
});

const initialState = {
  pageData: {}
};

export const staticPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_STATIC_PAGE:
      return {
        ...state,
        pageData: action.payload
      };

    default:
      return state;
  }
};

export function* fetchStaticPageWorker(action) {
  const response = yield getResponseOr404(api.staticPageDetail, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFetchStaticPage(response.data));
  else yield put(failFetchStaticPage(response.errors));
}

export function* staticPageSaga() {
  yield takeLatest(FETCH_STATIC_PAGE, fetchStaticPageWorker);
}
