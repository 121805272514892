import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'ServiceProviderForm/';

const FETCH_COUNTRIES = `${actionPrefix}FETCH_COUNTRIES`;
const SUCCESS_FETCH_COUNTRIES = `${actionPrefix}SUCCESS_FETCH_COUNTRIES`;
const FAIL_FETCH_COUNTRIES = `${actionPrefix}FAIL_FETCH_COUNTRIES`;

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES
});

export const successFetchCountries = payload => ({
  type: SUCCESS_FETCH_COUNTRIES,
  payload
});

export const failFetchCountries = payload => ({
  type: FAIL_FETCH_COUNTRIES,
  payload
});

export const countriesReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COUNTRIES:
      state['countries'] = action.payload.results;
      break;

    default:
      return state;
  }
};

function* fetchCountriesWorker(action) {
  const response = yield makeRequest(api.countriesList, {
    requestData: {
      params: {
        limit: 500,
        offset: 0,
        ordering: 1
      }
    }
  });

  if (response.success) yield put(successFetchCountries(response.data));
  else yield put(failFetchCountries(response.errors));
}

export function* countriesSaga() {
  yield takeLatest(FETCH_COUNTRIES, fetchCountriesWorker);
}
