import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import { eventReducer } from 'pages/Event/';
import { companyReducer } from 'pages/Company/';
import { resourceReducer } from 'pages/Resource/';
import { solutionReducer } from 'pages/Solution/';
import { investorReducer } from 'pages/Investor/';
import { connectVideoReducer } from 'pages/ConnectVideo/';

import { serviceProviderReducer } from 'pages/ServiceProvider';
import { serviceProviderFormReducer } from 'shared/components/ServiceProviderForm/ducks';

import { influencerReducer } from 'pages/Influencer/';

import { tenderReducer } from 'pages/Tender/';
import { tenderResponseReducer } from 'pages/TenderResponse/';
import { shareTenderModalReducer } from 'shared/components/ShareTenderModal/ducks';

import { tenderReducer as tenderWidgetReducer } from 'shared/components/TenderWidget/ducks';
import { availableTendersSectionReducer } from 'shared/components/AvailableTendersSection/ducks';

import { newsReducer } from 'pages/News/ducks';
import { homePageReducer } from 'pages/Home/ducks';
import { dashboardReducer } from 'pages/Dashboard/ducks';
import { modalReducer } from 'shared/components/Modals/reducer';
import { fetchMeReducer } from 'shared/HOCs/FetchInitials/ducks';
import { fetchVendorCountReducer } from 'shared/HOCs/WithVendorCount/ducks';
import { onboardingReducer } from 'shared/components/Signup/ducks/';
import { constantsReducer } from 'shared/HOCs/FetchInitials/ducks/constants';
import { staticPageReducer } from 'pages/StaticPage/ducks';
import { editProfileReducer } from 'pages/EditProfile/ducks';
import { notificationBarReducer } from 'shared/components/NotificationBar/ducks';
import { forgotPasswordReducer } from 'shared/components/ForgotPasswordModal/ducks';
import { resetPasswordReducer } from 'pages/ResetPassword/ducks';
import { filtersReducer } from 'shared/components/Filters/ducks';
import { changePlanReducer } from 'shared/components/ChangePlanModal/ducks';
import { globalSearchReducer } from 'pages/GlobalSearch/ducks';
import { productFormReducer } from 'shared/components/ProductFormFields/ducks';
import { messagingReducer } from 'pages/Messaging/ducks';
import { userOpinionsReducer } from 'shared/components/UsersOpinion/ducks';
import { inviteTeamMembersReducer } from 'shared/components/InviteTeamMembersModal/ducks';
import { holisticsReducer } from 'shared/components/HolisticsEmbed/ducks';
import { fetchTenderPartnerReducer } from 'shared/HOCs/WithTenderPartner/ducks';
import { fetchTenderPartnerTendersReducer } from 'pages/TenderPartner/AvailableTenders/ducks';
import { postSignUpModalReducer } from 'shared/components/PostSignUpModal/ducks';
import { maturityMatrixReducer } from 'shared/components/MaturityMatrix/ducks';
import { rankDifferenceWidgetReducer } from 'shared/components/RankDifferenceWidget/ducks';
import { positionFieldReducer } from 'shared/forms/PositionField/ducks';
import { connectButtonReducer } from 'shared/components/ConnectButton/ducks';
import { notificationReducer } from 'pages/Notifications/ducks';
import { countryFieldReducer } from 'shared/forms/CountryField/ducks';
import { newsLanguageFieldReducer } from 'shared/forms/NewsLanguageField/ducks';
import { cityFieldReducer } from 'shared/forms/CityField/ducks';
import { jobReducer } from 'pages/Jobs';
import { featuredJobsSectionReducer } from 'shared/components/FeaturedJobsSection/ducks';
import { companyPredefinedSearchReducer } from 'pages/Company/PredefinedSearch/ducks';
import { caseStudiesReducer } from 'pages/CaseStudies/ducks';
import { websocketsReducer } from 'shared/utils/pusher/ducks';
import { userPreferencesWidgetReducer } from 'shared/components/UserPreferencesWidget/ducks';

const appReducer = combineReducers({
  routing: routerReducer,
  form: formReducer,
  pages: combineReducers({
    news: newsReducer,
    event: eventReducer,
    tender: tenderReducer,
    company: companyReducer,
    solution: solutionReducer,
    resource: resourceReducer,
    influencer: influencerReducer,
    serviceProvider: serviceProviderReducer,
    dashboard: dashboardReducer,
    home: homePageReducer,
    staticPage: staticPageReducer,
    editProfile: editProfileReducer,
    resetPassword: resetPasswordReducer,
    globalSearch: globalSearchReducer,
    messaging: messagingReducer,
    tenderResponse: tenderResponseReducer,
    investor: investorReducer,
    tenderPartner: fetchTenderPartnerReducer,
    tenderPartnerTenders: fetchTenderPartnerTendersReducer,
    connectVideo: connectVideoReducer,
    notifications: notificationReducer,
    jobs: jobReducer,
    companyPredefinedSearch: companyPredefinedSearchReducer,
    caseStudies: caseStudiesReducer,
    websockets: websocketsReducer
  }),
  shared: combineReducers({
    userPreferencesWidget: userPreferencesWidgetReducer,
    tenderWidget: tenderWidgetReducer,
    productForm: productFormReducer,
    serviceProviderForm: serviceProviderFormReducer,
    inviteTeamMembers: inviteTeamMembersReducer,
    postSignUpModal: postSignUpModalReducer,
    maturityMatrix: maturityMatrixReducer,
    positionField: positionFieldReducer,
    availableTendersSection: availableTendersSectionReducer,
    connectButton: connectButtonReducer,
    rankDifferenceWidget: rankDifferenceWidgetReducer,
    countryField: countryFieldReducer,
    newsLanguageField: newsLanguageFieldReducer,
    cityField: cityFieldReducer,
    featuredJobsSection: featuredJobsSectionReducer,
    shareTenderModal: shareTenderModalReducer
  }),
  signup: onboardingReducer,
  constants: constantsReducer,
  me: fetchMeReducer,
  vendorCount: fetchVendorCountReducer,
  modal: modalReducer,
  notificationBar: notificationBarReducer,
  forgotPassword: forgotPasswordReducer,
  changePlan: changePlanReducer,
  filters: filtersReducer,
  userOpinions: userOpinionsReducer,
  holistics: holisticsReducer
});

export default appReducer;
