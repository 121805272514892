import { push } from 'react-router-redux';
import { put, takeLatest } from 'redux-saga/effects';
import history from 'config/history';

import {
  DASHBOARD_URL,
  PROPERTY_JOURNEY_URL,
  PROPTECH_JOURNEY_URL,
  HOME_URL
} from 'global-constants';

import { buildUrlFromNextRedirect } from 'shared/utils/buildUrlFromNext';
import { setAuthorization } from 'shared/utils/setAuthorization';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { api, makeRequest } from 'shared/sdk';
import { fetchMe } from 'shared/HOCs/FetchInitials/ducks';

import { BASE_URL } from 'global-constants';

const actionPrefix = 'Login/';
export const REQUEST_LOGIN = `${actionPrefix}REQUEST_LOGIN`;
export const SUCCESS_LOGIN = `${actionPrefix}SUCCESS_LOGIN`;
export const FAIL_LOGIN = `${actionPrefix}FAIL_LOGIN`;

export const REQUEST_LOGIN_URL = BASE_URL + '/users/login/';

export const requestLogin = (
  formName,
  { email, password },
  extraActions = []
) => ({
  type: REQUEST_LOGIN,
  formName,
  payload: { email, password },
  extraActions
});

export function* loginWorker(action) {
  const response = yield makeRequest(api.login, {
    requestBody: action.payload
  });

  if (response.success) {
    setAuthorization(response.data.token);

    let extraActions = [];
    const nextURl = buildUrlFromNextRedirect(history.location.search);

    if (nextURl) {
      extraActions = [() => push(nextURl)];
    } else {
      const currentUrl = history.location.pathname;

      const urlsThatRedirectToDashboard = [
        PROPERTY_JOURNEY_URL,
        PROPTECH_JOURNEY_URL,
        HOME_URL
      ];

      if (urlsThatRedirectToDashboard.includes(currentUrl)) {
        extraActions = [() => push(DASHBOARD_URL)];
      }
    }

    yield put(fetchMe(extraActions));

    yield* action.extraActions.map(extraAction => put(extraAction()));
  } else return response.errors;
}

function* loginSaga() {
  yield takeLatest(REQUEST_LOGIN, formSubmitWorker(loginWorker));
}

export default loginSaga;
