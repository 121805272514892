import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { api, makeRequest } from 'shared/sdk';
import { COMPANY_DETAIL_URL } from 'global-constants';
import { substituteUrl } from 'shared/utils/substituteUrl';

const actionPrefix = 'AcceptInvitation/';

const REQUEST_ACCEPT_INVITATION = `${actionPrefix}REQUEST_ACCEPT_INVITATION`;
const SUCCESS_ACCEPT_INVITATION = `${actionPrefix}SUCCESS_ACCEPT_INVITATION`;
const FAIL_ACCEPT_INVITATION = `${actionPrefix}FAIL_ACCEPT_INVITATION`;

export const requestAcceptInvitation = invitationId => ({
  type: REQUEST_ACCEPT_INVITATION,
  payload: { invitationId }
});

const successAcceptInvitation = data => ({
  type: SUCCESS_ACCEPT_INVITATION,
  payload: data
});

const failAcceptInvitation = errors => ({
  type: FAIL_ACCEPT_INVITATION,
  payload: errors
});

const initialState = {
  loading: true,
  failed: false,
  errors: null
};

export const acceptInvitationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAIL_ACCEPT_INVITATION:
      return {
        ...state,
        loading: false,
        failed: true,
        errors: action.payload
      };
    default:
      return state;
  }
};

function* requestAcceptInvitationWorker(action) {
  const response = yield makeRequest(api.acceptInvitation, {
    lookupData: { invitationIdentifier: action.payload.invitationId }
  });

  if (response.success) {
    yield* [
      put(successAcceptInvitation(response.data)),
      put(
        push(
          substituteUrl(COMPANY_DETAIL_URL, {
            companySlug: response.data.vendor_slug
          })
        )
      )
    ];
  } else yield put(failAcceptInvitation(response.errors));
}

export function* acceptInvitationSaga() {
  yield takeLatest(REQUEST_ACCEPT_INVITATION, requestAcceptInvitationWorker);
}
