import { PusherManager } from 'shared/utils/pusher';

const logout = (shoudlRefresh = true) => {
  localStorage.removeItem('token');
  // Only logged users have an open connection to the Pusher server.
  PusherManager.disconnect();
  // refresh the page to refresh the store
  if (shoudlRefresh) window.location.reload();
};

export default logout;
