export const generateProgressTabData = (tabs, selectedTabNumeration) => {
  return tabs.map(tab => {
    if (selectedTabNumeration > tab.numeration) {
      return { ...tab, completed: true };
    }

    if (selectedTabNumeration === tab.numeration) {
      return { ...tab, completed: false, marked: true };
    }

    if (selectedTabNumeration < tab.numeration) {
      return { ...tab, marked: false };
    }

    return tab;
  });
};
