// TODO: Split into different folders
import { put, takeLatest } from 'redux-saga/effects';
import { stopSubmit, setSubmitSucceeded } from 'redux-form';

import { MODALS } from 'global-constants';
import { openModal, closeModal } from 'shared/components/Modals/reducer';
import { api, makeRequest } from 'shared/sdk';
import { fetchMe } from 'shared/HOCs/FetchInitials/ducks/index.js';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { normalizePrice } from './utils';

const actionPrefix = 'ChangePlan/';

export const PAYMENT_STEPS = {
  SELECT_PLAN: 1,
  PROFILE_FORM: 2,
  MAKE_PAYMENT: 3
};

const SET_PAYMENT_STEP = `${actionPrefix}SET_PAYMENT_STEP`;
const REQUEST_CHANGE_PLAN = `${actionPrefix}REQUEST_CHANGE_PLAN`;
const SUCCESS_CHANGE_PLAN = `${actionPrefix}SUCCESS_CHANGE_PLAN`;
const FAIL_CHANGE_PLAN = `${actionPrefix}FAIL_CHANGE_PLAN`;
const RESET = `${actionPrefix}RESET`;
const REQUEST_UPDATE_BILLING_INFO = `${actionPrefix}REQUEST_UPDATE_BILLING_INFO`;
const SUCCESS_UPDATE_BILLING_INFO = `${actionPrefix}SUCCESS_UPDATE_BILLING_INFO`;
const FAIL_UPDATE_BILLING_INFO = `${actionPrefix}FAIL_UPDATE_BILLING_INFO`;
const FETCH_PLAN_PRICES = `${actionPrefix}FETCH_PLAN_PRICES`;
const SUCCESS_FETCH_PLAN_PRICES = `${actionPrefix}SUCCESS_FETCH_PLAN_PRICES`;
const FAIL_FETCH_PLAN_PRICES = `${actionPrefix}FAIL_FETCH_PLAN_PRICES`;

export const PAYMENT_FORM_NAME = 'subscriptionPaymentForm';

export const requestChangePlan = (
  { token = null, name = null, address = null, coupon = null } = {},
  extraActions = []
) => ({
  type: REQUEST_CHANGE_PLAN,
  payload: {
    token,
    name,
    address,
    coupon,
    extraActions
  }
});

export const successChangePlan = payload => ({
  type: SUCCESS_CHANGE_PLAN,
  payload
});

export const failChangePlan = payload => ({
  type: FAIL_CHANGE_PLAN,
  payload
});

export const setPaymentStep = step => ({
  type: SET_PAYMENT_STEP,
  payload: { step }
});

export const resetState = () => ({
  type: RESET
});

export const requestUpdateBillingInfo = (payload, formName) => ({
  type: REQUEST_UPDATE_BILLING_INFO,
  payload,
  formName
});

const successUpdateBillingInfo = payload => ({
  type: SUCCESS_UPDATE_BILLING_INFO,
  payload
});

const failUpdateBillingInfo = payload => ({
  type: FAIL_UPDATE_BILLING_INFO,
  payload
});

export const fetchPlanPrices = () => ({
  type: FETCH_PLAN_PRICES
});

export const successFetchPlanPrices = payload => ({
  type: SUCCESS_FETCH_PLAN_PRICES,
  payload
});

export const failFetchPlanPrices = payload => ({
  type: FAIL_FETCH_PLAN_PRICES,
  payload
});

const initialState = {
  paymentStep: PAYMENT_STEPS.SELECT_PLAN,
  planType: null,
  processingSubscription: false,
  isFetchingPrices: true,
  premiumPlanPrice: null
};

export const changePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CHANGE_PLAN:
      return {
        ...state,
        processingSubscription: true
      };

    case SUCCESS_CHANGE_PLAN:
      return {
        ...state,
        processingSubscription: false
      };

    case FAIL_CHANGE_PLAN:
      return {
        ...state,
        processingSubscription: false
      };

    case SET_PAYMENT_STEP:
      return {
        ...state,
        paymentStep: action.payload.step
      };

    case FETCH_PLAN_PRICES:
      return {
        ...state,
        isFetchingPrices: true
      };

    case SUCCESS_FETCH_PLAN_PRICES:
      return {
        ...state,
        isFetchingPrices: false,
        premiumPlanPrice: action.payload
      };

    case FAIL_FETCH_PLAN_PRICES:
      return {
        ...state,
        isFetchingPrices: false
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
};

function* changePlanWorker(action) {
  const { token, coupon, extraActions } = action.payload;

  const response = yield makeRequest(api.subscribeToPlan, {
    requestBody: { card_token: token, coupon }
  });

  if (response.success) {
    yield* [
      put(successChangePlan(response.data)),
      put(setSubmitSucceeded(PAYMENT_FORM_NAME)),
      put(closeModal(MODALS.CHANGE_PLAN)),
      put(resetState()),
      put(
        openModal({
          modalName: MODALS.SUCCESS_CHANGE_PLAN
        })
      ),
      put(fetchMe())
    ];
    yield* extraActions.map(action => action());
  } else {
    yield* [
      put(failChangePlan(response.errors)),
      put(stopSubmit(PAYMENT_FORM_NAME, response.errors))
    ];
    return response.errors;
  }
}

function* updateBillingInfoWorker(action) {
  const response = yield makeRequest(api.updateBillingInfo, {
    requestBody: action.payload
  });

  if (response.success)
    yield* [
      put(successUpdateBillingInfo(response.data)),
      put(setPaymentStep(PAYMENT_STEPS.MAKE_PAYMENT))
    ];
  else {
    yield* [put(failUpdateBillingInfo(response.errors))];
  }
}

function* fetchPlanPricesWorker() {
  const response = yield makeRequest(api.getPremiumPlanPrice);

  if (response.success) {
    const payload = normalizePrice(response.data.price);

    yield put(successFetchPlanPrices(payload));
  } else
    yield put(
      failFetchPlanPrices({ error: 'Failed to fetch premium plan price.' })
    );
}

export function* changePlanSaga() {
  yield takeLatest(REQUEST_CHANGE_PLAN, changePlanWorker);
  yield takeLatest(
    REQUEST_UPDATE_BILLING_INFO,
    formSubmitWorker(updateBillingInfoWorker)
  );
  yield takeLatest(FETCH_PLAN_PRICES, fetchPlanPricesWorker);
}
