import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { updateInfluencer } from './utils';

const actionPrefix = `Messaging/`;

const FETCH_USER_INFORMATION = `${actionPrefix}FETCH_USER_INFORMATION`;
const SUCCESS_FETCH_USER_INFORMATION = `${actionPrefix}SUCCESS_FETCH_USER_INFORMATION`;
const FAIL_FETCH_USER_INFORMATION = `${actionPrefix}FAIL_FETCH_USER_INFORMATION`;

export const fetchUserInformation = slug => ({
  type: FETCH_USER_INFORMATION,
  payload: { slug }
});

const successFetchUserInformation = payload => ({
  type: SUCCESS_FETCH_USER_INFORMATION,
  payload
});

const failFetchUserInformation = payload => ({
  type: FAIL_FETCH_USER_INFORMATION,
  payload
});

export const userInformationReducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER_INFORMATION:
      state.fetchingCurrentUserInformation = true;
      break;

    case SUCCESS_FETCH_USER_INFORMATION:
      state.currentUserInformation = action.payload;
      state.fetchingCurrentUserInformation = false;
      if (!_.isEmpty(state.followedInfluencers))
        state.followedInfluencers = updateInfluencer(
          state.followedInfluencers,
          action.payload
        );
      break;

    case FAIL_FETCH_USER_INFORMATION:
      state.currentUserInformation = action.payload;
      state.fetchingCurrentUserInformation = false;
      break;

    default:
      break;
  }
};

function* fetchUserInformationWorker(action) {
  const { slug } = action.payload;

  const response = yield makeRequest(api.influencerDetail, {
    lookupData: { slug }
  });

  if (response.success) yield put(successFetchUserInformation(response.data));
  else yield put(failFetchUserInformation(response.errors));
}

export function* userInformationWatcher() {
  yield takeLatest(FETCH_USER_INFORMATION, fetchUserInformationWorker);
}
