import _ from 'lodash';
import { put, takeLatest, select, delay } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import {
  getCurrentConversationId,
  getPollingTrigger,
  getLatestMessage,
  getMarkSeenPollingTrigger
} from './selectors';

import { getConversationsWithUpdatedLatestMessage } from './utils';

const actionPrefix = 'Messaging/';

const FETCH_INITIAL_CONVERSATION_MESSAGES = `${actionPrefix}FETCH_CONVERSATION_MESSAGES`;
const SUCCESS_FETCH_INITIAL_CONVERSATION_MESSAGES = `${actionPrefix}SUCCESS_FETCH_CONVERSATION_MESSAGES`;
const FAIL_FETCH_INITIAL_CONVERSATION_MESSAGES = `${actionPrefix}FAIL_FETCH_CONVERSATION_MESSAGES`;

const FETCH_PREVIOUS_CONVERSATION_MESSAGES = `${actionPrefix}FETCH_PREVIOUS_CONVERSATION_MESSAGES`;
const SUCCESS_FETCH_PREVIOUS_CONVERSATION_MESSAGES = `${actionPrefix}SUCCESS_FETCH_PREVIOUS_CONVERSATION_MESSAGES`;
const FAIL_FETCH_PREVIOUS_CONVERSATION_MESSAGES = `${actionPrefix}FAIL_FETCH_PREVIOUS_CONVERSATION_MESSAGES`;

const FETCH_NEW_CONVERSATION_MESSAGES = `${actionPrefix}FETCH_NEW_MESSAGES`;
const SUCCESS_FETCH_NEW_CONVERSATION_MESSAGES = `${actionPrefix}SUCCESS_FETCH_NEW_CONVERSATION_MESSAGES`;

const START_POLLING_CURRENT_CONVERSATION_MESSAGES = `${actionPrefix}START_POLLING_CURRENT_CONVERSATION_MESSAGES`;
const STOP_POLLING_CURRENT_CONVERSATION_MESSAGES = `${actionPrefix}STOP_POLLING_CURRENT_CONVERSATION_MESSAGES`;

const REQUEST_CREATE_MESSAGE = `${actionPrefix}REQUEST_CREATE_MESSAGE`;
const SUCCESS_CREATE_MESSAGE = `${actionPrefix}SUCCESS_CREATE_MESSAGE`;
const FAIL_CREATE_MESSAGE = `${actionPrefix}FAIL_CREATE_MESSAGE`;

const START_POLLING_SEEN_MARKING = `${actionPrefix}START_POLLING_SEEN_MARKING`;
const STOP_POLLING_SEEN_MARKING = `${actionPrefix}STOP_POLLING_SEEN_MARKING`;

export const fetchInitialConversationMessages = conversationId => ({
  type: FETCH_INITIAL_CONVERSATION_MESSAGES,
  payload: { conversationId }
});

const successFetchInitialConversationMessages = payload => ({
  type: SUCCESS_FETCH_INITIAL_CONVERSATION_MESSAGES,
  payload
});

const failFetchInitialConversationMessages = payload => ({
  type: FAIL_FETCH_INITIAL_CONVERSATION_MESSAGES,
  payload
});

export const fetchPreviousConversationMessages = ({ url, extra }) => ({
  type: FETCH_PREVIOUS_CONVERSATION_MESSAGES,
  payload: { url, extra }
});

const successFetchPreviousConversationMessages = payload => ({
  type: SUCCESS_FETCH_PREVIOUS_CONVERSATION_MESSAGES,
  payload
});

const failFetchPreviousConversationMessages = errors => ({
  type: FAIL_FETCH_PREVIOUS_CONVERSATION_MESSAGES,
  errors
});

export const fetchNewConversationMessages = () => ({
  type: FETCH_NEW_CONVERSATION_MESSAGES
});

const successFetchNewConversationMessages = payload => ({
  type: SUCCESS_FETCH_NEW_CONVERSATION_MESSAGES,
  payload
});

const startPollingCurrentConversationMessages = trigger => ({
  type: START_POLLING_CURRENT_CONVERSATION_MESSAGES,
  payload: { trigger }
});

export const stopPollingCurrentConversationMessages = () => ({
  type: STOP_POLLING_CURRENT_CONVERSATION_MESSAGES
});

export const requestCreateMessage = data => ({
  type: REQUEST_CREATE_MESSAGE,
  payload: data
});

const successCreateMessage = payload => ({
  type: SUCCESS_CREATE_MESSAGE,
  payload
});

const failCreateMessage = payload => ({
  type: FAIL_CREATE_MESSAGE,
  payload
});

export const startPollingSeenMarking = trigger => ({
  type: START_POLLING_SEEN_MARKING,
  payload: { trigger }
});

export const stopPollingSeenMarking = () => ({
  type: STOP_POLLING_SEEN_MARKING
});

export const messagesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INITIAL_CONVERSATION_MESSAGES:
      state.loadingMessages = true;
      break;
    case SUCCESS_FETCH_INITIAL_CONVERSATION_MESSAGES:
      state.writtenMessages = [];
      state.previousMessagesUrl = action.payload.next;
      state.currentMessages = action.payload.results;

      if (!_.isEmpty(action.payload.results)) {
        state.lastFetchedMessageId = action.payload.results[0].id;
        state.conversationHasNewMessages = true;
        state.conversations = getConversationsWithUpdatedLatestMessage(
          state,
          action.payload.results
        );
      } else state.conversationHasNewMessages = false;
      state.loadingMessages = false;

      break;

    case FETCH_PREVIOUS_CONVERSATION_MESSAGES:
      state.loadingPreviousMessages = true;
      break;

    case SUCCESS_FETCH_PREVIOUS_CONVERSATION_MESSAGES:
      state.loadingPreviousMessages = false;
      state.previousMessagesUrl = action.payload.next;
      state.currentMessages = [
        ...state.currentMessages,
        ...action.payload.results
      ];
      break;

    case FETCH_NEW_CONVERSATION_MESSAGES:
      state.loadingMessages = true;
      break;
    case SUCCESS_FETCH_NEW_CONVERSATION_MESSAGES:
      state.writtenMessages = [];

      if (!_.isEmpty(action.payload.results)) {
        state.currentMessages = [
          ...action.payload.results,
          ...state.currentMessages
        ];
        state.lastFetchedMessageId = action.payload.results[0].id;
        state.conversationHasNewMessages = true;
        state.conversations = getConversationsWithUpdatedLatestMessage(
          state,
          action.payload.results
        );
      } else state.conversationHasNewMessages = false;
      state.loadingMessages = false;

      break;

    case START_POLLING_CURRENT_CONVERSATION_MESSAGES:
      state.pollCurrentConversationLatestMessages = true;
      break;

    case STOP_POLLING_CURRENT_CONVERSATION_MESSAGES:
      state.pollCurrentConversationLatestMessages = false;
      break;

    case REQUEST_CREATE_MESSAGE:
      state.writtenMessages = [action.payload, ...state.writtenMessages];
      state.conversationHasNewMessages = true;
      break;

    case START_POLLING_SEEN_MARKING:
      state.pollMarkSeen = true;
      break;

    case STOP_POLLING_SEEN_MARKING:
      state.pollMarkSeen = false;
      break;

    default:
      break;
  }
};

function* fetchInitialConversationMessagesWorker(action) {
  const response = yield makeRequest(api.conversationMessageList, {
    lookupData: { conversationId: action.payload.conversationId },
    requestData: { params: { limit: 30, offset: 0 } }
  });

  if (response.success)
    yield put(successFetchInitialConversationMessages(response.data));
  else yield put(failFetchInitialConversationMessages(response.errors));
}

function* triggerPollingWorker(action) {
  yield* [
    put(startPollingCurrentConversationMessages(true)),
    put(startPollingSeenMarking(true))
  ];
}

function* fetchPreviousConversationMessagesWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.payload.url
  });

  if (response.success) {
    yield put(successFetchPreviousConversationMessages(response.data));

    _.map(action.payload.extra, action => action());
  } else {
    yield put(failFetchPreviousConversationMessages(response.errors));
  }
}

function* pollCurrentConversationMessagesWorker(action) {
  let trigger = action.payload.trigger;

  while (trigger) {
    let currentConversationId = yield select(getCurrentConversationId);
    let lastFetchedMessageId = yield select(getLatestMessage);

    let response = yield makeRequest(api.conversationMessageList, {
      lookupData: { conversationId: currentConversationId },
      requestData: {
        params: { limit: 30, offset: 0, after: lastFetchedMessageId }
      }
    });

    if (response.success)
      yield put(successFetchNewConversationMessages(response.data));

    yield delay(2000);
    trigger = yield select(getPollingTrigger);
  }
}

function* createMessageWorker(action) {
  yield put(stopPollingCurrentConversationMessages());
  const currentConversationId = yield select(getCurrentConversationId);

  const response = yield makeRequest(api.createMessage, {
    lookupData: { conversationId: currentConversationId },
    requestBody: { content: action.payload.content }
  });

  if (response.success) yield* [put(successCreateMessage(response.data))];
  else yield put(failCreateMessage(response.errors));
  yield put(startPollingCurrentConversationMessages(true));
}

function* pollSeenMarkingWorker(action) {
  let { trigger } = action.payload;

  while (trigger) {
    let currentConversationId = yield select(getCurrentConversationId);

    yield makeRequest(api.markSeenMessages, {
      lookupData: { conversationId: currentConversationId }
    });

    yield delay(4000);

    trigger = yield select(getMarkSeenPollingTrigger);
  }
}

export function* messagesWatcher() {
  yield takeLatest(
    FETCH_INITIAL_CONVERSATION_MESSAGES,
    fetchInitialConversationMessagesWorker
  );
  yield takeLatest(
    FETCH_PREVIOUS_CONVERSATION_MESSAGES,
    fetchPreviousConversationMessagesWorker
  );
  yield takeLatest(
    SUCCESS_FETCH_INITIAL_CONVERSATION_MESSAGES,
    triggerPollingWorker
  );
  yield takeLatest(
    START_POLLING_CURRENT_CONVERSATION_MESSAGES,
    pollCurrentConversationMessagesWorker
  );
  yield takeLatest(REQUEST_CREATE_MESSAGE, createMessageWorker);
  yield takeLatest(START_POLLING_SEEN_MARKING, pollSeenMarkingWorker);
}
