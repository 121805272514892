import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Tenders/';

const DOWNLOAD_TENDER_RESPONSE_EXPORT = `${actionPrefix}DOWNLOAD_TENDER_RESPONSE_EXPORT`;
const SUCCESS_DOWNLOAD_TENDER_RESPONSE_EXPORT = `${actionPrefix}SUCCESS_DOWNLOAD_TENDER_RESPONSE_EXPORT`;
const FAIL_DOWNLOAD_TENDER_RESPONSE_EXPORT = `${actionPrefix}FAIL_DOWNLOAD_TENDER_RESPONSE_EXPORT`;

export const downloadTenderResponseExport = ({
  tenderId,
  responseIds,
  responseHandlers
}) => ({
  type: DOWNLOAD_TENDER_RESPONSE_EXPORT,
  payload: { tenderId, responseIds },
  responseHandlers
});

const successDownloadTenderResponseExport = payload => ({
  type: SUCCESS_DOWNLOAD_TENDER_RESPONSE_EXPORT,
  payload
});

const failDownloadTenderResponseExport = payload => ({
  type: FAIL_DOWNLOAD_TENDER_RESPONSE_EXPORT,
  payload
});

export const downloadTenderResponseExportReducer = (state, action) => {
  switch (action.type) {
    case DOWNLOAD_TENDER_RESPONSE_EXPORT:
      state['isFetchingExport'] = true;
      break;

    case SUCCESS_DOWNLOAD_TENDER_RESPONSE_EXPORT:
      state['isFetchingExport'] = false;
      break;

    default:
      return state;
  }
};

function* downloadTenderResponseExportWorker(action) {
  const { tenderId, responseIds } = action.payload;
  const response = yield makeRequest(api.tenderResponseExport, {
    lookupData: { tenderId },
    requestBody: { ids: responseIds }
  });

  if (response.success) {
    action.responseHandlers.map(handler => handler(response.data));

    yield put(successDownloadTenderResponseExport(response.data));
  } else {
    yield put(failDownloadTenderResponseExport(response.errors));

    return response.errors;
  }
}

export function* downloadTenderResponseExportWatcher() {
  yield takeLatest(
    DOWNLOAD_TENDER_RESPONSE_EXPORT,
    downloadTenderResponseExportWorker
  );
}
