import { eventReducer, eventWatcher } from './event';

const actionPrefix = 'EditEvent/';

const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const resetState = () => ({ type: RESET_STATE });

const initialState = {
  event: null
};

export const eventEditReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === RESET_STATE) return initialState;

  eventReducer(newState, action);

  return newState;
};

export const eventEditSaga = [eventWatcher];

export * from './event';
