import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';

import { normalizeRenderedSelect } from 'shared/forms/RenderSelect';
import { setAuthorization } from 'shared/utils/setAuthorization';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { api, makeRequest } from 'shared/sdk';
import { fetchMe } from 'shared/HOCs/FetchInitials/ducks';
import { GARegister } from 'shared/utils/GA';

import { nextStep, uploadAvatarS3 } from '../';

const actionPrefix = 'Signup/';
const REGISTER = `${actionPrefix}REGISTER`;
const SUCCESS_REGISTER = `${actionPrefix}SUCCESS_REGISTER`;
const FAIL_REGISTER = `${actionPrefix}FAIL_REGISTER`;

export const register = (formName, data) => {
  return {
    type: REGISTER,
    formName,
    payload: data
  };
};

const successRegister = () => {
  return {
    type: SUCCESS_REGISTER
  };
};

const failRegister = errors => {
  return {
    type: FAIL_REGISTER,
    payload: {
      errors
    }
  };
};

function* registerWorker(action) {
  let data = action.payload;

  const position = normalizeRenderedSelect(data.profile.position);

  data = { ...data, profile: { ...data.profile, position } };

  const avatarArr = _.get(action.payload, 'profile.avatar', null);

  if (!_.isNil(avatarArr)) {
    delete data.profile.avatar;

    const s3avatar = yield uploadAvatarS3(avatarArr[0]);

    data = {
      ...data,
      profile: { ...data.profile, avatar: s3avatar }
    };
  }

  const response = yield makeRequest(api.register, {
    requestBody: data
  });

  if (response.success) {
    setAuthorization(response.data.auth_token);

    // GA Register event
    GARegister();

    yield* [put(successRegister()), put(nextStep()), put(fetchMe())];
  } else {
    yield put(failRegister(response.errors));

    return response.errors;
  }
}

export function* registerSaga() {
  yield takeLatest(REGISTER, formSubmitWorker(registerWorker));
}
