import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Events/';
export const FETCH_PREVIEW_EVENTS = `${actionPrefix}FETCH_PREVIEW_EVENTS`;
export const SUCCESS_FETCH_PREVIEW_EVENTS = `${actionPrefix}SUCCESS_FETCH_PREVIEW_EVENTS`;
export const FAIL_FETCH_PREVIEW_EVENTS = `${actionPrefix}FAIL_FETCH_PREVIEW_EVENTS`;

export const FETCH_FEATURED_EVENTS = `${actionPrefix}FETCH_FEATURED_EVENTS`;
export const SUCCESS_FETCH_FEATURED_EVENTS = `${actionPrefix}SUCCESS_FETCH_FEATURED_EVENTS`;
export const FAIL_FETCH_FEATURED_EVENTS = `${actionPrefix}FAIL_FETCH_FEATURED_EVENTS`;

export const fetchFeaturedEvents = () => ({
  type: FETCH_FEATURED_EVENTS
});

const successFetchFeaturedEvents = payload => ({
  type: SUCCESS_FETCH_FEATURED_EVENTS,
  payload
});

const failFetchFeaturedEvents = payload => ({
  type: FAIL_FETCH_FEATURED_EVENTS,
  payload
});

export const fetchPreviewEvents = filters => ({
  type: FETCH_PREVIEW_EVENTS,
  filters
});

const successFetchPreviewEvents = payload => ({
  type: SUCCESS_FETCH_PREVIEW_EVENTS,
  payload
});

const failFetchPreviewEvents = payload => ({
  type: FAIL_FETCH_PREVIEW_EVENTS,
  payload
});

export const previewEventsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_FEATURED_EVENTS:
      state['previewEvents'] = action.payload;
      break;

    case SUCCESS_FETCH_PREVIEW_EVENTS:
      state['previewEvents'] = action.payload.results;
      break;

    default:
      return state;
  }
};

function* fetchFeaturedEventsWorker(action) {
  const response = yield makeRequest(api.featuredEventsList);

  if (response.success) yield put(successFetchFeaturedEvents(response.data));
  else yield put(failFetchFeaturedEvents(response.errors));
}

function* fetchPreviewEventsWorker(action) {
  const response = yield makeRequest(api.eventsList, {
    requestData: { params: action.filters }
  });

  if (response.success) yield put(successFetchPreviewEvents(response.data));
  else yield put(failFetchPreviewEvents(response.errors));
}

export function* previewEventsWatcher() {
  yield takeLatest(FETCH_PREVIEW_EVENTS, fetchPreviewEventsWorker);
  yield takeLatest(FETCH_FEATURED_EVENTS, fetchFeaturedEventsWorker);
}
