import { takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { stringifyParams } from 'shared/utils/url';

const actionPrefix = 'ConnectLogin/';

const REQUEST_GENERATE_AUTH_TOKEN = `${actionPrefix}REQUEST_LOGIN`;

export const generateSSOAuthorizationToken = ({ redirectPath }) => ({
  type: REQUEST_GENERATE_AUTH_TOKEN,
  payload: { redirectPath }
});

function* generateSSOAuthorizationTokenWorker(action) {
  const { redirectPath } = action.payload;
  const { data, success } = yield makeRequest(
    api.generateSSOAuthorizationToken,
    {
      options: { redirectToHome: false }
    }
  );

  if (success) {
    const { token, redirect_url } = data;
    const queryParams = stringifyParams({
      token,
      redirectPath
    });

    window.location.href = `${redirect_url}?${queryParams}`;
  }
}

export function* connectLoginSaga() {
  yield takeLatest(
    REQUEST_GENERATE_AUTH_TOKEN,
    generateSSOAuthorizationTokenWorker
  );
}
