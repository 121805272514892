const changeIsAttendingStatus = state => {
  return {
    ...state.event,
    is_attending: !state.event.is_attending
  };
};

export const addAttendeeUtil = state => {
  state.performingAddOrRemoveAttendeeOperation = false;
  state.event = changeIsAttendingStatus(state);
  state.previewAttendees = {
    ...state.previewAttendees,
    count: state.previewAttendees.count + 1
  };
  return state;
};

export const removeAttendeeUtil = state => {
  state.performingAddOrRemoveAttendeeOperation = false;
  state.event = changeIsAttendingStatus(state);
  state.previewAttendees = {
    ...state.previewAttendees,
    count: state.previewAttendees.count - 1
  };
  return state;
};
