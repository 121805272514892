import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Signup/Positions/';

const FETCH_POSITIONS = `${actionPrefix}FETCH_POSITIONS`;
const SUCCESS_FETCH_POSITIONS = `${actionPrefix}SUCCESS_FETCH_POSITIONS`;
const FAIL_FETCH_POSITIONS = `${actionPrefix}FAIL_FETCH_POSITIONS`;

export const fetchPositions = ({ value }) => ({
  type: FETCH_POSITIONS,
  payload: { value }
});

const successFetchPositions = ({ data: { results } }) => ({
  type: SUCCESS_FETCH_POSITIONS,
  payload: { results }
});

const failFetchPositions = ({ errors }) => ({
  type: FAIL_FETCH_POSITIONS,
  payload: { errors }
});

const initialState = { positions: [] };

export const positionFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_POSITIONS:
      const positions = action.payload.results.map(({ name, id }) => ({
        value: id,
        label: name
      }));

      state['positions'] = positions;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchPositionsWorker(action) {
  const { value } = action.payload;

  const response = yield makeRequest(api.fetchPositions, {
    requestData: { params: { name: value } }
  });

  if (response.success) {
    yield put(successFetchPositions(response));
  } else {
    yield put(failFetchPositions(response));
  }
}

export function* positionFieldSaga() {
  yield takeLatest(FETCH_POSITIONS, fetchPositionsWorker);
}
