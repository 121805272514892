import { put, takeLatest, select } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { buildFilters } from 'shared/utils/filters';
import { addToList } from 'shared/utils/reducerUtils';

import * as selectors from './selectors';

const actionPrefix = 'Events/';
export const FETCH_EVENTS = `${actionPrefix}FETCH_EVENTS`;
export const SUCCESS_FETCH_EVENTS = `${actionPrefix}SUCCESS_FETCH_EVENTS`;
export const FAIL_FETCH_EVENTS = `${actionPrefix}FAIL_FETCH_EVENTS`;

export const FETCH_NEXT_EVENTS = `${actionPrefix}FETCH_NEXT_EVENTS`;
export const SUCCESS_FETCH_NEXT_EVENTS = `${actionPrefix}SUCCESS_FETCH_NEXT_EVENTS`;

export const fetchEvents = (filters = []) => ({
  type: FETCH_EVENTS,
  payload: {
    filters
  }
});

const successFetchEvents = payload => ({
  type: SUCCESS_FETCH_EVENTS,
  payload
});

const failFetchEvents = payload => ({
  type: FAIL_FETCH_EVENTS,
  payload
});

export const fetchNextEvents = () => ({
  type: FETCH_NEXT_EVENTS
});

export const successFetchNextEvents = payload => ({
  type: SUCCESS_FETCH_NEXT_EVENTS,
  payload
});

export const listEventsReducer = (initialState, state, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
    case FETCH_NEXT_EVENTS:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_EVENTS:
      state['events'] = action.payload.results;
      state['nextUrl'] = action.payload.next;
      state['eventCount'] = action.payload.count;
      state['isFetching'] = false;
      break;

    case SUCCESS_FETCH_NEXT_EVENTS:
      state['events'] = addToList(state.events, action.payload.results);
      state['nextUrl'] = action.payload.next;
      state['isFetching'] = false;
      break;

    default:
      return state;
  }
};

const PREVIEW_EVENTS_COUNT = 20;
const PREVIEW_EVENTS_START = 0;

function* fetchEventsWorker(action) {
  const filters = [
    ...action.payload.filters,
    { name: 'limit', value: PREVIEW_EVENTS_COUNT },
    { name: 'offset', value: PREVIEW_EVENTS_START }
  ];

  const response = yield makeRequest(api.eventsList, {
    requestData: { params: buildFilters(filters) }
  });

  if (response.success) yield put(successFetchEvents(response.data));
  else yield put(failFetchEvents(response.errors));
}

function* fetchNextEventsWorker(action) {
  const nextUrl = yield select(selectors.getNextUrl);
  const response = yield makeRequest(api.genericGet, { lookupData: nextUrl });

  if (response.success) yield put(successFetchNextEvents(response.data));
  else yield put(failFetchEvents(response.errors));
}

export function* eventsWatcher() {
  yield takeLatest(FETCH_EVENTS, fetchEventsWorker);
  yield takeLatest(FETCH_NEXT_EVENTS, fetchNextEventsWorker);
}
