import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Job/Form/Company/';

const FETCH_USER_COMPANY_DATA = `${actionPrefix}FETCH_USER_COMPANY_DATA`;
const SUCCESS_FETCH_USER_COMPANY_DATA = `${actionPrefix}SUCCESS_FETCH_USER_COMPANY_DATA`;

export const fetchUserCompanyData = ({ companySlug }) => ({
  type: FETCH_USER_COMPANY_DATA,
  payload: { companySlug }
});

const successFetchUserCompanyData = ({ data }) => ({
  type: SUCCESS_FETCH_USER_COMPANY_DATA,
  payload: { data }
});

export const companyReducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER_COMPANY_DATA:
      state['isFetchingFormData'] = true;
      break;

    case SUCCESS_FETCH_USER_COMPANY_DATA:
      state['company'] = action.payload.data;
      state['isFetchingFormData'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchCompanyWorker(action) {
  const { companySlug } = action.payload;

  const response = yield getResponseOr404(api.companiesDetail, {
    lookupData: { slug: companySlug }
  });

  if (response.success) {
    yield put(successFetchUserCompanyData(response));
  }
}

export function* companySaga() {
  yield takeLatest(FETCH_USER_COMPANY_DATA, fetchCompanyWorker);
}
