import React from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import _ from 'lodash';

import Button from 'shared/components/Button';

import { withModal } from 'shared/components/Modals/';
import { MODALS } from 'global-constants';

import styles from './styles.module.scss';

import notificationsImage from 'assets/images/buildings.png';

class PermissionPrompt extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.setContentClassName(
        classNames(styles.modalContent, styles.modalContentOpen)
      );
    }, 500);
  }

  state = {
    contentClassName: styles.modalContent
  };

  close = () => {
    const { closeModal, onClose } = this.props;

    onClose(closeModal);
  };

  dismissNotifications = () => {
    const { closeModal, onClose, dismissNotifications } = this.props;

    onClose(() => {
      dismissNotifications();
      closeModal();
    });
  };

  requestPermission = () => {
    this.props.requestPermission(this.close());
  };

  setContentClassName = className => {
    this.setState({ contentClassName: className });
  };

  render() {
    return (
      <Portal>
        <div className={styles.modalDim}>
          <div className={styles.modalContainer}>
            <div className={this.state.contentClassName}>
              <div className={styles.leftSectionContainer}>
                <div
                  className={styles.notificationsImage}
                  style={{ backgroundImage: `url(${notificationsImage})` }}
                />
              </div>
              <div className={styles.rightSectionContainer}>
                <div className={styles.textContainer}>
                  <div className={styles.title}>
                    Don’t miss out on your latest Unissu updates!
                  </div>
                  <div className={styles.description}>
                    Get notified instantly when there’s an update for you on
                    Unissu. New followers, latest resources, company updates;
                    stay informed by receiving notifications from Unissu.
                  </div>
                  <div className={styles.actionsContainer}>
                    <div className={styles.cancelLink} onClick={this.close}>
                      Not Now
                    </div>
                    <Button
                      className={styles.enableBtn}
                      color="primary"
                      onClick={this.requestPermission}>
                      Enable Notifications
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {!_.isNil(this.props.user) &&
              !_.isEmpty(this.props.user) && (
                <div
                  className={styles.dismissContainer}
                  onClick={this.dismissNotifications}>
                  <div className={styles.dismissText}>
                    Do not show this again
                  </div>
                </div>
              )}
          </div>
        </div>
      </Portal>
    );
  }
}

export default withModal(MODALS.NOTIFICATION_PERMISSION_PROMPT)(
  PermissionPrompt
);
