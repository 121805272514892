import { countriesReducer, countriesWatcher } from './countries';
import { sectorsReducer, sectorsWatcher } from './sectors';
import { userProfileReducer, userProfileWatcher } from './userProfile';
import { leaveVendorReducer, leaveVendorWatcher } from './leaveVendor';
import { preferencesReducer, userPreferencesWatcher } from './preferences';
import { resourcesReducer, resourcesWatcher } from './resources';
import { tabManagementReducer, EDIT_PROFILE_TABS } from './tabManagement';
import { deleteAccountWatcher } from './deleteAccount';
import { promoCodeWatcher } from './promoCode';
import {
  updateCredentialsReducer,
  updateCredentialsWatcher
} from './updateCredentials';
import { connectVideosReducer, connectVideosWatcher } from './connectVideos';
import { experienceReducer, experienceSaga } from './experience';
import { qualificationsReducer, qualificationsSaga } from './qualifications';

const DELETE_STATE = 'EditProfile/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  countries: [],
  sectors: [],
  userProfile: {},
  vendor: null,
  resources: [],
  resourceCount: 0,
  isFetchingResources: false,
  resourcesNext: null,
  activeTab: EDIT_PROFILE_TABS.PROFILE,
  isFetchingTenders: true,
  securitySettings: null,
  connectVideos: [],
  connectVideosCount: 0,
  connectVideosNextUrl: null,
  isSubmittingConnectTalkDetailsForm: false,
  connectTalkDetails: null,
  isFetchingConnectTalkDetails: true,
  isFetchingConnectVideos: false,
  fetchedInitialResources: false,
  fetchedInitialConnectVideos: false,
  experience: [],
  qualifications: [],
  isFetchingExperience: false,
  isFetchingQualifications: false,
  experienceNextUrl: null,
  qualificationsNextUrl: null,
  patchingPreferences: false,
  userPreferences: null
};

export const editProfileReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  countriesReducer(newState, action);
  sectorsReducer(newState, action);
  userProfileReducer(newState, action);
  leaveVendorReducer(newState, action);
  tabManagementReducer(newState, action);
  preferencesReducer(newState, action);
  resourcesReducer(newState, action);
  updateCredentialsReducer(newState, action);
  connectVideosReducer(newState, action);
  experienceReducer(newState, action);
  qualificationsReducer(newState, action);

  return newState;
};

export const editProfileSaga = [
  countriesWatcher,
  sectorsWatcher,
  userProfileWatcher,
  leaveVendorWatcher,
  userPreferencesWatcher,
  resourcesWatcher,
  deleteAccountWatcher,
  promoCodeWatcher,
  updateCredentialsWatcher,
  connectVideosWatcher,
  experienceSaga,
  qualificationsSaga
];

export * from './countries';
export * from './sectors';
export * from './userProfile';
export * from './leaveVendor';
export * from './tabManagement';
export * from './preferences';
export * from './resources';
export * from './deleteAccount';
export * from './promoCode';
export * from './updateCredentials';
export * from './connectVideos';
export * from './experience';
export * from './qualifications';
