import { postsReducer, postsSaga } from './posts';
import { commentsReducer, commentsSaga } from './comments';
import { commentRepliesReducer, commentRepliesSaga } from './commentReplies';
import { urlPreviewReducer, urlPreviewSaga } from './urlPreview';
import { postDetailsReducer, postDetailsSaga } from './postDetails';
import { filterReducer, filterSaga } from './filters';

const actionPrefix = 'Dashboard/';

const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const resetState = () => ({
  type: RESET_STATE
});

const initialState = {
  posts: [],
  sponsoredPosts: [],
  isPinning: false,
  nextPostsUrl: null,
  nextSponsoredPostsUrl: null,
  isFetching: true,
  isFetchingSponsored: true,
  isCreatingPost: false,
  pollDashboardPosts: false,
  newPostsArePresent: false,
  pollScrapeRequest: false,
  scrapeRequest: null,
  dashboardPreferences: {},
  disableDashboardPreferencesCheckboxes: false
};

export const dashboardReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === RESET_STATE) {
    newState = initialState;
  }

  postsReducer(newState, action);
  commentsReducer(newState, action);
  commentRepliesReducer(newState, action);
  urlPreviewReducer(newState, action);
  postDetailsReducer(newState, action);
  filterReducer(newState, action);

  return newState;
};

export const dashboardSagas = [
  postsSaga,
  commentsSaga,
  commentRepliesSaga,
  urlPreviewSaga,
  postDetailsSaga,
  filterSaga
];

export * from './posts';
export * from './comments';
export * from './commentReplies';
export * from './urlPreview';
export * from './postDetails';
export * from './filters';
