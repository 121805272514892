import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'GlobalSearch/';

const FETCH_EVENTS = `${actionPrefix}FETCH_EVENTS`;
const SUCCESS_FETCH_EVENTS = `${actionPrefix}SUCCESS_FETCH_EVENTS`;
const FAIL_FETCH_EVENTS = `${actionPrefix}FAIL_FETCH_EVENTS`;

export const fetchEvents = filters => ({
  type: FETCH_EVENTS,
  payload: { filters }
});

export const successFetchEvents = payload => ({
  type: SUCCESS_FETCH_EVENTS,
  payload
});

export const failFetchEvents = payload => ({
  type: FAIL_FETCH_EVENTS,
  payload
});

export const eventsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      state['fetchingEvents'] = true;
      break;

    case SUCCESS_FETCH_EVENTS:
      state['events'] = action.payload.results;
      state['eventsCount'] = action.payload.count;
      state['fetchingEvents'] = false;
      break;
    default:
      return state;
  }
};

function* evenEventsWorker(action) {
  const response = yield makeRequest(api.eventsList, {
    requestData: { params: { limit: 3, ...action.payload.filters } }
  });

  if (response.success) yield put(successFetchEvents(response.data));
  else yield put(failFetchEvents(response.errors));
}

export function* eventsSaga() {
  yield takeLatest(FETCH_EVENTS, evenEventsWorker);
}
