import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { limitCount } from 'shared/utils/reducerUtils';

const actionPrefix = 'Companies/List/';
export const FETCH_PREVIEW_COMPANIES = `${actionPrefix}FETCH_PREVIEW_COMPANIES`;
export const SUCCESS_FETCH_PREVIEW_COMPANIES = `${actionPrefix}SUCCESS_FETCH_PREVIEW_COMPANIES`;
export const FAIL_FETCH_PREVIEW_COMPANIES = `${actionPrefix}FAIL_FETCH_PREVIEW_COMPANIES`;

const FETCH_FEATURED_COMPANIES = `${actionPrefix}FETCH_FEATURED_COMPANIES`;
const SUCCESS_FETCH_FEATURED_COMPANIES = `${actionPrefix}SUCCESS_FETCH_FEATURED_COMPANIES`;
const FAIL_FETCH_FEATURED_COMPANIES = `${actionPrefix}FAIL_FETCH_FEATURED_COMPANIES`;

export const fetchFeaturedCompanies = () => ({
  type: FETCH_FEATURED_COMPANIES
});

const successFetchFeaturedCompanies = payload => ({
  type: SUCCESS_FETCH_FEATURED_COMPANIES,
  payload
});

const failFetchFeaturedCompanies = payload => ({
  type: FAIL_FETCH_FEATURED_COMPANIES,
  payload
});

export const fetchPreviewCompanies = filters => ({
  type: FETCH_PREVIEW_COMPANIES,
  filters
});

export const successFetchPreviewCompanies = payload => ({
  type: SUCCESS_FETCH_PREVIEW_COMPANIES,
  payload
});

export const failFetchPreviewCompanies = payload => ({
  type: FAIL_FETCH_PREVIEW_COMPANIES,
  payload
});

export const previewCompaniesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_PREVIEW_COMPANIES:
    case FETCH_FEATURED_COMPANIES:
      state['previewCompanies'] = [];
      break;

    case SUCCESS_FETCH_PREVIEW_COMPANIES:
      state['previewCompanies'] = action.payload.results;
      break;

    case SUCCESS_FETCH_FEATURED_COMPANIES:
      state['previewCompanies'] = limitCount(action.payload, 4);
      break;

    default:
      return state;
  }
};

function* fetchPreviewCompaniesWorker(action) {
  const response = yield makeRequest(api.companiesList, {
    requestData: { params: action.filters }
  });

  if (response.success) yield put(successFetchPreviewCompanies(response.data));
  else yield put(failFetchPreviewCompanies(response.errors));
}

function* fetchFeaturedCompaniesWorker(action) {
  const params = { page: 2 };
  const response = yield makeRequest(api.featuredCompaniesList, {
    requestData: { params }
  });

  if (response.success) yield put(successFetchFeaturedCompanies(response.data));
  else yield put(failFetchFeaturedCompanies(response.errors));
}

export function* previewCompaniesWatcher() {
  yield takeLatest(FETCH_PREVIEW_COMPANIES, fetchPreviewCompaniesWorker);
  yield takeLatest(FETCH_FEATURED_COMPANIES, fetchFeaturedCompaniesWorker);
}
