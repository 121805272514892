import { put } from 'redux-saga/effects';
import { api, getResponseOr404 } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

const actionPrefix = 'EventDetail/';
export const FETCH_EVENT = `${actionPrefix}FETCH_EVENT`;
const SUCCESS_FETCH_EVENT = `${actionPrefix}SUCCESS_FETCH_EVENT`;
const FAIL_FETCH_EVENT = `${actionPrefix}FAIL_FETCH_EVENT`;

export const fetchEvent = eventSlug => ({
  type: FETCH_EVENT,
  payload: {
    eventSlug
  }
});

export const successFetchEvent = data => ({
  type: SUCCESS_FETCH_EVENT,
  payload: {
    data
  }
});

export const failFetchEvent = errors => ({
  type: FAIL_FETCH_EVENT,
  payload: {
    errors
  }
});

export const detailReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_EVENT:
      newState['event'] = { ...action.payload.data };
      break;
    case FAIL_FETCH_EVENT:
      newState['errors'] = { ...action.payload.errors };
      break;

    default:
      return newState;
  }
};

function* fetchEventWorker(action) {
  const response = yield getResponseOr404(api.eventDetail, {
    lookupData: { eventSlug: action.payload.eventSlug }
  });

  if (response.success) yield put(successFetchEvent(response.data));
  else yield put(failFetchEvent(response.errors));
}

export function* detailWatcher() {
  yield takeLatest(FETCH_EVENT, fetchEventWorker);
}
