import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { MODALS } from 'global-constants';
import { closeModal } from 'shared/components/Modals/reducer';

import { addToList } from 'shared/utils/reducerUtils';
import {
  getEmptyMessagingTitle,
  addLatestMessageToConversations
} from './utils';

const EMPTY_MESSAGING_INFLUENCER_BOX_COUNT = 6;

const actionPrefix = 'Messaging/';

const FETCH_CONVERSATIONS = `${actionPrefix}FETCH_CONVERSATIONS`;
const SUCCESS_FETCH_CONVERSATIONS = `${actionPrefix}SUCCESS_FETCH_CONVERSATIONS`;
const FAIL_FETCH_CONVERSATIONS = `${actionPrefix}FAIL_FETCH_CONVERSATIONS`;

const FETCH_NEXT_CONVERSATIONS = `${actionPrefix}FETCH_NEXT_CONVERSATIONS`;
const SUCCESS_FETCH_NEXT_CONVERSATIONS = `${actionPrefix}SUCCESS_FETCH_NEXT_CONVERSATIONS`;
const FAIL_FETCH_NEXT_CONVERSATIONS = `${actionPrefix}FAIL_FETCH_NEXT_CONVERSATIONS`;

const REQUEST_CREATE_CONVERSATION = `${actionPrefix}REQUEST_CREATE_CONVERSATION`;
const SUCCESS_CREATE_CONVERSATION = `${actionPrefix}SUCCESS_CREATE_CONVERSATION`;
const FAIL_CREATE_CONVERSATION = `${actionPrefix}FAIL_CREATE_CONVERSATION`;

const SET_CURRENT_CONVERSATION = `${actionPrefix}SET_CURRENT_CONVERSATION`;

const REQUEST_DELETE_CONVERSATION = `${actionPrefix}REQUEST_DELETE_CONVERSATION`;
const SUCCESS_DELETE_CONVERSATION = `${actionPrefix}SUCCESS_DELETE_CONVERSATION`;
const FAIL_DELETE_CONVERSATION = `${actionPrefix}FAIL_DELETE_CONVERSATION`;

const REQUEST_BLOCK_USER = `${actionPrefix}REQUEST_BLOCK_USER`;
const SUCCESS_BLOCK_USER = `${actionPrefix}SUCCESS_BLOCK_USER`;
const FAIL_BLOCK_USER = `${actionPrefix}FAIL_BLOCK_USER`;

const FETCH_CONNECTED = `${actionPrefix}FETCH_CONNECTED`;
const SUCCESS_FETCH_CONNECTED = `${actionPrefix}SUCCESS_FETCH_CONNECTED`;
const FAIL_FETCH_CONNECTED = `${actionPrefix}FAIL_FETCH_CONNECTED`;

const FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS = `${actionPrefix}FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS`;
const SUCCESS_FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS = `${actionPrefix}SUCCESS_FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS`;
const FAIL_FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS = `${actionPrefix}FAIL_FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS`;

export const fetchConversations = (extra = []) => ({
  type: FETCH_CONVERSATIONS,
  extra
});

const successFetchConversations = payload => ({
  type: SUCCESS_FETCH_CONVERSATIONS,
  payload
});

const failFetchConversations = payload => ({
  type: FAIL_FETCH_CONVERSATIONS,
  payload
});

export const fetchNextConversations = ({ url }) => ({
  type: FETCH_NEXT_CONVERSATIONS,
  payload: { url }
});

const successFetchNextConversations = payload => ({
  type: SUCCESS_FETCH_NEXT_CONVERSATIONS,
  payload
});

const failFetchNextConversations = payload => ({
  type: FAIL_FETCH_NEXT_CONVERSATIONS,
  payload
});

export const requestCreateConversation = (participants, extra = []) => ({
  type: REQUEST_CREATE_CONVERSATION,
  payload: { participants, extra }
});

const successCreateConversation = payload => ({
  type: SUCCESS_CREATE_CONVERSATION,
  payload
});

const failCreateConversation = payload => ({
  type: FAIL_CREATE_CONVERSATION,
  payload
});

export const setCurrentConversation = conversation => ({
  type: SET_CURRENT_CONVERSATION,
  payload: { conversation }
});

export const requestDeleteConversation = conversationId => ({
  type: REQUEST_DELETE_CONVERSATION,
  payload: { conversationId }
});

const successDeleteConversation = payload => ({
  type: SUCCESS_DELETE_CONVERSATION,
  payload
});

const failDeleteConversation = payload => ({
  type: FAIL_DELETE_CONVERSATION,
  payload
});

export const requestBlockUser = (userSlug, deleteConversation = false) => ({
  type: REQUEST_BLOCK_USER,
  payload: { userSlug, deleteConversation }
});

const successBlockUser = payload => ({
  type: SUCCESS_BLOCK_USER,
  payload
});

const failBlockUser = payload => ({
  type: FAIL_BLOCK_USER,
  payload
});

const fetchConnected = () => ({
  type: FETCH_CONNECTED
});

const successFetchConnected = (
  payload,
  missingConnectedInfluencersCount = 0
) => ({
  type: SUCCESS_FETCH_CONNECTED,
  payload,
  missingConnectedInfluencersCount
});

const failFetchConnected = payload => ({
  type: FAIL_FETCH_CONNECTED,
  payload
});

const fetchLatestMessageForConversations = payload => ({
  type: FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS,
  payload
});

const successFetchLatestMessageForConversations = payload => ({
  type: SUCCESS_FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS,
  payload
});

const failFetchLatestMessageForConversations = errors => ({
  type: FAIL_FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS,
  payload: { errors }
});

export const conversationsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_CONVERSATIONS:
      state.loadingConversations = true;
      break;

    case SUCCESS_FETCH_CONVERSATIONS:
      state.conversations = action.payload.results;
      state.nextConversationsUrl = action.payload.next;
      break;

    case FETCH_NEXT_CONVERSATIONS:
      state.loadingNextConversations = true;
      break;

    case SUCCESS_FETCH_NEXT_CONVERSATIONS:
      state.conversations = [...state.conversations, ...action.payload.results];
      state.nextConversationsUrl = action.payload.next;
      break;

    case SET_CURRENT_CONVERSATION:
      state.currentConversation = action.payload.conversation;
      break;

    case FETCH_CONNECTED:
      state.loadingInfluencers = true;
      break;

    case SUCCESS_FETCH_CONNECTED:
      state.loadingInfluencers = false;
      state.connectedInfluencers = action.payload;
      state.emptyMessagingTitle = getEmptyMessagingTitle(
        action.missingConnectedInfluencersCount
      );
      break;

    case SUCCESS_FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS:
      state.loadingConversations = false;
      state.loadingNextConversations = false;
      state.conversations = addLatestMessageToConversations({
        conversations: state.conversations,
        latestMessages: action.payload
      });
      break;

    default:
      break;
  }
};

function* fetchConversationsWorker(action) {
  const response = yield makeRequest(api.conversationList, {
    requestData: { params: { limit: 30 } }
  });

  if (response.success) {
    yield* [
      put(successFetchConversations(response.data)),
      put(fetchLatestMessageForConversations(response.data))
    ];

    if (_.isEmpty(response.data.results)) {
      yield put(fetchConnected());
    } else action.extra.map(action => action());
  } else yield put(failFetchConversations(response.errors));
}

function* fetchNextConversationsWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.payload.url
  });

  if (response.success) {
    yield* [
      put(successFetchNextConversations(response.data)),
      put(fetchLatestMessageForConversations(response.data))
    ];
  } else yield put(failFetchNextConversations(response.errors));
}

function* fetchConnectedWorker(action) {
  const response = yield makeRequest(api.influencerList, {
    requestData: {
      params: { limit: EMPTY_MESSAGING_INFLUENCER_BOX_COUNT, connected: true }
    }
  });

  if (response.success) {
    let influencers = response.data.results;
    const missingInfluencerCount =
      EMPTY_MESSAGING_INFLUENCER_BOX_COUNT - response.data.results.length;

    if (missingInfluencerCount > 0) {
      const additionalInfluencers = yield makeRequest(api.influencerList, {
        requestData: {
          params: {
            limit: missingInfluencerCount,
            not_connected: true,
            ordering: 4
          }
        }
      });

      influencers = addToList(influencers, additionalInfluencers.data.results);
    }
    yield put(successFetchConnected(influencers, missingInfluencerCount));
  } else yield put(failFetchConnected(response.errors));
}

function* createConversationWorker(action) {
  const { participants, extra } = action.payload;

  const response = yield makeRequest(api.conversationCreate, {
    requestBody: { participants }
  });

  if (response.success)
    yield* [
      put(successCreateConversation(response.data)),
      ...extra.map(item => item(response.data))
    ];
  else yield failCreateConversation(response.errors);
}

function* deleteConversationWorker(action) {
  const { conversationId } = action.payload;

  const response = yield makeRequest(api.conversationDelete, {
    lookupData: { conversationId }
  });

  if (response.success)
    yield* [
      put(successDeleteConversation(response.data)),
      put(closeModal(MODALS.DELETE_CONVERSATION_CONFIRMATION)),
      put(setCurrentConversation({})),
      put(fetchConversations())
    ];
  else yield put(failDeleteConversation(response.errors));
}

function* blockUserWorker(action) {
  const { userSlug, deleteConversation } = action.payload;

  const response = yield makeRequest(api.blockUser, {
    lookupData: { userSlug },
    requestBody: { delete_conversation: deleteConversation }
  });

  if (response.success)
    yield* [
      put(successBlockUser(response.data)),
      put(closeModal(MODALS.BLOCK_USER_CONFIRMATION_FROM_MESSAGING)),
      put(setCurrentConversation({})),
      put(fetchConversations())
    ];
  else yield put(failBlockUser(response.errors));
}

function* fetchLatestMessageForConversationsWorker(action) {
  const unformattedConversationIds = action.payload.results.map(
    item => item.id
  );

  const formattedConversationIds = unformattedConversationIds.join(',');

  const response = yield makeRequest(api.conversationLatestMessageList, {
    requestData: { params: { conversation_ids: formattedConversationIds } }
  });

  if (response.success) {
    yield put(successFetchLatestMessageForConversations(response.data));
  } else {
    yield put(failFetchLatestMessageForConversations(response.errors));
  }
}

export function* conversationsWatcher() {
  yield takeLatest(FETCH_CONVERSATIONS, fetchConversationsWorker);
  yield takeLatest(FETCH_NEXT_CONVERSATIONS, fetchNextConversationsWorker);
  yield takeLatest(REQUEST_CREATE_CONVERSATION, createConversationWorker);
  yield takeLatest(REQUEST_DELETE_CONVERSATION, deleteConversationWorker);
  yield takeLatest(REQUEST_BLOCK_USER, blockUserWorker);
  yield takeLatest(FETCH_CONNECTED, fetchConnectedWorker);
  yield takeLatest(
    FETCH_LATEST_MESSAGE_FOR_CONVERSATIONS,
    fetchLatestMessageForConversationsWorker
  );
}
