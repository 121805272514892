const actionPrefix = 'EditProfile/';

export const EDIT_PROFILE_TABS = {
  PROFILE: 0,
  CAREER: 1,
  // YOUR_PLAN: 2, // it's a fake tab
  ARTICLES: 3,
  CONNECT_VIDEOS: 4,
  SECURITY: 5,
  PREFERENCES: 6,
  CPD: 7
};

const SET_ACTIVE_TAB = `${actionPrefix}SET_ACTIVE_TAB`;

export const setActiveTab = tab => ({
  type: SET_ACTIVE_TAB,
  payload: {
    tab
  }
});

export const tabManagementReducer = (newState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      newState['activeTab'] = action.payload.tab;
      break;

    default:
      break;
  }
};
