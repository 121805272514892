import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'Dashboard/Posts/';

const LIKE_POST = `${actionPrefix}LIKE_POST`;
const SUCCESS_LIKE_POST = `${actionPrefix}SUCCESS_LIKE_POST`;
const FAIL_LIKE_POST = `${actionPrefix}FAIL_LIKE_POST`;

const RESET_LIKES = `${actionPrefix}RESET_LIKES`;

const FETCH_LIKES = `${actionPrefix}FETCH_LIKES`;
const SUCCESS_FETCH_LIKES = `${actionPrefix}SUCCESS_FETCH_LIKES`;

const CREATE_POST = `${actionPrefix}CREATE_POST`;
const SUCCESS_CREATE_POST = `${actionPrefix}SUCCESS_CREATE_POST`;
const FAIL_CREATE_POST = `${actionPrefix}FAIL_CREATE_POST`;

const FETCH_INITIAL_POSTS = `${actionPrefix}FETCH_INITIAL_POSTS`;
const SUCCESS_FETCH_INITIAL_POSTS = `${actionPrefix}SUCCESS_FETCH_INITIAL_POSTS`;

const FETCH_INITIAL_SPONSORED_POSTS = `${actionPrefix}FETCH_INITIAL_SPONSORED_POSTS`;
const SUCCESS_FETCH_INITIAL_SPONSORED_POSTS = `${actionPrefix}SUCCESS_FETCH_INITIAL_SPONSORED_POSTS`;

const FETCH_MORE_POSTS = `${actionPrefix}FETCH_MORE_POSTS`;
const SUCCESS_FETCH_MORE_POSTS = `${actionPrefix}SUCCESS_FETCH_MORE_POSTS`;

const FETCH_MORE_SPONSORED_POSTS = `${actionPrefix}FETCH_MORE_SPONSORED_POSTS`;
const SUCCESS_FETCH_MORE_SPONSORED_POSTS = `${actionPrefix}SUCCESS_FETCH_MORE_SPONSORED_POSTS`;

const DELETE_POST = `${actionPrefix}DELETE_POST`;
const SUCCESS_DELETE_POST = `${actionPrefix}SUCCESS_DELETE_POST`;

const TOGGLE_PIN_POST = `${actionPrefix}TOGGLE_PIN_POST`;
const SUCCESS_TOGGLE_PIN_POST = `${actionPrefix}SUCCESS_TOGGLE_PIN_POST`;

const NEW_POST_IS_PRESENT = `${actionPrefix}NEW_POST_IS_PRESENT`;

export const likePost = ({ postId }) => ({
  type: LIKE_POST,
  payload: { postId }
});

const successLikePost = ({ data }) => ({
  type: SUCCESS_LIKE_POST,
  payload: data
});

const failLikePost = errors => ({
  type: FAIL_LIKE_POST,
  payload: { errors }
});

export const resetLikes = ({ postId }) => ({
  type: RESET_LIKES,
  payload: { postId }
});

export const fetchLikes = ({ postId, nextUrl }) => ({
  type: FETCH_LIKES,
  payload: { postId, nextUrl }
});

const successFetchLikes = ({ data, postId }) => ({
  type: SUCCESS_FETCH_LIKES,
  payload: { data, postId }
});

export const createPost = ({
  content,
  mentionedUsers,
  scrapeRequestId,
  extra = []
}) => ({
  type: CREATE_POST,
  payload: { content, scrapeRequestId, mentionedUsers },
  extra
});

export const successCreatePost = ({ post }) => ({
  type: SUCCESS_CREATE_POST,
  payload: { post }
});

export const failCreatePost = errors => ({
  type: FAIL_CREATE_POST,
  payload: { errors }
});

export const fetchInitialPosts = ({ ordering }) => ({
  type: FETCH_INITIAL_POSTS,
  payload: { ordering }
});

const successFetchInitialPosts = payload => ({
  type: SUCCESS_FETCH_INITIAL_POSTS,
  payload
});

export const fetchMorePosts = url => ({
  type: FETCH_MORE_POSTS,
  payload: { url }
});

const successFetchMorePosts = payload => ({
  type: SUCCESS_FETCH_MORE_POSTS,
  payload
});

export const fetchMoreSponsoredPosts = url => ({
  type: FETCH_MORE_SPONSORED_POSTS,
  payload: { url }
});

const successFetchMoreSponsoredPosts = payload => ({
  type: SUCCESS_FETCH_MORE_SPONSORED_POSTS,
  payload
});

export const fetchInitialSponsoredPosts = ({ ordering }) => ({
  type: FETCH_INITIAL_SPONSORED_POSTS,
  payload: { ordering }
});

const successFetchInitialSponsoredPosts = payload => ({
  type: SUCCESS_FETCH_INITIAL_SPONSORED_POSTS,
  payload
});

export const deletePost = ({ postId }) => ({
  type: DELETE_POST,
  payload: { postId }
});

const successDeletePost = ({ postId }) => ({
  type: SUCCESS_DELETE_POST,
  payload: { postId }
});

export const togglePinPost = ({ postId }) => ({
  type: TOGGLE_PIN_POST,
  payload: { postId }
});

const successTogglePinPost = ({ postId }) => ({
  type: SUCCESS_TOGGLE_PIN_POST,
  payload: { postId }
});

export const newPostIsPresent = ({ message, post_id }) => ({
  type: NEW_POST_IS_PRESENT,
  payload: { message, post_id }
});

export const postsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_LIKE_POST:
      state['posts'] = updateByIdentifier({
        collection: state.posts,
        identifierField: 'id',
        identifierValue: action.payload.id,
        newItem: action.payload
      });
      break;

    case RESET_LIKES:
      state['posts'] = updateByIdentifier({
        collection: state.posts,
        identifierField: 'id',
        identifierValue: action.payload.postId,
        newItem: { isFetchingLikes: false, likes: [], likesNextUrl: null }
      });
      break;

    case FETCH_LIKES:
      state['posts'] = updateByIdentifier({
        collection: state.posts,
        identifierField: 'id',
        identifierValue: action.payload.postId,
        newItem: { isFetchingLikes: true }
      });
      break;

    case SUCCESS_FETCH_LIKES:
      state['posts'] = _.map(
        state.posts,
        post =>
          post.id === action.payload.postId
            ? {
                ...post,
                isFetchingLikes: false,
                likesNextUrl: action.payload.data.next,
                likes: addToList(
                  _.get(post, 'likes', []),
                  action.payload.data.results
                )
              }
            : post
      );
      break;

    case CREATE_POST:
      state['isCreatingPost'] = true;
      break;

    case SUCCESS_CREATE_POST:
      state['posts'] = addToList([action.payload.post], state.posts);
      state['isCreatingPost'] = false;
      break;

    case FAIL_CREATE_POST:
      state['isCreatingPost'] = false;
      break;

    case FETCH_INITIAL_POSTS:
      state['isFetching'] = true;
      state['newPostsArePresent'] = false;
      break;

    case SUCCESS_FETCH_INITIAL_POSTS:
      state['posts'] = action.payload.results;
      state['nextPostsUrl'] = action.payload.next;
      state['isFetching'] = false;
      break;

    case FETCH_INITIAL_SPONSORED_POSTS:
      state['isFetchingSponsored'] = true;
      break;

    case SUCCESS_FETCH_INITIAL_SPONSORED_POSTS:
      state['sponsoredPosts'] = action.payload.results;
      state['nextSponsoredPostsUrl'] = action.payload.next;
      state['isFetchingSponsored'] = false;
      break;

    case FETCH_MORE_POSTS:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_MORE_POSTS:
      state['posts'] = addToList(state.posts, action.payload.results);
      state['nextPostsUrl'] = action.payload.next;
      state['isFetching'] = false;
      break;

    case FETCH_MORE_SPONSORED_POSTS:
      state['isFetchingSponsored'] = true;
      break;

    case SUCCESS_FETCH_MORE_SPONSORED_POSTS:
      state['sponsoredPosts'] = addToList(
        state.sponsoredPosts,
        action.payload.results
      );
      state['nextSponsoredPostsUrl'] = action.payload.next;
      state['isFetchingSponsored'] = false;
      break;

    case SUCCESS_DELETE_POST:
      state['posts'] = state.posts.filter(
        post => post.id !== action.payload.postId
      );
      break;

    case TOGGLE_PIN_POST:
      state['isPinning'] = true;
      break;

    case SUCCESS_TOGGLE_PIN_POST:
      state['isPinning'] = false;
      state['posts'] = state.posts.map(
        post =>
          post.id === action.payload.postId
            ? { ...post, pinned: !post.pinned }
            : post
      );
      break;

    case NEW_POST_IS_PRESENT:
      state['newPostsArePresent'] = true;
      break;

    default:
      return state;
  }

  return state;
};

function* likePostWorker(action) {
  const { postId } = action.payload;

  let response;

  response = yield makeRequest(api.likePost, {
    lookupData: { postId }
  });

  if (response.success) {
    yield put(successLikePost(response));
  } else {
    yield put(failLikePost(response));
  }
}

function* fetchLikesWorker(action) {
  const { postId, nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.fetchPostLikes, {
      lookupData: { postId },
      requestData: { params: { limit: 10, offset: 0 } }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  }

  if (response.success) {
    yield put(successFetchLikes({ data: response.data, postId }));
  }
}

function* createPostWorker(action) {
  const {
    content,
    scrapeRequestId: scrape_request,
    mentionedUsers: mentioned_users_ids
  } = action.payload;

  const response = yield makeRequest(api.createPost, {
    requestBody: { content, mentioned_users_ids, scrape_request }
  });

  if (response.success) {
    yield put(successCreatePost({ post: response.data }));
    action.extra.map(action => action());
  } else {
    yield put(failCreatePost(response));
  }
}

function* fetchInitialPostsWorker(action) {
  const { ordering } = action.payload;

  const response = yield makeRequest(api.postsList, {
    requestData: { params: { limit: 20, sponsored: false, ordering } }
  });

  if (response.success) {
    yield put(successFetchInitialPosts(response.data));
  }
}

function* fetchInitialSponsoredPostsWorker(action) {
  const { ordering } = action.payload;

  const response = yield makeRequest(api.postsList, {
    requestData: { params: { limit: 5, sponsored: true, ordering } }
  });

  if (response.success) {
    yield put(successFetchInitialSponsoredPosts(response.data));
  }
}

function* fetchMorePostsWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.payload.url
  });

  if (response.success) {
    yield put(successFetchMorePosts(response.data));
  }
}

function* fetchMoreSponsoredPostsWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.payload.url
  });

  if (response.success) {
    yield put(successFetchMoreSponsoredPosts(response.data));
  }
}

function* deletePostWorker(action) {
  const { postId } = action.payload;

  const response = yield makeRequest(api.postDelete, {
    lookupData: { postId }
  });

  if (response.success) {
    yield* [
      put(successDeletePost({ postId })),
      put(openNotificationBar('Post has been deleted successfully.'))
    ];
  } else {
    const errorMessage = _.head(response.errors.non_field_errors);

    if (errorMessage) {
      yield put(openNotificationBar(errorMessage, null, true));
    }
  }
}

function* togglePinPostWorker(action) {
  const { postId } = action.payload;

  const response = yield makeRequest(api.postTogglePin, {
    lookupData: { postId }
  });

  if (response.success) {
    yield* [
      put(
        openNotificationBar('Success! Changes will be visible after refresh.')
      ),
      put(successTogglePinPost({ postId }))
    ];
  }
}

export function* postsSaga() {
  yield takeLatest(TOGGLE_PIN_POST, togglePinPostWorker);
  yield takeLatest(LIKE_POST, likePostWorker);
  yield takeLatest(CREATE_POST, createPostWorker);
  yield takeLatest(FETCH_INITIAL_POSTS, fetchInitialPostsWorker);
  yield takeLatest(
    FETCH_INITIAL_SPONSORED_POSTS,
    fetchInitialSponsoredPostsWorker
  );
  yield takeLatest(FETCH_MORE_POSTS, fetchMorePostsWorker);
  yield takeLatest(FETCH_MORE_SPONSORED_POSTS, fetchMoreSponsoredPostsWorker);
  yield takeLatest(FETCH_LIKES, fetchLikesWorker);
  yield takeLatest(DELETE_POST, deletePostWorker);
}
