import { serviceProvidersReducer, serviceProvidersWatcher } from './list';

const DELETE_STATE = 'ServiceProviders/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  serviceProviders: [],
  nextUrl: null,
  isFetching: true
};

export const listServiceProvidersReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  serviceProvidersReducer(newState, action);

  return newState;
};

export const serviceProvidersSagas = [serviceProvidersWatcher];
export * from './list';
