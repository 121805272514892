import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';

import FilterBox from 'shared/components/FilterBox';

import './styles.scss';

export const renderSelect = ({ className, onBlur, ...props }) => {
  return (
    <Select
      {...props}
      className={classnames(className, 'react-select-container')}
      classNamePrefix="react-select"
      onBlur={e => !_.isNil(onBlur) && onBlur(e)}
    />
  );
};

export const formSelect = props => {
  const {
    formErrors,
    meta: { touched, error, warning }
  } = props;
  const fieldErrors = _.get(formErrors, props.input.name);

  return (
    <div>
      <Select
        {...props}
        {...props.input}
        styles={props.stylesObj}
        className={classnames('react-select-container', props.className)}
        classNamePrefix="react-select"
        onBlur={() => props.input.onBlur()}
      />
      {touched &&
        ((error && <span className="invalid-feedback">{error}</span>) ||
          (warning && <span>{warning}</span>))}
      {touched &&
        fieldErrors &&
        fieldErrors.map((error, index) => (
          <span className="invalid-feedback" key={index}>
            {error}
          </span>
        ))}
    </div>
  );
};

export const boxSelect = props => {
  const {
    options,
    meta: { touched, error, warning }
  } = props;

  return (
    <React.Fragment>
      <div className="box-select-wrapper">
        {options.map(option => {
          return (
            <FilterBox
              className="box-select-item-wrapper"
              title={option.label}
              selected={!_.isNil(_.find(props.input.value, option))}
              onClickEvent={() => {
                props.input.onChange(
                  _.xorBy(props.input.value, [option], 'value')
                );
                props.input.onBlur();
              }}
            />
          );
        })}
      </div>
      {touched &&
        ((error && <span className="invalid-feedback">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </React.Fragment>
  );
};

const normalizeMultipleValues = values => values.map(item => item.value);
const normalizeSingleValue = value => value.value;

export const normalizeRenderedSelect = (value, isMultiple = false) => {
  if (!_.isNil(value)) {
    return isMultiple
      ? normalizeMultipleValues(value)
      : normalizeSingleValue(value);
  }

  return value;
};

export const normalizeRenderedSelectValues = (data = {}, fieldNames = []) => {
  const result = { ...data };

  for (let fieldName of fieldNames) {
    if (!_.isNil(data[fieldName])) {
      if (!_.isNil(data[fieldName].length)) {
        result[fieldName] = normalizeRenderedSelect(data[fieldName], true);
      } else {
        result[fieldName] = normalizeRenderedSelect(data[fieldName], false);
      }
    }
  }

  return result;
};
