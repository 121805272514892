import { LOCATION_CHANGE } from 'react-router-redux';

const actionPrefix = 'NotificationBar/';

const OPEN_NOTIFICATION_BAR = `${actionPrefix}OPEN_NOTIFICTAION_BAR`;
const CLOSE_NOTIFICATION_BAR = `${actionPrefix}CLOSE_NOTIFICTAION_BAR`;

export const openNotificationBar = (
  content,
  redirectUrl,
  isWarning = false
) => ({
  type: OPEN_NOTIFICATION_BAR,
  payload: { content, redirectUrl, isWarning }
});

export const closeNotificationBar = () => ({
  type: CLOSE_NOTIFICATION_BAR
});

const initialState = {
  isOpen: false,
  content: ''
};

let prevPathname = '';

export const notificationBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NOTIFICATION_BAR:
      return {
        ...state,
        isOpen: true,
        content: action.payload.content,
        isWarning: action.payload.isWarning,
        redirectUrl: action.payload.redirectUrl
      };

    case CLOSE_NOTIFICATION_BAR:
      return {
        ...state,
        isOpen: false,
        content: ''
      };

    case LOCATION_CHANGE:
      let newState = {
        ...state,
        isOpen: false,
        content: '',
        prevPathname: prevPathname
      };
      prevPathname = action.payload.pathname;

      return newState;

    default:
      return state;
  }
};
