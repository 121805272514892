import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'GlobalSearch/';
const FETCH_SERVICE_PROVIDERS = `${actionPrefix}FETCH_SERVICE_PROVIDERS`;
const SUCCESS_FETCH_SERVICE_PROVIDERS = `${actionPrefix}SUCCESS_FETCH_SERVICE_PROVIDERS`;
const FAIL_FETCH_SERVICE_PROVIDERS = `${actionPrefix}FAIL_FETCH_SERVICE_PROVIDERS`;

export const fetchServiceProviders = args => ({
  type: FETCH_SERVICE_PROVIDERS,
  payload: args
});

export const successFetchServiceProviders = payload => ({
  type: SUCCESS_FETCH_SERVICE_PROVIDERS,
  payload
});

export const failFetchServiceProviders = payload => ({
  type: FAIL_FETCH_SERVICE_PROVIDERS,
  payload
});

export const serviceProvidersReducer = (state, action) => {
  switch (action.type) {
    case FETCH_SERVICE_PROVIDERS:
      state['fetchingServiceProviders'] = true;
      break;

    case SUCCESS_FETCH_SERVICE_PROVIDERS:
      state['serviceProviders'] = action.payload.results;
      state['serviceProvidersCount'] = action.payload.count;

      state['fetchingServiceProviders'] = false;
      break;

    default:
      return state;
  }
};

function* fetchServiceProvidersWorker(action) {
  const response = yield makeRequest(api.serviceProvidersList, {
    requestData: { params: { limit: 4, ...action.payload } }
  });

  if (response.success) yield put(successFetchServiceProviders(response.data));
  else yield put(failFetchServiceProviders(response.errors));
}

export function* serviceProvidersSaga() {
  yield takeLatest(FETCH_SERVICE_PROVIDERS, fetchServiceProvidersWorker);
}
