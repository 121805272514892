export const COUNTRY = {
  name: 'country',
  multiple: true,
  select: true,
  display: true,
  title: 'Country',
  colspan: 'colspan-1'
};

export const SECTOR = {
  name: 'sector',
  multiple: true,
  display: true,
  title: 'Sector',
  colspan: 'colspan-1'
};

export const LIFECYCLE = {
  name: 'lifecycle',
  multiple: true,
  display: true,
  title: 'Lifecycle',
  colspan: 'colspan-1'
};

export const TECHNOLOGY = {
  name: 'technology',
  multiple: true,
  select: true,
  display: true,
  title: 'Technology',
  choices: 'technologies',
  solutionType: 1,
  colspan: 'colspan-1'
};

export const JOB_FUNCTION = {
  name: 'job_function',
  multiple: true,
  select: true,
  display: true,
  title: 'Job Function',
  choices: 'jobFunctions',
  solutionType: 2,
  colspan: 'colspan-1'
};

export const BUSINESS_MODEL = {
  name: 'business_model',
  multiple: true,
  select: true,
  display: true,
  title: 'Business Model',
  choices: 'businessModels',
  solutionType: 4,
  colspan: 'colspan-1'
};

export const SUB_INDUSTRY = {
  name: 'sub_industry',
  multiple: true,
  select: true,
  display: true,
  title: 'Sub Industry',
  choices: 'subIndustries',
  solutionType: 3,
  colspan: 'colspan-1'
};

export const ORDER_BY = {
  id: 'filter-order-by',
  name: 'ordering',
  display: true,
  multiple: false,
  select: true,
  isInstant: true,
  isSeparate: true,
  prefix: 'Sort by:',
  suffix: ''
};

export const LANGUAGE = {
  ...ORDER_BY,
  name: 'language',
  id: 'filter-language',
  prefix: 'Show',
  suffix: 'Results',
  remainOnMobile: true,
  defaultOption: {
    label: 'English',
    value: 1
  }
};

export const INFLUENCER_ORDER_BY = {
  ...ORDER_BY,
  defaultOption: {
    label: 'Most Influential All Time',
    value: 6
  }
};

export const RESOURCE_ORDER_BY = {
  ...ORDER_BY,
  remainOnMobile: true,
  defaultOption: {
    label: 'Most Recent',
    value: 1
  }
};

export const COMPANIES_ORDER_BY = {
  ...ORDER_BY,
  defaultOption: {
    label: 'Recommended',
    value: 1
  }
};

export const SERVICE_PROVIDERS_ORDER_BY = {
  ...ORDER_BY,
  defaultOption: {
    label: 'Unsorted',
    value: 1
  }
};

export const INVESTOR_ORDER_BY = {
  ...ORDER_BY,
  defaultOption: {
    label: 'Unsorted',
    value: 0
  }
};

export const SEARCH = {
  id: 'filter-search-input',
  name: 'search',
  multiple: false,
  display: true,
  select: false,
  title: 'Search',
  colspan: 'colspan-2',
  main: true,
  isInstant: true
};

export const INLINE_SEARCH = {
  id: 'inline-filter-search-input',
  name: 'search',
  multiple: false,
  display: true,
  select: false,
  title: 'Search',
  main: true,
  isInstant: true,
  isSeparate: true,
  remainOnMobile: true
};

export const AUTHOR = {
  name: 'author',
  multiple: true,
  select: true,
  display: true,
  title: 'Author',
  colspan: 'colspan-1'
};

export const ARTICLE_TYPE = {
  name: 'resource_type',
  multiple: true,
  display: true,
  title: 'Type',
  colspan: 'colspan-1'
};

export const FUNDING = {
  name: 'is_raising_funds',
  multiple: false,
  display: true,
  title: 'Show Fundraising Companies',
  colspan: 'colspan-half',
  isInstant: false
};

export const SERVICE_TYPE = {
  name: 'service_types',
  multiple: true,
  select: true,
  display: true,
  title: 'Service Type',
  colspan: 'colspan-1'
};

export const SHOW_INACTIVE = {
  name: 'is_inactive',
  multiple: false,
  display: true,
  title: 'Show Inactive Companies',
  colspan: 'colspan-half',
  isInstant: false,
  defaultValue: false
};

export const INVESTMENT_STAGE = {
  name: 'investment_stage',
  multiple: true,
  select: true,
  display: true,
  title: 'Investment Stage',
  colspan: 'colspan-1'
};

export const INVESTOR_TYPE = {
  name: 'investor_types',
  multiple: true,
  select: true,
  display: true,
  title: 'Investor Type',
  colspan: 'colspan-1'
};

const COMPANIES_FILTERS = [
  { ...INLINE_SEARCH, placeholder: 'Search Company Directory' },
  LIFECYCLE,
  COUNTRY,
  SECTOR,
  SUB_INDUSTRY,
  TECHNOLOGY,
  JOB_FUNCTION,
  BUSINESS_MODEL,
  FUNDING,
  SHOW_INACTIVE,
  COMPANIES_ORDER_BY
];

const EVENTS_FILTERS = [
  { ...INLINE_SEARCH, placeholder: 'Search PropTech Events' },
  COUNTRY,
  SECTOR,
  SUB_INDUSTRY
];

const RESOURCES_FILTERS = [
  { ...INLINE_SEARCH, placeholder: 'Search Content Library' },
  ARTICLE_TYPE,
  COUNTRY,
  SECTOR,
  SUB_INDUSTRY,
  TECHNOLOGY,
  AUTHOR,
  RESOURCE_ORDER_BY
];

const INFLUENCERS_FILTERS = [
  { ...INLINE_SEARCH, placeholder: 'Search Influencer Directory' },
  COUNTRY,
  TECHNOLOGY,
  SUB_INDUSTRY,
  INFLUENCER_ORDER_BY
];

const SERVICE_PROVIDERS_FILTERS = [
  { ...INLINE_SEARCH, placeholder: 'Search Service Provider Directory' },
  SERVICE_TYPE,
  COUNTRY,
  SECTOR,
  SERVICE_PROVIDERS_ORDER_BY
];

const INVESTORS_FILTERS = [
  { ...INLINE_SEARCH, placeholder: 'Search Investor Directory' },
  TECHNOLOGY,
  SECTOR,
  SUB_INDUSTRY,
  INVESTMENT_STAGE,
  INVESTOR_TYPE,
  COUNTRY,
  INVESTOR_ORDER_BY
];

const CONNECT_VIDEO_FILTERS = [
  { ...INLINE_SEARCH, placeholder: 'Search Connect Videos' },
  TECHNOLOGY,
  COUNTRY
];

const NEWS_FILTERS = [
  {
    ...INLINE_SEARCH,
    placeholder: 'Search PropTech News'
  },
  LANGUAGE
];

const JOB_FILTERS = [
  {
    ...INLINE_SEARCH,
    placeholder: 'Search for available PropTech Jobs...'
  },
  TECHNOLOGY,
  SECTOR,
  SUB_INDUSTRY,
  COUNTRY
];

const CASE_STUDY_FILTERS = [
  {
    ...INLINE_SEARCH,
    placeholder: 'Search for PropTech Case Studies...'
  },
  TECHNOLOGY,
  JOB_FUNCTION,
  COUNTRY
];

export const COMPANIES_PAGE_NAME = 'companies';
export const RESOURCES_PAGE_NAME = 'resources';
export const EVENTS_PAGE_NAME = 'events';
export const INFLUENCERS_PAGE_NAME = 'influencers';
export const SERVICE_PROVIDERS_PAGE_NAME = 'serviceProviders';
export const INVESTORS_PAGE_NAME = 'investors';
export const CONNECT_VIDEO_PAGE_NAME = 'connectVideos';
export const NEWS_PAGE_NAME = 'news';
export const JOB_PAGE_NAME = 'jobs';
export const CASE_STUDY_PAGE_NAME = 'caseStudies';

export const FILTERS = {
  [COMPANIES_PAGE_NAME]: COMPANIES_FILTERS,
  [RESOURCES_PAGE_NAME]: RESOURCES_FILTERS,
  [EVENTS_PAGE_NAME]: EVENTS_FILTERS,
  [INFLUENCERS_PAGE_NAME]: INFLUENCERS_FILTERS,
  [SERVICE_PROVIDERS_PAGE_NAME]: SERVICE_PROVIDERS_FILTERS,
  [INVESTORS_PAGE_NAME]: INVESTORS_FILTERS,
  [CONNECT_VIDEO_PAGE_NAME]: CONNECT_VIDEO_FILTERS,
  [NEWS_PAGE_NAME]: NEWS_FILTERS,
  [JOB_PAGE_NAME]: JOB_FILTERS,
  [CASE_STUDY_PAGE_NAME]: CASE_STUDY_FILTERS
};

export const SOLUTION_FILTER_NAMES = {
  [TECHNOLOGY.solutionType]: TECHNOLOGY.name,
  [JOB_FUNCTION.solutionType]: JOB_FUNCTION.name,
  [SUB_INDUSTRY.solutionType]: SUB_INDUSTRY.name,
  [BUSINESS_MODEL.solutionType]: BUSINESS_MODEL.name
};

export const FILTERS_OF_ORDER_BY_TYPE = [LANGUAGE, ORDER_BY];
