import _ from 'lodash';

import {
  COUNTRY,
  SECTOR,
  TECHNOLOGY,
  JOB_FUNCTION,
  BUSINESS_MODEL
} from 'shared/components/Filters/constants';

const SOLUTION_TYPE_TO_FILTER_NAME_MAPPING = {
  Technology: TECHNOLOGY.name,
  'Job Function': JOB_FUNCTION.name,
  'Business Model': BUSINESS_MODEL.name
};

export const getFilterValues = ({ solution, sector, country }) => {
  const filters = {
    [SECTOR.name]: _.get(sector, 'id'),
    [COUNTRY.name]: _.get(country, 'id'),
    [SOLUTION_TYPE_TO_FILTER_NAME_MAPPING[
      _.get(solution, 'type.label')
    ]]: _.get(solution, 'id')
  };

  return _.pickBy(filters, item => !_.isNil(item)); // remove empty values
};
