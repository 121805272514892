import _ from 'lodash';
import { takeLatest } from 'redux-saga/effects';

import { makeRequest } from 'shared/sdk';

import { typeToApiMapping } from './constants';

const actionPrefix = 'Shared/DetailTracker/';

const TRACK_IMPRESSION = `${actionPrefix}TRACK_IMPRESSION`;

export const trackImpression = ({ slug, type }) => ({
  type: TRACK_IMPRESSION,
  payload: { slug, type }
});

function* trackImpressionWorker(action) {
  const { slug, type } = action.payload;

  const callAPI = typeToApiMapping[[type]];

  if (_.isNil(callAPI)) {
    return;
  }

  yield makeRequest(callAPI, {
    lookupData: { slug }
  });
}

export function* trackingSaga() {
  yield takeLatest(TRACK_IMPRESSION, trackImpressionWorker);
}
