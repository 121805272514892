import moment from 'moment';
import { JWT_EXPIRATION_DELTA_HOURS } from 'global-constants';

export const setAuthorization = token => {
  localStorage.setItem('token', token);
  /*
    We need to use cookie authorization instead of Basic HTTP
    authorization because of the VanillaForums integration
    We set the token received from login/refresh as a cookie as well
  */

  localStorage.setItem(
    'tokenExpiresAt',
    moment().add(JWT_EXPIRATION_DELTA_HOURS, 'hours')
  );

  document.cookie = `JWT=${token};domain=.unissu.com`;
};
