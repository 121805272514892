import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { buildFilters } from 'shared/utils/filters';

const actionPrefix = 'ConnectVideo/List/';

const DELETE_STATE = `${actionPrefix}DELETE_STATE`;

const FETCH_CONNECT_VIDEOS = `${actionPrefix}FETCH_CONNECT_VIDEOS`;
const SUCCESS_FETCH_CONNECT_VIDEOS = `${actionPrefix}SUCCESS_FETCH_CONNECT_VIDEOS`;
const FAIL_FETCH_CONNECT_VIDEOS = `${actionPrefix}FAIL_FETCH_CONNECT_VIDEOS`;

const FETCH_NEXT_CONNECT_VIDEOS = `${actionPrefix}FETCH_NEXT_CONNECT_VIDEOS`;
const SUCCESS_FETCH_NEXT_CONNECT_VIDEOS = `${actionPrefix}SUCCESS_FETCH_NEXT_CONNECT_VIDEOS`;
const FAIL_FETCH_NEXT_CONNECT_VIDEOS = `${actionPrefix}FAIL_FETCH_NEXT_CONNECT_VIDEOS`;

const FETCH_HEADLINE_CONNECT_SPONSORS = `${actionPrefix}FETCH_HEADLINE_CONNECT_SPONSORS`;
const SUCCESS_FETCH_HEADLINE_CONNECT_SPONSORS = `${actionPrefix}SUCCESS_FETCH_HEADLINE_CONNECT_SPONSORS`;
const FAIL_FETCH_HEADLINE_CONNECT_SPONSORS = `${actionPrefix}FAIL_FETCH_HEADLINE_CONNECT_SPONSORS`;

export const deleteState = () => ({
  type: DELETE_STATE
});

export const fetchConnectVideos = ({ filters }) => ({
  type: FETCH_CONNECT_VIDEOS,
  payload: { filters }
});

const successFetchConnectVideos = ({ data: { next, results } }) => ({
  type: SUCCESS_FETCH_CONNECT_VIDEOS,
  payload: { results, next }
});

const failFetchConnectVideos = ({ errors }) => ({
  type: FAIL_FETCH_CONNECT_VIDEOS,
  payload: { errors }
});

export const fetchNextConnectVideos = ({ nextUrl }) => ({
  type: FETCH_NEXT_CONNECT_VIDEOS,
  payload: { nextUrl }
});

const successFetchNextConnectVideos = ({ data: { next, results } }) => ({
  type: SUCCESS_FETCH_NEXT_CONNECT_VIDEOS,
  payload: { results, next }
});

const failFetchNextConnectVideos = ({ errors }) => ({
  type: FAIL_FETCH_NEXT_CONNECT_VIDEOS,
  payload: { errors }
});

export const fetchHeadlineConnectSponsors = () => ({
  type: FETCH_HEADLINE_CONNECT_SPONSORS
});

const successFetchHeadlineConnectSponsors = ({ data: { results } }) => ({
  type: SUCCESS_FETCH_HEADLINE_CONNECT_SPONSORS,
  payload: { results }
});

const failFetchHeadlineConnectSponsors = ({ errors }) => ({
  type: FAIL_FETCH_HEADLINE_CONNECT_SPONSORS,
  payload: { errors }
});

const initialState = {
  nextUrl: null,
  isFetching: false,
  connectVideos: [],
  headlineSponsors: [],
  isFetchingHeadlineSponsors: false
};

export const connectVideoListReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case DELETE_STATE:
      newState = initialState;
      break;

    case FETCH_CONNECT_VIDEOS:
      newState['isFetching'] = true;
      newState['connectVideos'] = [];
      break;

    case SUCCESS_FETCH_CONNECT_VIDEOS:
      newState['isFetching'] = false;
      newState['nextUrl'] = action.payload.next;
      newState['connectVideos'] = action.payload.results;
      break;

    case SUCCESS_FETCH_NEXT_CONNECT_VIDEOS:
      newState['nextUrl'] = action.payload.next;
      newState['connectVideos'] = [
        ...state['connectVideos'],
        ...action.payload.results
      ];
      break;

    case FETCH_HEADLINE_CONNECT_SPONSORS:
      newState['isFetchingHeadlineSponsors'] = true;
      break;

    case SUCCESS_FETCH_HEADLINE_CONNECT_SPONSORS:
      newState['headlineSponsors'] = action.payload.results;
      newState['isFetchingHeadlineSponsors'] = false;
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchConnectVideosWorker(action) {
  const { filters } = action.payload;

  const response = yield makeRequest(api.connectVideoList, {
    requestData: { params: { limit: 15, ...buildFilters(filters) } }
  });

  if (response.success) {
    yield put(successFetchConnectVideos(response));
  } else {
    yield put(failFetchConnectVideos(response));
  }
}

function* fetchNextConnectVideosWorker(action) {
  const { nextUrl } = action.payload;

  const response = yield makeRequest(api.genericGet, {
    lookupData: nextUrl
  });

  if (response.success) {
    yield put(successFetchNextConnectVideos(response));
  } else {
    yield put(failFetchNextConnectVideos(response));
  }
}

function* fetchHeadlineConnectSponsorsWorker(action) {
  const response = yield makeRequest(api.headlineConnectSponsorsList);

  if (response.success) {
    yield put(successFetchHeadlineConnectSponsors(response));
  } else {
    yield put(failFetchHeadlineConnectSponsors(response));
  }
}

export function* connectVideoListWatcher() {
  yield takeLatest(FETCH_CONNECT_VIDEOS, fetchConnectVideosWorker);
  yield takeLatest(FETCH_NEXT_CONNECT_VIDEOS, fetchNextConnectVideosWorker);
  yield takeLatest(
    FETCH_HEADLINE_CONNECT_SPONSORS,
    fetchHeadlineConnectSponsorsWorker
  );
}
