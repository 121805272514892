import React from 'react';

import classnames from 'classnames';
import { Portal } from 'react-portal';

import desktopLogo from 'assets/images/square_logo.svg';
import mobileLogo from 'assets/images/logo.svg';

import { disableBodyScroll, enableBodyScroll } from 'shared/utils/common';

import styles from './styles.module.scss';

export class FullScreenModal extends React.Component {
  componentDidMount() {
    disableBodyScroll();
  }

  componentWillUnmount() {
    enableBodyScroll();
  }

  render() {
    const { closeModal, children, withClose = true } = this.props;
    return (
      <Portal>
        <div className={styles.fullscreenModalContainer}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeadline}>
              <div className={styles.unissuLogo}>
                <img className="remove-xs" src={desktopLogo} alt="Logo" />
                <img className="remove-sm-min" src={mobileLogo} alt="Logo" />
              </div>
              {withClose && (
                <div className={styles.modalClose} onClick={closeModal}>
                  <i className={classnames(styles.closeBtn, 'material-icons')}>
                    clear
                  </i>
                </div>
              )}
            </div>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </Portal>
    );
  }
}
