export const ORGANIZATION_SCHEMA_IDENTIFIER = 'organization-schema';
export const WEBSITE_SCHEMA_IDENTIFIER = 'website-schema';

export const ORGANIZATION_SCHEMA_CODE = JSON.stringify({
  '@context': 'https://schema.org/',
  '@type': 'Organization',
  description:
    'Unissu is a community-driven global platform housing a directory, forum, resources and much more, helping you to find, filter and analyse PropTech businesses, products and resources.',
  name: 'Unissu',
  url: process.env.REACT_APP_MAIN_URL,
  logo: `${process.env.PUBLIC_URL}/assets/images/logo.png`,
  sameAs: [
    'https://twitter.com/unissu_global',
    'https://www.linkedin.com/company/unissu/'
  ]
});

export const WEBSITE_SCHEMA_CODE = JSON.stringify({
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: 'Unissu',
  url: process.env.REACT_APP_MAIN_URL,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${
      process.env.REACT_APP_MAIN_URL
    }/search?search={search_term_string}`,
    'query-input': 'required name=search_term_string'
  }
});
