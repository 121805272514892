import _ from 'lodash';
import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

import { nextStep } from '../';
const actionPrefix = 'Signup/';

const FETCH_COMPANY_BY_EMAIL = `${actionPrefix}FETCH_COMPANY_BY_EMAIL`;
const SUCCESS_FETCH_COMPANY_BY_EMAIL = `${actionPrefix}SUCCESS_FETCH_COMPANY_BY_EMAIL`;
const FAIL_FETCH_COMPANY_BY_EMAIL = `${actionPrefix}FAIL_FETCH_COMPANY_BY_EMAIL`;

const REQUEST_JOIN_COMPANY = `${actionPrefix}REQUEST_JOIN_COMPANY`;
const SUCCESS_JOIN_COMPANY = `${actionPrefix}SUCCESS_JOIN_COMPANY`;
const FAIL_JOIN_COMPANY = `${actionPrefix}FAIL_JOIN_COMPANY`;

const SET_COMPANY = `${actionPrefix}SET_COMPANY`;
const SET_COMPANY_EMAIL = `${actionPrefix}SET_COMPANY_EMAIL`;

export const fetchCompanyByEmail = (email = null, extraActions = []) => ({
  type: FETCH_COMPANY_BY_EMAIL,
  payload: {
    email
  },
  extraActions
});

const successFetchCompanyByEmail = payload => ({
  type: SUCCESS_FETCH_COMPANY_BY_EMAIL,
  payload
});

const failFetchCompanyByEmail = payload => ({
  type: FAIL_FETCH_COMPANY_BY_EMAIL,
  payload
});

export const requestJoinCompany = (slug, membership_type, work_email) => ({
  type: REQUEST_JOIN_COMPANY,
  slug,
  payload: {
    membership_type,
    work_email
  }
});

export const successJoinCompany = payload => ({
  type: SUCCESS_JOIN_COMPANY,
  payload
});

export const failJoinCompany = payload => ({
  type: FAIL_JOIN_COMPANY,
  payload
});

export const setCompany = (detectedCompany = null) => ({
  type: SET_COMPANY,
  payload: {
    detectedCompany
  }
});

export const setCompanyEmail = (responseData, email = null) => ({
  type: SET_COMPANY_EMAIL,
  payload: {
    responseData,
    email
  }
});

export const joinCompanyReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COMPANY_BY_EMAIL:
      if (action.payload.id) {
        newState['detectedCompany'] = action.payload;
      }
      break;

    case SET_COMPANY:
      newState['detectedCompany'] = action.payload.detectedCompany;
      break;

    case SET_COMPANY_EMAIL:
      if (action.payload.responseData.email === '')
        newState['joinTeamError'] = true;
      else newState['joinTeamError'] = false;

      newState['workEmail'] = action.payload.email;
      break;

    default:
      return newState;
  }
};

function* fetchCompanyByEmailWorker(action) {
  let response;

  if (_.isNil(action.payload.email)) {
    response = yield makeRequest(api.getCompanyByEmail);
  } else {
    response = yield makeRequest(api.postCompanyByEmail, {
      requestBody: action.payload
    });
  }

  if (response.success) {
    yield put(successFetchCompanyByEmail(response.data));
    yield* action.extraActions.map(extraAction =>
      put(extraAction(response.data))
    );
  } else yield put(failFetchCompanyByEmail(response.errors));
}

function* requestJoinCompanyWorker(action) {
  const response = yield makeRequest(api.joinCompany, {
    lookupData: { slug: action.slug },
    requestBody: action.payload
  });

  if (response.success)
    yield* [put(successJoinCompany(response.data)), put(nextStep())];
  else yield put(failJoinCompany(response.errors));
}

export function* joinCompanySaga() {
  yield takeLatest(FETCH_COMPANY_BY_EMAIL, fetchCompanyByEmailWorker);
  yield takeLatest(REQUEST_JOIN_COMPANY, requestJoinCompanyWorker);
}
