import React from 'react';

import _ from 'lodash';
import { detect } from 'detect-browser';

class BrowserDetection extends React.Component {
  componentDidMount() {
    const browser = detect();
    const majorVersion = _.get(browser, 'version', '').split('.')[0];
    let result = true;
    switch (browser && browser.name) {
      case 'chrome':
        result = majorVersion >= 64;
        break;
      case 'firefox':
        result = majorVersion >= 58;
        break;
      case 'opera':
        result = majorVersion >= 49;
        break;
      case 'safari':
        result = majorVersion >= 11;
        break;
      case 'edge':
        result = majorVersion >= 41;
        break;
      default:
        result = true;
    }

    if (!result) {
      alert(
        'You are using an older browser version. Some features might not behave properly.'
      );
    }
  }

  render() {
    return null;
  }
}

export default BrowserDetection;
