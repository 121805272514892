import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'UserOpinions/';
const FETCH_USER_OPINIONS = `${actionPrefix}FETCH_USER_OPINIONS`;
const SUCCESS_FETCH_USER_OPINIONS = `${actionPrefix}SUCCESS_FETCH_USER_OPINIONS`;
const FAIL_FETCH_USER_OPINIONS = `${actionPrefix}FAIL_FETCH_USER_OPINIONS`;

const initialState = {
  opinions: [],
  fetching: true
};

export const fetchUserOpinions = () => ({
  type: FETCH_USER_OPINIONS
});

export const successFetchUserOpinions = payload => ({
  type: SUCCESS_FETCH_USER_OPINIONS,
  payload
});

export const failFetchUserOpinions = payload => ({
  type: FAIL_FETCH_USER_OPINIONS,
  payload
});

export const userOpinionsReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SUCCESS_FETCH_USER_OPINIONS:
      newState = {
        opinions: action.payload,
        fetching: false
      };
      break;

    default:
      return state;
  }
  return Object.assign({}, state, newState);
};

function* fetchUserOpinionsWorker(action) {
  const response = yield makeRequest(api.userOpinions);

  if (response.success) yield put(successFetchUserOpinions(response.data));
  else yield put(failFetchUserOpinions(response.errors));
}

function* userOpinionsSaga() {
  yield takeLatest(FETCH_USER_OPINIONS, fetchUserOpinionsWorker);
}

export default userOpinionsSaga;
