import React from 'react';

import { withRouter } from 'react-router-dom';

const WithPush = WrappedComponent => {
  class HOC extends React.Component {
    render() {
      const {
        history: { push },
        history,
        ...rest
      } = this.props;
      return <WrappedComponent push={push} history={history} {...rest} />;
    }
  }

  return withRouter(HOC);
};

export default WithPush;
