import _ from 'lodash';
import { put } from 'redux-saga/effects';
import { startSubmit, stopSubmit, setSubmitFailed } from 'redux-form';

export const formSubmitWorker = inner => {
  return function*(action) {
    if (_.isNil(action.formName))
      throw new Error('`formName` must be passed to action');

    yield put(startSubmit(action.formName));
    const returnedErrors = yield inner(action);

    let errors = {};

    if (!_.isNil(returnedErrors)) {
      errors = { ...returnedErrors };

      if (!_.isNil(returnedErrors.non_field_errors)) {
        errors._error = returnedErrors.non_field_errors;
      }

      yield put(setSubmitFailed(action.formName));
    }

    yield put(stopSubmit(action.formName, errors));
  };
};
