import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { nextStep } from 'shared/components/Signup/ducks/manageSteps';

const actionPrefix = 'validateCredentials/';

const VALIDATE_EMAIL = `${actionPrefix}VALIDATE_EMAIL`;
const VALIDATE_PASSWORD = `${actionPrefix}VALIDATE_PASSWORD`;
const VALIDATE_CREDENTIALS = `${actionPrefix}VALIDATE_CREDENTIALS`;

export const validateEmail = data => ({
  type: VALIDATE_EMAIL,
  payload: {
    data
  }
});

export const validatePassword = data => ({
  type: VALIDATE_PASSWORD,
  payload: {
    data
  }
});

export const validateCredentials = (data, formName) => ({
  type: VALIDATE_CREDENTIALS,
  payload: { data },
  formName
});

function* validateCredentialsWorker(action) {
  const { email, password } = action.payload.data;
  const passwordResponse = yield makeRequest(api.validatePassword, {
    requestBody: { password }
  });

  const emailResponse = yield makeRequest(api.validateEmail, {
    requestBody: { email }
  });

  if (emailResponse.success && passwordResponse.success) yield put(nextStep());
  else return { ...emailResponse.errors, ...passwordResponse.errors };
}

export function* validateCredentialsWatcher() {
  yield takeLatest(
    VALIDATE_CREDENTIALS,
    formSubmitWorker(validateCredentialsWorker)
  );
}
