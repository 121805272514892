import React from 'react';

import classnames from 'classnames';
import { Portal } from 'react-portal';

import { disableBodyScroll, enableBodyScroll } from 'shared/utils/common';

import styles from './styles.module.scss';

class PopOverModal extends React.Component {
  componentDidMount() {
    disableBodyScroll();
  }

  componentWillUnmount() {
    enableBodyScroll();
  }

  render() {
    const {
      closeModal,
      children,
      headline,
      className,
      footer,
      withoutHeaderBorder,
      modalCloseIconClassName
    } = this.props;

    return (
      <Portal>
        <div className={styles.modalWrapper}>
          <div className={styles.modalDim}>
            <div className={classnames(className, styles.modal)}>
              <div
                className={classnames(styles.header, {
                  [styles.withoutHeaderBorder]: withoutHeaderBorder
                })}>
                <div className={styles.heading}>{headline}</div>
                <div
                  className={classnames(
                    { [styles.modalClose]: !modalCloseIconClassName },
                    { [modalCloseIconClassName]: modalCloseIconClassName }
                  )}
                  onClick={closeModal}>
                  <i className={classnames(styles.closeBtn, 'material-icons')}>
                    clear
                  </i>
                </div>
              </div>
              <div className={styles.content}>
                {children}
                <div className={classnames({ [styles.footer]: footer })}>
                  {footer}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

export default PopOverModal;
