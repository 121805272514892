import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { uploadFile } from 'shared/utils/fileUpload';
import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'EditCompany/Products/';

const CREATE_PRODUCT = `${actionPrefix}CREATE_PRODUCT`;
const SUCCESS_CREATE_PRODUCT = `${actionPrefix}SUCCESS_CREATE_PRODUCT`;
const FAIL_CREATE_PRODUCT = `${actionPrefix}FAIL_CREATE_PRODUCT`;

export const createProduct = (slug, data) => ({
  type: CREATE_PRODUCT,
  payload: { slug, data }
});

const successCreateProduct = payload => ({
  type: SUCCESS_CREATE_PRODUCT,
  payload
});

const failCreateProduct = payload => ({
  type: FAIL_CREATE_PRODUCT,
  payload
});

export const productsReducer = (newState, action) => {
  switch (action.type) {
    case CREATE_PRODUCT:
      newState['creatingProduct'] = true;
      break;

    case SUCCESS_CREATE_PRODUCT:
      newState['creatingProduct'] = false;
      newState['company'] = {
        ...newState.company,
        products: addToList(newState.company.products, action.payload)
      };
      break;

    case FAIL_CREATE_PRODUCT:
      newState['creatingProduct'] = false;
      break;

    default:
      break;
  }
};

function* createProductWorker(action) {
  let { data } = action.payload;
  const logoArr = _.get(action.payload.data, 'logo', null);

  if (!_.isNil(logoArr) && Array.isArray(logoArr)) {
    const logo = logoArr[0];

    const logoUrl = yield uploadFile(logo, api.signProductLogo);

    data = Object.assign({}, action.payload.data, { logo: logoUrl });
  }

  const response = yield makeRequest(api.createProduct, {
    requestBody: data,
    lookupData: { slug: action.payload.slug }
  });

  if (response.success) yield put(successCreateProduct(response.data));
  else yield put(failCreateProduct(response.errors));
}

export function* productsWatcher() {
  yield takeLatest(CREATE_PRODUCT, createProductWorker);
}
