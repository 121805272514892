import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Companies/Detail/Ratings/';

const FETCH_RATINGS = `${actionPrefix}FETCH_RATINGS`;
const SUCCESS_FETCH_RATINGS = `${actionPrefix}SUCCESS_FETCH_RATINGS`;
const FAIL_FETCH_RATINGS = `${actionPrefix}FAIL_FETCH_RATINGS`;

const UPDATE_RATING = `${actionPrefix}UPDATE_RATING`;
const SUCCESS_UPDATE_RATING = `${actionPrefix}SUCCESS_UPDATE_RATING`;
const FAIL_UPDATE_RATING = `${actionPrefix}FAIL_UPDATE_RATING`;

const LINK_ANONYMOUS_RATINGS_TO_USER = `${actionPrefix}LINK_ANONYMOUS_RATINGS_TO_USER`;
const SUCCESS_LINK_ANONYMOUS_RATINGS_TO_USER = `${actionPrefix}SUCCESS_LINK_ANONYMOUS_RATINGS_TO_USER`;
const FAIL_LINK_ANONYMOUS_RATINGS_TO_USER = `${actionPrefix}FAIL_LINK_ANONYMOUS_RATINGS_TO_USER`;

export const fetchRatings = ({ type, slug, extra }) => ({
  type: FETCH_RATINGS,
  payload: {
    slug,
    type
  },
  extra
});

const successFetchRatings = data => ({
  type: SUCCESS_FETCH_RATINGS,
  payload: {
    data
  }
});

const failFetchRatings = errors => ({
  type: FAIL_FETCH_RATINGS,
  payload: {
    errors
  }
});

export const updateRating = ({
  type,
  slug,
  token,
  rating,
  savePointToLocalStorage
}) => ({
  type: UPDATE_RATING,
  payload: {
    type,
    slug,
    token,
    rating
  },
  savePointToLocalStorage
});

const successUpdateRating = () => ({
  type: SUCCESS_UPDATE_RATING
});

const failUpdateRating = errors => ({
  type: FAIL_UPDATE_RATING,
  payload: { errors }
});

export const linkAnonymousRatingsToUser = ({ votesData, extra = [] }) => ({
  type: LINK_ANONYMOUS_RATINGS_TO_USER,
  payload: { votesData },
  extra
});

const successLinkAnonymousRatingsToUser = data => ({
  type: SUCCESS_LINK_ANONYMOUS_RATINGS_TO_USER,
  payload: { data }
});

const failLinkAnonymousRatingsToUser = errors => ({
  type: FAIL_LINK_ANONYMOUS_RATINGS_TO_USER,
  payload: { errors }
});

const initialState = {
  ratings: [],
  isLoading: false
};

export const maturityMatrixReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RATINGS:
    case LINK_ANONYMOUS_RATINGS_TO_USER:
      state['isLoading'] = true;
      break;

    case SUCCESS_FETCH_RATINGS:
      state['isLoading'] = false;
      state['ratings'] = action.payload.data.results;
      break;

    case SUCCESS_LINK_ANONYMOUS_RATINGS_TO_USER:
      state['isLoading'] = false;
      break;

    default:
      return state;
  }
  return state;
};

function* fetchRatingsWorker(action) {
  const { slug, type } = action.payload;
  const response = yield makeRequest(api.fetchRatings, {
    lookupData: { slug },
    requestData: { params: { company_type: type } }
  });

  if (response.success) {
    yield* [
      put(successFetchRatings(response.data)),
      ...action.extra.map(action => action(response.data.results))
    ];
  } else {
    yield put(failFetchRatings(response.errors));
  }
}

function* updateRatingWorker(action) {
  const { slug, token, rating, type } = action.payload;

  const response = yield makeRequest(api.updateRating, {
    lookupData: { slug },
    requestBody: { ...rating, token: token, company_type: type }
  });

  if (response.success) {
    yield put(successUpdateRating(response.data));
    action.savePointToLocalStorage(response.data);
  } else {
    yield put(failUpdateRating(response.errors));
  }
}

function* linkAnonymousRatingsToUserWorker(action) {
  const { votesData } = action.payload;

  const response = yield makeRequest(api.linkAnonymousRatingsToUser, {
    requestBody: votesData
  });

  if (response.success) {
    yield put(successLinkAnonymousRatingsToUser(response.data));
    action.extra.map(action => action());
  } else {
    yield put(failLinkAnonymousRatingsToUser(response.errors));
  }
}

export function* maturityMatrixSaga() {
  yield takeLatest(FETCH_RATINGS, fetchRatingsWorker);
  yield takeLatest(UPDATE_RATING, updateRatingWorker);
  yield takeLatest(
    LINK_ANONYMOUS_RATINGS_TO_USER,
    linkAnonymousRatingsToUserWorker
  );
}
