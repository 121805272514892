import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { api, makeRequest } from 'shared/sdk';

import { uploadFile } from 'shared/utils/fileUpload';
import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'EditProfile/Qualifications/';

const FETCH_QUALIFICATIONS = `${actionPrefix}FETCH_QUALIFICATIONS`;
const SUCCESS_FETCH_QUALIFICATIONS = `${actionPrefix}SUCCESS_FETCH_QUALIFICATIONS`;

const DELETE_QUALIFICATION = `${actionPrefix}DELETE_QUALIFICATION`;
const SUCCESS_DELETE_QUALIFICATION = `${actionPrefix}SUCCESS_DELETE_QUALIFICATION`;

const UPDATE_OR_CREATE_QUALIFICATION = `${actionPrefix}UPDATE_OR_CREATE_QUALIFICATION`;
const SUCCESS_UPDATE_OR_CREATE_QUALIFICATION = `${actionPrefix}SUCCESS_UPDATE_OR_CREATE_QUALIFICATION`;

export const fetchQualifications = ({ influencerSlug, nextUrl }) => ({
  type: FETCH_QUALIFICATIONS,
  payload: { influencerSlug, nextUrl }
});

const successFetchQualifications = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_QUALIFICATIONS,
  payload: { results, next }
});

export const deleteQualification = ({ qualificationId }) => ({
  type: DELETE_QUALIFICATION,
  payload: { qualificationId }
});

const successDeleteQualification = ({ qualificationId }) => ({
  type: SUCCESS_DELETE_QUALIFICATION,
  payload: { qualificationId }
});

export const updateOrCreateQualification = ({
  formName,
  data,
  qualificationId,
  extra
}) => ({
  type: UPDATE_OR_CREATE_QUALIFICATION,
  payload: { data, qualificationId, extra },
  formName
});

const successUpdateOrCreateQualification = ({ qualificationId, data }) => ({
  type: SUCCESS_UPDATE_OR_CREATE_QUALIFICATION,
  payload: { data, qualificationId }
});

export const qualificationsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_QUALIFICATIONS:
      state['isFetchingQualifications'] = true;
      break;

    case SUCCESS_FETCH_QUALIFICATIONS:
      state['isFetchingQualifications'] = false;
      state['qualifications'] = addToList(
        state.qualifications,
        action.payload.results
      );
      state['qualificationsNextUrl'] = action.payload.next;
      break;

    case SUCCESS_DELETE_QUALIFICATION:
      state['qualifications'] = state.qualifications.filter(
        item => item.id !== action.payload.qualificationId
      );
      break;

    case SUCCESS_UPDATE_OR_CREATE_QUALIFICATION:
      if (action.payload.qualificationId) {
        state['qualifications'] = updateByIdentifier({
          collection: state.qualifications,
          identifierField: 'id',
          identifierValue: action.payload.qualificationId,
          newItem: action.payload.data
        });
      } else {
        state['qualifications'] = addToList(
          [action.payload.data],
          state.qualifications
        );
      }
      break;

    default:
      return state;
  }

  return state;
};

function* fetchQualificationsWorker(action) {
  const { influencerSlug, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    response = yield makeRequest(api.qualificationsList, {
      requestData: { params: { limit: 10 } },
      lookupData: { influencerSlug }
    });
  }

  if (response.success) {
    yield put(successFetchQualifications(response));
  }
}

function* deleteQualificationWorker(action) {
  const { qualificationId } = action.payload;

  const response = yield makeRequest(api.qualificationDelete, {
    lookupData: { qualificationId }
  });

  if (response.success) {
    yield* [
      put(successDeleteQualification({ qualificationId })),
      put(openNotificationBar('Qualification has been deleted successfully.'))
    ];
  }
}

function* updateOrCreateQualificationWorker(action) {
  let { data } = action.payload;
  const { qualificationId, extra } = action.payload;

  const logoArr = _.get(action.payload.data, 'logo', null);

  if (!_.isNil(logoArr) && Array.isArray(logoArr)) {
    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signQualificationLogo);

    data = Object.assign({}, action.payload.data, { logo: logoUrl });
  }

  const postUrl = _.isNil(qualificationId)
    ? api.qualificationCreate
    : api.qualificationEdit;

  const response = yield makeRequest(postUrl, {
    requestBody: data,
    lookupData: { qualificationId }
  });

  if (response.success) {
    const notificationMessage = `Qualification has been ${
      _.isNil(qualificationId) ? 'created' : 'updated'
    } successfully!`;

    yield* [
      put(
        successUpdateOrCreateQualification({
          qualificationId,
          data: response.data
        })
      ),
      put(openNotificationBar(notificationMessage))
    ];
    extra.map(extraAction => extraAction());
  } else {
    return response.errors;
  }
}

export function* qualificationsSaga() {
  yield takeLatest(FETCH_QUALIFICATIONS, fetchQualificationsWorker);
  yield takeLatest(DELETE_QUALIFICATION, deleteQualificationWorker);
  yield takeLatest(
    UPDATE_OR_CREATE_QUALIFICATION,
    formSubmitWorker(updateOrCreateQualificationWorker)
  );
}
