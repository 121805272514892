import React from 'react';

import _ from 'lodash';
import classnames from 'classnames';

import styles from './styles.module.scss';

const FilterBox = ({
  image,
  title,
  onClickEvent,
  selected,
  className,
  selectedImageClassname
}) => {
  const styleImage = { backgroundImage: `url(${image})` };
  return (
    <div
      className={classnames(
        styles.filterBoxWrapper,
        className,
        { [styles.selected]: selected },
        { [styles.missingImage]: _.isNil(image) }
      )}
      onClick={onClickEvent}>
      <div
        className={classnames(styles.infoWrapper, selected && styles.selected)}>
        {image && (
          <div
            className={classnames(
              styles.image,
              selected && selectedImageClassname
            )}
            style={styleImage}
          />
        )}
        {title}
      </div>
    </div>
  );
};

export default FilterBox;
