import _ from 'lodash';
import { put, takeLatest, select } from 'redux-saga/effects';

import {
  multipleFileUploadWorkerFactory,
  asGenericUploadHook
} from 'shared/utils/fileUpload';
import { api, makeRequest } from 'shared/sdk';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

import { getFiles, getJobSlug, getCloseModal } from './selectors';

const actionPrefix = 'Jobs/Detail/Apply/';

const SIGN_FILES = `${actionPrefix}SIGN_FILES`;
const SUCCESS_SIGN_FILES = `${actionPrefix}SUCCESS_SIGN_FILES`;
const FAIL_SIGN_FILES = `${actionPrefix}FAIL_SIGN_FILES`;

const APPLY_SUCCESS = `${actionPrefix}APPLY_SUCCEESS`;

const RESET_ERRORS = `${actionPrefix}RESET_ERRORS`;

export const apply = ({ jobSlug, files, closeModal }) => ({
  type: SIGN_FILES,
  payload: { jobSlug, files, closeModal }
});

const applySuccess = () => ({
  type: APPLY_SUCCESS
});

const successUploadJobApplicationFiles = payload => ({
  type: SUCCESS_SIGN_FILES,
  payload
});

const failUploadJobApplicationFiles = errors => ({
  type: FAIL_SIGN_FILES,
  payload: { errors }
});

export const resetModalErrors = () => ({
  type: RESET_ERRORS
});

export const applyReducer = (state, action) => {
  switch (action.type) {
    case RESET_ERRORS:
      state['apply']['modal']['errors'] = [];
      break;

    case SIGN_FILES:
      state['apply']['isApplying'] = true;
      state['apply']['files'] = action.payload.files;
      state['apply']['jobSlug'] = action.payload.jobSlug;
      state['apply']['modal']['closeModal'] = action.payload.closeModal;
      break;

    case SUCCESS_SIGN_FILES:
      state['apply']['isApplying'] = false;
      break;

    case FAIL_SIGN_FILES:
      state['apply']['isApplying'] = false;
      state['apply']['modal']['errors'] = action.payload.errors;
      break;

    case APPLY_SUCCESS:
      state['job']['has_applied'] = true;
      break;

    default:
      return state;
  }

  return state;
};

function* applyWorker(action) {
  const fileUrls = action.payload.files;

  const fileObject = yield select(getFiles);
  const jobSlug = yield select(getJobSlug);
  const closeModal = yield select(getCloseModal);

  const requestBody = {
    documents_data: _.zip(fileObject, fileUrls).map(([{ name }, url]) => ({
      filename: name,
      file_path: url
    }))
  };

  const response = yield makeRequest(api.jobApplicationCreate, {
    requestBody,
    lookupData: { jobSlug }
  });

  closeModal();

  if (response.success) {
    const notificationContent =
      'Your job application has been submitted successfully.';

    yield* [put(openNotificationBar(notificationContent)), put(applySuccess())];
  } else {
    const notificationContent = 'Something went wrong. Please try again later.';

    yield put(openNotificationBar(notificationContent, null, true));
  }
}

export function* applySaga() {
  yield takeLatest(
    SIGN_FILES,
    multipleFileUploadWorkerFactory(
      api.jobSignDocuments,
      asGenericUploadHook(successUploadJobApplicationFiles),
      asGenericUploadHook(failUploadJobApplicationFiles)
    )
  );
  yield takeLatest(SUCCESS_SIGN_FILES, applyWorker);
}
