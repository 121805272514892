import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest, getResponseOr404 } from 'shared/sdk';

import { uploadFile } from 'shared/utils/fileUpload';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'EditProfile/';
const FETCH_USER_PROFILE = `${actionPrefix}FETCH_USER_PROFILE`;
const SUCCESS_FETCH_USER_PROFILE = `${actionPrefix}SUCCESS_FETCH_USER_PROFILE`;
const FAIL_FETCH_USER_PROFILE = `${actionPrefix}FAIL_FETCH_USER_PROFILE`;

const UPDATE_USER_PROFILE = `${actionPrefix}UPDATE_USER_PROFILE`;
const SUCCESS_UPDATE_USER_PROFILE = `${actionPrefix}SUCCESS_UPDATE_USER_PROFILE`;
const FAIL_UPDATE_USER_PROFILE = `${actionPrefix}FAIL_UPDATE_USER_PROFILE`;

export const fetchProfile = () => ({
  type: FETCH_USER_PROFILE
});

const successFetchUserProfile = userProfile => ({
  type: SUCCESS_FETCH_USER_PROFILE,
  payload: {
    userProfile
  }
});

const failFetchUserProfile = errors => ({
  type: FAIL_FETCH_USER_PROFILE,
  payload: {
    errors
  }
});

export const updateProfile = (formName, data, extraActions = []) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: {
      data,
      extraActions
    },
    formName
  };
};

const successUpdateProfile = userProfile => ({
  type: SUCCESS_UPDATE_USER_PROFILE,
  payload: {
    userProfile
  }
});

const failUpdateProfile = errors => ({
  type: FAIL_UPDATE_USER_PROFILE,
  payload: {
    errors
  }
});

export const userProfileReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_USER_PROFILE:
      newState['userProfile'] = action.payload.userProfile;
      newState['vendor'] = _.get(action.payload.userProfile, 'vendor');
      newState['service_provider'] = _.get(
        action.payload.userProfile,
        'service_provider'
      );
      newState['investor'] = _.get(action.payload.userProfile, 'investor');
      break;
    case FAIL_FETCH_USER_PROFILE:
      newState['errors'] = action.payload.errors;
      break;

    default:
      return newState;
  }
};

function* fetchUserProfileWorker() {
  const response = yield getResponseOr404(api.userProfileGet);

  if (response.success) yield put(successFetchUserProfile(response.data));
  else yield put(failFetchUserProfile(response.errors));
}

function* updateUserProfileWorker(action) {
  let { data } = action.payload;

  const avatarArr = _.get(action.payload.data, 'profile.avatar', null);

  // The additional check `Array.isArray(avatarArr)` is made to ensure that
  // new avatar had been added by dropzone
  // if not avatarArr will be empty or string (url of image returned by BE)
  if (!_.isNil(avatarArr) && Array.isArray(avatarArr)) {
    delete action.payload.data.profile.avatar;

    const avatar = avatarArr[0];
    const avatarUrl = yield uploadFile(avatar, api.signUserAvatar);

    data = Object.assign({}, action.payload.data, {
      profile: Object.assign({}, action.payload.data.profile, {
        avatar: avatarUrl
      })
    });
  }

  const response = yield makeRequest(api.userProfileUpdate, {
    requestBody: data
  });

  if (response.success) {
    yield put(successUpdateProfile(response.data));
    yield* action.payload.extraActions.map(extraAction =>
      put(extraAction(response.data))
    );
  } else {
    yield put(failUpdateProfile(response.errors));
  }

  return response.errors;
}

export function* userProfileWatcher() {
  yield takeLatest(FETCH_USER_PROFILE, fetchUserProfileWorker);
  yield takeLatest(
    UPDATE_USER_PROFILE,
    formSubmitWorker(updateUserProfileWorker)
  );
}
