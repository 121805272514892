import { takeLatest } from 'redux-saga/effects';
import { arrayMove } from 'react-sortable-hoc';

import {
  multipleFileUploadWorkerFactory,
  asGenericUploadHook,
  toImages
} from 'shared/utils/fileUpload';
import { api } from 'shared/sdk';
import { addToList, removeAtIndex } from 'shared/utils/reducerUtils';

const actionPrefix = 'CreateCompany/';

const START_VENDOR_IMAGES_UPLOAD = `${actionPrefix}START_VENDOR_IMAGE_UPLOAD`;
const SUCCESS_VENDOR_IMAGES_UPLOAD = `${actionPrefix}SUCCESS_VENDOR_IMAGE_UPLOAD`;
const FAIL_VENDOR_IMAGES_UPLOAD = `${actionPrefix}FAIL_VENDOR_IMAGE_UPLOAD`;

const REMOVE_VENDOR_IMAGE = `${actionPrefix}REMOVE_VENDOR_IMAGE`;
const MOVE_VENDOR_IMAGE = `${actionPrefix}MOVE_VENDOR_IMAGE`;

const SAVE_HEADLINES_DATA = `${actionPrefix}SAVE_HEADLINES_DATA`;

export const startVendorImagesUpload = payload => ({
  type: START_VENDOR_IMAGES_UPLOAD,
  payload
});

const successVendorImagesUpload = payload => ({
  type: SUCCESS_VENDOR_IMAGES_UPLOAD,
  payload
});

const failVendorImagesUpload = payload => ({
  type: FAIL_VENDOR_IMAGES_UPLOAD,
  payload
});

export const removeVendorImage = payload => ({
  type: REMOVE_VENDOR_IMAGE,
  payload
});

export const moveVendorImage = payload => ({
  type: MOVE_VENDOR_IMAGE,
  payload
});

export const saveHeadlinesData = payload => ({
  type: SAVE_HEADLINES_DATA,
  payload
});

export const headlinesReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_VENDOR_IMAGES_UPLOAD:
      newState['vendorImages'] = addToList(
        newState.vendorImages,
        toImages(action.payload.files)
      );
      break;

    case REMOVE_VENDOR_IMAGE:
      newState['vendorImages'] = removeAtIndex(
        newState.vendorImages,
        action.payload.index
      );

      break;

    case MOVE_VENDOR_IMAGE:
      newState['vendorImages'] = arrayMove(
        newState.vendorImages,
        action.payload.oldIndex,
        action.payload.newIndex
      );

      break;

    case SAVE_HEADLINES_DATA:
      newState['companyHeadlinesData'] = action.payload;
      break;

    default:
      break;
  }
};

export function* headlinesWatcher() {
  yield takeLatest(
    START_VENDOR_IMAGES_UPLOAD,
    multipleFileUploadWorkerFactory(
      api.signVendorImage,
      asGenericUploadHook(successVendorImagesUpload),
      asGenericUploadHook(failVendorImagesUpload)
    )
  );
}
