import _ from 'lodash';

export const addToList = (container, element) => container.concat(element);

export const removeAtIndex = (container, indexToRemove) => {
  return container.filter((item, index) => index !== indexToRemove);
};

export const removeOccurrences = (container, elementToRemove) =>
  container.filter(item => item !== elementToRemove);

export const limitCount = (container, count) => {
  if (container.length > count) container = container.slice(0, count);

  return container;
};

export const removeOccurenceWithId = (container, id) => {
  return container.filter(item => item.id !== id);
};

export const updateByIdentifier = ({
  collection,
  identifierField,
  identifierValue,
  newItem
}) =>
  _.map(
    collection,
    item =>
      _.get(item, identifierField) === identifierValue
        ? { ...item, ...newItem }
        : item
  );
