import { getFormValues } from 'redux-form';

import { normalizeUrl } from 'shared/utils/url';
import { normalizeCompanyContactFields } from 'shared/utils/company';
import { normalizeRenderedSelect as normalize } from 'shared/forms/RenderSelect';

import {
  SERVICE_PROVIDER_HEADLINES_FORM,
  SERVICE_PROVIDER_LOCATION_FORM,
  SERVICE_PROVIDER_CONTACT_FORM,
  SERVICE_PROVIDER_CASE_STUDY_FORM
} from 'shared/components/ServiceProviderForm/ducks';

export const getServiceProviderData = state => {
  const formHeadlinesData = getFormValues(SERVICE_PROVIDER_HEADLINES_FORM)(
    state
  );

  const serviceProviderImages =
    state.shared.serviceProviderForm.serviceProviderImages;

  const headlinesData = {
    ...formHeadlinesData,
    images: serviceProviderImages,
    service_types: normalize(formHeadlinesData.service_types, true),
    sectors: normalize(formHeadlinesData.sectors, true),
    clients: normalize(formHeadlinesData.clients, true),
    website: normalizeUrl({ url: formHeadlinesData.website })
  };

  const formLocationData = getFormValues(SERVICE_PROVIDER_LOCATION_FORM)(state);
  const locationData = {
    ...formLocationData,
    billing_city: normalize(formLocationData.billing_city, false),
    billing_country: normalize(formLocationData.billing_country, false),
    operating_markets: normalize(formLocationData.operating_markets, true)
  };

  let contactData = getFormValues(SERVICE_PROVIDER_CONTACT_FORM)(state);

  contactData = normalizeCompanyContactFields(contactData);

  const caseStudyData = getFormValues(SERVICE_PROVIDER_CASE_STUDY_FORM)(state);

  return {
    ...headlinesData,
    ...locationData,
    ...contactData,
    ...caseStudyData
  };
};
