import { put, takeLatest, takeLeading } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Dashboard/Filters/';

const FETCH_DASHBOARD_PREFERENCES = `${actionPrefix}FETCH_DASHBOARD_PREFERENCES`;
const SUCCESS_FETCH_DASHBOARD_PREFERENCES = `${actionPrefix}SUCCESS_FETCH_DASHBOARD_PREFERENCES`;

const SAVE_DASHBOARD_PREFERENCES = `${actionPrefix}SAVE_DASHBOARD_PREFERENCES`;
const SUCCESS_SAVE_DASHBOARD_PREFERENCES = `${actionPrefix}SUCCESS_SAVE_DASHBOARD_PREFERENCES`;

const UPDATE_DASHBOARD_PREFERENCES = `${actionPrefix}UPDATE_DASHBOARD_PREFERENCES`;

export const fetchDashboardPreferences = () => ({
  type: FETCH_DASHBOARD_PREFERENCES
});

const successFetchDashboardPreferences = ({ data }) => ({
  type: SUCCESS_FETCH_DASHBOARD_PREFERENCES,
  payload: data
});

export const updateDashboardPreferences = ({ preferences }) => ({
  // we use this action to update the preferences value in the store
  type: UPDATE_DASHBOARD_PREFERENCES,
  payload: { preferences }
});

export const saveDashboardPreferences = ({ preferences, extra }) => ({
  // we use this action to send the preferences value to the BE
  type: SAVE_DASHBOARD_PREFERENCES,
  payload: { preferences, extra }
});

const successSaveDashboardPreferences = ({ data }) => ({
  type: SUCCESS_SAVE_DASHBOARD_PREFERENCES,
  payload: data
});

export const filterReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_PREFERENCES:
    case SAVE_DASHBOARD_PREFERENCES:
      state['disableDashboardPreferencesCheckboxes'] = true;
      break;

    case SUCCESS_FETCH_DASHBOARD_PREFERENCES:
    case SUCCESS_SAVE_DASHBOARD_PREFERENCES:
      state['dashboardPreferences'] = action.payload;
      state['disableDashboardPreferencesCheckboxes'] = false;
      break;

    case UPDATE_DASHBOARD_PREFERENCES:
      state['dashboardPreferences'] = {
        ...state.dashboardPreferences,
        ...action.payload.preferences
      };
      break;

    default:
      return state;
  }

  return state;
};

function* fetchDashboardPreferencesWorker(action) {
  const response = yield makeRequest(api.dashboardPreferencesRetrieve);

  if (response.success) {
    yield put(successFetchDashboardPreferences(response));
  }
}

function* saveDashboardPreferencesWorker(action) {
  const { preferences, extra } = action.payload;

  const response = yield makeRequest(api.dashboardPreferencesUpdate, {
    requestBody: preferences
  });

  if (response.success) {
    yield put(successSaveDashboardPreferences(response));

    extra.map(extraAction => extraAction());
  }
}

export function* filterSaga() {
  // Reference: https://redux-saga.js.org/docs/api/#takeleadingpattern-saga-args
  // We use `takeLeading` instead of `takeLatest` because we render the component twice and
  // both of the components make a separate call. `takeLeading` blocks the pattern until
  // all previous calls to it are complete.

  yield takeLeading(
    FETCH_DASHBOARD_PREFERENCES,
    fetchDashboardPreferencesWorker
  );

  yield takeLatest(SAVE_DASHBOARD_PREFERENCES, saveDashboardPreferencesWorker);
}
