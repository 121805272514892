import _ from 'lodash';

export const normalizePrice = price => {
  if (_.isNil(price)) return price;

  const [integer, fraction] = price.split('.').map(part => parseInt(part));

  if (fraction === 0) return integer;

  return price;
};
