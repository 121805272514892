import { takeLatest } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Investor/Detail/';

export const FETCH_INVESTMENTS = `${actionPrefix}FETCH_INVESTMENTS`;
export const SUCCESS_FETCH_INVESTMENTS = `${actionPrefix}SUCCESS_FETCH_INVESTMENTS`;
export const FAIL_FETCH_INVESTMENTS = `${actionPrefix}FAIL_FETCH_INVESTMENTS`;

export const fetchInvestments = ({
  slug,
  ordering,
  limit = 10,
  offset = 0
}) => ({
  type: FETCH_INVESTMENTS,
  payload: { slug, limit, offset, ordering }
});

const successFetchInvestments = data => ({
  type: SUCCESS_FETCH_INVESTMENTS,
  payload: {
    data
  }
});

const failFetchInvestments = errors => ({
  type: FAIL_FETCH_INVESTMENTS,
  payload: {
    errors
  }
});

export const investmentReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVESTMENTS:
      state['isFetchingInvestments'] = true;
      break;

    case SUCCESS_FETCH_INVESTMENTS:
      state['investments'] = action.payload.data.results;
      state['isFetchingInvestments'] = false;
      break;

    case FAIL_FETCH_INVESTMENTS:
      state['isFetchingInvestments'] = false;
      break;

    default:
      return state;
  }
};

export function* fetchInvestmentsWorker(action) {
  const lookupData = { slug: action.payload.slug };
  const requestData = {
    params: {
      limit: action.payload.limit,
      offset: action.payload.offset,
      ordering: action.payload.ordering
    }
  };

  const response = yield getResponseOr404(api.investorInvestmentList, {
    lookupData,
    requestData
  });

  if (response.success) {
    yield put(successFetchInvestments(response.data));
  } else yield put(failFetchInvestments(response.errors));
}

export function* investmentSaga() {
  yield takeLatest(FETCH_INVESTMENTS, fetchInvestmentsWorker);
}
