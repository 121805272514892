import { getFormValues } from 'redux-form';

import { normalizeRenderedSelect as normalize } from 'shared/forms/RenderSelect';

import { SERVICE_PROVIDER_LOCATION_FORM } from 'shared/components/ServiceProviderForm/ducks';

export const getServiceProviderLocationData = state => {
  const formLocationData = getFormValues(SERVICE_PROVIDER_LOCATION_FORM)(state);

  const data = {
    ...formLocationData,
    billing_city: normalize(formLocationData.billing_city, false),
    billing_country: normalize(formLocationData.billing_country, false),
    operating_markets: normalize(formLocationData.operating_markets, true)
  };

  return {
    ...data
  };
};
