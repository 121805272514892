import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'GlobalSearch/';
const FETCH_CONNECT_VIDEOS = `${actionPrefix}FETCH_CONNECTVIDEOS`;
const SUCCESS_FETCH_CONNECT_VIDEOS = `${actionPrefix}SUCCESS_FETCH_CONNECTVIDEOS`;

export const fetchConnectVideos = args => ({
  type: FETCH_CONNECT_VIDEOS,
  payload: args
});

export const successFetchConnectVideos = payload => ({
  type: SUCCESS_FETCH_CONNECT_VIDEOS,
  payload
});

export const connectVideosReducer = (state, action) => {
  switch (action.type) {
    case FETCH_CONNECT_VIDEOS:
      state['fetchingConnectVideos'] = true;
      break;

    case SUCCESS_FETCH_CONNECT_VIDEOS:
      state['connectVideos'] = action.payload.results;
      state['connectVideosCount'] = action.payload.count;

      state['fetchingConnectVideos'] = false;
      break;

    default:
      return state;
  }
};

function* fetchConnectVideosWorker(action) {
  const response = yield makeRequest(api.connectVideoList, {
    requestData: { params: { limit: 3, ...action.payload } }
  });

  if (response.success) {
    yield put(successFetchConnectVideos(response.data));
  }
}

export function* connectVideosSaga() {
  yield takeLatest(FETCH_CONNECT_VIDEOS, fetchConnectVideosWorker);
}
