import _ from 'lodash';
import { push } from 'react-router-redux';
import { put, takeLatest, select } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { SERVICE_PROVIDERS_URL, MODALS } from 'global-constants';
import { openModal } from 'shared/components/Modals/reducer';

import { uploadFile } from 'shared/utils/fileUpload';

import { getServiceProviderData } from './selectors';

const actionPrefix = 'CreateServiceProvider/';

const CREATE_SERVICE_PROVIDER = `${actionPrefix}CREATE_SERVICE_PROVIDER`;
const SUCCESS_CREATE_SERVICE_PROVIDER = `${actionPrefix}SUCCESS_CREATE_SERVICE_PROVIDER`;
const FAIL_CREATE_SERVICE_PROVIDER = `${actionPrefix}FAIL_CREATE_SERVICE_PROVIDER`;

export const createServiceProvider = () => ({
  type: CREATE_SERVICE_PROVIDER
});

const successCreateServiceProvider = payload => ({
  type: SUCCESS_CREATE_SERVICE_PROVIDER,
  payload
});

const failCreateServiceProvider = payload => ({
  type: FAIL_CREATE_SERVICE_PROVIDER,
  payload
});

function* createServiceProviderWorker(action) {
  const ServiceProviderData = yield select(getServiceProviderData);

  let data = ServiceProviderData;

  const logoArr = _.get(ServiceProviderData, 'logo', null);

  if (!_.isNil(logoArr)) {
    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signServiceProviderLogo);

    data = Object.assign({}, data, { logo: logoUrl });
  }

  const createResponse = yield makeRequest(api.createServiceProvider, {
    requestBody: data
  });

  if (createResponse.success)
    yield* [
      put(successCreateServiceProvider(createResponse.data)),
      put(push(SERVICE_PROVIDERS_URL)),
      put(openModal({ modalName: MODALS.SUCCESS_SUBMIT_COMPANY }))
    ];
  else {
    yield put(failCreateServiceProvider(createResponse.errors));

    return createResponse.errors;
  }
}

export function* createServiceProviderSaga() {
  yield takeLatest(CREATE_SERVICE_PROVIDER, createServiceProviderWorker);
}
