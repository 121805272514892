import { combineReducers } from 'redux';

import {
  solutionReducer as solutionDetailReducer,
  solutionSaga as solutionDetailSagas
} from './Detail/ducks';
import { listSolutionsReducer, solutionsSaga } from './List/ducks';

export const solutionReducer = combineReducers({
  list: listSolutionsReducer,
  detail: solutionDetailReducer
});

export const solutionSagas = [solutionDetailSagas, solutionsSaga];
