import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'CountryField/';

const FETCH_LANGUAGES = `${actionPrefix}FETCH_LANGUAGES`;
const SUCCESS_FETCH_LANGUAGES = `${actionPrefix}SUCCESS_FETCH_LANGUAGES`;

export const fetchLanguages = () => ({ type: FETCH_LANGUAGES });

const successFetchLanguages = ({ data: { results } }) => ({
  type: SUCCESS_FETCH_LANGUAGES,
  payload: { results }
});

const initialState = {
  options: []
};

export const newsLanguageFieldReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case SUCCESS_FETCH_LANGUAGES:
      newState['options'] = action.payload.results.map(({ id, name }) => ({
        value: id,
        label: name
      }));
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchLanguagesWorker(action) {
  const response = yield makeRequest(api.newsLanguageList, {
    requestData: {
      params: {
        limit: 500,
        offset: 0,
        ordering: 1
      }
    }
  });

  if (response.success) {
    yield put(successFetchLanguages(response));
  }
}

export function* newsLanguageFieldSaga() {
  yield takeLatest(FETCH_LANGUAGES, fetchLanguagesWorker);
}
