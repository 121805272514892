import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'Influencer/Detail/ConnectVideos/';

const FETCH_CONNECT_VIDEOS = `${actionPrefix}FETCH_CONNECT_VIDEOS`;
const SUCCESS_FETCH_CONNECT_VIDEOS = `${actionPrefix}SUCCESS_FETCH_CONNECT_VIDEOS`;

export const fetchConnectVideos = ({ influencerId, nextUrl }) => ({
  type: FETCH_CONNECT_VIDEOS,
  payload: { influencerId, nextUrl }
});

const successFetchConnectVideos = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_CONNECT_VIDEOS,
  payload: { results, next }
});

export const connectVideosReducer = (state, action) => {
  switch (action.type) {
    case FETCH_CONNECT_VIDEOS:
      state['isFetchingConnectVideos'] = true;
      break;

    case SUCCESS_FETCH_CONNECT_VIDEOS:
      state['connectVideos'] = addToList(
        state.connectVideos,
        action.payload.results
      );
      state['connectVideosNextUrl'] = action.payload.next;
      state['isFetchingConnectVideos'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchConnectVideosWorker(action) {
  const { influencerId, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    const params = { author: influencerId, limit: 10 };

    response = yield makeRequest(api.connectVideoList, {
      requestData: { params }
    });
  }

  if (response.success) {
    yield put(successFetchConnectVideos(response));
  }
}

export function* connectVideosSaga() {
  yield takeLatest(FETCH_CONNECT_VIDEOS, fetchConnectVideosWorker);
}
