import _ from 'lodash';

export const getComputedRank = (
  currentIndex,
  newInfluencers,
  oldInfluencers
) => {
  const currentRank = newInfluencers[currentIndex].rank;

  return _.get(_.last(oldInfluencers), 'rank') === currentRank ||
    _.get(newInfluencers[currentIndex - 1], 'rank') === currentRank ||
    _.get(newInfluencers[currentIndex + 1], 'rank') === currentRank
    ? '=' + currentRank
    : currentRank;
};
