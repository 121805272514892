import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'GlobalSearch/';

const FETCH_RESOURCES = `${actionPrefix}FETCH_RESOURCES`;
const SUCCESS_FETCH_RESOURCES = `${actionPrefix}SUCCESS_FETCH_RESOURCES`;
const FAIL_FETCH_RESOURCES = `${actionPrefix}FAIL_FETCH_RESOURCES`;

export const fetchResources = filters => ({
  type: FETCH_RESOURCES,
  filters
});

export const successFetchInitialResources = payload => ({
  type: SUCCESS_FETCH_RESOURCES,
  payload
});

export const failFetchResources = payload => ({
  type: FAIL_FETCH_RESOURCES,
  payload
});

export const resourcesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_RESOURCES:
      state['fetchingResources'] = true;
      break;

    case SUCCESS_FETCH_RESOURCES:
      state['resources'] = action.payload.results;
      state['resourcesCount'] = action.payload.count;
      state['fetchingResources'] = false;
      break;

    default:
      return state;
  }
};

function* fetchInitialResourcesWorker(action) {
  const response = yield makeRequest(api.resourceList, {
    requestData: { params: { limit: 6, ...action.filters } }
  });
  if (response.success) {
    yield put(successFetchInitialResources(response.data));
  } else yield put(failFetchResources(response.errors));
}

export function* resourcesSaga() {
  yield takeLatest(FETCH_RESOURCES, fetchInitialResourcesWorker);
}
