import { takeLatest } from 'redux-saga/effects';
import { arrayMove } from 'react-sortable-hoc';

import {
  multipleFileUploadWorkerFactory,
  asGenericUploadHook,
  toImages
} from 'shared/utils/fileUpload';
import { api } from 'shared/sdk';
import { addToList, removeAtIndex } from 'shared/utils/reducerUtils';

const actionPrefix = 'CreateServiceProvider/';

const START_SERVICE_PROVIDER_IMAGES_UPLOAD = `${actionPrefix}START_SERVICE_PROVIDER_IMAGE_UPLOAD`;
const SUCCESS_SERVICE_PROVIDER_IMAGES_UPLOAD = `${actionPrefix}SUCCESS_SERVICE_PROVIDER_IMAGE_UPLOAD`;
const FAIL_SERVICE_PROVIDER_IMAGES_UPLOAD = `${actionPrefix}FAIL_SERVICE_PROVIDER_IMAGE_UPLOAD`;

const REMOVE_SERVICE_PROVIDER_IMAGE = `${actionPrefix}REMOVE_SERVICE_PROVIDER_IMAGE`;
const MOVE_SERVICE_PROVIDER_IMAGE = `${actionPrefix}MOVE_SERVICE_PROVIDER_IMAGE`;

const SET_SERVICE_PROVIDER_IMAGES = `${actionPrefix}SET_SERVICE_PROVIDER_IMAGES`;

export const startServiceProviderImagesUpload = payload => ({
  type: START_SERVICE_PROVIDER_IMAGES_UPLOAD,
  payload
});

const successServiceProviderImagesUpload = payload => ({
  type: SUCCESS_SERVICE_PROVIDER_IMAGES_UPLOAD,
  payload
});

const failServiceProviderImagesUpload = payload => ({
  type: FAIL_SERVICE_PROVIDER_IMAGES_UPLOAD,
  payload
});

export const removeServiceProviderImage = payload => ({
  type: REMOVE_SERVICE_PROVIDER_IMAGE,
  payload
});

export const moveServiceProviderImage = payload => ({
  type: MOVE_SERVICE_PROVIDER_IMAGE,
  payload
});

export const setServiceProviderImages = images => ({
  type: SET_SERVICE_PROVIDER_IMAGES,
  payload: { images }
});

export const headlinesReducer = (newState, action) => {
  switch (action.type) {
    case SET_SERVICE_PROVIDER_IMAGES:
      newState['serviceProviderImages'] = action.payload.images;
      break;

    case SUCCESS_SERVICE_PROVIDER_IMAGES_UPLOAD:
      newState['serviceProviderImages'] = addToList(
        newState.serviceProviderImages,
        toImages(action.payload.files)
      );
      break;

    case REMOVE_SERVICE_PROVIDER_IMAGE:
      newState['serviceProviderImages'] = removeAtIndex(
        newState.serviceProviderImages,
        action.payload.index
      );

      break;

    case MOVE_SERVICE_PROVIDER_IMAGE:
      newState['serviceProviderImages'] = arrayMove(
        newState.serviceProviderImages,
        action.payload.oldIndex,
        action.payload.newIndex
      );

      break;

    default:
      break;
  }
};

export function* headlinesWatcher() {
  yield takeLatest(
    START_SERVICE_PROVIDER_IMAGES_UPLOAD,
    multipleFileUploadWorkerFactory(
      api.signServiceProviderImage,
      asGenericUploadHook(successServiceProviderImagesUpload),
      asGenericUploadHook(failServiceProviderImagesUpload)
    )
  );
}
