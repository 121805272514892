import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'TenderWidget/';

const FETCH_TENDERS = `${actionPrefix}FETCH_TENDERS`;
const SUCCESS_FETCH_TENDERS = `${actionPrefix}SUCCESS_FETCH_TENDERS`;

const initialState = {
  tenders: [],
  isFetching: false
};

export const fetchTenders = () => ({
  type: FETCH_TENDERS
});

const successFetchTenders = payload => ({
  type: SUCCESS_FETCH_TENDERS,
  payload
});

export const tenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TENDERS:
      return { ...state, isFetching: true };

    case SUCCESS_FETCH_TENDERS:
      return { ...state, tenders: action.payload.results, isFetching: false };

    default:
      return state;
  }
};

function* fetchTendersWorker(action) {
  const response = yield makeRequest(api.tendersList, {
    requestData: {
      params: {
        is_open: true,
        limit: 10,
        ordering: 1 // ending soonest
      }
    }
  });

  if (response.success) yield put(successFetchTenders(response.data));
}

export function* tendersSaga() {
  yield takeLatest(FETCH_TENDERS, fetchTendersWorker);
}
