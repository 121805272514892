import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { replace } from 'react-router-redux';

import { PAGE_NOT_FOUND_URL } from 'global-constants';

import { uploadFile } from 'shared/utils/fileUpload';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { api, makeRequest, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'EditEvent/';

const REQUEST_FETCH_EVENT = `${actionPrefix}REQUEST_FETCH_EVENT`;
const SUCCESS_FETCH_EVENT = `${actionPrefix}SUCCESS_FETCH_EVENT`;
const FAIL_FETCH_EVENT = `${actionPrefix}FAIL_FETCH_EVENT`;

const REQUEST_EDIT_EVENT = `${actionPrefix}/REQUEST_EDIT_EVENT`;
const SUCCESS_EDIT_EVENT = `${actionPrefix}SUCCESS_EDIT_EVENT`;
const FAIL_EDIT_EVENT = `${actionPrefix}FAIL_EDIT_EVENT`;

export const requestFetchEvent = (slug, user) => ({
  type: REQUEST_FETCH_EVENT,
  payload: { slug, user }
});

const successFetchEvent = payload => ({
  type: SUCCESS_FETCH_EVENT,
  payload
});

const failFetchEvent = payload => ({
  type: FAIL_FETCH_EVENT,
  payload
});

export const requestEditEvent = (formName, slug, data, extraActions = []) => ({
  type: REQUEST_EDIT_EVENT,
  formName,
  payload: { slug, data },
  extraActions
});

const successEditEvent = payload => ({
  type: SUCCESS_EDIT_EVENT,
  payload
});

const failEditEvent = payload => ({
  type: FAIL_EDIT_EVENT,
  payload
});

export const eventReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_EVENT:
      state.event = action.payload;

      break;

    default:
      break;
  }

  return state;
};

function* fetchEventWorker(action) {
  const response = yield getResponseOr404(api.eventDetail, {
    lookupData: { eventSlug: action.payload.slug }
  });

  if (response.data.proposed_by.id !== action.payload.user.id) {
    yield put(replace(PAGE_NOT_FOUND_URL));

    return;
  }

  if (response.success) yield put(successFetchEvent(response.data));
  else yield put(failFetchEvent(response.errors));
}

function* updateEventWorker(action) {
  const eventData = action.payload.data;

  let data = eventData;

  const imageArr = _.get(eventData, 'image', null);

  if (
    !_.isNil(imageArr) &&
    !_.isEmpty(imageArr) &&
    imageArr[0] instanceof File
  ) {
    const image = imageArr[0];

    const imageUrl = yield uploadFile(image, api.signEventImage);
    data = Object.assign({}, data, { image: imageUrl });
  }

  const response = yield makeRequest(api.eventUpdate, {
    lookupData: { eventSlug: action.payload.slug },
    requestBody: data
  });

  if (response.success) {
    yield put(successEditEvent(response.data));
    yield* action.extraActions.map(action => put(action()));
  } else {
    yield* [put(failEditEvent(response.errors))];
    const errors = {
      ...response.errors,
      start_date: response.errors.starting_at,
      end_date: response.errors.ending_at
    };
    return errors;
  }
}

export function* eventWatcher() {
  yield takeLatest(REQUEST_FETCH_EVENT, fetchEventWorker);
  yield takeLatest(REQUEST_EDIT_EVENT, formSubmitWorker(updateEventWorker));
}
