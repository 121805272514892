import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

import { fetchMe } from 'shared/HOCs/FetchInitials/ducks/index.js';

import { fetchProfile } from '../';

const actionPrefix = 'EditProfile/';
export const LEAVE_VENDOR = `${actionPrefix}LEAVE_VENDOR`;
const SUCCESS_LEAVE_VENDOR = `${actionPrefix}SUCCESS_LEAVE_VENDOR`;
const FAIL_LEAVE_VENDOR = `${actionPrefix}FAIL_LEAVE_VENDOR`;

export const leaveVendor = ({
  isServiceProvider = false,
  isInvestor = false
}) => ({
  type: LEAVE_VENDOR,
  payload: { isServiceProvider, isInvestor }
});

export const successLeaveVendor = () => ({
  type: SUCCESS_LEAVE_VENDOR,
  payload: {}
});

export const failLeaveVendor = errors => ({
  type: FAIL_LEAVE_VENDOR,
  payload: {
    errors
  }
});

export const leaveVendorReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_LEAVE_VENDOR:
      newState['vendor'] = null;
      newState['service_provider'] = null;
      break;
    case FAIL_LEAVE_VENDOR:
      newState['errors'] = action.payload.errors;
      break;

    default:
      return newState;
  }
};

function* leaveVendorWorker(action) {
  const { isInvestor, isServiceProvider } = action.payload;

  let requestApi = api.leaveVendor;

  if (isServiceProvider) {
    requestApi = api.leaveServiceProvider;
  } else if (isInvestor) {
    requestApi = api.leaveInvestor;
  }

  const response = yield makeRequest(requestApi);

  if (response.success)
    yield* [put(successLeaveVendor()), put(fetchMe()), put(fetchProfile())];
  else yield put(failLeaveVendor(response.errors));
}

export function* leaveVendorWatcher() {
  yield takeLatest(LEAVE_VENDOR, leaveVendorWorker);
}
