import { put, takeLatest, select } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

import { getServiceProviderLocationData } from './selectors';

const actionPrefix = 'EditServiceProvider/';

const UPDATE_LOCATION = `${actionPrefix}UPDATE_LOCATION`;
const FAIL_UPDATE_LOCATION = `${actionPrefix}FAIL_UPDATE_LOCATION`;
const SUCCESS_UPDATE_LOCATION = `${actionPrefix}SUCCESS_UPDATE_LOCATION`;

export const updateLocation = (formName, slug) => ({
  type: UPDATE_LOCATION,
  formName,
  slug
});

const failUpdateServiceProviderLocation = payload => ({
  type: FAIL_UPDATE_LOCATION,
  payload
});

const successUpdateServiceProviderLocation = payload => ({
  type: SUCCESS_UPDATE_LOCATION,
  payload
});

function* updateServiceProviderWorker(action) {
  const serviceProviderLocationData = yield select(
    getServiceProviderLocationData
  );

  const response = yield makeRequest(api.updateLocationServiceProvider, {
    lookupData: { slug: action.slug },
    requestBody: serviceProviderLocationData
  });

  if (response.success) {
    yield put(successUpdateServiceProviderLocation(response.data));
    yield put(openNotificationBar('Location data was updated successfully'));
  } else {
    yield put(failUpdateServiceProviderLocation(response.errors));

    return response.errors;
  }
}

export function* updateLocationSaga() {
  yield takeLatest(
    UPDATE_LOCATION,
    formSubmitWorker(updateServiceProviderWorker)
  );
}
