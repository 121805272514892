import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';
import { buildFiltersFromObj } from 'shared/utils/filters';

const actionPrefix = 'Influencers/';
const FETCH_INFLUENCERS_PREVIEW = `${actionPrefix}FETCH_INFLUENCERS_PREVIEW`;
const SUCCESS_FETCH_INFLUENCERS_PREVIEW = `${actionPrefix}SUCCESS_FETCH_INFLUENCERS_PREVIEW`;
const FAIL_FETCH_INFLUENCERS_PREVIEW = `${actionPrefix}FAIL_FETCH_INFLUENCERS_PREVIEW`;

const FETCH_NEW_INFLUENCERS = `${actionPrefix}FETCH_NEW_INFLUENCERS`;
const SUCCESS_FETCH_NEW_INFLUENCERS = `${actionPrefix}SUCCESS_FETCH_NEW_INFLUENCERS`;
const FAIL_FETCH_NEW_INFLUENCERS = `${actionPrefix}FAIL_FETCH_NEW_INFLUENCERS`;

const FETCH_FEATURED_INFLUENCERS_PREVIEW = `${actionPrefix}FETCH_FEATURED_INFLUENCERS_PREVIEW`;
const SUCCESS_FETCH_FEATURED_INFLUENCERS_PREVIEW = `${actionPrefix}SUCCESS_FETCH_FEATURED_INFLUENCERS_PREVIEW`;
const FAIL_FETCH_FEATURED_INFLUENCERS_PREVIEW = `${actionPrefix}FAIL_FETCH_FEATURED_INFLUENCERS_PREVIEW`;

export const fetchInfluencersPreview = filters => ({
  type: FETCH_INFLUENCERS_PREVIEW,
  payload: {
    filters
  }
});

const successFetchInfluencersPreview = data => ({
  type: SUCCESS_FETCH_INFLUENCERS_PREVIEW,
  payload: {
    data
  }
});

const failFetchInfluencersPreview = errors => ({
  type: FAIL_FETCH_INFLUENCERS_PREVIEW,
  payload: {
    errors
  }
});

export const fetchNewInfluencers = filters => ({
  type: FETCH_NEW_INFLUENCERS,
  payload: {
    filters
  }
});

const successFetchNewInfluencers = data => ({
  type: SUCCESS_FETCH_NEW_INFLUENCERS,
  payload: {
    data
  }
});

const failFetchNewInfluencers = errors => ({
  type: FAIL_FETCH_NEW_INFLUENCERS,
  payload: {
    errors
  }
});

export const fetchFeaturedInfluencersPreview = filters => ({
  type: FETCH_FEATURED_INFLUENCERS_PREVIEW,
  payload: {
    filters
  }
});

const successFetchFeaturedInfluencersPreview = data => ({
  type: SUCCESS_FETCH_FEATURED_INFLUENCERS_PREVIEW,
  payload: {
    data
  }
});

const failFetchFeaturedInfluencersPreview = errors => ({
  type: FAIL_FETCH_FEATURED_INFLUENCERS_PREVIEW,
  payload: {
    errors
  }
});

export const influencersPreviewReducer = (newState, action) => {
  switch (action.type) {
    case FETCH_INFLUENCERS_PREVIEW:
    case FETCH_NEW_INFLUENCERS:
    case FETCH_FEATURED_INFLUENCERS_PREVIEW:
      newState['influencersPreview'] = [];
      newState['isFetchingInfluencersPreview'] = true;
      break;

    case SUCCESS_FETCH_INFLUENCERS_PREVIEW:
      newState['isFetchingInfluencersPreview'] = false;
      newState['influencersPreview'] = action.payload.data.results;
      break;

    case SUCCESS_FETCH_FEATURED_INFLUENCERS_PREVIEW:
      newState['isFetchingInfluencersPreview'] = false;
      newState['influencersPreview'] = action.payload.data;
      break;

    case SUCCESS_FETCH_NEW_INFLUENCERS:
      newState['isFetchingInfluencersPreview'] = false;
      newState['influencersPreview'] = action.payload.data.results;
      break;

    default:
      return newState;
  }
};

function* fetchInfluencersPreviewWorker(action) {
  const filters = action.payload.filters;
  const response = yield makeRequest(api.previewInfluencers, {
    requestData: { params: buildFiltersFromObj(filters) }
  });

  if (response.success)
    yield put(successFetchInfluencersPreview(response.data));
  else yield put(failFetchInfluencersPreview(response.errors));
}

function* fetchNewInfluencersWorker(action) {
  const filters = action.payload.filters;
  const response = yield makeRequest(api.influencersList, {
    requestData: { params: buildFiltersFromObj(filters) }
  });
  if (response.success) yield put(successFetchNewInfluencers(response.data));
  else yield put(failFetchNewInfluencers(response.errors));
}

function* fetchFeaturedInfluencersPreviewWorker(action) {
  const response = yield makeRequest(api.featuredInfluencers);

  if (response.success)
    yield put(successFetchFeaturedInfluencersPreview(response.data));
  else yield put(failFetchFeaturedInfluencersPreview(response.errors));
}

export function* influencersPreviewWatcher() {
  yield takeLatest(FETCH_INFLUENCERS_PREVIEW, fetchInfluencersPreviewWorker);
  yield takeLatest(FETCH_NEW_INFLUENCERS, fetchNewInfluencersWorker);
  yield takeLatest(
    FETCH_FEATURED_INFLUENCERS_PREVIEW,
    fetchFeaturedInfluencersPreviewWorker
  );
}
