import React from 'react';

import registerServiceWorker from './registerServiceWorker';

import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';

import { HOME_URL } from 'global-constants';

import routes from 'config/routes';
import history from 'config/history';
import store from 'config/store';

import PushProvider from 'shared/components/PushProvider';
import ScriptInjector from 'shared/components/ScriptInjector';
import NotificationBar from 'shared/components/NotificationBar';
import BrowserDetection from 'shared/components/BrowserDetection';

import { initSentry } from 'shared/utils/sentry';
import { GAInitialize, GAPageView } from 'shared/utils/GA';

import 'assets/styles/base.scss';

syncHistoryWithStore(history, store);

// Sentry Error Tracking
initSentry();

// Global page tracking
const initGA = history => {
  GAInitialize();
  history.location.pathname === HOME_URL && GAPageView(history.location);
  history.listen(location => GAPageView(history.location));
};

initGA(history);

ReactDOM.render(
  <Provider store={store}>
    <ScriptInjector>
      <Router history={history}>
        <div>
          {routes()}
          <NotificationBar />
          <BrowserDetection />
          <PushProvider />
        </div>
      </Router>
    </ScriptInjector>
  </Provider>,
  document.getElementById('root')
);

const isServiceWorkerEnabled = JSON.parse(
  process.env.REACT_APP_SERVICE_WORKER_ENABLED
);

if (isServiceWorkerEnabled) registerServiceWorker();
