import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Job/Form/Detail/';

const FETCH_JOB = `${actionPrefix}FETCH_JOB`;
const SUCCESS_FETCH_JOB = `${actionPrefix}SUCCESS_FETCH_JOB`;

export const fetchJob = ({ jobSlug }) => ({
  type: FETCH_JOB,
  payload: { jobSlug }
});

const successFetchJob = ({ data }) => ({
  type: SUCCESS_FETCH_JOB,
  payload: { data }
});

export const detailReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_JOB:
      state['job'] = action.payload.data;
      state['isFetchingJob'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchJobWorker(action) {
  const { jobSlug } = action.payload;

  const response = yield getResponseOr404(api.jobDetail, {
    lookupData: { jobSlug }
  });

  if (response.success) {
    yield put(successFetchJob(response));
  }
}

export function* detailSaga() {
  yield takeLatest(FETCH_JOB, fetchJobWorker);
}
