import _ from 'lodash';
import { replace } from 'react-router-redux';
import { put, takeLatest } from 'redux-saga/effects';

import { PAGE_NOT_FOUND_URL, INFLUENCER_PREVIEW_URL } from 'global-constants';

import { api, makeRequest } from 'shared/sdk';
import { substituteUrl } from 'shared/utils/substituteUrl';

const actionPrefix = 'InfluencerIdToSlugRedirectPage/';

const FETCH_SLUG_AND_REDIRECT_TO_DETAIL = `${actionPrefix}FETCH_SLUG_AND_REDIRECT_TO_DETAIL`;

export const fetchSlugAndRedirectToDetail = ({ influencerId }) => ({
  type: FETCH_SLUG_AND_REDIRECT_TO_DETAIL,
  payload: { influencerId }
});

function* fetchSlugAndRedirectToDetailWorker(action) {
  const { influencerId } = action.payload;

  const response = yield makeRequest(api.usersList, {
    requestData: {
      params: { limit: 1, id: influencerId }
    }
  });

  const results = _.get(response, 'data.results');

  const firstResultSlug = _.get(_.head(results), 'slug');

  if (response.success && !_.isNil(firstResultSlug)) {
    const url = substituteUrl(INFLUENCER_PREVIEW_URL, {
      influencerSlug: firstResultSlug
    });
    yield put(replace(url));
  } else {
    yield put(replace(PAGE_NOT_FOUND_URL));
  }
}

export function* influencerIdToSlugRedirectSaga() {
  yield takeLatest(
    FETCH_SLUG_AND_REDIRECT_TO_DETAIL,
    fetchSlugAndRedirectToDetailWorker
  );
}
