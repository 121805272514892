import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Investor/Detail/Follow';

export const TOGGLE_INVESTOR_FOLLOW = `${actionPrefix}/TOGGLE_INVESTOR_FOLLOW`;
export const SUCCESS_TOGGLE_INVESTOR_FOLLOW = `${actionPrefix}/SUCCESS_TOGGLE_INVESTOR_FOLLOW`;
export const FAIL_TOGGLE_INVESTOR_FOLLOW = `${actionPrefix}/FAIL_TOGGLE_INVESTOR_FOLLOW`;

export const toggleInvestorFollow = slug => ({
  type: TOGGLE_INVESTOR_FOLLOW,
  payload: { slug }
});

export const successToggleInvestorFollow = data => ({
  type: SUCCESS_TOGGLE_INVESTOR_FOLLOW,
  payload: { data }
});

export const failToggleInvestorFollow = errors => ({
  type: FAIL_TOGGLE_INVESTOR_FOLLOW,
  payload: { errors }
});

export const followReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_INVESTOR_FOLLOW:
      state['isPerformingFollowOperation'] = true;
      break;

    case SUCCESS_TOGGLE_INVESTOR_FOLLOW:
      state['investor'] = {
        ...state.investor,
        ...action.payload.data
      };
      state['isPerformingFollowOperation'] = false;
      break;

    case FAIL_TOGGLE_INVESTOR_FOLLOW:
      state['isPerformingFollowOperation'] = false;
      break;

    default:
      return state;
  }
};

export function* toggleInvestorFollowWorker(action) {
  const response = yield makeRequest(api.investorToggleFollow, {
    lookupData: action.payload
  });

  if (response.success) {
    yield put(successToggleInvestorFollow(response.data));
  } else yield put(failToggleInvestorFollow(response.errors));
}

export function* followSaga() {
  yield takeLatest(TOGGLE_INVESTOR_FOLLOW, toggleInvestorFollowWorker);
}
