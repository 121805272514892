import _ from 'lodash';

export const getCurrentConversationId = state =>
  _.get(state.pages.messaging.currentConversation, 'id', null);

export const getPollingTrigger = state =>
  state.pages.messaging.pollCurrentConversationLatestMessages;

export const getLatestMessage = state =>
  state.pages.messaging.lastFetchedMessageId;

export const getMarkSeenPollingTrigger = state =>
  state.pages.messaging.pollMarkSeen;
