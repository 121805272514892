import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { api, makeRequest } from 'shared/sdk';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'Home/BookATour/';

const BOOK_A_TOUR = `${actionPrefix}BOOK_A_TOUR`;

export const bookATour = ({
  data,
  formName,
  submissionSuccessActions = []
}) => ({
  type: BOOK_A_TOUR,
  formName,
  payload: { data, submissionSuccessActions }
});

function* bookATourWorker(action) {
  const { data, submissionSuccessActions } = action.payload;

  const response = yield makeRequest(api.bookATour, { requestBody: data });

  if (response.success) {
    const notificationContent = 'Tour request has been submitted successfully.';

    if (_.isEmpty(submissionSuccessActions)) {
      yield put(openNotificationBar(notificationContent));
    } else {
      submissionSuccessActions.map(action => action());
    }
  } else {
    return response.errors;
  }
}

export function* bookATourSaga() {
  yield takeLatest(BOOK_A_TOUR, formSubmitWorker(bookATourWorker));
}
