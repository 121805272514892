import _ from 'lodash';
import { push } from 'react-router-redux';
import { put, takeLatest, select } from 'redux-saga/effects';
import { arrayMove } from 'react-sortable-hoc';

import { EDIT_SERVICE_PROVIDER_URL } from 'global-constants';
import { substituteUrl } from 'shared/utils/substituteUrl';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { addToList, removeAtIndex } from 'shared/utils/reducerUtils';
import { api, makeRequest } from 'shared/sdk';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';
import { successFetchMe } from 'shared/HOCs/FetchInitials/ducks/index.js';
import { setEditMode } from 'shared/components/ServiceProviderForm/ducks';
import {
  uploadFile,
  multipleFileUploadWorkerFactory,
  asGenericUploadHook,
  toImages
} from 'shared/utils/fileUpload';

import { getServiceProviderHeadlinesData } from './selectors';
import { fetchServiceProvider } from '../serviceProvider';

const actionPrefix = 'EditServiceProvider/';

const UPDATE_SERVICE_PROVIDER = `${actionPrefix}UPDATE_SERVICE_PROVIDER`;
const SUCCESS_UPDATE_SERVICE_PROVIDER = `${actionPrefix}SUCCESS_UPDATE_SERVICE_PROVIDER`;
const FAIL_UPDATE_SERVICE_PROVIDER = `${actionPrefix}FAIL_UPDATE_SERVICE_PROVIDER`;

const START_SERVICE_PROVIDER_IMAGES_UPLOAD = `${actionPrefix}START_SERVICE_PROVIDER_IMAGE_UPLOAD`;
const SUCCESS_SERVICE_PROVIDER_IMAGES_UPLOAD = `${actionPrefix}SUCCESS_SERVICE_PROVIDER_IMAGE_UPLOAD`;
const FAIL_SERVICE_PROVIDER_IMAGES_UPLOAD = `${actionPrefix}FAIL_SERVICE_PROVIDER_IMAGE_UPLOAD`;

const REMOVE_SERVICE_PROVIDER_IMAGE = `${actionPrefix}REMOVE_SERVICE_PROVIDER_IMAGE`;
const MOVE_SERVICE_PROVIDER_IMAGE = `${actionPrefix}MOVE_SERVICE_PROVIDER_IMAGE`;

export const updateHeadlines = (formName, slug) => ({
  type: UPDATE_SERVICE_PROVIDER,
  formName,
  slug
});

const successUpdateServiceProvider = payload => ({
  type: SUCCESS_UPDATE_SERVICE_PROVIDER,
  payload
});

const failUpdateServiceProvider = payload => ({
  type: FAIL_UPDATE_SERVICE_PROVIDER,
  payload
});
// TODO: Remove copy-pasta vendor from action names.
export const startVendorImagesUpload = (id, payload) => ({
  type: START_SERVICE_PROVIDER_IMAGES_UPLOAD,
  id,
  payload
});

const successVendorImagesUpload = payload => ({
  type: SUCCESS_SERVICE_PROVIDER_IMAGES_UPLOAD,
  payload
});

const failVendorImagesUpload = payload => ({
  type: FAIL_SERVICE_PROVIDER_IMAGES_UPLOAD,
  payload
});

export const removeVendorImage = payload => ({
  type: REMOVE_SERVICE_PROVIDER_IMAGE,
  payload
});

export const moveVendorImage = payload => ({
  type: MOVE_SERVICE_PROVIDER_IMAGE,
  payload
});

export const updateHeadlinesReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_SERVICE_PROVIDER_IMAGES_UPLOAD:
      newState['serviceProviderImages'] = addToList(
        newState.serviceProviderImages,
        toImages(action.payload.files)
      );
      break;

    case REMOVE_SERVICE_PROVIDER_IMAGE:
      newState['serviceProviderImages'] = removeAtIndex(
        newState['serviceProviderImages'],
        action.payload.index
      );
      break;

    case MOVE_SERVICE_PROVIDER_IMAGE:
      newState['serviceProviderImages'] = arrayMove(
        newState['serviceProviderImages'],
        action.payload.oldIndex,
        action.payload.newIndex
      );
      break;

    default:
      break;
  }
};

function* updateHeadlinesWorker(action) {
  const serviceProviderData = yield select(getServiceProviderHeadlinesData);

  let data = serviceProviderData;
  const logoArr = _.get(serviceProviderData, 'logo', null);

  if (!_.isNil(logoArr) && Array.isArray(logoArr)) {
    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signServiceProviderLogo);

    data = Object.assign({}, serviceProviderData, { logo: logoUrl });
  }

  const updateResponse = yield makeRequest(api.updateServiceProviderHeadlines, {
    lookupData: { slug: action.slug },
    requestBody: data
  });

  if (updateResponse.success) {
    yield put(successUpdateServiceProvider(updateResponse.data));

    if (action.slug !== updateResponse.data['slug']) {
      const meResponse = yield makeRequest(api.getMe);

      if (meResponse.success) yield put(successFetchMe(meResponse.data));
      yield put(
        push(
          substituteUrl(EDIT_SERVICE_PROVIDER_URL, {
            serviceProviderSlug: updateResponse.data['slug']
          })
        )
      );
      yield put(fetchServiceProvider(updateResponse.data['slug']));
      yield put(setEditMode());
    }
    yield put(openNotificationBar('Service provider was updated successfully'));
  } else {
    yield put(failUpdateServiceProvider(updateResponse.errors));
    return updateResponse.errors;
  }
}

export function* updateHeadlinesSaga() {
  yield takeLatest(
    UPDATE_SERVICE_PROVIDER,
    formSubmitWorker(updateHeadlinesWorker)
  );
  yield takeLatest(
    START_SERVICE_PROVIDER_IMAGES_UPLOAD,
    multipleFileUploadWorkerFactory(
      api.signServiceProviderImage,
      asGenericUploadHook(successVendorImagesUpload),
      asGenericUploadHook(failVendorImagesUpload)
    )
  );
}
