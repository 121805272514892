import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { takeLatest } from 'redux-saga/effects';

import { setAuthorization } from 'shared/utils/setAuthorization';
import { api, makeRequest } from 'shared/sdk';
import { openModal } from 'shared/components/Modals/reducer';
import { MODALS } from 'global-constants';
import { fetchMe } from 'shared/HOCs/FetchInitials/ducks/index.js';
import { nextStep } from 'shared/components/Signup/ducks/manageSteps';
import { GARegisterLinkedIn } from 'shared/utils/GA';

import { DASHBOARD_URL } from 'global-constants';

const actionPrefix = 'LinkedInLogin/';
const START_LINKEDIN_SIGNUP = `${actionPrefix}START_LINKEDIN_SIGNUP`;
const FAIL_LINKEDIN_SIGNUP = `${actionPrefix}FAIL_LINKEDIN_SIGNUP`;

export const linkedInSignUp = ({ code, redirect_uri }) => ({
  type: START_LINKEDIN_SIGNUP,
  payload: { code, redirect_uri }
});

export const failLinkedInSignUp = payload => ({
  type: FAIL_LINKEDIN_SIGNUP,
  payload
});

export function* startLinkedInSignUpWorker(action) {
  const response = yield makeRequest(api.linkedInSignUpDetail, {
    requestBody: action.payload
  });

  const nextUrl = localStorage.getItem('nextUrl');
  localStorage.removeItem('nextUrl');

  if (response.success) {
    setAuthorization(response.data.auth_token);
    if (response.data.new_user) {
      GARegisterLinkedIn();

      let redirectUrl = DASHBOARD_URL;

      if (nextUrl) {
        // In this case the user will be redirected to DASHBOARD
        // with the previous nextUrl set as 'next'.
        // This means that after the user finishes the signup journey he will be
        // redirected to the page corresponding to 'nextUrl'
        // This is needed because the default behavior of the signup modal
        // is to redirect to DASHBOARD after the end of the journey, which makes
        // the direct redirect(before opening the modal) redundant
        redirectUrl = `${redirectUrl}?next=${nextUrl}`;
      }

      yield* [
        put(fetchMe()),
        put(push(redirectUrl)),
        put(
          openModal({
            modalName: MODALS.SIGNUP
          })
        ),
        put(nextStep())
      ];
    } else {
      yield* [put(fetchMe()), put(push(nextUrl ? nextUrl : DASHBOARD_URL))];
    }
  } else yield put(failLinkedInSignUp(response.errors));
}

export function* linkedInLoginSaga() {
  yield takeLatest(START_LINKEDIN_SIGNUP, startLinkedInSignUpWorker);
}
