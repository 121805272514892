import { takeLatest } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Investor/Detail/';

export const FETCH_INVESTOR = `${actionPrefix}FETCH_INVESTOR`;
export const SUCCESS_FETCH_INVESTOR = `${actionPrefix}SUCCESS_FETCH_INVESTOR`;
export const FAIL_FETCH_INVESTOR = `${actionPrefix}FAIL_FETCH_INVESTOR`;

export const fetchInvestor = slug => ({
  type: FETCH_INVESTOR,
  payload: { slug }
});

export const successFetchInvestor = data => ({
  type: SUCCESS_FETCH_INVESTOR,
  payload: {
    data
  }
});

export const failFetchInvestor = errors => ({
  type: FAIL_FETCH_INVESTOR,
  payload: {
    errors
  }
});

export const detailReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVESTOR:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_INVESTOR:
      state['investor'] = action.payload.data;
      state['isFetching'] = false;
      break;

    case FAIL_FETCH_INVESTOR:
      state['isFetching'] = false;
      break;

    default:
      return state;
  }
};

export function* fetchInvestorWorker(action) {
  const response = yield getResponseOr404(api.investorDetail, {
    lookupData: action.payload
  });

  if (response.success) {
    yield put(successFetchInvestor(response.data));
  } else yield put(failFetchInvestor(response.errors));
}

export function* detailSaga() {
  yield takeLatest(FETCH_INVESTOR, fetchInvestorWorker);
}
