import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import appReducer from 'global-reducer';
import globalSagas from 'global-saga';

import history from './history';

const sagaMiddleware = createSagaMiddleware();

const routerMid = routerMiddleware(history);

let middleware = [sagaMiddleware, routerMid];

let composeEnhancer = f => f;

/* develblock:start */
// This will be removed from production bundle.js
composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* develblock:end */

const store = createStore(
  appReducer,
  composeEnhancer(applyMiddleware(...middleware))
);

sagaMiddleware.run(globalSagas);

export default store;
