import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

const actionPrefix = 'Event/Form/';
const FETCH_USERS = `${actionPrefix}FETCH_USERS`;
const SUCCESS_FETCH_USERS = `${actionPrefix}SUCCESS_FETCH_USERS`;
const FAIL_FETCH_USERS = `${actionPrefix}FAIL_FETCH_USERS`;
const RESET_USERS = `${actionPrefix}RESET_USERS`;

export const fetchUsers = search => ({
  type: FETCH_USERS,
  payload: {
    search
  }
});

const successFetchUsers = payload => ({
  type: SUCCESS_FETCH_USERS,
  payload
});

const failFetchUsers = payload => ({
  type: FAIL_FETCH_USERS,
  payload
});

export const resetUsers = () => ({
  type: RESET_USERS
});

export const usersReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_USERS:
      newState.users = action.payload.results;
      break;

    case FAIL_FETCH_USERS:
      newState.errors = action.payload.errors;
      break;

    case RESET_USERS:
      newState.users = [];
      break;

    default:
      return newState;
  }
};

function* fetchUsersWorker(action) {
  const response = yield makeRequest(api.usersList, {
    requestData: { params: action.payload }
  });

  if (response.success) yield put(successFetchUsers(response.data));
  else yield put(failFetchUsers(response.errors));
}

export function* usersWatcher() {
  yield takeLatest(FETCH_USERS, fetchUsersWorker);
}
