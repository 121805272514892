export const handleSuccessFetchCompanies = ({
  preferentialCompanies = [],
  listCompanies = []
}) => {
  const preferentialCompanyIds = preferentialCompanies.map(item => item.id);
  const filteredListCompanies = listCompanies.filter(
    item => !preferentialCompanyIds.includes(item.id)
  );

  return {
    preferentialCompanies,
    listCompanies: filteredListCompanies,
    allCompanies: preferentialCompanies.concat(filteredListCompanies)
  };
};

export const handleSuccessFetchNextCompanies = ({
  currentPreferentialCompanies,
  currentListCompanies,
  nextCompanies
}) => {
  const preferentialCompanyIds = currentPreferentialCompanies.map(
    item => item.id
  );
  const filteredListCompanies = currentListCompanies
    .concat(nextCompanies)
    .filter(item => !preferentialCompanyIds.includes(item.id));

  return {
    preferentialCompanies: currentPreferentialCompanies,
    listCompanies: filteredListCompanies,
    allCompanies: (currentPreferentialCompanies || []).concat(
      filteredListCompanies
    )
  };
};
