import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest, getResponseOr404 } from 'shared/sdk';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'CreateTenderResponse/';

const CREATE_TENDER_RESPONSE = `${actionPrefix}CREATE_TENDER_RESPONSE`;
const SUCCESS_CREATE_TENDER_RESPONSE = `${actionPrefix}SUCCESS_CREATE_TENDER_RESPONSE`;

const CHECK_PREVIOUS_RESPONSES = `${actionPrefix}CHECK_PREVIOUS_RESPONSES`;

export const createTenderResponse = (
  responseFormId,
  tenderCodeSetter,
  extraActions
) => ({
  type: CREATE_TENDER_RESPONSE,
  payload: { response_form_id: responseFormId },
  tenderCodeSetter,
  extraActions
});

const successCreateTenderResponse = () => ({
  type: SUCCESS_CREATE_TENDER_RESPONSE
});

export const checkPreviousResponses = ({ responseFormId, fetchForm }) => ({
  type: CHECK_PREVIOUS_RESPONSES,
  payload: {
    responseFormId,
    fetchForm
  }
});

function* createTenderResponseWorker(action) {
  const response = yield getResponseOr404(api.createTenderResponse, {
    requestBody: action.payload
  });

  if (response.success) {
    yield put(successCreateTenderResponse());
    yield* action.extraActions.map(action => action());
    action.tenderCodeSetter(response.data);
  }
}

function* checkPreviousResponsesWorker(action) {
  const { responseFormId, fetchForm } = action.payload;

  const response = yield makeRequest(api.tenderResponseDetailsForUser, {
    lookupData: {
      responseFormId
    }
  });

  if (response.success) {
    if (response.data.user_has_responded) {
      yield put(
        openNotificationBar(
          'You have already responded to this Tender!',
          null,
          true
        )
      );
    } else {
      fetchForm();

      if (response.data.company_member_has_responded) {
        yield put(
          openNotificationBar(
            'Member of your company has already responded to this Tender!',
            null,
            true
          )
        );
      }
    }
  }
}

export function* createTenderResponseSaga() {
  yield takeLatest(CREATE_TENDER_RESPONSE, createTenderResponseWorker);
  yield takeLatest(CHECK_PREVIOUS_RESPONSES, checkPreviousResponsesWorker);
}
