import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { MODALS, RESOURCES_URL } from 'global-constants';
import { api, makeRequest } from 'shared/sdk';
import { uploadFile } from 'shared/utils/fileUpload';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { openModal } from 'shared/components/Modals/reducer';

const actionPrefix = 'CreateResourceProposal/';

const SUBMIT_RESOURCE_PROPOSAL = `${actionPrefix}SUBMIT_RESOURCE_PROPOSAL`;
const SUCCESS_SUBMIT_RESOURCE_PROPOSAL = `${actionPrefix}SUCCESS_SUBMIT_RESOURCE_PROPOSAL`;
const FAIL_SUBMIT_RESOURCE_PROPOSAL = `${actionPrefix}FAIL_SUBMIT_RESOURCE_PROPOSAL`;

export const submitResourceProposal = (formName, data) => ({
  type: SUBMIT_RESOURCE_PROPOSAL,
  formName,
  payload: data
});

const successSubmitResourceProposal = data => ({
  type: SUCCESS_SUBMIT_RESOURCE_PROPOSAL,
  payload: data
});

const failSubmitResourceProposal = data => ({
  type: FAIL_SUBMIT_RESOURCE_PROPOSAL,
  payload: data
});

function* submitResourceWorker(action) {
  let data = action.payload;

  const imageArr = _.get(data, 'image', null);

  if (!_.isNil(imageArr)) {
    const image = imageArr[0];

    const imageUrl = yield uploadFile(image, api.signResourceImage);

    data = Object.assign({}, data, { image: imageUrl });
  }

  const response = yield makeRequest(api.createResourceProposal, {
    requestBody: data
  });

  if (response.success) {
    yield* [
      put(successSubmitResourceProposal(response.data)),
      put(push(RESOURCES_URL)),
      put(openModal({ modalName: MODALS.SUCCESS_SUBMIT_ARTICLE }))
    ];
  } else {
    yield* [put(failSubmitResourceProposal(response.errors))];
    window.scroll(0, 0);
    return response.errors;
  }
}

export function* submitResourceWatcher() {
  yield takeLatest(
    SUBMIT_RESOURCE_PROPOSAL,
    formSubmitWorker(submitResourceWorker)
  );
}
