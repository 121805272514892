import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

class Button extends React.Component {
  render() {
    const {
      id,
      children,
      onClick,
      disabled,
      type,
      className,
      color,
      style,
      title = null,
      isLoading = false
    } = this.props;

    return (
      <button
        id={id}
        type={type || 'button'}
        className={classNames(
          className,
          styles.Button,
          styles[color],
          disabled ? styles.disabled : ''
        )}
        onClick={onClick}
        disabled={disabled || isLoading}
        title={title}
        style={style}>
        {isLoading ? (
          <svg className={styles.progressRing} width="30" height="30">
            <circle
              className={styles.progressRingCircle}
              stroke="white"
              strokeWidth="2"
              fill="transparent"
              r="13"
              cx="15"
              cy="15"
            />
          </svg>
        ) : (
          <h5>{children}</h5>
        )}
      </button>
    );
  }
}

export default Button;
