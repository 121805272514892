import React from 'react';
import { connect } from 'react-redux';

import { selectModal, closeModal, storeDataInModal } from '../reducer';

// import { removeModalFromUrl } from 'utils/modals';

export const withModal = (
  modalName,
  mapStateToModalData = (state, data) => ({}),
  mapDispatchToModalProps = {}
) => {
  return function(WrappedComponent) {
    class Wrapper extends React.Component {
      render() {
        const closeModalHandler = () => {
          this.props.closeModal(modalName);
        };
        const closeModalExtraActions = [() => closeModal(modalName)];

        const storeDataExtraActions = transformData => {
          return [data => storeDataInModal(modalName, transformData(data))];
        };

        return (
          <WrappedComponent
            {...this.props}
            data={this.props.data}
            modalName={modalName}
            closeModal={closeModalHandler}
            closeModalExtraActions={closeModalExtraActions}
            storeDataExtraActions={storeDataExtraActions}
          />
        );
      }
    }

    const mapStateToProps = state => {
      const { data } = selectModal(state, modalName);
      const modalAdditionalData = mapStateToModalData(state, data);

      return {
        data: {
          ...data,
          ...modalAdditionalData
        }
      };
    };

    const mapDispatchToProps = {
      closeModal,
      ...mapDispatchToModalProps
    };

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(Wrapper);
  };
};
