import { put, takeLatest } from 'redux-saga/effects';
import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'TenderResponse/Detail/';

const FETCH_TENDER_RESPONSE = `${actionPrefix}FETCH_TENDER_RESPONSE`;
const SUCCESS_FETCH_TENDER_RESPONSE = `${actionPrefix}SUCCESS_FETCH_TENDER_RESPONSE`;

export const CHANGE_TENDER_RESPONSE_STATUS = `${actionPrefix}CHANGE_TENDER_RESPONSE_STATUS`;

export const fetchTenderResponse = tenderResponseId => ({
  type: FETCH_TENDER_RESPONSE,
  payload: { tenderResponseId }
});

const successFetchTenderResponse = payload => ({
  type: SUCCESS_FETCH_TENDER_RESPONSE,
  payload
});

export const changeResponseStatus = ({ status }) => ({
  type: CHANGE_TENDER_RESPONSE_STATUS,
  payload: {
    status
  }
});

export const detailReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_TENDER_RESPONSE_STATUS:
      state['tenderResponse'] = {
        ...state['tenderResponse'],
        ...action.payload
      };
      break;

    case FETCH_TENDER_RESPONSE:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_TENDER_RESPONSE:
      state['tenderResponse'] = action.payload;
      state['isFetching'] = false;
      break;

    default:
      break;
  }
};

function* fetchTenderResponseWorker(action) {
  const { tenderResponseId } = action.payload;

  const response = yield getResponseOr404(api.fetchTenderResponse, {
    lookupData: { tenderResponseId }
  });

  if (response.success) {
    yield put(successFetchTenderResponse(response.data));
  }
}

export function* detailSaga() {
  yield takeLatest(FETCH_TENDER_RESPONSE, fetchTenderResponseWorker);
}
