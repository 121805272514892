import _ from 'lodash';

export const getConversationsWithUpdatedLatestMessage = (
  state,
  groupedMessages
) => {
  return state.conversations.map(item => {
    const messages = _.get(groupedMessages, `${item.id}`, null);
    if (!_.isNil(messages))
      return {
        ...item,
        latest_message: { ...messages[0], is_seen: false }
      };

    return { ...item };
  });
};
