import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'InfluencerDetail/Experience/';

const FETCH_EXPERIENCE = `${actionPrefix}FETCH_EXPERIENCE`;
const SUCCESS_FETCH_EXPERIENCE = `${actionPrefix}SUCCESS_FETCH_EXPERIENCE`;

export const fetchExperience = ({ influencerSlug, nextUrl }) => ({
  type: FETCH_EXPERIENCE,
  payload: { influencerSlug, nextUrl }
});

const successFetchExperience = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_EXPERIENCE,
  payload: { results, next }
});

export const experienceReducer = (state, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCE:
      state['isFetchingExperience'] = true;
      break;

    case SUCCESS_FETCH_EXPERIENCE:
      state['isFetchingExperience'] = false;
      state['experience'] = addToList(state.experience, action.payload.results);
      state['experienceNextUrl'] = action.payload.next;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchExperienceWorker(action) {
  const { influencerSlug, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    response = yield makeRequest(api.experienceList, {
      requestData: { params: { limit: 10 } },
      lookupData: { influencerSlug }
    });
  }

  if (response.success) {
    yield put(successFetchExperience(response));
  }
}

export function* experienceSaga() {
  yield takeLatest(FETCH_EXPERIENCE, fetchExperienceWorker);
}
