import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'PromoCode/';

const ACTIVATE_PROMO_CODE = `${actionPrefix}ACTIVATE_PROMO_CODE`;
const SUCCESS_ACTIVATE_PROMO_CODE = `${actionPrefix}SUCCESS_ACTIVATE_PROMO_CODE`;
const FAIL_ACTIVATE_PROMO_CODE = `${actionPrefix}FAIL_ACTIVATE_PROMO_CODE`;

export const activatePromoCode = (formData, formName) => ({
  type: ACTIVATE_PROMO_CODE,
  payload: {
    promocode: formData.promocode
  },
  formName
});

export const successActivatePromoCode = payload => ({
  type: SUCCESS_ACTIVATE_PROMO_CODE,
  payload
});

export const failActivatePromoCode = errors => ({
  type: FAIL_ACTIVATE_PROMO_CODE,
  payload: { errors }
});

function* promoCodeWorker(action) {
  const { promocode } = action.payload;

  const response = yield makeRequest(api.activatePromoCode, {
    requestBody: { promocode }
  });

  if (response.success) {
    const notificationContent =
      'Congratulations! You have successfully activated your trial of our Premium Features.';

    yield* [
      put(successActivatePromoCode(response.data)),
      put(openNotificationBar(notificationContent))
    ];
  } else {
    yield put(failActivatePromoCode(response.errors));
    return response.errors;
  }
}

export function* promoCodeWatcher() {
  yield takeLatest(ACTIVATE_PROMO_CODE, formSubmitWorker(promoCodeWorker));
}
