import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { buildFiltersFromObj } from 'shared/utils/filters';

const actionPrefix = 'filters/';
export const FETCH_SERVICE_PROVIDERS = `${actionPrefix}FETCH_SERVICE_PROVIDERS`;
export const SUCCESS_FETCH_SERVICE_PROVIDERS = `${actionPrefix}SUCCESS_FETCH_SERVICE_PROVIDERS`;
export const FAIL_FETCH_SERVICE_PROVIDERS = `${actionPrefix}FAIL_FETCH_SERVICE_PROVIDERS`;

export const fetchServiceProviders = (filters = {}) => ({
  type: FETCH_SERVICE_PROVIDERS,
  payload: {
    filters
  }
});

export const successFetchServiceProviders = payload => ({
  type: SUCCESS_FETCH_SERVICE_PROVIDERS,
  payload
});

export const failFetchServiceProviders = payload => ({
  type: FAIL_FETCH_SERVICE_PROVIDERS,
  payload
});

export const serviceProvidersReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SERVICE_PROVIDERS:
      state.filterChoices['serviceProviders'] = action.payload.results;
      break;

    default:
      return state;
  }
};

function* fetchServiceProvidersWorker(action) {
  const filters = action.payload.filters;

  const response = yield makeRequest(api.companiesList, {
    requestData: { params: buildFiltersFromObj(filters) }
  });

  if (response.success) yield put(successFetchServiceProviders(response.data));
  else yield put(failFetchServiceProviders(response.errors));
}

export function* serviceProvidersWatcher() {
  yield takeLatest(FETCH_SERVICE_PROVIDERS, fetchServiceProvidersWorker);
}
