import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Job/Form/Detail/';

const FETCH_JOB_DETAIL = `${actionPrefix}FETCH_JOB_DETAIL`;
const SUCCESS_FETCH_JOB_DETAIL = `${actionPrefix}SUCCESS_FETCH_JOB_DETAIL`;

export const fetchJobDetail = ({ jobSlug }) => ({
  type: FETCH_JOB_DETAIL,
  payload: { jobSlug }
});

const successFetchJobDetail = ({ data }) => ({
  type: SUCCESS_FETCH_JOB_DETAIL,
  payload: { data }
});

export const detailReducer = (state, action) => {
  switch (action.type) {
    case FETCH_JOB_DETAIL:
      state['isFetchingFormData'] = true;
      break;

    case SUCCESS_FETCH_JOB_DETAIL:
      state['job'] = action.payload.data;
      state['isFetchingFormData'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchJobDetailWorker(action) {
  const { jobSlug } = action.payload;

  const response = yield getResponseOr404(api.jobDetail, {
    lookupData: { jobSlug }
  });

  if (response.success) {
    yield put(successFetchJobDetail(response));
  }
}

export function* detailSaga() {
  yield takeLatest(FETCH_JOB_DETAIL, fetchJobDetailWorker);
}
