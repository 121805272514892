import _ from 'lodash';

import {
  COMPANY_DETAIL_URL,
  INVESTOR_DETAIL_URL,
  SERVICE_PROVIDER_DETAIL_URL
} from 'global-constants';

import { normalizeUrl } from 'shared/utils/url';
import { substituteUrl } from 'shared/utils/substituteUrl';

export const getCompanyLocation = ({ company }) =>
  [
    _.get(company, 'billing_city.name'),
    _.get(company, 'billing_country.name')
  ].join(', ');

export const normalizeCompanyContactFields = data => {
  const urlFields = [
    'facebook',
    'linkedin',
    'twitter',
    'youtube',
    'instagram',
    'weibo',
    'wechat',
    'youku',
    'naver',
    'kakao'
  ];

  _.forEach(
    urlFields,
    field =>
      (data[field] = normalizeUrl({
        url: data[field],
        protocol: 'https'
      }))
  );

  return data;
};

export const redirectToCompany = ({
  vendor,
  serviceProvider,
  investor,
  push
}) => {
  const companySlug = _.get(vendor, 'slug');
  const serviceProviderSlug = _.get(serviceProvider, 'slug');
  const investorSlug = _.get(investor, 'slug');

  if (companySlug) {
    push(substituteUrl(COMPANY_DETAIL_URL, { companySlug }));
  } else if (serviceProviderSlug) {
    push(substituteUrl(SERVICE_PROVIDER_DETAIL_URL, { serviceProviderSlug }));
  } else if (investorSlug) {
    push(substituteUrl(INVESTOR_DETAIL_URL, { investorSlug }));
  }
};
