import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'CreateResourceProposal/';
const FETCH_RESOURCE_TYPES = `${actionPrefix}FETCH_RESOURCE_TYPES`;
const SUCCESS_FETCH_RESOURCE_TYPES = `${actionPrefix}SUCCESS_FETCH_RESOURCE_TYPES`;
const FAIL_FETCH_RESOURCE_TYPES = `${actionPrefix}FAIL_FETCH_RESOURCE_TYPES`;

export const fetchResourceTypes = () => ({
  type: FETCH_RESOURCE_TYPES
});

const successFetchResourceTypes = ({ results, count, next }) => ({
  type: SUCCESS_FETCH_RESOURCE_TYPES,
  payload: {
    results,
    count,
    next
  }
});

const failFetchResourceTypes = payload => ({
  type: FAIL_FETCH_RESOURCE_TYPES,
  payload
});

export const resourceTypesReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_RESOURCE_TYPES:
      state['resourceTypes'] = action.payload.results;
      break;
    default:
      return state;
  }
  return state;
};

function* fetchResourceTypesWorker(action) {
  const response = yield makeRequest(api.resourceTypesList);

  if (response.success) yield put(successFetchResourceTypes(response.data));
  else yield put(failFetchResourceTypes(response.errors));
}

export function* resourceTypesWatcher() {
  yield takeLatest(FETCH_RESOURCE_TYPES, fetchResourceTypesWorker);
}
