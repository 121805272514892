import { fork, all } from 'redux-saga/effects';
import loginSaga from 'shared/components/LoginModal/ducks';

import changeTenderResponseStatusSaga from 'shared/components/TenderResponseStatusForm/ducks';

import { companySagas } from 'pages/Company/';

import { resourceSagas } from 'pages/Resource/';

import { solutionSagas } from 'pages/Solution/';

import { serviceProviderSagas } from 'pages/ServiceProvider/';
import { serviceProviderFormSaga } from 'shared/components/ServiceProviderForm/ducks';

import { eventSagas } from 'pages/Event/';
import { investorSagas } from 'pages/Investor/';
import { influencerSagas } from 'pages/Influencer/';
import { connectVideoSagas } from 'pages/ConnectVideo';

import { tenderSagas } from 'pages/Tender/';
import { tenderResponseSagas } from 'pages/TenderResponse/';
import { shareTenderModalSaga } from 'shared/components/ShareTenderModal/ducks';
import { tendersSaga as tenderWidgetSaga } from 'shared/components/TenderWidget/ducks';
import { availableTendersSectionSaga } from 'shared/components/AvailableTendersSection/ducks';

import { influencerIdToSlugRedirectSaga } from 'pages/InfluencerIdToSlugRedirect/ducks';

import { newsSaga } from 'pages/News/ducks';
import { onboardingSagas } from 'shared/components/Signup/ducks/';
import { linkedInLoginSaga } from 'pages/LinkedInLogin/ducks';
import { homeSagas } from 'pages/Home/ducks';
import { fetchInitialsSagas } from 'shared/HOCs/FetchInitials/ducks';
import { fetchVendorCountSagas } from 'shared/HOCs/WithVendorCount/ducks';
import { staticPageSaga } from 'pages/StaticPage/ducks';
import { dashboardSagas } from 'pages/Dashboard/ducks';
import { editProfileSaga } from 'pages/EditProfile/ducks';
import { forgotPasswordSaga } from 'shared/components/ForgotPasswordModal/ducks';
import { resetPasswordSaga } from 'pages/ResetPassword/ducks';
import { filtersSaga } from 'shared/components/Filters/ducks';
import { changePlanSaga } from 'shared/components/ChangePlanModal/ducks';
import { globalSearchSagas } from 'pages/GlobalSearch/ducks';
import { inviteTeamMembersSaga } from 'shared/components/InviteTeamMembersModal/ducks';
import { productFormSaga } from 'shared/components/ProductFormFields/ducks';
import { messagingSagas } from 'pages/Messaging/ducks';
import { quillSaga } from 'shared/components/QuillEditor/ducks';
import userOpinionsSaga from 'shared/components/UsersOpinion/ducks';
import { sendMessagesSaga } from 'shared/components/SendMessagesModal/ducks';
import { sendNotificationSaga } from 'pages/SendNotification/ducks';
import { claimCompanyWatcher } from 'shared/components/ClaimCompanyModal/ducks';
import { pushProviderSaga } from 'shared/components/PushProvider/ducks';
import { holisticsSaga } from 'shared/components/HolisticsEmbed/ducks';
import { fetchTenderPartnerSaga } from 'shared/HOCs/WithTenderPartner/ducks';
import { fetchTenderPartnerTendersSaga } from 'pages/TenderPartner/AvailableTenders/ducks';
import { postSignUpModalSaga } from 'shared/components/PostSignUpModal/ducks';
import { maturityMatrixSaga } from 'shared/components/MaturityMatrix/ducks';
import { rankDifferenceWidgetSaga } from 'shared/components/RankDifferenceWidget/ducks';
import { userPreferencesWidgetSaga } from 'shared/components/UserPreferencesWidget/ducks';
import { positionFieldSaga } from 'shared/forms/PositionField/ducks';
import { connectButtonSaga } from 'shared/components/ConnectButton/ducks';
import { notificationSagas } from 'pages/Notifications/ducks';
import { mentionsTextAreaSaga } from 'shared/components/MentionsTextArea/ducks';
import { countryFieldSaga } from 'shared/forms/CountryField/ducks';
import { newsLanguageFieldSaga } from 'shared/forms/NewsLanguageField/ducks';
import { cityFieldSaga } from 'shared/forms/CityField/ducks';
import { jobSaga } from 'pages/Jobs';
import { trackingSaga } from 'shared/components/DetailTracker/ducks';
import { featuredJobsSectionSaga } from 'shared/components/FeaturedJobsSection/ducks';
import { companyPredefinedSearchSaga } from 'pages/Company/PredefinedSearch/ducks';
import { caseStudiesSaga } from 'pages/CaseStudies/ducks';
import { connectLoginSaga } from 'pages/ConnectLogin/ducks';

const sagas = [
  loginSaga,
  trackingSaga,
  changeTenderResponseStatusSaga,
  linkedInLoginSaga,
  staticPageSaga,
  forgotPasswordSaga,
  resetPasswordSaga,
  changePlanSaga,
  productFormSaga,
  quillSaga,
  userOpinionsSaga,
  inviteTeamMembersSaga,
  claimCompanyWatcher,
  sendMessagesSaga,
  pushProviderSaga,
  holisticsSaga,
  fetchTenderPartnerSaga,
  fetchTenderPartnerTendersSaga,
  tenderWidgetSaga,
  postSignUpModalSaga,
  maturityMatrixSaga,
  positionFieldSaga,
  newsSaga,
  rankDifferenceWidgetSaga,
  connectButtonSaga,
  userPreferencesWidgetSaga,
  mentionsTextAreaSaga,
  influencerIdToSlugRedirectSaga,
  countryFieldSaga,
  newsLanguageFieldSaga,
  cityFieldSaga,
  featuredJobsSectionSaga,
  companyPredefinedSearchSaga,
  caseStudiesSaga,
  shareTenderModalSaga,
  connectLoginSaga,
  ...jobSaga,
  ...notificationSagas,
  ...dashboardSagas,
  ...investorSagas,
  ...eventSagas,
  ...tenderSagas,
  ...companySagas,
  ...solutionSagas,
  ...resourceSagas,
  ...influencerSagas,
  ...serviceProviderSagas,
  ...serviceProviderFormSaga,
  ...filtersSaga,
  ...fetchInitialsSagas,
  ...fetchVendorCountSagas,
  ...onboardingSagas,
  ...homeSagas,
  ...editProfileSaga,
  ...globalSearchSagas,
  ...messagingSagas,
  ...sendNotificationSaga,
  ...tenderResponseSagas,
  ...availableTendersSectionSaga,
  ...connectVideoSagas
];

function* globalSagas() {
  const globalSagasForks = sagas.map(saga => fork(saga));

  yield all(globalSagasForks);
}

export default globalSagas;
