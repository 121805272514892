import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { buildFiltersFromObj } from 'shared/utils/filters';
import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';

const actionPrefix = 'Create/Tender/';

const FETCH_TENDERS = `${actionPrefix}FETCH_TENDERS`;
const SUCCESS_FETCH_TENDERS = `${actionPrefix}SUCCESS_FETCH_TENDERS`;

const FETCH_NEXT_TENDERS = `${actionPrefix}FETCH_NEXT_TENDERS`;
const SUCCESS_FETCH_NEXT_TENDERS = `${actionPrefix}SUCCESS_FETCH_NEXT_TENDERS`;

const FETCH_RESPONSES = `${actionPrefix}FETCH_RESPONSES`;
const SUCCESS_FETCH_RESPONSES = `${actionPrefix}SUCCESS_FETCH_RESPONSES`;

const CHANGE_TENDER_RESPONSE_STATUS = `${actionPrefix}CHANGE_TENDER_RESPONSE_STATUS`;

export const fetchTenders = (filters = {}) => ({
  type: FETCH_TENDERS,
  payload: {
    filters
  }
});

const successFetchTenders = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_TENDERS,
  payload: { results, next }
});

export const fetchNextTenders = ({ nextUrl }) => ({
  type: FETCH_NEXT_TENDERS,
  payload: { nextUrl }
});

const successFetchNextTenders = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_NEXT_TENDERS,
  payload: { results, next }
});

export const fetchResponses = ({ tenderId, nextUrl }) => ({
  type: FETCH_RESPONSES,
  payload: { tenderId, nextUrl }
});

const successFetchResponses = ({ tenderId, data: { results, next } }) => ({
  type: SUCCESS_FETCH_RESPONSES,
  payload: { results, next, tenderId }
});

export const changeResponseStatus = ({ tenderId, responseId, status }) => ({
  type: CHANGE_TENDER_RESPONSE_STATUS,
  payload: {
    tenderId,
    responseId,
    status
  }
});

export const listTendersReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TENDERS:
      state['isFetching'] = true;
      state['tenders'] = [];
      state['nextUrl'] = null;
      break;

    case SUCCESS_FETCH_TENDERS:
      state['nextUrl'] = action.payload.next;
      state['tenders'] = action.payload.results;
      state['isFetching'] = false;
      break;

    case FETCH_NEXT_TENDERS:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_NEXT_TENDERS:
      state['nextUrl'] = action.payload.next;
      state['tenders'] = addToList(state.tenders, action.payload.results);
      state['isFetching'] = false;
      break;

    case FETCH_RESPONSES:
      state['tenders'] = updateByIdentifier({
        collection: state.tenders,
        identifierField: 'id',
        identifierValue: action.payload.tenderId,
        newItem: { isFetchingResponses: true }
      });
      break;

    case SUCCESS_FETCH_RESPONSES:
      state['tenders'] = state.tenders.map(
        tender =>
          tender.id === action.payload.tenderId
            ? {
                ...tender,
                isFetchingResponses: false,
                responsesNextUrl: action.payload.next,
                responses: addToList(
                  _.get(tender, 'responses', []),
                  action.payload.results
                )
              }
            : tender
      );
      break;

    case CHANGE_TENDER_RESPONSE_STATUS:
      const { responseId, status } = action.payload;
      state['tenders'] = state.tenders.map(
        tender =>
          tender.id === action.payload.tenderId
            ? {
                ...tender,
                responses: tender.responses.map(
                  response =>
                    response.id === responseId
                      ? { ...response, status }
                      : response
                )
              }
            : tender
      );
      break;

    default:
      return state;
  }
};

function* fetchTendersWorker(action) {
  const response = yield makeRequest(api.tendersListByUser, {
    requestData: {
      params: { ...buildFiltersFromObj(action.payload.filters), limit: 10 }
    }
  });

  if (response.success) {
    yield put(successFetchTenders(response));
  }
}

function* fetchNextTendersWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.payload.nextUrl
  });

  if (response.success) {
    yield put(successFetchNextTenders(response));
  }
}

function* fetchResponsesWorker(action) {
  const { tenderId, nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.tenderResponseList, {
      lookupData: { tenderId },
      requestData: {
        params: { limit: 10 }
      }
    });
  } else {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  }

  if (response.success) {
    yield put(successFetchResponses({ tenderId, data: response.data }));
  }
}

export function* tendersWatcher() {
  yield takeLatest(FETCH_TENDERS, fetchTendersWorker);
  yield takeLatest(FETCH_NEXT_TENDERS, fetchNextTendersWorker);
  yield takeLatest(FETCH_RESPONSES, fetchResponsesWorker);
}
