import { generateProgressTabData } from './utils';

const actionPrefix = 'CreateCompany/';

export const PROGRESS_TABS = {
  HEADLINES: { numeration: 1, name: 'Headlines', marked: true },
  PRODUCTS: { numeration: 2, name: 'Products' },
  LOCATION: { numeration: 3, name: 'Location' },
  CONTACT: { numeration: 4, name: 'Contact' }
};

const SET_ACTIVE_PROGRESS_TAB = `${actionPrefix}SET_ACTIVE_PROGRESS_TAB`;

export const setActiveProgressTab = selectedTabNumeration => ({
  type: SET_ACTIVE_PROGRESS_TAB,
  payload: { selectedTabNumeration }
});

export const progressBarReducer = (newState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PROGRESS_TAB:
      newState.progressTabs = generateProgressTabData(
        newState.progressTabs,
        action.payload.selectedTabNumeration
      );

      newState.activeProgressTab = action.payload.selectedTabNumeration;
      break;

    default:
      return newState;
  }
};
