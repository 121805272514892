import _ from 'lodash';
import moment from 'moment';

import { DATETIME_FORMAT } from 'global-constants';

export const removeHtmlTags = text => {
  if (_.isNil(text)) {
    return '';
  }

  return text.replace(/<\/?[^>]+(>|$)/g, '');
};

export const toggleHubspotChat = () => {
  const { HubSpotConversations } = window;

  if (_.isNil(HubSpotConversations)) {
    return;
  }

  window.HubSpotConversations.widget.open();
};
export const randomNumberBetweenZeroAndOne = () => {
  let s = Math.random();
  s = Math.sin(s) * 10000;
  return s - Math.floor(s);
};

export const nullIfNotANumber = value => {
  return _.isString(value) && _.isEmpty(value) ? null : value;
};

export const filterMatchPluralForm = (count = 0, countable) => {
  return `${count || 0} ${countable}${count === 1 ? '' : 's'} match${
    count === 1 ? 'es' : ''
  } your requirements`;
};

export const getUserCompanyName = user => {
  return (
    _.get(user, 'vendor.name') ||
    _.get(user, 'service_provider.name') ||
    _.get(user, 'investor.name')
  );
};

export const getEventHostName = event => {
  return _.get(event, 'host_vendor.name');
};

export const scrollNamedElementIntoView = name => {
  const elements = document.getElementsByName(name);

  if (elements.length > 0) {
    const element = Array.from(elements).filter(
      el => el.nodeName === 'INPUT'
    )[0];

    // Inputs with type hiddent don't have a position in the rendered DOM tree
    if (element.type === 'hidden') {
      // However other types with hidden visibility have a possition
      element.style.visibility = 'hidden';
      element.type = 'text';
      element.style.padding = 0;
      element.style.border = 0;
      element.style.width = 0;
      element.style.height = 0;
    }

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }
};

export const downloadFile = (data, filename) => {
  if (!_.isNil(data.content)) {
    const csvBlob = new Blob([data.content], {
      type: 'text/csv;charset=utf-8'
    });

    const link = window.URL.createObjectURL(csvBlob);

    let downloadElement = document.createElement('a');
    downloadElement.href = link;
    downloadElement.download = filename;

    downloadElement.click();
  }
};

export const firstTabIfTabIsInvalid = (tab, tabs = {}) => {
  const tabKeys = _.keys(tabs);

  if (tabKeys.includes(tab)) {
    return tab;
  }

  return _.head(tabKeys);
};

export const checkIfHasCompany = ({ user }) => {
  if (!user) {
    return false;
  }

  const hasVendor = !_.isNil(user.vendor);
  const hasInvestor = !_.isNil(user.investor);
  const hasServiceProvider = !_.isNil(user.service_provider);

  return hasVendor || hasInvestor || hasServiceProvider;
};

export const getReadableTimeDelta = ({ time }) => {
  if (_.isNil(time)) return '';

  time = moment.utc(time, DATETIME_FORMAT);
  const deltaSeconds = moment().diff(time, 'seconds');

  if (deltaSeconds < 60) return 'A few seconds ago';

  const deltaMinutes = moment().diff(time, 'minutes');

  if (deltaMinutes < 2) return 'A minute ago';
  if (deltaMinutes < 60) return 'A few minutes ago';
  if (deltaMinutes < 120) return 'An hour ago';

  const deltaHours = moment().diff(time, 'hours');

  if (deltaHours < 24) return 'A few hours ago';

  const deltaDays = moment().diff(time, 'days');

  if (deltaDays < 2) return 'Yesterday';
  if (deltaDays < 7) return `${deltaDays} days ago`;
  if (deltaDays < 14) return 'About a week ago';
  if (deltaDays < 31) return `${Math.floor(deltaDays / 7)} weeks ago`;

  const deltaMonths = moment().diff(time, 'months');

  if (deltaMonths < 2) return 'About a month ago';
  if (deltaMonths < 12) return `${deltaMonths} months ago`;
  if (deltaMonths === 12) return 'A year ago';
  else return 'More than a year ago';
};

export const scrollToTop = ({ topOffset = 0 } = {}) => {
  window.scrollTo({
    left: 0,
    top: topOffset,
    behavior: 'smooth'
  });
};

export const getCurrencySymbol = ({ currency }) => {
  switch (currency) {
    case 'N/A':
      return '';
    case 'AUD':
    case 'CAD':
    case 'INR':
    case 'HKD':
    case 'NZD':
    case 'SGD':
    case 'USD':
      return '$';
    case 'BRL':
      return 'R$';
    case 'CHF':
      return 'CHF';
    case 'CNY':
    case 'JPY':
      return '¥';
    case 'DDK':
    case 'NOK':
    case 'SEK':
      return 'kr';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'ZAR':
      return 'R';
    default:
      return currency;
  }
};

export const formatNumberRangeWithCommas = ({ range }) => {
  // Turn a number from 5000-6000 to 5,000-6,000, 100000+ to 100,000+.

  if (_.endsWith(range, '+')) {
    const number = _.head(_.split(range, '+'));

    return `${parseInt(number).toLocaleString('en')}+`;
  }

  const numbers = _.split(range, '-');

  const formattedNumbers = _.map(numbers, number => {
    number = parseInt(number);

    if (_.isNaN(number)) {
      return '';
    }

    return number.toLocaleString('en');
  });

  return _.join(formattedNumbers, '-');
};

export const disableBodyScroll = () => {
  document.body.style.overflowY = 'hidden';
};

export const enableBodyScroll = () => {
  document.body.style.overflowY = 'auto';
};

export const getExtensionFromFilename = filename => _.last(filename.split('.'));
