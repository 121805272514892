import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { api, makeRequest } from 'shared/sdk';

import { uploadFile } from 'shared/utils/fileUpload';
import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'EditProfile/Experience/';

const FETCH_EXPERIENCE = `${actionPrefix}FETCH_EXPERIENCE`;
const SUCCESS_FETCH_EXPERIENCE = `${actionPrefix}SUCCESS_FETCH_EXPERIENCE`;

const DELETE_EXPERIENCE = `${actionPrefix}DELETE_EXPERIENCE`;
const SUCCESS_DELETE_EXPERIENCE = `${actionPrefix}SUCCESS_DELETE_EXPERIENCE`;

const UPDATE_OR_CREATE_EXPERIENCE = `${actionPrefix}UPDATE_OR_CREATE_EXPERIENCE`;
const SUCCESS_UPDATE_OR_CREATE_EXPERIENCE = `${actionPrefix}SUCCESS_UPDATE_OR_CREATE_EXPERIENCE`;

export const fetchExperience = ({ influencerSlug, nextUrl }) => ({
  type: FETCH_EXPERIENCE,
  payload: { influencerSlug, nextUrl }
});

const successFetchExperience = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_EXPERIENCE,
  payload: { results, next }
});

export const deleteExperience = ({ experienceId }) => ({
  type: DELETE_EXPERIENCE,
  payload: { experienceId }
});

const successDeleteExperience = ({ experienceId }) => ({
  type: SUCCESS_DELETE_EXPERIENCE,
  payload: { experienceId }
});

export const updateOrCreateExperience = ({
  formName,
  data,
  experienceId,
  extra
}) => ({
  type: UPDATE_OR_CREATE_EXPERIENCE,
  payload: { data, experienceId, extra },
  formName
});

const successUpdateOrCreateExperience = ({ experienceId, data }) => ({
  type: SUCCESS_UPDATE_OR_CREATE_EXPERIENCE,
  payload: { data, experienceId }
});

export const experienceReducer = (state, action) => {
  switch (action.type) {
    case FETCH_EXPERIENCE:
      state['isFetchingExperience'] = true;
      break;

    case SUCCESS_FETCH_EXPERIENCE:
      state['isFetchingExperience'] = false;
      state['experience'] = addToList(state.experience, action.payload.results);
      state['experienceNextUrl'] = action.payload.next;
      break;

    case SUCCESS_DELETE_EXPERIENCE:
      state['experience'] = state.experience.filter(
        item => item.id !== action.payload.experienceId
      );
      break;

    case SUCCESS_UPDATE_OR_CREATE_EXPERIENCE:
      if (action.payload.experienceId) {
        state['experience'] = updateByIdentifier({
          collection: state.experience,
          identifierField: 'id',
          identifierValue: action.payload.experienceId,
          newItem: action.payload.data
        });
      } else {
        state['experience'] = addToList(
          [action.payload.data],
          state.experience
        );
      }
      break;

    default:
      return state;
  }

  return state;
};

function* fetchExperienceWorker(action) {
  const { influencerSlug, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    response = yield makeRequest(api.experienceList, {
      requestData: { params: { limit: 10 } },
      lookupData: { influencerSlug }
    });
  }

  if (response.success) {
    yield put(successFetchExperience(response));
  }
}

function* deleteExperienceWorker(action) {
  const { experienceId } = action.payload;

  const response = yield makeRequest(api.experienceDelete, {
    lookupData: { experienceId }
  });

  if (response.success) {
    yield* [
      put(successDeleteExperience({ experienceId })),
      put(openNotificationBar('Experience has been deleted successfully.'))
    ];
  }
}

function* updateOrCreateExperienceWorker(action) {
  let { data } = action.payload;
  const { experienceId, extra } = action.payload;

  const logoArr = _.get(action.payload.data, 'logo', null);

  if (!_.isNil(logoArr) && Array.isArray(logoArr)) {
    delete action.payload.data.logo;

    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signExperienceLogo);

    data = Object.assign({}, action.payload.data, { logo: logoUrl });
  }

  const postUrl = _.isNil(experienceId)
    ? api.experienceCreate
    : api.experienceEdit;

  const response = yield makeRequest(postUrl, {
    requestBody: data,
    lookupData: { experienceId }
  });

  if (response.success) {
    const notificationMessage = `Experience has been ${
      _.isNil(experienceId) ? 'created' : 'updated'
    } successfully!`;

    yield* [
      put(
        successUpdateOrCreateExperience({ experienceId, data: response.data })
      ),
      put(openNotificationBar(notificationMessage))
    ];
    extra.map(extraAction => extraAction());
  } else {
    return response.errors;
  }
}

export function* experienceSaga() {
  yield takeLatest(FETCH_EXPERIENCE, fetchExperienceWorker);
  yield takeLatest(DELETE_EXPERIENCE, deleteExperienceWorker);
  yield takeLatest(
    UPDATE_OR_CREATE_EXPERIENCE,
    formSubmitWorker(updateOrCreateExperienceWorker)
  );
}
