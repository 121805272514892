import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

const actionPrefix = 'Companies/Detail/';

export const FETCH_SIMILAR_COMPANIES = `${actionPrefix}FETCH_SIMILAR_COMPANIES`;
const SUCCESS_FETCH_SIMILAR_COMPANIES = `${actionPrefix}SUCCESS_FETCH_SIMILAR_COMPANIES`;
const FAIL_FETCH_SIMILAR_COMPANIES = `${actionPrefix}FAIL_FETCH_SIMILAR_COMPANIES`;

export const fetchSimilarCompanies = slug => ({
  type: FETCH_SIMILAR_COMPANIES,
  payload: {
    slug
  }
});

export const successFetchSimilarCompanies = payload => ({
  type: SUCCESS_FETCH_SIMILAR_COMPANIES,
  payload
});

export const failFetchSimilarCompanies = errors => ({
  type: FAIL_FETCH_SIMILAR_COMPANIES,
  errors
});

export const similarCompaniesReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SIMILAR_COMPANIES:
      state['similarCompanies'] = action.payload.results;
      break;
    default:
      return state;
  }
};

function* fetchSimilarCompaniesWorker(action) {
  const { slug } = action.payload;

  const response = yield makeRequest(api.similarCompanies, {
    lookupData: { slug }
  });

  if (response.data) yield put(successFetchSimilarCompanies(response.data));
  else yield put(failFetchSimilarCompanies(response.errors));
}

export function* similarCompaniesSaga() {
  yield takeLatest(FETCH_SIMILAR_COMPANIES, fetchSimilarCompaniesWorker);
}
