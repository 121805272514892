import _ from 'lodash';

export const prepareUserData = ({ results }) =>
  results.map(user => ({
    ...user,
    display: `${user.profile.first_name} ${user.profile.surname}`
  }));

export const filterAdditionalResults = ({ search, additionalResults }) =>
  _.filter(additionalResults, user => {
    const name = `${_.get(user, 'profile.first_name')} ${_.get(
      user,
      'profile.surname'
    )}`;

    return _.includes(_.toLower(name), _.toLower(search));
  });
