import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'TenderResponse/Sharing/';

const FETCH_TENDER_COLLABORATORS = `${actionPrefix}FETCH_TENDER_COLLABORATORS`;
const SUCCESS_FETCH_TENDER_COLLABORATORS = `${actionPrefix}SUCCESS_FETCH_TENDER_COLLABORATORS`;

export const fetchTenderCollaborators = ({ tenderId, nextUrl }) => ({
  type: FETCH_TENDER_COLLABORATORS,
  payload: { tenderId, nextUrl }
});

const successFetchTenderCollaborators = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_TENDER_COLLABORATORS,
  payload: { results, next }
});

export const sharingReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TENDER_COLLABORATORS:
      state['tenderCollaborators']['isFetching'] = true;
      break;

    case SUCCESS_FETCH_TENDER_COLLABORATORS:
      state['tenderCollaborators']['isFetching'] = false;
      state['tenderCollaborators']['nextUrl'] = action.payload.next;
      state['tenderCollaborators']['collaborators'] = addToList(
        state.tenderCollaborators.collaborators,
        action.payload.results
      );
      break;

    default:
      break;
  }
};

function* fetchTenderCollaboratorsWorker(action) {
  const { nextUrl, tenderId } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.tenderCollaboratorList, {
      lookupData: { tenderId },
      requestData: { params: { limit: 10 } }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  }

  if (response.success) {
    yield put(successFetchTenderCollaborators(response));
  }
}

export function* sharingSaga() {
  yield takeLatest(FETCH_TENDER_COLLABORATORS, fetchTenderCollaboratorsWorker);
}
