import { sectorsReducer, sectorsWatcher } from './sectors';
import { manageFiltersReducer, filtersWatcher } from './manageFilters';
import { countriesReducer, countriesWatcher } from './countries';
import { solutionsReducer, solutionsWatcher } from './solutions';
import { authorsReducer, authorsWatcher } from './authors';
import { resourceTypesReducer, resourceTypesWatcher } from './resourceTypes';
import { companiesReducer, companiesWatcher } from './companies';
import {
  serviceProvidersReducer,
  serviceProvidersWatcher
} from './serviceProviders';

const initialState = {
  filterChoices: {
    sectors: [],
    countries: [],
    solutions: [],
    authors: [],
    resourceTypes: [],
    companies: [],
    technologies: [],
    jobFunctions: [],
    businessModels: [],
    subIndustries: [],
    serviceProviders: []
  }
};

export const filtersReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  sectorsReducer(newState, action);
  manageFiltersReducer(newState, action);
  countriesReducer(newState, action);
  solutionsReducer(newState, action);
  authorsReducer(newState, action);
  resourceTypesReducer(newState, action);
  companiesReducer(newState, action);
  serviceProvidersReducer(newState, action);

  return newState;
};

export const filtersSaga = [
  filtersWatcher,
  sectorsWatcher,
  countriesWatcher,
  solutionsWatcher,
  authorsWatcher,
  resourceTypesWatcher,
  companiesWatcher,
  serviceProvidersWatcher
];

export * from './manageFilters';
export * from './sectors';
export * from './countries';
export * from './solutions';
export * from './authors';
export * from './resourceTypes';
export * from './companies';
export * from './serviceProviders';
export * from './manageFilters/selectors';
