import { put, takeLatest, select } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { buildFilters } from 'shared/utils/filters';
import { addToList } from 'shared/utils/reducerUtils';

import * as selectors from './selectors';

const actionPrefix = 'Investors/';
export const FETCH_INVESTORS = `${actionPrefix}FETCH_INVESTORS`;
export const SUCCESS_FETCH_INVESTORS = `${actionPrefix}SUCCESS_FETCH_INVESTORS`;
export const FAIL_FETCH_INVESTORS = `${actionPrefix}FAIL_FETCH_INVESTORS`;

export const FETCH_NEXT_INVESTORS = `${actionPrefix}FETCH_NEXT_INVESTORS`;
export const SUCCESS_FETCH_NEXT_INVESTORS = `${actionPrefix}SUCCESS_FETCH_NEXT_INVESTORS`;

export const fetchInvestors = (filters = []) => ({
  type: FETCH_INVESTORS,
  payload: {
    filters
  }
});

const successFetchInvestors = payload => ({
  type: SUCCESS_FETCH_INVESTORS,
  payload
});

const failFetchInvestors = payload => ({
  type: FAIL_FETCH_INVESTORS,
  payload
});

export const fetchNextInvestors = () => ({
  type: FETCH_NEXT_INVESTORS
});

export const successFetchNextInvestors = payload => ({
  type: SUCCESS_FETCH_NEXT_INVESTORS,
  payload
});

export const listInvestorsReducer = (initialState, state, action) => {
  switch (action.type) {
    case FETCH_INVESTORS:
    case FETCH_NEXT_INVESTORS:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_INVESTORS:
      state['investors'] = action.payload.results;
      state['investorCount'] = action.payload.count;
      state['nextUrl'] = action.payload.next;
      state['isFetching'] = false;
      break;

    case SUCCESS_FETCH_NEXT_INVESTORS:
      state['investors'] = addToList(state.investors, action.payload.results);
      state['nextUrl'] = action.payload.next;
      state['isFetching'] = false;
      break;

    default:
      return state;
  }
};

function* fetchInvestorsWorker(action) {
  const filters = [...action.payload.filters];

  const response = yield makeRequest(api.investorList, {
    requestData: { params: buildFilters(filters) }
  });

  if (response.success) yield put(successFetchInvestors(response.data));
  else yield put(failFetchInvestors(response.errors));
}

function* fetchNextInvestorsWorker(action) {
  const nextUrl = yield select(selectors.getNextUrl);
  const response = yield makeRequest(api.genericGet, { lookupData: nextUrl });

  if (response.success) yield put(successFetchNextInvestors(response.data));
  else yield put(failFetchInvestors(response.errors));
}

export function* investorsWatcher() {
  yield takeLatest(FETCH_INVESTORS, fetchInvestorsWorker);
  yield takeLatest(FETCH_NEXT_INVESTORS, fetchNextInvestorsWorker);
}
