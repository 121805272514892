import { put, takeLatest, select, delay } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { checkIfShouldBePollingScrapeRequest } from './selectors';

const actionPrefix = 'Dashboard/UrlPreview/';

const RESET_SCRAPE_REQUEST = `${actionPrefix}RESET_SCRAPE_REQUEST`;

const CREATE_SCRAPE_REQUEST = `${actionPrefix}CREATE_SCRAPE_REQUEST`;

const START_SCRAPE_REQUEST_POLLING = `${actionPrefix}START_SCRAPE_REQUEST_POLLING`;
const STOP_SCRAPE_REQUEST_POLLING = `${actionPrefix}STOP_SCRAPE_REQUEST_POLLING`;

export const resetScrapeRequest = () => ({
  type: RESET_SCRAPE_REQUEST
});

export const createScrapeRequest = ({ url }) => ({
  type: CREATE_SCRAPE_REQUEST,
  payload: { url }
});

const startScrapeRequestPolling = ({ scrapeRequestId }) => ({
  type: START_SCRAPE_REQUEST_POLLING,
  payload: { scrapeRequestId }
});

const stopScrapeRequestPolling = data => ({
  type: STOP_SCRAPE_REQUEST_POLLING,
  payload: { data }
});

export const urlPreviewReducer = (state, action) => {
  switch (action.type) {
    case CREATE_SCRAPE_REQUEST:
      state['pollScrapeRequest'] = true;
      break;

    case STOP_SCRAPE_REQUEST_POLLING:
      state['pollScrapeRequest'] = false;
      state['scrapeRequest'] = action.payload.data;
      break;

    case RESET_SCRAPE_REQUEST:
      state['pollScrapeREquest'] = false;
      state['scrapeRequest'] = null;
      break;

    default:
      return state;
  }

  return state;
};

function* createScrapeRequestWorker(action) {
  const { url } = action.payload;

  const response = yield makeRequest(api.createScrapeRequest, {
    requestBody: { url }
  });

  if (response.success) {
    const scrapeRequestId = response.data.scrape_request_id;

    yield put(startScrapeRequestPolling({ scrapeRequestId }));
  }
}

function* pollScrapeRequestWorker(action) {
  const { scrapeRequestId } = action.payload;
  let shouldPollScrapeRequest = yield select(
    checkIfShouldBePollingScrapeRequest
  );

  while (shouldPollScrapeRequest) {
    const response = yield makeRequest(api.scrapeRequestDetail, {
      lookupData: { scrapeRequestId }
    });

    if (response.success) {
      const { succeeded, failed } = response.data;

      if (failed || succeeded) {
        yield put(stopScrapeRequestPolling(response.data));
      }
    } else {
      yield put(stopScrapeRequestPolling({ failed: true }));
    }

    yield delay(1000);

    shouldPollScrapeRequest = yield select(checkIfShouldBePollingScrapeRequest);
  }
}

export function* urlPreviewSaga() {
  yield takeLatest(CREATE_SCRAPE_REQUEST, createScrapeRequestWorker);
  yield takeLatest(START_SCRAPE_REQUEST_POLLING, pollScrapeRequestWorker);
}
