import { put, takeLatest } from 'redux-saga/effects';
import { reset } from 'redux-form';

import { api, makeRequest } from 'shared/sdk';
import { setAuthorization } from 'shared/utils/setAuthorization';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'UpdateCredentials/';

const FETCH_SECURITY_SETTINGS = `${actionPrefix}FETCH_SECURITY_SETTINGS`;
const SUCCESS_FETCH_SECURITY_SETTINGS = `${actionPrefix}SUCCESS_FETCH_SECURITY_SETTINGS`;
const FAIL_FETCH_SECURITY_SETTINGS = `${actionPrefix}FAIL_FETCH_SECURITY_SETTINGS`;

const CHANGE_PASSWORD = `${actionPrefix}CHANGE_PASSWORD`;
const SUCCESS_CHANGE_PASSWORD = `${actionPrefix}SUCCESS_CHANGE_PASSWORD`;
const FAIL_CHANGE_PASSWORD = `${actionPrefix}FAIL_CHANGE_PASSWORD`;

const CHANGE_EMAIL = `${actionPrefix}CHANGE_EMAIL`;
const SUCCESS_CHANGE_EMAIL = `${actionPrefix}SUCCESS_CHANGE_EMAIL`;
const FAIL_CHANGE_EMAIL = `${actionPrefix}FAIL_CHANGE_EMAIL`;

export const fetchSecuritySettings = () => ({
  type: FETCH_SECURITY_SETTINGS
});

const successFetchSecuritySettings = payload => ({
  type: SUCCESS_FETCH_SECURITY_SETTINGS,
  payload
});

const failFetchSecuritySettings = errors => ({
  type: FAIL_FETCH_SECURITY_SETTINGS,
  payload: { errors }
});

export const changePassword = (payload, formName) => ({
  type: CHANGE_PASSWORD,
  payload,
  formName
});

const successChangePassword = payload => ({
  type: SUCCESS_CHANGE_PASSWORD,
  payload
});

const failChangePassword = errors => ({
  type: FAIL_CHANGE_PASSWORD,
  payload: { errors }
});

export const changeEmail = ({ data, formName }) => ({
  type: CHANGE_EMAIL,
  payload: data,
  formName
});

const successChangeEmail = payload => ({
  type: SUCCESS_CHANGE_EMAIL,
  payload
});

const failChangeEmail = errors => ({
  type: FAIL_CHANGE_EMAIL,
  payload: { errors }
});

export const updateCredentialsReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SECURITY_SETTINGS:
      newState['securitySettings'] = action.payload;
      break;

    case SUCCESS_CHANGE_PASSWORD:
      newState['securitySettings'] = {
        ...newState.securitySettings,
        ...action.payload
      };
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchSecuritySettingsWorker(action) {
  const response = yield makeRequest(api.securitySettings);

  if (response.success) yield put(successFetchSecuritySettings(response.data));
  else yield put(failFetchSecuritySettings(response.errors));
}

function* changePasswordWorker(action) {
  const { password, confirm_password } = action.payload;

  const response = yield makeRequest(api.changePassword, {
    requestBody: { password, confirm_password }
  });

  if (response.success) {
    const notificationContent = 'Your password has been changed successfully!';

    yield* [
      put(reset(action.formName)),
      put(successChangePassword(response.data)),
      put(openNotificationBar(notificationContent))
    ];
  } else {
    yield put(failChangePassword(response.errors));
    return response.errors;
  }
}

function* changeEmailWorker(action) {
  const { email, password } = action.payload;

  const response = yield makeRequest(api.changeEmail, {
    requestBody: { email, password }
  });

  if (response.success) {
    const notificationContent = 'Your email has been changed successfully!';

    setAuthorization(response.data.token);

    yield* [
      put(reset(action.formName)),
      put(successChangeEmail(response.data)),
      put(openNotificationBar(notificationContent))
    ];
  } else {
    yield put(failChangeEmail(response.errors));
    return response.errors;
  }
}

export function* updateCredentialsWatcher() {
  yield takeLatest(FETCH_SECURITY_SETTINGS, fetchSecuritySettingsWorker);
  yield takeLatest(CHANGE_PASSWORD, formSubmitWorker(changePasswordWorker));
  yield takeLatest(CHANGE_EMAIL, formSubmitWorker(changeEmailWorker));
}
