import _ from 'lodash';
import { push } from 'react-router-redux';
import { api, makeRequest } from 'shared/sdk';
import { put, takeLatest } from 'redux-saga/effects';

import { MESSAGING_URL } from 'global-constants';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'CompanyDetail/';

const REQUEST_DECK = `${actionPrefix}REQUEST_DECK`;
const SUCCESS_REQUEST_DECK = `${actionPrefix}SUCCESS_REQUEST_DECK`;
const FAIL_REQUEST_DECK = `${actionPrefix}FAIL_REQUEST_DECK`;

export const requestDeck = vendor => ({
  type: REQUEST_DECK,
  payload: { vendor }
});

const successRequestDeck = () => ({
  type: SUCCESS_REQUEST_DECK
});

const failRequestDeck = errors => ({
  type: FAIL_REQUEST_DECK,
  payload: errors
});

function* requestDeckWorker(action) {
  const response = yield makeRequest(api.requestDeck, {
    lookupData: { vendorSlug: action.payload.vendor }
  });

  if (response.success) {
    let extra = [() => push(MESSAGING_URL)];
    const { receiver_slug } = response.data;
    const notificationContent = 'Deck request has been sent.';

    if (_.isNull(receiver_slug))
      extra = [() => openNotificationBar(notificationContent)];

    yield* [put(successRequestDeck()), ...extra.map(action => put(action()))];
  } else yield put(failRequestDeck(response.errors));
}

export function* requestDeckSaga() {
  yield takeLatest(REQUEST_DECK, requestDeckWorker);
}
