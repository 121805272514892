import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { HOME_URL } from 'global-constants';
import logout from 'shared/utils/logout';
const actionPrefix = 'EditProfile/';
export const DELETE_ACCOUNT = `${actionPrefix}DELETE_ACCOUNT`;
const SUCCESS_DELETE_ACCOUNT = `${actionPrefix}SUCCESS_DELETE_ACCOUNT`;
const FAIL_DELETE_ACCOUNT = `${actionPrefix}FAIL_DELETE_ACCOUNT`;

export const deleteAccount = () => ({
  type: DELETE_ACCOUNT
});

export const successDeleteAccount = () => ({
  type: SUCCESS_DELETE_ACCOUNT,
  payload: {}
});

export const failDeleteAccount = errors => ({
  type: FAIL_DELETE_ACCOUNT,
  payload: {
    errors
  }
});

function* deleteAccountWorker() {
  const response = yield makeRequest(api.deleteAccount);
  if (response.success) {
    yield* [put(successDeleteAccount()), put(push(HOME_URL))];
    logout();
  } else yield put(failDeleteAccount(response.errors));
}

export function* deleteAccountWatcher() {
  yield takeLatest(DELETE_ACCOUNT, deleteAccountWorker);
}
