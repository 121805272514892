import _ from 'lodash';

export const getInfluencerOrderingByMostInfluentialInThePastWeek = state => {
  const label = 'Most Influential All Time';

  return _.find(
    _.get(state, 'constants.constants.users.INFLUENCER_ORDERING_CHOICES', []),
    choice => choice.label === label
  );
};
