import _ from 'lodash';
import { takeLatest, put } from 'redux-saga/effects';

import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { api, makeRequest } from 'shared/sdk';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'Job/Form/Edit/';

const EDIT_JOB = `${actionPrefix}EDIT_JOB`;

const CLOSE_JOB = `${actionPrefix}CLOSE_JOB`;
const SUCCESS_CLOSE_JOB = `${actionPrefix}SUCCESS_CLOSE_JOB`;

export const editJob = ({ jobSlug, data, formName, successActions }) => ({
  type: EDIT_JOB,
  payload: { data, successActions, jobSlug },
  formName
});

export const closeJob = ({ jobSlug }) => ({
  type: CLOSE_JOB,
  payload: { jobSlug }
});

const successCloseJob = () => ({
  type: SUCCESS_CLOSE_JOB
});

export const editReducer = (state, action) => {
  switch (action.type) {
    case CLOSE_JOB:
      state['isClosing'] = true;
      break;

    case SUCCESS_CLOSE_JOB:
      state['isClosing'] = false;
      state['job'] = { ...state.job, open: false };
      break;

    default:
      return state;
  }

  return state;
};

function* editJobWorker(action) {
  const { jobSlug, data, successActions } = action.payload;

  const response = yield makeRequest(api.jobEdit, {
    requestBody: data,
    lookupData: { jobSlug }
  });

  if (response.success) {
    successActions.map(action => action(response));
  } else if (!_.isEmpty(response.errors.non_field_errors)) {
    // the field errors are handled by the form
    const errorMessage = response.errors.non_field_errors[0];

    yield put(openNotificationBar(errorMessage, null, true));

    return response.errors;
  }
}

function* closeJobWorker(action) {
  const { jobSlug } = action.payload;

  const response = yield makeRequest(api.jobClose, { lookupData: { jobSlug } });

  if (response.success) {
    yield* [
      put(successCloseJob()),
      put(openNotificationBar('Job has been closed successfully!'))
    ];
  } else {
    yield put(
      openNotificationBar(
        'Something went wrong. Please try again later.',
        null,
        true
      )
    );
  }
}

export function* editSaga() {
  yield takeLatest(EDIT_JOB, formSubmitWorker(editJobWorker));
  yield takeLatest(CLOSE_JOB, closeJobWorker);
}
