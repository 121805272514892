import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'InfluencerDetail/Qualifications/';

const FETCH_QUALIFICATIONS = `${actionPrefix}FETCH_QUALIFICATIONS`;
const SUCCESS_FETCH_QUALIFICATIONS = `${actionPrefix}SUCCESS_FETCH_QUALIFICATIONS`;

export const fetchQualifications = ({ influencerSlug, nextUrl }) => ({
  type: FETCH_QUALIFICATIONS,
  payload: { influencerSlug, nextUrl }
});

const successFetchQualifications = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_QUALIFICATIONS,
  payload: { results, next }
});

export const qualificationsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_QUALIFICATIONS:
      state['isFetchingQualifications'] = true;
      break;

    case SUCCESS_FETCH_QUALIFICATIONS:
      state['isFetchingQualifications'] = false;
      state['qualifications'] = addToList(
        state.qualifications,
        action.payload.results
      );
      state['qualificationsNextUrl'] = action.payload.next;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchQualificationsWorker(action) {
  const { influencerSlug, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    response = yield makeRequest(api.qualificationsList, {
      requestData: { params: { limit: 10 } },
      lookupData: { influencerSlug }
    });
  }

  if (response.success) {
    yield put(successFetchQualifications(response));
  }
}

export function* qualificationsSaga() {
  yield takeLatest(FETCH_QUALIFICATIONS, fetchQualificationsWorker);
}
