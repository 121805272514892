import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'ServiceProviderForm/';

const FETCH_SECTORS = `${actionPrefix}FETCH_SECTORS`;
const SUCCESS_FETCH_SECTORS = `${actionPrefix}SUCCESS_FETCH_SECTORS`;
const FAIL_FETCH_SECTORS = `${actionPrefix}FAIL_FETCH_SECTORS`;

export const fetchSectors = () => ({
  type: FETCH_SECTORS
});

export const successFetchSectors = payload => ({
  type: SUCCESS_FETCH_SECTORS,
  payload
});

export const failFetchSectors = payload => ({
  type: FAIL_FETCH_SECTORS,
  payload
});

export const sectorsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SECTORS:
      state['sectors'] = action.payload.results;
      break;

    default:
      return state;
  }
};

function* fetchSectorsWorker(action) {
  const response = yield makeRequest(api.sectorList);

  if (response.success) yield put(successFetchSectors(response.data));
  else yield put(failFetchSectors(response.errors));
}

export function* sectorsSaga() {
  yield takeLatest(FETCH_SECTORS, fetchSectorsWorker);
}
