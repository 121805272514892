import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { buildFilters } from 'shared/utils/filters';
import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'ResourceList/';

const FETCH_RESOURCES = `${actionPrefix}FETCH_RESOURCES`;
const SUCCESS_FETCH_RESOURCES = `${actionPrefix}SUCCESS_FETCH_RESOURCES`;
const FAIL_FETCH_RESOURCES = `${actionPrefix}FAIL_FETCH_RESOURCES`;

const FETCH_NEXT_RESOURCES = `${actionPrefix}FETCH_NEXT_RESOURCES`;
const SUCCESS_FETCH_NEXT_RESOURCES = `${actionPrefix}SUCCESS_FETCH_NEXT_RESOURCES`;
const FAIL_FETCH_NEXT_RESOURCES = `${actionPrefix}FAIL_FETCH_NEXT_RESOURCES`;

export const fetchResources = (filters = []) => ({
  type: FETCH_RESOURCES,
  payload: { filters }
});

export const successFetchResources = payload => ({
  type: SUCCESS_FETCH_RESOURCES,
  payload
});

export const failFetchResources = payload => ({
  type: FAIL_FETCH_RESOURCES,
  payload
});

export const fetchNextResources = url => ({
  type: FETCH_NEXT_RESOURCES,
  url
});

export const successFetchNextResources = payload => ({
  type: SUCCESS_FETCH_NEXT_RESOURCES,
  payload
});

export const failFetchNextResources = payload => ({
  type: FAIL_FETCH_NEXT_RESOURCES,
  payload
});

export const resourceListReducer = (initialState, state, action) => {
  switch (action.type) {
    case FETCH_RESOURCES:
    case FETCH_NEXT_RESOURCES:
      state['fetchingResources'] = true;
      break;

    case SUCCESS_FETCH_RESOURCES:
      const { results, next } = action.payload;
      state['resources'] = results;
      state['nextUrl'] = next;
      state['fetchingResources'] = false;
      break;

    case SUCCESS_FETCH_NEXT_RESOURCES:
      state['resources'] = addToList(state.resources, action.payload.results);
      state['nextUrl'] = action.payload.next;
      state['fetchingResources'] = false;
      break;

    default:
      return state;
  }
};

function* fetchResourcesWorker(action) {
  let { filters } = action.payload;

  const response = yield makeRequest(api.resourceList, {
    requestData: { params: { ...buildFilters(filters), limit: 13 } }
  });

  if (response.success) {
    yield put(successFetchResources(response.data));
  } else yield put(failFetchResources(response.errors));
}

function* fetchNextResourcesWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.url,
    requestData: {
      params: { limit: 8 }
    }
  });

  if (response.success) yield put(successFetchNextResources(response.data));
  else yield put(failFetchResources(response.errors));
}

export function* resourceListSaga() {
  yield takeLatest(FETCH_RESOURCES, fetchResourcesWorker);
  yield takeLatest(FETCH_NEXT_RESOURCES, fetchNextResourcesWorker);
}
