import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = `Tenders/ClosedTenderDetail/`;

const DELETE_STATE = `${actionPrefix}/DELETE_STATE`;

const FETCH_TENDER = `${actionPrefix}/FETCH_TENDER`;
const SUCCESS_FETCH_TENDER = `${actionPrefix}/SUCCESS_FETCH_TENDER`;

export const deleteState = () => ({ type: DELETE_STATE });

export const fetchTender = ({ tenderId }) => ({
  type: FETCH_TENDER,
  payload: { tenderId }
});

const successFetchTender = ({ data }) => ({
  type: SUCCESS_FETCH_TENDER,
  payload: { data }
});

const initialState = {
  isFetching: true,
  tender: null
};

export const closedTenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_STATE:
      state = initialState;
      break;

    case FETCH_TENDER:
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_TENDER:
      state['tender'] = action.payload.data;
      state['isFetching'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchTenderWorker(action) {
  const { tenderId } = action.payload;

  const response = yield getResponseOr404(api.tenderDetail, {
    lookupData: { tenderId }
  });

  if (response.success) {
    yield put(successFetchTender(response));
  }
}

export function* closedTenderSaga() {
  yield takeLatest(FETCH_TENDER, fetchTenderWorker);
}
