import _ from 'lodash';
import { all, put } from 'redux-saga/effects';

import {
  getS3SignRequest,
  getS3UploadRequest,
  getMultipleUploadRequests
} from './utils';

export function* uploadFile(file, signatureSdk) {
  const signResponse = yield getS3SignRequest(file, signatureSdk);
  yield getS3UploadRequest(file, signResponse.data);

  return `${process.env.REACT_APP_MEDIA_URL}${signResponse.data.fields.key}`;
}

export const multipleFileUploadWorkerFactory = (
  signatureSdk,
  onSuccessHook,
  onFailureHook,
  successOnMissingFiles = false
) => {
  return function*(action) {
    if (_.isEmpty(action.payload.files)) {
      if (successOnMissingFiles) {
        // sometimes we want to keep going forward even when there aren't any files to upload
        return yield onSuccessHook(action.payload.files);
      } else {
        throw new Error('Missing payload files');
      }
    }

    const signRequests = action.payload.files.map(file =>
      getS3SignRequest(file, signatureSdk)
    );
    const signResponses = yield all(signRequests);

    const allResponsesAreSuccessful = _.reduce(
      signResponses,
      (accumulator, item) => accumulator && item.success,
      true
    );

    if (!allResponsesAreSuccessful) {
      const failedSignErrors = signResponses
        .filter(response => !response.success)
        .map(response => response.errors);

      yield onFailureHook(failedSignErrors);
    } else {
      const uploadRequests = getMultipleUploadRequests(
        action.payload.files,
        signResponses.map(response => response.data)
      );
      const uploadResponses = yield all(uploadRequests);

      const failedResponses = uploadResponses.filter(
        response => !response.success
      );

      const failurePayload = {
        errors: failedResponses.map(response => response.errors)
      };

      yield onFailureHook(failurePayload);
      const successPayload = {
        files: signResponses.map(
          signatureResponse =>
            `${process.env.REACT_APP_MEDIA_URL}${
              signatureResponse.data.fields.key
            }`
        )
      };
      yield onSuccessHook(successPayload);
    }
  };
};

export const asGenericUploadHook = func => {
  return payload => put(func(payload));
};

export const toImages = files => {
  return files.map(file => ({ image: file }));
};
