import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Companies/Detail/ProfileVisits/';

const FETCH_INITIAL_PROFILE_VISITS = `${actionPrefix}FETCH_INITIAL_PROFILE_VISITS`;
const SUCCESS_FETCH_INITIAL_PROFILE_VISITS = `${actionPrefix}SUCCESS_FETCH_INITIAL_PROFILE_VISITS`;
const FAIL_FETCH_INITIAL_PROFILE_VISITS = `${actionPrefix}FAIL_FETCH_INITIAL_PROFILE_VISITS`;

const FETCH_MODAL_PROFILE_VISITS = `${actionPrefix}FETCH_MODAL_PROFILE_VISITS`;
const SUCCESS_FETCH_MODAL_PROFILE_VISITS = `${actionPrefix}SUCCESS_FETCH_MODAL_PROFILE_VISITS`;
const FAIL_FETCH_MODAL_PROFILE_VISITS = `${actionPrefix}FAIL_FETCH_MODAL_PROFILE_VISITS`;

const CLEAR_MODAL_PROFILE_VISITS = `${actionPrefix}CLEAR_MODAL_PROFILE_VISITS`;

const INITIAL_PROFILE_VISITS_LIMIT = 5;
const MODAL_PROFILE_VISITS_LIMIT = 12;

export const fetchInitialProfileVisits = ({ companySlug }) => ({
  type: FETCH_INITIAL_PROFILE_VISITS,
  payload: { companySlug }
});

const successFetchInitialProfileVisits = ({ data }) => ({
  type: SUCCESS_FETCH_INITIAL_PROFILE_VISITS,
  payload: data
});

const failFetchInitialProfileVisits = ({ errors }) => ({
  type: FAIL_FETCH_INITIAL_PROFILE_VISITS,
  payload: errors
});

export const fetchModalProfileVisits = ({ companySlug, url }) => ({
  type: FETCH_MODAL_PROFILE_VISITS,
  payload: { companySlug, url }
});

const successFetchModalProfileVisits = ({ data }) => ({
  type: SUCCESS_FETCH_MODAL_PROFILE_VISITS,
  payload: data
});

const failFetchModalProfileVisits = ({ errors }) => ({
  type: FAIL_FETCH_MODAL_PROFILE_VISITS,
  payload: errors
});

export const clearModalVisits = () => ({
  type: CLEAR_MODAL_PROFILE_VISITS
});

export const profileVisitsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INITIAL_PROFILE_VISITS:
      state['isFetchingInitialProfileVisits'] = true;
      break;

    case FETCH_MODAL_PROFILE_VISITS:
      state['isFetchingModalProfileVisits'] = true;
      break;

    case SUCCESS_FETCH_INITIAL_PROFILE_VISITS:
      state['isFetchingInitialProfileVisits'] = false;
      state['profileVisits'] = action.payload.results;
      break;

    case SUCCESS_FETCH_MODAL_PROFILE_VISITS:
      state['isFetchingModalProfileVisits'] = false;
      state['profileVisitsNextPageUrl'] = action.payload.next;
      state['modalProfileVisits'] = [
        ...state['modalProfileVisits'],
        ...action.payload.results
      ];
      break;

    case CLEAR_MODAL_PROFILE_VISITS:
      state['modalProfileVisits'] = [];
      state['profileVisitsNextPageUrl'] = null;
      break;

    default:
      return state;
  }
  return state;
};

function* fetchInitialProfileVisitsWorker(action) {
  const { companySlug } = action.payload;

  const response = yield makeRequest(api.fetchVendorImpressions, {
    lookupData: { slug: companySlug },
    requestData: { params: { limit: INITIAL_PROFILE_VISITS_LIMIT } }
  });

  if (response.success) {
    yield put(successFetchInitialProfileVisits(response));
  } else {
    yield put(failFetchInitialProfileVisits(response));
  }
}

function* fetchModalProfileVisitsWorker(action) {
  const { companySlug, url } = action.payload;

  let response;

  if (url) {
    response = yield makeRequest(api.genericGet, {
      lookupData: url
    });
  } else {
    response = yield makeRequest(api.fetchVendorImpressions, {
      lookupData: { slug: companySlug },
      requestData: { params: { limit: MODAL_PROFILE_VISITS_LIMIT } }
    });
  }

  if (response.success) {
    yield put(successFetchModalProfileVisits(response));
  } else {
    yield put(failFetchModalProfileVisits(response));
  }
}

export function* profileVisitsSaga() {
  yield takeLatest(
    FETCH_INITIAL_PROFILE_VISITS,
    fetchInitialProfileVisitsWorker
  );
  yield takeLatest(FETCH_MODAL_PROFILE_VISITS, fetchModalProfileVisitsWorker);
}
