import { vendorsReducer, vendorsWatcher } from './vendors';
import { influencersReducer, influencersSaga } from './influencers';
import { resourcesReducer, resourcesSaga } from './resources';
import { eventsReducer, eventsSaga } from './events';
import {
  serviceProvidersReducer,
  serviceProvidersSaga
} from './serviceProviders';
import { investorsReducer, investorsSaga } from './investors';
import { connectVideosReducer, connectVideosSaga } from './connectVideos';

const initialState = {
  vendors: [],
  vendorsCount: 0,
  fetchingVendors: false,
  resources: [],
  resourcesCount: 0,
  fetchingResources: false,
  influencers: [],
  influencersCount: 0,
  fetchingInfluencers: false,
  serviceProviders: [],
  serviceProvidersCount: 0,
  fetchingServiceProviders: false,
  events: [],
  eventsCount: 0,
  fetchingEvents: false,
  investors: [],
  investorsCount: 0,
  fetchingInvestors: false,
  connectVideos: [],
  connectVideosCount: 0,
  fetchingConnectVideos: false
};

export const globalSearchReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  vendorsReducer(newState, action);
  influencersReducer(newState, action);
  resourcesReducer(newState, action);
  serviceProvidersReducer(newState, action);
  eventsReducer(newState, action);
  investorsReducer(newState, action);
  connectVideosReducer(newState, action);

  return newState;
};

export const globalSearchSagas = [
  vendorsWatcher,
  influencersSaga,
  resourcesSaga,
  serviceProvidersSaga,
  eventsSaga,
  investorsSaga,
  connectVideosSaga
];

export * from './vendors';
export * from './influencers';
export * from './resources';
export * from './investors';
export * from './serviceProviders';
export * from './events';
export * from './connectVideos';
