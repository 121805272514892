import _ from 'lodash';

let OneSignal = window.OneSignal || [];

export const initPushService = user => {
  // Initiate
  OneSignal.push(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID
    });
  });

  OneSignal.push(() => {
    OneSignal.on('subscriptionChange', subscribed => {
      if (subscribed && !_.isNil(user && !_.isEmpty(user))) {
        OneSignal.push(() => {
          OneSignal.setExternalUserId(user.id.toString());
        });
      }
    });
  });
};

export const assignOneSignalExternalID = user => {
  if (_.isNil(user) || _.isEmpty(user)) return;

  OneSignal.push(() => {
    OneSignal.isPushNotificationsEnabled(isEnabled => {
      OneSignal.getExternalUserId().then(extID => {
        if (_.isNil(extID)) {
          OneSignal.push(() => {
            OneSignal.setExternalUserId(user.id.toString());
          });
        }
      });
    });
  });
};

export const triggerPrompt = openPrompt => {
  OneSignal.push(() => {
    OneSignal.isPushNotificationsEnabled(isEnabled => {
      if (!isEnabled) {
        OneSignal.isOptedOut().then(result => {
          if (!result) {
            OneSignal.getNotificationPermission(
              permission =>
                permission === OneSignal.notificationPermission.Default &&
                openPrompt()
            );
          }
        });
      }
    });
  });
};

export const requestPermission = (user, callBack = () => {}) => {
  OneSignal.push(() => OneSignal.showNativePrompt().then(result => callBack()));
};
