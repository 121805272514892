export const getInfluencers = state => {
  return state.pages.influencer.list.influencers;
};

export const getFilters = state => {
  return state.pages.influencer.list.filters;
};

export const getInfluencersCount = state => {
  return state.pages.influencer.list.influencersCount;
};

export const getInfluencerFilters = state => {
  return state.pages.influencer.list.filters;
};

export const getNextPageUrl = state => {
  return state.pages.influencer.list.nextPageUrl;
};

export const getIsFetching = state => {
  return state.pages.influencer.list.isFetching;
};
