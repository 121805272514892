import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { buildFiltersFromObj } from 'shared/utils/filters';
import { addToList } from 'shared/utils/reducerUtils';

import { updateSolutions } from './utils';

const actionPrefix = 'Solutions/List/';
export const FETCH_SOLUTIONS = `${actionPrefix}FETCH_SOLUTIONS`;
export const SUCCESS_FETCH_SOLUTIONS = `${actionPrefix}SUCCESS_FETCH_SOLUTIONS`;
export const FAIL_FETCH_SOLUTIONS = `${actionPrefix}FAIL_FETCH_SOLUTIONS`;

export const FETCH_NEXT_SOLUTIONS = `${actionPrefix}FETCH_NEXT_SECTORS`;
export const SUCCESS_FETCH_NEXT_SOLUTIONS = `${actionPrefix}SUCCESS_FETCH_NEXT_SOLUTIONS`;

export const TOGGLE_SOLUTION_FOLLOW = `${actionPrefix}TOGGLE_SOLUTION_FOLLOW`;
export const SUCCESS_TOGGLE_SOLUTION_FOLLOW = `${actionPrefix}SUCCESS_TOGGLE_SOLUTION_FOLLOW`;

const DELETE_STATE = `${actionPrefix}/DELETE_STATE`;

export const deleteState = () => ({ type: DELETE_STATE });

export const fetchNextSolutions = url => ({
  type: FETCH_NEXT_SOLUTIONS,
  url
});

export const fetchSolutions = (filters = {}) => ({
  type: FETCH_SOLUTIONS,
  payload: {
    filters
  }
});

const successFetchSolutions = ({ results, count, next }) => ({
  type: SUCCESS_FETCH_SOLUTIONS,
  payload: {
    results,
    count,
    next
  }
});

const successFetchNextSolutions = ({ results, count, next }) => ({
  type: SUCCESS_FETCH_NEXT_SOLUTIONS,
  payload: {
    results,
    count,
    next
  }
});

const failFetchSolutions = payload => ({
  type: FAIL_FETCH_SOLUTIONS,
  payload
});

export const toggleSolutionFollow = ({ slug, isFollowed }) => ({
  type: TOGGLE_SOLUTION_FOLLOW,
  payload: { slug, isFollowed }
});

const successToggleSolutionFollow = ({ slug, is_followed }) => ({
  type: SUCCESS_TOGGLE_SOLUTION_FOLLOW,
  payload: { slug, isFollowed: is_followed }
});

const initialState = {
  solutions: [],
  nextSolutionsUrl: null,
  isFetching: true,
  isPerformingFollowOperation: false
};

export const listSolutionsReducer = (state = initialState, action) => {
  if (action.type === DELETE_STATE) state = initialState;

  switch (action.type) {
    case FETCH_SOLUTIONS:
    case FETCH_NEXT_SOLUTIONS:
      return {
        ...state,
        isFetching: true
      };

    case SUCCESS_FETCH_SOLUTIONS:
      return {
        ...state,
        solutions: action.payload.results,
        nextSolutionsUrl: action.payload.next,
        isFetching: false
      };

    case SUCCESS_FETCH_NEXT_SOLUTIONS:
      return {
        ...state,
        solutions: addToList(state.solutions, action.payload.results),
        nextSolutionsUrl: action.payload.next,
        isFetching: false
      };

    case TOGGLE_SOLUTION_FOLLOW:
      return {
        ...state,
        isPerformingFollowOperation: true
      };

    case SUCCESS_TOGGLE_SOLUTION_FOLLOW:
      return {
        ...state,
        solutions: updateSolutions({
          slug: action.payload.slug,
          followed: action.payload.isFollowed,
          solutions: state.solutions
        }),
        isPerformingFollowOperation: false
      };

    default:
      return state;
  }
};

function* fetchSolutionsWorker(action) {
  const { filters } = action.payload;

  const response = yield makeRequest(api.solutionsList, {
    requestData: { params: buildFiltersFromObj(filters) }
  });

  if (response.success) yield put(successFetchSolutions(response.data));
  else yield put(failFetchSolutions(response.errors));
}

function* fetchNextSolutionsWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.url
  });

  if (response.success) yield put(successFetchNextSolutions(response.data));
  else yield put(failFetchSolutions(response.errors));
}

function* toggleSolutionFollowWorker(action) {
  let url = api.followSolution;

  if (action.payload.isFollowed) {
    url = api.unfollowSolution;
  }

  const lookupData = { slug: action.payload.slug };

  const response = yield makeRequest(url, {
    lookupData
  });

  if (response.success) yield put(successToggleSolutionFollow(response.data));
}

export function* solutionsSaga() {
  yield takeLatest(FETCH_SOLUTIONS, fetchSolutionsWorker);
  yield takeLatest(FETCH_NEXT_SOLUTIONS, fetchNextSolutionsWorker);
  yield takeLatest(TOGGLE_SOLUTION_FOLLOW, toggleSolutionFollowWorker);
}
