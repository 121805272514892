import _ from 'lodash';

import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BASE_URL } from 'global-constants';
import { getConfig } from 'shared/sdk/config';
import { api, makeRequest, getResponseOr404 } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'EditTender/';

const EDIT_TENDER = `${actionPrefix}EDIT_TENDER`;
const SUCCESS_EDIT_TENDER = `${actionPrefix}SUCCESS_EDIT_TENDER`;
const FAIL_EDIT_TENDER = `${actionPrefix}FAIL_EDIT_TENDER`;

const FETCH_TENDER = `${actionPrefix}FETCH_TENDER`;
const SUCCESS_FETCH_TENDER = `${actionPrefix}SUCCESS_FETCH_TENDER`;
const FAIL_FETCH_TENDER = `${actionPrefix}FAIL_FETCH_TENDER`;

const SIGN_BRIEF_PDF_URL = BASE_URL + '/users/sign-tender-brief-pdf/';

export const fetchTender = tenderId => ({
  type: FETCH_TENDER,
  payload: { tenderId }
});

const successFetchTender = payload => ({
  type: SUCCESS_FETCH_TENDER,
  payload
});

const failFetchTender = payload => ({
  type: FAIL_FETCH_TENDER,
  payload
});

export const editTender = (
  formName,
  tenderId,
  data,
  briefPdf,
  extraActions = []
) => ({
  type: EDIT_TENDER,
  formName,
  tenderId,
  payload: { data, briefPdf },
  extraActions
});

const successEditTender = payload => ({
  type: SUCCESS_EDIT_TENDER,
  payload
});

const failEditTender = payload => ({
  type: FAIL_EDIT_TENDER,
  payload
});

const initialState = {
  isFetching: true,
  tender: {}
};

export const editTenderReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case FETCH_TENDER:
      newState['isFetching'] = true;
      break;
    case SUCCESS_FETCH_TENDER:
      newState['tender'] = action.payload;
      newState['isFetching'] = false;
      break;
    default:
      return newState;
  }

  return newState;
};

function* editTenderWorker(action) {
  let { data, briefPdf } = action.payload;
  const config = getConfig();

  if (!_.isNil(briefPdf) && Array.isArray(briefPdf)) {
    const file = briefPdf[0];
    const fileData = {
      filename: file.name,
      file_type: file.type
    };

    // Sign file
    const s3Response = yield call(
      axios.post,
      SIGN_BRIEF_PDF_URL,
      fileData,
      config
    );

    const s3FileData = s3Response.data;

    // Generate S3 upload data
    const postData = new FormData();

    _.each(s3FileData.fields, (field, fieldName) => {
      postData.append(fieldName, field);
    });

    postData.append('file', file);

    // Upload file to S3
    yield call(axios.post, s3FileData.url, postData);
    const s3File = `${process.env.REACT_APP_MEDIA_URL}${s3FileData.fields.key}`;

    data = Object.assign({}, action.payload.data, { brief_pdf: s3File });
  }

  const editResponse = yield makeRequest(api.editTender, {
    lookupData: { tenderId: action.tenderId },
    requestBody: data
  });

  if (editResponse.success)
    yield* [
      put(successEditTender(editResponse.data)),
      ...action.extraActions.map(action => put(action()))
    ];
  else {
    yield put(failEditTender(editResponse.errors));

    return editResponse.errors;
  }
}

function* fetchTenderWorker(action) {
  const response = yield getResponseOr404(api.fetchTender, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFetchTender(response.data));
  else {
    yield put(failFetchTender(response.errors));
  }
}

export function* editTenderSaga() {
  yield takeLatest(EDIT_TENDER, formSubmitWorker(editTenderWorker));
  yield takeLatest(FETCH_TENDER, fetchTenderWorker);
}
