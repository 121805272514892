import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'CreateResourceProposal/';

const FETCH_SOLUTIONS = `${actionPrefix}FETCH_SOLUTIONS`;
const SUCCESS_FETCH_SOLUTIONS = `${actionPrefix}SUCCESS_FETCH_SOLUTIONS`;
const FAIL_FETCH_SOLUTIONS = `${actionPrefix}FAIL_FETCH_SOLUTIONS`;

export const fetchSolutions = filters => ({
  type: FETCH_SOLUTIONS,
  payload: { filters }
});

export const successFetchSolutions = payload => ({
  type: SUCCESS_FETCH_SOLUTIONS,
  payload
});

export const failFetchSolutions = payload => ({
  type: FAIL_FETCH_SOLUTIONS,
  payload
});

export const solutionsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SOLUTIONS:
      state['technologies'] = action.payload.results;
      break;
    default:
      return state;
  }
};

function* fetchSolutionsWorker(action) {
  const response = yield makeRequest(api.solutionsList, {
    requestData: { params: action.payload.filters }
  });

  if (response.success) yield put(successFetchSolutions(response.data));
  else yield put(failFetchSolutions(response.errors));
}

export function* solutionsWatcher() {
  yield takeLatest(FETCH_SOLUTIONS, fetchSolutionsWorker);
}
