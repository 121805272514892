import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'ConnectVideo/Detail/Comments/';

const CREATE_COMMENT = `${actionPrefix}CREATE_COMMENT`;
const SUCCESS_CREATE_COMMENT = `${actionPrefix}SUCCESS_CREATE_COMMENT`;
const FAIL_CREATE_COMMENT = `${actionPrefix}FAIL_CREATE_COMMENT`;

const FETCH_COMMENTS = `${actionPrefix}FETCH_COMMENTS`;
const SUCCESS_FETCH_COMMENTS = `${actionPrefix}SUCCESS_FETCH_COMMENTS`;
const FAIL_FETCH_COMMENTS = `${actionPrefix}FAIL_FETCH_COMMENTS`;

export const createComment = ({ content, postId, mentions }) => ({
  type: CREATE_COMMENT,
  payload: { content, postId, mentions }
});

const successCreateComment = ({ data }) => ({
  type: SUCCESS_CREATE_COMMENT,
  payload: { data }
});

const failCreateComment = ({ errors }) => ({
  type: FAIL_CREATE_COMMENT,
  errors
});

export const fetchComments = ({ postId, url }) => ({
  type: FETCH_COMMENTS,
  payload: { postId, url }
});

const successFetchComments = ({ results, nextUrl }) => ({
  type: SUCCESS_FETCH_COMMENTS,
  payload: { results, nextUrl }
});

const failFetchComments = ({ errors }) => ({
  type: FAIL_FETCH_COMMENTS,
  errors
});

export const commentsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_CREATE_COMMENT:
      state['comments'] = addToList([action.payload.data], state['comments']);
      break;

    case FETCH_COMMENTS:
      state['isFetchingComments'] = true;
      break;

    case SUCCESS_FETCH_COMMENTS:
      state['comments'] = _.uniqBy(
        addToList(state['comments'], action.payload.results),
        'id'
      );
      state['commentsNextUrl'] = action.payload.nextUrl;
      state['isFetchingComments'] = false;
      break;

    default:
      return state;
  }
};

function* createCommentWorker(action) {
  const { postId, content, mentions } = action.payload;

  const response = yield makeRequest(api.commentPost, {
    lookupData: { postId },
    requestBody: { content, mentioned_users_ids: mentions }
  });

  if (response.success) {
    yield put(successCreateComment({ data: response.data }));
  } else {
    yield put(failCreateComment(response));
  }
}

function* fetchCommentsWorker(action) {
  const { postId, url } = action.payload;

  let response;

  if (!_.isNil(url)) {
    response = yield makeRequest(api.genericGet, {
      lookupData: url
    });
  } else {
    response = yield makeRequest(api.fetchPostComments, {
      lookupData: { postId },
      requestData: { params: { limit: 5 } }
    });
  }

  if (response.success) {
    yield put(
      successFetchComments({
        results: response.data.results,
        nextUrl: response.data.next
      })
    );
  } else {
    yield put(failFetchComments(response));
  }
}

export function* commentsSaga() {
  yield takeLatest(CREATE_COMMENT, createCommentWorker);
  yield takeLatest(FETCH_COMMENTS, fetchCommentsWorker);
}
