import { PROGRESS_TABS, progressBarReducer } from './progressBar';
import { headlinesReducer, headlinesWatcher } from './headlines';
import { countriesReducer, countriesSaga } from './countries';
import { sectorsReducer, sectorsSaga } from './sectors';
import { vendorsReducer, vendorsSaga } from './vendors';

const DELETE_STATE = 'ServiceProviderForm/DELETE_STATE';
const SET_EDIT_MODE = 'ServiceProviderForm/SET_EDIT_MODE';

export const deleteState = () => ({ type: DELETE_STATE });
export const setEditMode = () => ({ type: SET_EDIT_MODE });

export const SERVICE_PROVIDER_HEADLINES_FORM = 'serviceProviderHeadlinesForm';
export const SERVICE_PROVIDER_LOCATION_FORM = 'serviceProviderLocationForm';
export const SERVICE_PROVIDER_CONTACT_FORM = 'serviceProviderContactForm';
export const SERVICE_PROVIDER_CASE_STUDY_FORM = 'serviceProviderCaseStudyForm';

const initialState = {
  progressTabs: [
    PROGRESS_TABS.HEADLINES,
    PROGRESS_TABS.LOCATION,
    PROGRESS_TABS.CONTACT,
    PROGRESS_TABS.CASE_STUDY
  ],
  activeProgressTab: PROGRESS_TABS.HEADLINES.numeration,
  serviceProviderImages: [],
  countries: [],
  sectors: [],
  vendors: [],
  isEdit: false
};

export const serviceProviderFormReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === DELETE_STATE) newState = initialState;
  if (action.type === SET_EDIT_MODE) newState['isEdit'] = true;

  progressBarReducer(newState, action);
  headlinesReducer(newState, action);
  countriesReducer(newState, action);
  sectorsReducer(newState, action);
  vendorsReducer(newState, action);

  return newState;
};

export const serviceProviderFormSaga = [
  headlinesWatcher,
  countriesSaga,
  sectorsSaga,
  vendorsSaga
];

export * from './progressBar';
export * from './headlines';
export * from './countries';
export * from './sectors';
export * from './vendors';
