export const REQUIRED_FUNDRAISING_FIELDS = [
  'overview',
  'problem_statement',
  'solution',
  'tax_benefits',
  'investment_jurisdiction',
  'target_amount',
  'target_amount_currency',
  'instrument_type'
];
