import { combineReducers } from 'redux';

import {
  influencerReducer as influencerDetailReducer,
  influencerSagas as influencerDetailSagas
} from './Detail/ducks';
import { influencersReducer, influencersSagas } from './List/ducks';

export const influencerReducer = combineReducers({
  detail: influencerDetailReducer,
  list: influencersReducer
});

export const influencerSagas = [...influencerDetailSagas, ...influencersSagas];
