import _ from 'lodash';
import { takeLatest, put } from 'redux-saga/effects';

import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { api, makeRequest } from 'shared/sdk';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'Job/Form/Create/';

const CREATE_JOB = `${actionPrefix}CREATE_JOB`;

export const createJob = ({ data, formName, successActions }) => ({
  type: CREATE_JOB,
  payload: { data, successActions },
  formName
});

function* createJobWorker(action) {
  const { data, successActions } = action.payload;

  const response = yield makeRequest(api.jobCreate, { requestBody: data });

  if (response.success) {
    successActions.map(action => action(response));
  } else if (!_.isEmpty(response.errors.non_field_errors)) {
    // the field errors are handled by the form
    const errorMessage = response.errors.non_field_errors[0];

    yield put(openNotificationBar(errorMessage, null, true));

    return response.errors;
  }
}

export function* createSaga() {
  yield takeLatest(CREATE_JOB, formSubmitWorker(createJobWorker));
}
