import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'Influencer/Detail/Resources/';

const FETCH_RESOURCES = `${actionPrefix}FETCH_RESOURCES`;
const SUCCESS_FETCH_RESOURCES = `${actionPrefix}SUCCESS_FETCH_RESOURCES`;

export const fetchResources = ({ influencerId, nextUrl }) => ({
  type: FETCH_RESOURCES,
  payload: { influencerId, nextUrl }
});

const successFetchResources = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_RESOURCES,
  payload: { results, next }
});

export const resourcesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_RESOURCES:
      state['isFetchingResources'] = true;
      break;

    case SUCCESS_FETCH_RESOURCES:
      state['resources'] = addToList(state.resources, action.payload.results);
      state['resourcesNextUrl'] = action.payload.next;
      state['isFetchingResources'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchResourcesWorker(action) {
  const { influencerId, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    const params = { author: influencerId, limit: 5 };

    response = yield makeRequest(api.resourceList, {
      requestData: { params }
    });
  }

  if (response.success) {
    yield put(successFetchResources(response));
  }
}

export function* resourcesSaga() {
  yield takeLatest(FETCH_RESOURCES, fetchResourcesWorker);
}
