import _ from 'lodash';
import { put, takeLatest, takeEvery } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';

const actionPrefix = 'Company/Detail/Jobs/';

const FETCH_OPEN_JOBS = `${actionPrefix}FETCH_OPEN_JOBS`;
const SUCCESS_FETCH_OPEN_JOBS = `${actionPrefix}SUCCESS_FETCH_OPEN_JOBS`;

const FETCH_CLOSED_JOBS = `${actionPrefix}FETCH_CLOSED_JOBS`;
const SUCCESS_FETCH_CLOSED_JOBS = `${actionPrefix}SUCCESS_FETCH_CLOSED_JOBS`;

const FETCH_JOB_APPLICATIONS = `${actionPrefix}FETCH_JOB_APPLICATIONS`;
const SUCCESS_FETCH_JOB_APPLICATIONS = `${actionPrefix}SUCCESS_FETCH_JOB_APPLICATIONS`;

const PAGE_SIZE = 5;

export const fetchOpenJobs = ({ companySlug, nextUrl }) => ({
  type: FETCH_OPEN_JOBS,
  payload: {
    companySlug,
    nextUrl
  }
});

const successFetchOpenJobs = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_OPEN_JOBS,
  payload: { results, next }
});

export const fetchClosedJobs = ({ companySlug, nextUrl }) => ({
  type: FETCH_CLOSED_JOBS,
  payload: {
    companySlug,
    nextUrl
  }
});

const successFetchClosedJobs = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_CLOSED_JOBS,
  payload: { results, next }
});

export const fetchJobApplications = ({ jobSlug, nextUrl }) => ({
  type: FETCH_JOB_APPLICATIONS,
  payload: { jobSlug, nextUrl }
});

const successFetchJobApplications = ({ jobSlug, data: { results, next } }) => ({
  type: SUCCESS_FETCH_JOB_APPLICATIONS,
  payload: { jobSlug, results, next }
});

export const jobsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_OPEN_JOBS:
      state['isFetchingOpenJobs'] = true;
      break;

    case SUCCESS_FETCH_OPEN_JOBS:
      state['isFetchingOpenJobs'] = false;
      state['openJobsNextUrl'] = action.payload.next;
      state['openJobs'] = addToList(state.openJobs, action.payload.results);
      break;

    case FETCH_CLOSED_JOBS:
      state['isFetchingClosedJobs'] = true;
      break;

    case SUCCESS_FETCH_CLOSED_JOBS:
      state['isFetchingClosedJobs'] = false;
      state['closedJobsNextUrl'] = action.payload.next;
      state['closedJobs'] = addToList(state.closedJobs, action.payload.results);
      break;

    case FETCH_JOB_APPLICATIONS:
      state['openJobs'] = updateByIdentifier({
        collection: state.openJobs,
        identifierField: 'slug',
        identifierValue: action.payload.jobSlug,
        newItem: { isFetchingApplications: true }
      });
      state['closedJobs'] = updateByIdentifier({
        collection: state.closedJobs,
        identifierField: 'slug',
        identifierValue: action.payload.jobSlug,
        newItem: { isFetchingApplications: true }
      });
      break;

    case SUCCESS_FETCH_JOB_APPLICATIONS:
      state['openJobs'] = state.openJobs.map(
        job =>
          job.slug === action.payload.jobSlug
            ? {
                ...job,
                isFetchingApplications: false,
                applications: addToList(
                  _.get(job, 'applications', []),
                  action.payload.results
                ),
                nextUrl: action.payload.next
              }
            : job
      );
      state['closedJobs'] = state.closedJobs.map(
        job =>
          job.slug === action.payload.jobSlug
            ? {
                ...job,
                isFetchingApplications: false,
                applications: addToList(
                  _.get(job, 'applications', []),
                  action.payload.results
                ),
                nextUrl: action.payload.next
              }
            : job
      );
      break;

    default:
      return state;
  }

  return state;
};

function* fetchOpenJobsWorker(action) {
  const { companySlug, nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.jobList, {
      requestData: {
        params: { vendor: companySlug, open: true, limit: PAGE_SIZE }
      }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  }

  if (response.success) {
    yield put(successFetchOpenJobs(response));
  }
}

function* fetchClosedJobsWorker(action) {
  const { companySlug, nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.jobList, {
      requestData: {
        params: { vendor: companySlug, open: false, limit: PAGE_SIZE }
      }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  }

  if (response.success) {
    yield put(successFetchClosedJobs(response));
  }
}

function* fetchJobApplicationsWorker(action) {
  const { jobSlug, nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.jobApplicationList, {
      lookupData: { jobSlug },
      requestData: {
        params: { limit: PAGE_SIZE }
      }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  }

  if (response.success) {
    yield put(successFetchJobApplications({ data: response.data, jobSlug }));
  }
}

export function* jobsSaga() {
  yield takeLatest(FETCH_OPEN_JOBS, fetchOpenJobsWorker);
  yield takeLatest(FETCH_CLOSED_JOBS, fetchClosedJobsWorker);
  yield takeEvery(FETCH_JOB_APPLICATIONS, fetchJobApplicationsWorker);
}
