import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'ServiceProvider/Detail/TeamMembers/';

const FETCH_TEAM_MEMBERS = `${actionPrefix}FETCH_TEAM_MEMBERS`;
const SUCCESS_FETCH_TEAM_MEMBERS = `${actionPrefix}SUCCESS_FETCH_TEAM_MEMBERS`;
const FAIL_FETCH_TEAM_MEMBERS = `${actionPrefix}FAIL_FETCH_TEAM_MEMBERS`;

const FETCH_NEXT_TEAM_MEMBERS = `${actionPrefix}FETCH_NEXT_TEAM_MEMBERS`;
const SUCCESS_FETCH_NEXT_TEAM_MEMBERS = `${actionPrefix}SUCCESS_FETCH_NEXT_TEAM_MEMBERS`;
const FAIL_FETCH_NEXT_TEAM_MEMBERS = `${actionPrefix}FAIL_FETCH_NEXT_TEAM_MEMBERS`;

export const fetchTeamMembers = serviceProvider => ({
  type: FETCH_TEAM_MEMBERS,
  payload: {
    serviceProvider
  }
});

const successFetchTeamMembers = data => ({
  type: SUCCESS_FETCH_TEAM_MEMBERS,
  payload: {
    data
  }
});

const failFetchTeamMembers = errors => ({
  type: FAIL_FETCH_TEAM_MEMBERS,
  payload: { errors }
});

export const fetchNextTeamMembers = nextUrl => ({
  type: FETCH_NEXT_TEAM_MEMBERS,
  nextUrl
});

export const successFetchNextTeamMembers = data => ({
  type: SUCCESS_FETCH_NEXT_TEAM_MEMBERS,
  payload: { data }
});

export const failFetchNextTeamMembers = errors => ({
  type: FAIL_FETCH_NEXT_TEAM_MEMBERS,
  payload: { errors }
});

export const teamMembersReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TEAM_MEMBERS:
    case FETCH_NEXT_TEAM_MEMBERS:
      state['isFetchingTeam'] = true;
      break;
    case SUCCESS_FETCH_TEAM_MEMBERS:
      state['team'] = action.payload.data.results;
      state['teamMembersNext'] = action.payload.data.next;
      state['isFetchingTeam'] = false;
      break;
    case SUCCESS_FETCH_NEXT_TEAM_MEMBERS:
      state['team'] = [...state['team'], ...action.payload.data.results];
      state['teamMembersNext'] = action.payload.data.next;
      state['isFetchingTeam'] = false;
      break;
    default:
      return state;
  }
  return state;
};

export function* fetchTeamMembersWorker(action) {
  const { serviceProvider } = action.payload;

  const response = yield makeRequest(api.usersList, {
    requestData: { params: { service_provider: serviceProvider } }
  });
  if (response.success) yield put(successFetchTeamMembers(response.data));
  else yield put(failFetchTeamMembers(response.errors));
}

export function* fetchNextTeamMembersWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.nextUrl
  });

  if (response.success) yield put(successFetchNextTeamMembers(response.data));
  else yield put(failFetchNextTeamMembers(response.errors));
}

export function* teamMembersWatcher() {
  yield takeLatest(FETCH_TEAM_MEMBERS, fetchTeamMembersWorker);
  yield takeEvery(FETCH_NEXT_TEAM_MEMBERS, fetchNextTeamMembersWorker);
}
