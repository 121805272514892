import { combineReducers } from 'redux';

import { connectVideoListReducer, connectVideoListWatcher } from './List/ducks';
import {
  connectVideoDetailReducer,
  connectVideoDetailSagas
} from './Detail/ducks';

export const connectVideoReducer = combineReducers({
  list: connectVideoListReducer,
  detail: connectVideoDetailReducer
});

export const connectVideoSagas = [
  connectVideoListWatcher,
  ...connectVideoDetailSagas
];
