import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'ProductForm/';

const FETCH_TECHNOLOGIES = `${actionPrefix}FETCH_TECHNOLOGIES`;
const SUCCESS_FETCH_TECHNOLOGIES = `${actionPrefix}SUCCESS_FETCH_TECHNOLOGIES`;
const FAIL_FETCH_TECHNOLOGIES = `${actionPrefix}FAIL_FETCH_TECHNOLOGIES`;

const FETCH_COUNTRIES = `${actionPrefix}FETCH_COUNTRIES`;
const SUCCESS_FETCH_COUNTRIES = `${actionPrefix}SUCCESS_FETCH_COUNTRIES`;
const FAIL_FETCH_COUNTRIES = `${actionPrefix}FAIL_FETCH_COUNTRIES`;

const FETCH_SECTORS = `${actionPrefix}FETCH_SECTORS`;
const SUCCESS_FETCH_SECTORS = `${actionPrefix}SUCCESS_FETCH_SECTORS`;
const FAIL_FETCH_SECTORS = `${actionPrefix}FAIL_FETCH_SECTORS`;

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES
});

export const successFetchCountries = payload => ({
  type: SUCCESS_FETCH_COUNTRIES,
  payload
});

export const failFetchCountries = payload => ({
  type: FAIL_FETCH_COUNTRIES,
  payload
});

export const fetchSectors = () => ({
  type: FETCH_SECTORS
});

export const successFetchSectors = payload => ({
  type: SUCCESS_FETCH_SECTORS,
  payload
});

export const failFetchSectors = payload => ({
  type: FAIL_FETCH_SECTORS,
  payload
});

export const fetchSolutions = () => ({
  type: FETCH_TECHNOLOGIES
});

const successFetchSolutions = payload => ({
  type: SUCCESS_FETCH_TECHNOLOGIES,
  payload
});

const failFetchSolutions = payload => ({
  type: FAIL_FETCH_TECHNOLOGIES,
  payload
});

const initialState = {
  solutions: [],
  countries: [],
  sectors: []
};

export const productFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_TECHNOLOGIES:
      return {
        ...state,
        solutions: action.payload
      };

    case SUCCESS_FETCH_COUNTRIES:
      return {
        ...state,
        countries: action.payload.results
      };

    case SUCCESS_FETCH_SECTORS:
      return {
        ...state,
        sectors: action.payload.results
      };

    default:
      return state;
  }
};

function* fetchSolutionsWorker(action) {
  const response = yield makeRequest(api.solutionsListAll);

  if (response.success) yield put(successFetchSolutions(response.data));
  else yield put(failFetchSolutions(response.errors));
}

function* fetchCountriesWorker(action) {
  const response = yield makeRequest(api.countriesList, {
    requestData: {
      params: {
        limit: 500,
        offset: 0,
        ordering: 1
      }
    }
  });

  if (response.success) yield put(successFetchCountries(response.data));
  else yield put(failFetchCountries(response.errors));
}

function* fetchSectorsWorker(action) {
  const response = yield makeRequest(api.sectorList);

  if (response.success) yield put(successFetchSectors(response.data));
  else yield put(failFetchSectors(response.errors));
}

export function* productFormSaga() {
  yield takeLatest(FETCH_COUNTRIES, fetchCountriesWorker);
  yield takeLatest(FETCH_TECHNOLOGIES, fetchSolutionsWorker);
  yield takeLatest(FETCH_SECTORS, fetchSectorsWorker);
}
