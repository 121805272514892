import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'FeaturedJobsSection/';

const FETCH = `${actionPrefix}FETCH`;
const SUCCESS_FETCH = `${actionPrefix}SUCCESS_FETCH`;

export const fetchFeaturedJobs = () => ({
  type: FETCH
});

const successFetchFeaturedJobs = ({ data }) => ({
  type: SUCCESS_FETCH,
  payload: { data }
});

const initialState = {
  isFetching: true,
  jobs: []
};

export const featuredJobsSectionReducer = (state = initialState, action) => {
  state = { ...state };

  switch (action.type) {
    case SUCCESS_FETCH:
      state['isFetching'] = false;
      state['jobs'] = action.payload.data;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchWorker(action) {
  const response = yield makeRequest(api.jobFeaturedList);

  if (response.success) {
    yield put(successFetchFeaturedJobs(response));
  }
}

export function* featuredJobsSectionSaga() {
  yield takeLatest(FETCH, fetchWorker);
}
