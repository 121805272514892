import _ from 'lodash';
import { push } from 'react-router-redux';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { COMPANIES_URL, MODALS } from 'global-constants';
import { openModal } from 'shared/components/Modals/reducer';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { uploadFile } from 'shared/utils/fileUpload';

import { getCompanyData, getProductData } from './selectors';

const actionPrefix = 'CreateCompany/';

const SAVE_CONTACT_DATA = `${actionPrefix}SAVE_CONTACT_DATA`;

const CREATE_COMPANY = `${actionPrefix}CREATE_COMPANY`;
const SUCCESS_CREATE_COMPANY = `${actionPrefix}SUCCESS_CREATE_COMPANY`;
const FAIL_CREATE_COMPANY = `${actionPrefix}FAIL_CREATE_COMPANY`;

const SUCCESS_CREATE_PRODUCT = `${actionPrefix}SUCCESS_CREATE_PRODUCT`;
const FAIL_CREATE_PRODUCT = `${actionPrefix}FAIL_CREATE_PRODUCT`;

export const saveContactData = payload => ({
  type: SAVE_CONTACT_DATA,
  payload
});

export const createCompany = formName => ({
  type: CREATE_COMPANY,
  formName
});

const successCreateCompany = payload => ({
  type: SUCCESS_CREATE_COMPANY,
  payload
});

const failCreateCompany = payload => ({
  type: FAIL_CREATE_COMPANY,
  payload
});

const successCreateProduct = payload => ({
  type: SUCCESS_CREATE_PRODUCT,
  payload
});

const failCreateProduct = payload => ({
  type: FAIL_CREATE_PRODUCT,
  payload
});

export const contactReducer = (newState, action) => {
  switch (action.type) {
    case SAVE_CONTACT_DATA:
      newState['companyContactData'] = action.payload;
      break;

    default:
      break;
  }
};

function* createCompanyWorker(action) {
  const companyData = yield select(getCompanyData);

  let data = companyData;

  const logoArr = _.get(companyData, 'logo', null);

  if (!_.isNil(logoArr)) {
    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signVendorLogo);

    data = Object.assign({}, data, { logo: logoUrl });
  }

  const createResponse = yield makeRequest(api.createCompany, {
    requestBody: data
  });

  if (createResponse.success)
    yield* [
      put(successCreateCompany(createResponse.data)),
      put(push(COMPANIES_URL)),
      put(openModal({ modalName: MODALS.SUCCESS_SUBMIT_COMPANY }))
    ];
  else {
    yield put(failCreateCompany(createResponse.errors));

    return createResponse.errors;
  }
}

function* createProductsWorker(action) {
  const productsData = yield select(getProductData);
  const { slug } = action.payload;

  function* createProductRequest(productData) {
    let data = productData;
    const logoArr = _.get(productData, 'logo', null);

    if (!_.isNil(logoArr)) {
      const logo = logoArr[0];
      const logoUrl = yield uploadFile(logo, api.signVendorLogo);

      data = Object.assign({}, productData, { logo: logoUrl });
    }

    const response = yield makeRequest(api.createProduct, {
      requestBody: data,
      lookupData: { slug }
    });

    if (response.success) yield put(successCreateProduct(response.data));
    else yield put(failCreateProduct(response.errors));
  }

  yield all(productsData.map(data => createProductRequest(data)));
}

export function* contactWatcher() {
  yield takeLatest(CREATE_COMPANY, formSubmitWorker(createCompanyWorker));
  yield takeLatest(SUCCESS_CREATE_COMPANY, createProductsWorker);
}
