import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'PushProvider/';

const REQUEST_DISMISS_NOTIFICATIONS = `${actionPrefix}/REQUEST_DISMISS_NOTIFICATIONS`;
const SUCCESS_DISMISS_NOTIFICATIONS = `${actionPrefix}/SUCCESS_DISMISS_NOTIFICATIONS`;
const FAIL_DISMISS_NOTIFICATIONS = `${actionPrefix}/FAIL_DISMISS_NOTIFICATIONS`;

export const requestDismissNotifications = () => ({
  type: REQUEST_DISMISS_NOTIFICATIONS
});

const successDismissNotifications = () => ({
  type: SUCCESS_DISMISS_NOTIFICATIONS
});

const failDismissNotifications = payload => ({
  type: FAIL_DISMISS_NOTIFICATIONS,
  payload
});

function* dismissNotificationsWorker(action) {
  const response = yield makeRequest(api.dismissNotifications);

  if (response.success) yield put(successDismissNotifications());
  else yield put(failDismissNotifications(response.errors));
}

export function* pushProviderSaga() {
  yield takeLatest(REQUEST_DISMISS_NOTIFICATIONS, dismissNotificationsWorker);
}
