import { push } from 'react-router-redux';
import { api, makeRequest } from 'shared/sdk';
import { put, takeLatest } from 'redux-saga/effects';

import { MESSAGING_URL } from 'global-constants';

const actionPrefix = 'Tenders/TenderResponse/';

const MAKE_INTRO = `${actionPrefix}MAKE_INTRO`;
const SUCCESS_MAKE_INTRO = `${actionPrefix}SUCCESS_MAKE_INTRO`;
const FAIL_MAKE_INTRO = `${actionPrefix}FAIL_MAKE_INTRO`;

export const makeIntro = tenderResponseId => ({
  type: MAKE_INTRO,
  payload: { tenderResponseId }
});

const successMakeIntro = payload => ({
  type: SUCCESS_MAKE_INTRO,
  payload
});

const failMakeIntro = payload => ({
  type: FAIL_MAKE_INTRO,
  payload
});

export const makeIntroReducer = (state, action) => {
  switch (action.type) {
    case MAKE_INTRO:
      state['isMakingIntro'] = true;
      break;

    case SUCCESS_MAKE_INTRO:
      state['isMakingIntro'] = false;
      break;

    default:
      return state;
  }
};

function* makeIntroWorker(action) {
  const response = yield makeRequest(api.tenderResponseMakeIntro, {
    lookupData: action.payload
  });

  if (response.success) {
    const extra = [() => push(MESSAGING_URL)];

    yield* [
      put(successMakeIntro(response.data)),
      extra.map(action => put(action()))
    ];
  } else {
    yield put(failMakeIntro(response.errors));

    return response.errors;
  }
}

export function* makeIntroWatcher() {
  yield takeLatest(MAKE_INTRO, makeIntroWorker);
}
