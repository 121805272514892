import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Shared/ConstantLoader/';

const FETCH_CONSTANTS = `${actionPrefix}FETCH_CONSTANTS`;
const SUCCESS_FETCH_CONSTANTS = `${actionPrefix}SUCCESS_FETCH_CONSTANTS`;
const FAIL_FETCH_CONSTANTS = `${actionPrefix}FAIL_FETCH_CONSTANTS`;

export const fetchConstants = () => {
  return {
    type: FETCH_CONSTANTS
  };
};

export const successFetchConstants = payload => {
  return {
    type: SUCCESS_FETCH_CONSTANTS,
    payload
  };
};

export const failFetchConstants = payload => {
  return {
    type: FAIL_FETCH_CONSTANTS,
    payload
  };
};

const initialState = {
  constants: {}
};

export const constantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_CONSTANTS:
      return {
        ...state,
        constants: action.payload.constants
      };
    default:
      return state;
  }
};

function* fetchConstantsWorker(action) {
  const response = yield makeRequest(api.loadConstants);

  if (response.success) yield put(successFetchConstants(response.data));
  else yield put(failFetchConstants(response.errors));
}

export function* constantsSaga() {
  yield takeLatest(FETCH_CONSTANTS, fetchConstantsWorker);
}
