import { resourceTypesReducer, resourceTypesWatcher } from './resourceTypes';
import { sectorsReducer, sectorsWatcher } from './sectors';
import { solutionsReducer, solutionsWatcher } from './technologies';
import { submitResourceWatcher } from './submitArticle';

const initialState = {
  resourceTypes: [],
  sectors: [],
  technologies: []
};

export const resourceProposalReducer = (state = initialState, action) => {
  let newState = { ...state };

  resourceTypesReducer(newState, action);
  sectorsReducer(newState, action);
  solutionsReducer(newState, action);

  return newState;
};

export const resourceProposalSagas = [
  resourceTypesWatcher,
  sectorsWatcher,
  solutionsWatcher,
  submitResourceWatcher
];

export * from './resourceTypes';
export * from './sectors';
export * from './technologies';
export * from './submitArticle';
