import { put, takeEvery } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Company/Detail/Holistics/';

const GET_HOLISTICS_TOKEN = `${actionPrefix}GET_HOLISTICS_TOKEN`;
const SUCCESS_GET_HOLISTICS_TOKEN = `${actionPrefix}SUCCESS_GET_HOLISTICS_TOKEN`;
const FAIL_GET_HOLISTICS_TOKEN = `${actionPrefix}FAIL_GET_HOLISTICS_TOKEN`;

const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const resetState = () => ({
  type: RESET_STATE
});

export const getHolisticsToken = ({ payloadArgs = {}, holisticsSlug }) => ({
  type: GET_HOLISTICS_TOKEN,
  payload: { payloadArgs, holisticsSlug }
});

const successGetHolisticsToken = ({ data, holisticsSlug }) => ({
  type: SUCCESS_GET_HOLISTICS_TOKEN,
  payload: { data, holisticsSlug }
});

const failGetHolisticsToken = errors => ({
  type: FAIL_GET_HOLISTICS_TOKEN,
  payload: { errors }
});

export const holisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case SUCCESS_GET_HOLISTICS_TOKEN:
      state = {
        ...state,
        [action.payload.holisticsSlug]: {
          holisticsToken: action.payload.data.token,
          holisticsEmbedCode: action.payload.data.embed_code
        }
      };
      break;

    case RESET_STATE:
      state = {};
      break;

    default:
      break;
  }

  return state;
};

function* holisticsWorker(action) {
  const payload_args = action.payload.payloadArgs;
  const slug = action.payload.holisticsSlug;

  const response = yield makeRequest(api.getHolisticsToken, {
    requestBody: { payload_args, slug }
  });

  if (response.success) {
    yield put(
      successGetHolisticsToken({ data: response.data, holisticsSlug: slug })
    );
  } else {
    yield put(failGetHolisticsToken(response.errors));
  }
}

export function* holisticsSaga() {
  yield takeEvery(GET_HOLISTICS_TOKEN, holisticsWorker);
}
