import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import { api, makeRequest, getResponseOr404 } from 'shared/sdk';
import { uploadFile } from 'shared/utils/fileUpload';
import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'EditProduct/';

const FETCH_PRODUCT = `${actionPrefix}FETCH_PRODUCT`;
const SUCCESS_FETCH_PRODUCT = `${actionPrefix}SUCCESS_FETCH_PRODUCT`;
const FAIL_FETCH_PRODUCT = `${actionPrefix}FAIL_FETCH_PRODUCT`;

const UPDATE_PRODUCT = `${actionPrefix}UPDATE_PRODUCT`;
const SUCCESS_UPDATE_PRODUCT = `${actionPrefix}SUCCESS_UPDATE_PRODUCT`;
const FAIL_UPDATE_PRODUCT = `${actionPrefix}FAIL_UPDATE_PRODUCT`;

const DELETE_PRODUCT = `${actionPrefix}DELETE_PRODUCT`;
const SUCCESS_DELETE_PRODUCT = `${actionPrefix}SUCCESS_DELETE_PRODUCT`;
const FAIL_DELETE_PRODUCT = `${actionPrefix}FAIL_DELETE_PRODUCT`;

export const fetchProduct = id => ({
  type: FETCH_PRODUCT,
  payload: {
    id
  }
});

const successFetchProduct = payload => ({
  type: SUCCESS_FETCH_PRODUCT,
  payload
});

const failFetchProduct = payload => ({
  type: FAIL_FETCH_PRODUCT,
  payload
});

export const updateProduct = (id, data, extraActions = []) => ({
  type: UPDATE_PRODUCT,
  id,
  payload: {
    data,
    extraActions
  }
});

export const successUpdateProduct = payload => ({
  type: SUCCESS_UPDATE_PRODUCT,
  payload
});

export const failUpdateProduct = payload => ({
  type: FAIL_UPDATE_PRODUCT,
  payload
});

export const deleteProduct = (id, extraActions = []) => ({
  type: DELETE_PRODUCT,
  id,
  payload: {
    extraActions
  }
});

export const successDeleteProduct = payload => ({
  type: SUCCESS_DELETE_PRODUCT,
  payload
});

export const failDeleteProduct = payload => ({
  type: FAIL_DELETE_PRODUCT,
  payload
});

const initialState = {
  product: {},
  fetchingProduct: false
};

export const editProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT:
      return {
        ...state,
        fetchingProduct: true
      };

    case SUCCESS_FETCH_PRODUCT:
      return {
        ...state,
        product: action.payload,
        fetchingProduct: false
      };
    default:
      return state;
  }
};

function* fetchProductDetailWorker(action) {
  const response = yield getResponseOr404(api.productsDetail, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFetchProduct(response.data));
  else yield put(failFetchProduct(response.errors));
}

function* updateProductWorker(action) {
  let data = action.payload.data;

  const logoArr = _.get(action.payload.data, 'logo', null);

  if (!_.isNil(logoArr) && Array.isArray(logoArr)) {
    const logo = logoArr[0];
    const logoUrl = yield uploadFile(logo, api.signProductLogo);

    data = Object.assign({}, action.payload.data, { logo: logoUrl });
  }
  const response = yield makeRequest(api.updateProduct, {
    lookupData: { id: action.id },
    requestBody: data
  });

  if (response.success)
    yield* addToList(
      [put(successUpdateProduct(response.data))],
      action.payload.extraActions.map(action => put(action()))
    );
  else {
    yield put(failUpdateProduct(response.errors));
    yield put(stopSubmit('editProductForm', response.errors));
  }
}

function* deleteProductWorker(action) {
  const response = yield makeRequest(api.deleteProduct, {
    lookupData: { id: action.id }
  });

  if (response.success)
    yield* addToList(
      [put(successDeleteProduct(response.data))],
      action.payload.extraActions.map(action => put(action()))
    );
  else yield put(failDeleteProduct(response.errors));
}

export function* editProductSaga() {
  yield takeLatest(FETCH_PRODUCT, fetchProductDetailWorker);
  yield takeLatest(UPDATE_PRODUCT, updateProductWorker);
  yield takeLatest(DELETE_PRODUCT, deleteProductWorker);
}
