import _ from 'lodash';
import moment from 'moment';
import { call, put } from 'redux-saga/effects';
import { replace, push } from 'react-router-redux';

import { HOME_URL, MODALS, PAGE_NOT_FOUND_URL } from 'global-constants';
import { openModal } from 'shared/components/Modals/reducer';

import { getConfig } from './config';

function* configuredCall(sdk, requestBody, requestData, lookupData) {
  try {
    const response = yield call(sdk, {
      lookupData,
      requestBody,
      requestData: { ...getConfig(), ...requestData }
    });

    return { success: true, data: response.data, statusCode: response.status };
  } catch (error) {
    return {
      success: false,
      errors: _.get(error, 'response.data'),
      statusCode: _.get(error, 'response.status')
    };
  }
}

export function* unconfiguredCall(sdk, requestBody, requestData, lookupData) {
  try {
    const response = yield call(sdk, { requestData, requestBody, lookupData });

    return { success: true, data: response.data, statusCode: response.status };
  } catch (error) {
    return {
      success: false,
      errors: _.get(error, 'response.data'),
      statusCode: _.get(error, 'response.status')
    };
  }
}

function* handleValidToken(sdk, requestBody, requestData, lookupData, options) {
  let response = yield configuredCall(
    sdk,
    requestBody,
    requestData,
    lookupData
  );

  if (response.statusCode === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiresAt');

    response = yield unconfiguredCall(
      sdk,
      requestBody,
      requestData,
      lookupData
    );

    if (response.statusCode === 401) {
      let actions = [put(openModal({ modalName: MODALS.LOGIN }))];
      const redirectToHome = _.get(options, 'redirectToHome', true);

      if (redirectToHome) {
        actions = [put(push(HOME_URL)), ...actions];
      }

      yield* actions;
    }
  }

  return response;
}

function* handleInvalidToken(
  sdk,
  requestBody,
  requestData,
  lookupData,
  options
) {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiresAt');

  const response = yield unconfiguredCall(
    sdk,
    requestBody,
    requestData,
    lookupData
  );

  if (response.statusCode === 401) {
    let actions = [put(openModal({ modalName: MODALS.LOGIN }))];
    const redirectToHome = _.get(options, 'redirectToHome', true);

    if (redirectToHome) {
      actions = [put(push(HOME_URL)), ...actions];
    }

    yield* actions;
  }

  return response;
}

export function* makeRequest(
  sdk,
  { requestBody = {}, requestData = {}, lookupData = {}, options = {} } = {}
) {
  // options receives:
  //    redirectToHome(default true): a param used if a request returns 401
  //                                  to redirect to home page.
  // Handles requests with expired authentication headers
  const isTokenValid =
    moment(localStorage.getItem('tokenExpiresAt')) > moment();

  if (isTokenValid) {
    return yield handleValidToken(
      sdk,
      requestBody,
      requestData,
      lookupData,
      options
    );
  } else {
    return yield handleInvalidToken(
      sdk,
      requestBody,
      requestData,
      lookupData,
      options
    );
  }
}

export function* getResponseOr404(sdk, args = {}) {
  const response = yield makeRequest(sdk, args);

  if (response.statusCode === 404) {
    yield put(replace(PAGE_NOT_FOUND_URL)); // here we replace instead of push in order to keep the history(including the backlink) the same
  }

  return response;
}
