const actionPrefix = 'Signup/';

export const REGISTER = `${actionPrefix}REGISTER`;
export const PROFILE_FORM = `${actionPrefix}PROFILE_FORM`;
export const CHOOSE_ROLE = `${actionPrefix}CHOOSE_ROLE`;

export const CHOOSE_SECTORS = `${actionPrefix}CHOOSE_SECTORS`;
export const CHOOSE_LIFECYCLE = `${actionPrefix}CHOOSE_LIFECYCLE`;

export const CHOOSE_COUNTRY = `${actionPrefix}CHOOSE_COUNTRY`;

export const CHOOSE_SERVICE_PROVIDERS_TYPES = `${actionPrefix}CHOOSE_SERVICE_PROVIDERS_TYPES`;
export const ADD_SERVICE = `${actionPrefix}ADD_SERVICE`;

export const BASE_STEPS = [REGISTER, PROFILE_FORM, CHOOSE_ROLE];

export const SERVICE_PROVIDER_STEPS = [
  CHOOSE_SERVICE_PROVIDERS_TYPES,
  CHOOSE_COUNTRY,
  ADD_SERVICE
];

export const NON_SERVICE_PROVIDER_STEPS = [
  CHOOSE_SECTORS,
  CHOOSE_LIFECYCLE,
  CHOOSE_COUNTRY
];

const NEXT_STEP = `${actionPrefix}NEXT_STEP`;
const PREVIOUS_STEP = `${actionPrefix}PREVIOUS_STEP`;
const ADD_STEPS = `${actionPrefix}ADD_STEPS`;

export const nextStep = () => {
  return {
    type: NEXT_STEP
  };
};

export const previousStep = () => {
  return {
    type: PREVIOUS_STEP
  };
};

export const addSteps = steps => {
  return {
    type: ADD_STEPS,
    steps
  };
};

export const manageStepsReducer = (newState, action) => {
  const { steps, currentStep } = newState;

  switch (action.type) {
    case NEXT_STEP:
      const indexOfCurrStep = steps.indexOf(currentStep);
      newState['currentStep'] = steps[indexOfCurrStep + 1];
      break;

    case PREVIOUS_STEP:
      const indexOfCurrentStep = steps.indexOf(currentStep);
      newState['currentStep'] = steps[indexOfCurrentStep - 1];
      break;

    case ADD_STEPS:
      newState['steps'] = BASE_STEPS.concat(action.steps);

      break;

    default:
      return newState;
  }
};
