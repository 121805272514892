import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { buildFilters } from 'shared/utils/filters';

const actionPrefix = 'Job/List/';

const DELETE_STATE = `${actionPrefix}DELETE_STATE`;

const FETCH_JOBS = `${actionPrefix}FETCH_JOBS`;
const SUCCESS_FETCH_JOBS = `${actionPrefix}SUCCESS_FETCH_JOBS`;
const FAIL_FETCH_JOBS = `${actionPrefix}FAIL_FETCH_JOBS`;

const FETCH_NEXT_JOBS = `${actionPrefix}FETCH_NEXT_JOBS`;
const SUCCESS_FETCH_NEXT_JOBS = `${actionPrefix}SUCCESS_FETCH_NEXT_JOBS`;
const FAIL_FETCH_NEXT_JOBS = `${actionPrefix}FAIL_FETCH_NEXT_JOBS`;

export const deleteState = () => ({
  type: DELETE_STATE
});

export const fetchJobs = ({ filters }) => ({
  type: FETCH_JOBS,
  payload: { filters }
});

const successFetchJobs = ({ data: { next, results } }) => ({
  type: SUCCESS_FETCH_JOBS,
  payload: { results, next }
});

const failFetchJobs = ({ errors }) => ({
  type: FAIL_FETCH_JOBS,
  payload: { errors }
});

export const fetchNextJobs = ({ nextUrl }) => ({
  type: FETCH_NEXT_JOBS,
  payload: { nextUrl }
});

const successFetchNextJobs = ({ data: { next, results } }) => ({
  type: SUCCESS_FETCH_NEXT_JOBS,
  payload: { results, next }
});

const failFetchNextJobs = ({ errors }) => ({
  type: FAIL_FETCH_NEXT_JOBS,
  payload: { errors }
});

const initialState = {
  nextUrl: null,
  isFetching: false,
  jobs: []
};

export const jobListReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case DELETE_STATE:
      newState = initialState;
      break;

    case FETCH_JOBS:
      newState['isFetching'] = true;
      newState['jobs'] = [];
      break;

    case SUCCESS_FETCH_JOBS:
      newState['isFetching'] = false;
      newState['nextUrl'] = action.payload.next;
      newState['jobs'] = action.payload.results;
      break;

    case SUCCESS_FETCH_NEXT_JOBS:
      newState['nextUrl'] = action.payload.next;
      newState['jobs'] = [...state['jobs'], ...action.payload.results];
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchJobsWorker(action) {
  const { filters } = action.payload;

  const response = yield makeRequest(api.jobList, {
    requestData: { params: { limit: 10, ...buildFilters(filters), open: true } }
  });

  if (response.success) {
    yield put(successFetchJobs(response));
  } else {
    yield put(failFetchJobs(response));
  }
}

function* fetchNextJobsWorker(action) {
  const { nextUrl } = action.payload;

  const response = yield makeRequest(api.genericGet, {
    lookupData: nextUrl
  });

  if (response.success) {
    yield put(successFetchNextJobs(response));
  } else {
    yield put(failFetchNextJobs(response));
  }
}

export function* jobListSaga() {
  yield takeLatest(FETCH_JOBS, fetchJobsWorker);
  yield takeLatest(FETCH_NEXT_JOBS, fetchNextJobsWorker);
}
