import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'ServiceProviderForm/';

const FETCH_VENDORS = `${actionPrefix}FETCH_VENDORS`;
const SUCCESS_FETCH_VENDORS = `${actionPrefix}SUCCESS_FETCH_VENDORS`;
const FAIL_FETCH_VENDORS = `${actionPrefix}FAIL_FETCH_VENDORS`;

const RESET_VENDORS = `${actionPrefix}RESET_VENDORS`;

export const fetchVendors = payload => ({
  type: FETCH_VENDORS,
  payload
});

export const successFetchVendors = payload => ({
  type: SUCCESS_FETCH_VENDORS,
  payload
});

export const failFetchVendors = payload => ({
  type: FAIL_FETCH_VENDORS,
  payload
});

export const resetVendors = () => ({
  type: RESET_VENDORS
});

export const vendorsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_VENDORS:
      state['vendors'] = action.payload;
      break;

    case RESET_VENDORS:
      state['vendors'] = [];
      break;

    default:
      return state;
  }
};

function* fetchVendorsWorker(action) {
  const response = yield makeRequest(api.companiesList, {
    requestData: { params: { search: action.payload } }
  });

  if (response.success) yield put(successFetchVendors(response.data.results));
  else yield put(failFetchVendors(response.errors));
}

export function* vendorsSaga() {
  yield takeLatest(FETCH_VENDORS, fetchVendorsWorker);
}
