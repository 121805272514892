import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'UserPreferencesWidget/';

const PATCH_USER_PREFERENCES = `${actionPrefix}PATCH_USER_PREFERENCES`;
const SUCCESS_PATCH_USER_PREFERENCES = `${actionPrefix}SUCCESS_PATCH_USER_PREFERENCES`;
const FAIL_PATCH_USER_PREFERENCES = `${actionPrefix}FAIL_PATCH_USER_PREFERENCES`;

const FETCH_USER_PREFERENCES = `${actionPrefix}FETCH_USER_PREFERENCES`;
const SUCCESS_FETCH_USER_PREFERENCES = `${actionPrefix}SUCCESS_FETCH_USER_PREFERENCES`;

const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const patchUserPreferences = payload => ({
  type: PATCH_USER_PREFERENCES,
  payload
});

const successPatchUserPreferences = payload => ({
  type: SUCCESS_PATCH_USER_PREFERENCES,
  payload
});

const failPatchUserPreferences = ({ errors }) => ({
  type: FAIL_PATCH_USER_PREFERENCES,
  payload: { errors }
});

export const fetchUserPreferences = () => ({
  type: FETCH_USER_PREFERENCES
});

const successFetchUserPreferences = ({ data }) => ({
  type: SUCCESS_FETCH_USER_PREFERENCES,
  payload: data
});

export const resetState = () => ({
  type: RESET_STATE
});

const initialState = {
  userPreferences: {}
};

export const userPreferencesWidgetReducer = (
  state = _.cloneDeep(initialState),
  action
) => {
  let newState = _.cloneDeep(state);

  switch (action.type) {
    case RESET_STATE:
      newState = _.cloneDeep(initialState);
      break;

    case SUCCESS_FETCH_USER_PREFERENCES:
      newState['userPreferences'] = action.payload;
      break;

    default:
      return newState;
  }

  return newState;
};

function* patchUserPreferencesWorker(action) {
  const response = yield makeRequest(api.updateUserPreferences, {
    requestBody: action.payload
  });

  if (response.success)
    yield* [
      put(fetchUserPreferences()),
      put(successPatchUserPreferences(response.data))
    ];
  else yield put(failPatchUserPreferences(response));
}

function* fetchUserPreferencesWorker(action) {
  const response = yield makeRequest(api.userPreferencesDetail);

  if (response.success) {
    yield put(successFetchUserPreferences(response));
  }
}

export function* userPreferencesWidgetSaga() {
  yield takeLatest(PATCH_USER_PREFERENCES, patchUserPreferencesWorker);
  yield takeLatest(FETCH_USER_PREFERENCES, fetchUserPreferencesWorker);
}
