import axios from 'axios';

import { BASE_URL } from 'global-constants';
import requests from './requests';
import { getConfig } from './config';

export const apiDefinitions = {
  genericGet: { url: location => location, request: requests.get },
  genericPost: { url: location => location, request: requests.post },
  loadConstants: { url: BASE_URL + '/constants/', request: requests.get },
  sectorList: {
    url: BASE_URL + '/vendors/sectors/list',
    request: requests.get
  },
  solutionsList: {
    url: BASE_URL + '/vendors/solutions/list/',
    request: requests.get
  },
  solutionsListAll: {
    url: BASE_URL + '/vendors/solutions/all/',
    request: requests.get
  },
  solutionDetail: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/solutions/${slug}/retrieve/`;
    },
    request: requests.get
  },
  followSolution: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/solutions/${slug}/follow/`;
    },
    request: requests.post
  },
  unfollowSolution: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/solutions/${slug}/unfollow/`;
    },
    request: requests.post
  },
  companiesList: { url: BASE_URL + '/vendors/list/', request: requests.get },
  serviceProvidersList: {
    url: BASE_URL + '/service-providers/list/',
    request: requests.get
  },
  featuredCompaniesList: {
    url: BASE_URL + '/vendors/list/featured/',
    request: requests.get
  },
  companiesDetail: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/retrieve/`;
    },
    request: requests.get
  },
  serviceProviderDetail: {
    url: ({ slug }) => {
      return `${BASE_URL}/service-providers/${slug}/retrieve/`;
    },
    request: requests.get
  },
  followCompany: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/follow/`;
    },
    request: requests.post
  },
  unfollowCompany: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/unfollow/`;
    },
    request: requests.post
  },
  vendorProfileVideoUploadUrl: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/profile-video-upload-url/`,
    request: requests.post
  },
  productsDetail: {
    url: ({ id }) => {
      return `${BASE_URL}/vendors/products/${id}/retrieve/`;
    },
    request: requests.get
  },
  resourceList: { url: BASE_URL + '/resources/list/', request: requests.get },
  resourceSimilarList: {
    url: ({ slug }) => `${BASE_URL}/resources/${slug}/similar/`,
    request: requests.get
  },
  connectVideoList: {
    url: BASE_URL + `/connect/list/`,
    request: requests.get
  },
  connectVideoDetail: {
    url: ({ connectVideoSlug }) =>
      `${BASE_URL}/connect/${connectVideoSlug}/retrieve/`,
    request: requests.get
  },
  trackPostShareImpression: {
    url: ({ postId }) =>
      `${BASE_URL}/tracking/post-share-impression/${postId}/create/`,
    request: requests.post
  },
  trackConnectVideoDetailImpression: {
    url: ({ slug }) => {
      return `${BASE_URL}/tracking/connect-resource-detail-impressions/${slug}/create/`;
    },
    request: requests.post
  },
  trackResourceDetailImpression: {
    url: ({ slug }) => {
      return `${BASE_URL}/tracking/resource-detail-impressions/${slug}/create/`;
    },
    request: requests.post
  },
  trackInvestorDetailImpression: {
    url: ({ slug }) => {
      return `${BASE_URL}/tracking/investor-detail-impressions/${slug}/create/`;
    },
    request: requests.post
  },
  trackServiceProviderDetailImpression: {
    url: ({ slug }) => {
      return `${BASE_URL}/tracking/service-provider-detail-impressions/${slug}/create/`;
    },
    request: requests.post
  },
  trackPostImpression: {
    url: ({ postId }) =>
      `${BASE_URL}/tracking/post-impressions/${postId}/create/`,
    request: requests.post
  },
  connectVideoSponsorsList: {
    url: ({ connectVideoSlug }) =>
      `${BASE_URL}/connect/${connectVideoSlug}/sponsors/`,
    request: requests.get
  },
  headlineConnectSponsorsList: {
    url: BASE_URL + '/connect/headline-sponsors/',
    request: requests.get
  },
  connectTalkDetailsSignPresentation: {
    url: `${BASE_URL}/connect/sign-connect-talk-details-presentaion/`,
    request: requests.post
  },
  connectTalkDetailsUpdate: {
    url: `${BASE_URL}/connect/talk-details-update/`,
    request: requests.post
  },
  connectTalkDetailsDetail: {
    url: `${BASE_URL}/connect/talk-details/`,
    request: requests.get
  },
  updateCompany: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/update/`;
    },
    request: requests.post
  },
  updateFundraising: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/update-fundraising/`;
    },
    request: requests.post
  },

  updateCompanyQuestions: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/questions-update/`;
    },
    request: requests.post
  },
  updateCompanyLocation: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/update-location/`;
    },
    request: requests.post
  },
  updateCompanyContact: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/update-contact/`;
    },
    request: requests.post
  },
  companySuggestEdit: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/suggest-edit/`;
    },
    request: requests.post
  },
  register: { url: BASE_URL + '/users/register/', request: requests.post },
  userPatch: { url: BASE_URL + '/users/update/', request: requests.post },
  countriesList: {
    url: BASE_URL + '/vendors/countries/list',
    request: requests.get
  },
  cityList: {
    url: BASE_URL + '/geo/cities/',
    request: requests.get
  },
  completeProfile: {
    url: BASE_URL + '/users/complete-profile/',
    request: requests.post
  },
  getCompanyByEmail: {
    url: BASE_URL + '/vendors/find-by-domain/',
    request: requests.get
  },
  postCompanyByEmail: {
    url: BASE_URL + '/vendors/find-by-domain/',
    request: requests.post
  },
  joinCompany: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/claim/`,
    request: requests.post
  },
  joinServiceProvider: {
    url: ({ slug }) => `${BASE_URL}/service-providers/${slug}/claim/`,
    request: requests.post
  },
  joinInvestor: {
    url: ({ slug }) => `${BASE_URL}/vendors/investors/${slug}/claim/`,
    request: requests.post
  },
  resourceDetail: {
    url: ({ slug }) => `${BASE_URL}/resources/${slug}/retrieve/`,
    request: requests.get
  },
  linkedInSignUpDetail: {
    url: BASE_URL + '/users/sign-in-through-linkedin/',
    request: requests.post
  },
  updateProduct: {
    url: ({ id }) => {
      return `${BASE_URL}/vendors/products/${id}/update/`;
    },
    request: requests.post
  },
  deleteProduct: {
    url: ({ id }) => {
      return `${BASE_URL}/vendors/products/${id}/delete/`;
    },
    request: requests.delete
  },
  staticPageDetail: {
    url: ({ pageSlug }) => {
      return `${BASE_URL}/static-pages/${pageSlug}/`;
    },
    request: requests.get
  },
  getMe: {
    url: BASE_URL + '/users/me/',
    request: url => axios.get(url, getConfig())
  },
  securitySettings: {
    url: BASE_URL + '/users/security-settings/',
    request: requests.get
  },
  changePassword: {
    url: BASE_URL + '/users/change-password/',
    request: requests.post
  },
  changeEmail: {
    url: BASE_URL + '/users/change-email/',
    request: requests.post
  },
  refreshToken: {
    url: BASE_URL + '/users/refresh-token/',
    request: requests.post
  },
  login: { url: BASE_URL + '/users/login/', request: requests.post },
  rankDifferenceList: {
    url: ({ userId }) => `${BASE_URL}/users/${userId}/closest-ranks/`,
    request: requests.get
  },
  postsList: {
    url: BASE_URL + '/dashboard/posts/list/',
    request: requests.get
  },
  postDelete: {
    url: ({ postId }) => `${BASE_URL}/dashboard/posts/edit/${postId}/`,
    request: requests.delete
  },
  postDetail: {
    url: ({ postId }) => `${BASE_URL}/dashboard/posts/detail/${postId}/`,
    request: requests.get
  },
  postTogglePin: {
    url: ({ postId }) => `${BASE_URL}/dashboard/posts/toggle-pin/${postId}/`,
    request: requests.get
  },
  createPost: {
    url: BASE_URL + '/dashboard/posts/create/',
    request: requests.post
  },
  likePost: {
    url: ({ postId }) => BASE_URL + `/dashboard/posts/${postId}/like/`,
    request: requests.post
  },
  fetchPostLikes: {
    url: ({ postId }) => BASE_URL + `/dashboard/posts/${postId}/likes/list/`,
    request: requests.get
  },
  fetchPostComments: {
    url: ({ postId }) => BASE_URL + `/dashboard/posts/${postId}/comments/list/`,
    request: requests.get
  },
  commentPost: {
    url: ({ postId }) =>
      BASE_URL + `/dashboard/posts/${postId}/comments/create/`,
    request: requests.post
  },
  replyToCommentPost: {
    url: ({ commentId }) =>
      BASE_URL + `/dashboard/posts/comments/${commentId}/replies/create/`,
    request: requests.post
  },
  fetchCommentReplies: {
    url: ({ commentId }) =>
      BASE_URL + `/dashboard/posts/comments/${commentId}/replies/list/`,
    request: requests.get
  },
  dashboardPreferencesRetrieve: {
    url: `${BASE_URL}/dashboard/preferences/`,
    request: requests.get
  },
  dashboardPreferencesUpdate: {
    url: `${BASE_URL}/dashboard/preferences/`,
    request: requests.post
  },
  userProfileGet: { url: `${BASE_URL}/users/profile/`, request: requests.get },
  userProfileUpdate: {
    url: `${BASE_URL}/users/profile/update/`,
    request: requests.post
  },
  activatePromoCode: {
    url: `${BASE_URL}/users/profile/update/redeem-promocode/`,
    request: requests.post
  },
  userProfileAndPreferencesUpdate: {
    url: `${BASE_URL}/users/update-profile-and-preferences/`,
    request: requests.post
  },
  leaveVendor: {
    url: `${BASE_URL}/users/leave-vendor/`,
    request: requests.post
  },
  leaveServiceProvider: {
    url: `${BASE_URL}/users/leave-service-provider/`,
    request: requests.post
  },
  leaveInvestor: {
    url: `${BASE_URL}/users/leave-investor/`,
    request: requests.post
  },
  signUserAvatar: {
    url: `${BASE_URL}/users/sign-avatar-image/`,
    request: requests.post
  },
  eventsList: { url: `${BASE_URL}/events/list/`, request: requests.get },
  usersList: { url: `${BASE_URL}/users/users/list/`, request: requests.get },
  authorList: { url: `${BASE_URL}/users/author/list/`, request: requests.get },
  influencerList: {
    url: `${BASE_URL}/users/influencers/list/`,
    request: requests.get
  },
  influencerDetail: {
    url: ({ slug }) => {
      return `${BASE_URL}/users/influencers/${slug}/`;
    },
    request: requests.get
  },
  influencersList: {
    url: `${BASE_URL}/users/influencers/list/`,
    request: requests.get
  },
  featuredInfluencers: {
    url: `${BASE_URL}/users/influencers/featured/`,
    request: requests.get
  },
  previewInfluencers: {
    url: `${BASE_URL}/users/influencers/preview/`,
    request: requests.get
  },
  eventDetail: {
    url: ({ eventSlug }) => {
      return `${BASE_URL}/events/${eventSlug}/retrieve/`;
    },
    request: requests.get
  },
  userPreferencesDetail: {
    url: `${BASE_URL}/users/preferences/`,
    request: requests.get
  },
  updateUserPreferences: {
    url: `${BASE_URL}/users/update-preferences/`,
    request: requests.post
  },
  createCompany: { url: `${BASE_URL}/vendors/create/`, request: requests.post },
  createProduct: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/products/create/`,
    request: requests.post
  },
  resourceTypesList: {
    url: `${BASE_URL}/resources/types/list/`,
    request: requests.get
  },
  createEventProposal: {
    url: `${BASE_URL}/events/proposal-create/`,
    request: requests.post
  },
  createResourceProposal: {
    url: `${BASE_URL}/resources/proposal-create/`,
    request: requests.post
  },
  inviteCompanyTeamMembers: {
    url: `${BASE_URL}/vendors/invite-members/`,
    request: requests.post
  },
  inviteServiceProviderTeamMembers: {
    url: `${BASE_URL}/service-providers/invite-members/`,
    request: requests.post
  },
  inviteInvestorTeamMembers: {
    url: `${BASE_URL}/vendors/investors/invite-members/`,
    request: requests.post
  },
  acceptInvitation: {
    url: ({ invitationIdentifier }) =>
      `${BASE_URL}/vendors/accept-invitation/${invitationIdentifier}/`,
    request: requests.post
  },
  acceptServiceProviderInvitation: {
    url: ({ invitationIdentifier }) =>
      `${BASE_URL}/service-providers/accept-invitation/${invitationIdentifier}/`,
    request: requests.post
  },
  acceptInvestorInvitation: {
    url: ({ invitationIdentifier }) =>
      `${BASE_URL}/vendors/investors/accept-invitation/${invitationIdentifier}/`,
    request: requests.post
  },
  confirmClaimRequest: {
    url: ({ claimRequestIdentifier }) =>
      `${BASE_URL}/vendors/confirm-claim/${claimRequestIdentifier}/`,
    request: requests.post
  },
  confirmServiceProviderClaimRequest: {
    url: ({ claimRequestIdentifier }) =>
      `${BASE_URL}/service-providers/confirm-claim/${claimRequestIdentifier}/`,
    request: requests.post
  },
  confirmInvestorClaimRequest: {
    url: ({ claimRequestIdentifier }) =>
      `${BASE_URL}/vendors/investors/confirm-claim/${claimRequestIdentifier}/`,
    request: requests.post
  },
  validateEmail: {
    url: `${BASE_URL}/users/validate-email/`,
    request: requests.post
  },
  validatePassword: {
    url: `${BASE_URL}/users/validate-password/`,
    request: requests.post
  },
  featuredEventsList: {
    url: `${BASE_URL}/events/list/featured/`,
    request: requests.get
  },
  validateVendorName: {
    url: `${BASE_URL}/vendors/validate-by-name/`,
    request: requests.post
  },
  validateVendorWebsite: {
    url: `${BASE_URL}/vendors/validate-website/`,
    request: requests.post
  },
  sendResetPasswordEmail: {
    url: `${BASE_URL}/users/send-reset-password-email/`,
    request: requests.post
  },
  resetPassword: {
    url: `${BASE_URL}/users/reset-password/`,
    request: requests.post
  },
  subscribeToPlan: {
    url: `${BASE_URL}/payments/subscribe/`,
    request: requests.post
  },
  signVendorLogo: {
    url: `${BASE_URL}/vendors/sign-vendor-logo/`,
    request: requests.post
  },
  signVendorImage: {
    url: `${BASE_URL}/vendors/sign-vendor-image/`,
    request: requests.post
  },
  signProductLogo: {
    url: `${BASE_URL}/vendors/sign-product-logo/`,
    request: requests.post
  },
  updateBillingInfo: {
    url: `${BASE_URL}/users/profile/update-billing/`,
    request: requests.post
  },
  getPremiumPlanPrice: {
    url: `${BASE_URL}/payments/subscription-price/`,
    request: requests.get
  },
  signEventImage: {
    url: `${BASE_URL}/events/sign-event-image/`,
    request: requests.post
  },
  eventAttendees: {
    url: ({ eventSlug }) => {
      return `${BASE_URL}/events/${eventSlug}/attendees/list/`;
    },
    request: requests.get
  },
  addAttendee: {
    url: ({ eventSlug }) => {
      return `${BASE_URL}/events/${eventSlug}/attendees/add/`;
    },
    request: requests.post
  },
  removeAttendee: {
    url: ({ eventSlug }) => {
      return `${BASE_URL}/events/${eventSlug}/attendees/remove/`;
    },
    request: requests.post
  },
  signResourceProposalFile: {
    url: `${BASE_URL}/resources/sign-proposal-file/`,
    request: requests.post
  },
  signResourceImage: {
    url: `${BASE_URL}/resources/sign-resource-image/`,
    request: requests.post
  },
  deleteAccount: { url: `${BASE_URL}/users/delete/`, request: requests.post },
  similarCompanies: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/${slug}/similar-vendors/`;
    },
    request: requests.get
  },
  similarServiceProviders: {
    url: ({ slug }) => {
      return `${BASE_URL}/service-providers/${slug}/similar/`;
    },
    request: requests.get
  },
  similarInvestors: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/investors/${slug}/similar/`;
    },
    request: requests.get
  },
  teamMembers: {
    url: `${BASE_URL}/vendors/team-members`,
    request: requests.get
  },
  getPreferentialVendor: {
    url: `${BASE_URL}/vendors/preferential-search/`,
    request: requests.get
  },
  userOpinions: {
    url: `${BASE_URL}/users/user-opinions/`,
    request: requests.get
  },
  conversationList: {
    url: `${BASE_URL}/messaging/conversations/list/`,
    request: requests.get
  },
  conversationLatestMessageList: {
    url: `${BASE_URL}/messaging/conversations-with-messages/`,
    request: requests.get
  },
  conversationMessageList: {
    url: ({ conversationId }) =>
      `${BASE_URL}/messaging/conversations/${conversationId}/messages/list/`,
    request: requests.get
  },
  createMessage: {
    url: ({ conversationId }) =>
      `${BASE_URL}/messaging/conversations/${conversationId}/messages/create/`,
    request: requests.post
  },
  conversationCreate: {
    url: `${BASE_URL}/messaging/conversations/create/`,
    request: requests.post
  },
  unseenMessageList: {
    url: `${BASE_URL}/messaging/messages/list/unseen/`,
    request: requests.get
  },
  markSeenMessages: {
    url: ({ conversationId }) =>
      `${BASE_URL}/messaging/conversations/${conversationId}/mark-seen/`,
    request: requests.post
  },
  conversationDelete: {
    url: ({ conversationId }) =>
      `${BASE_URL}/messaging/conversations/${conversationId}/delete/`,
    request: requests.post
  },
  blockUser: {
    url: ({ userSlug }) => `${BASE_URL}/users/${userSlug}/block/`,
    request: requests.post
  },
  requestDemo: {
    url: ({ vendorSlug }) => `${BASE_URL}/vendors/${vendorSlug}/request-demo/`,
    request: requests.post
  },
  requestDeck: {
    url: ({ vendorSlug }) => `${BASE_URL}/vendors/${vendorSlug}/request-deck/`,
    request: requests.post
  },
  companyNewsList: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/news/`,
    request: requests.get
  },
  companyNewsItemToggle: {
    url: ({ newsItemId }) => `${BASE_URL}/vendors/news/${newsItemId}/toggle/`,
    request: requests.post
  },
  createServiceProvider: {
    url: `${BASE_URL}/service-providers/create/`,
    request: requests.post
  },
  signServiceProviderLogo: {
    url: `${BASE_URL}/service-providers/sign-service-provider-logo/`,
    request: requests.post
  },
  signServiceProviderImage: {
    url: `${BASE_URL}/service-providers/sign-service-provider-image/`,
    request: requests.post
  },
  updateServiceProviderHeadlines: {
    url: ({ slug }) => {
      return `${BASE_URL}/service-providers/${slug}/update/`;
    },
    request: requests.post
  },
  updateLocationServiceProvider: {
    url: ({ slug }) => {
      return `${BASE_URL}/service-providers/${slug}/update-location/`;
    },
    request: requests.post
  },
  updateServiceProviderContact: {
    url: ({ slug }) => {
      return `${BASE_URL}/service-providers/${slug}/update-contact/`;
    },
    request: requests.post
  },
  updateServiceProviderCaseStudy: {
    url: ({ slug }) => {
      return `${BASE_URL}/service-providers/${slug}/update-case-study/`;
    },
    request: requests.post
  },
  validateServiceProviderWebsite: {
    url: `${BASE_URL}/service-providers/validate-website/`,
    request: requests.post
  },
  validateServiceProviderContactInfo: {
    url: `${BASE_URL}/service-providers/validate-contact-info/`,
    request: requests.post
  },
  getAllVendors: {
    url: `${BASE_URL}/vendors/list/all/`,
    request: requests.get
  },
  sendMessages: {
    url: `${BASE_URL}/messaging/conversations/broadcast-message/`,
    request: requests.post
  },
  sendNotification: {
    url: `${BASE_URL}/messaging/push-notifications/create/`,
    request: requests.post
  },
  createTender: {
    url: `${BASE_URL}/users/tenders/create/`,
    request: requests.post
  },
  fetchTender: {
    url: ({ tenderId }) => {
      return `${BASE_URL}/users/tenders/${tenderId}/`;
    },
    request: requests.get
  },
  deleteTender: {
    url: ({ tenderId }) => {
      return `${BASE_URL}/users/tenders/${tenderId}/delete/`;
    },
    request: requests.post
  },
  tendersListByUser: {
    url: `${BASE_URL}/users/tenders/list/`,
    request: requests.get
  },
  tenderDetail: {
    url: ({ tenderId }) => `${BASE_URL}/users/tenders/${tenderId}/detail/`,
    request: requests.get
  },
  tenderCollaboratorAdd: {
    url: ({ tenderId }) =>
      `${BASE_URL}/users/tenders/${tenderId}/add-collaborators/`,
    request: requests.post
  },
  tenderCollaboratorList: {
    url: ({ tenderId }) =>
      `${BASE_URL}/users/tenders/${tenderId}/collaborators/list/`,
    request: requests.get
  },
  editTender: {
    url: ({ tenderId }) => {
      return `${BASE_URL}/users/tenders/${tenderId}/update/`;
    },
    request: requests.post
  },
  tendersList: {
    url: `${BASE_URL}/users/tenders/public/list/`,
    request: requests.get
  },
  updateTenderResponseStatus: {
    url: ({ tenderResponseId }) => {
      return `${BASE_URL}/users/tenders/tender-response/${tenderResponseId}/update-status/`;
    },
    request: requests.post
  },
  tenderResponseMakeIntro: {
    url: ({ tenderResponseId }) => {
      return `${BASE_URL}/users/tenders/tender-response/${tenderResponseId}/make-intro/`;
    },
    request: requests.get
  },
  tenderResponseExport: {
    url: ({ tenderId }) => `${BASE_URL}/users/tenders/${tenderId}/export/`,
    request: requests.post
  },
  tenderResponseList: {
    url: ({ tenderId }) =>
      `${BASE_URL}/users/tenders/${tenderId}/tender-response/list/`,
    request: requests.get
  },
  fetchTenderResponse: {
    url: ({ tenderResponseId }) => {
      return `${BASE_URL}/users/tenders/tender-response/${tenderResponseId}/`;
    },
    request: requests.get
  },
  createTenderResponse: {
    url: `${BASE_URL}/users/tenders/tender-response/create/`,
    request: requests.post
  },
  tenderResponseDetailsForUser: {
    url: ({ responseFormId }) =>
      `${BASE_URL}/users/tenders/${responseFormId}/user-details/`,
    request: requests.get
  },
  tenderResponseTeamList: {
    url: ({ tenderResponseId }) =>
      `${BASE_URL}/users/tenders/tender-response/${tenderResponseId}/team/`,
    request: requests.get
  },
  eventUpdate: {
    url: ({ eventSlug }) => {
      return `${BASE_URL}/events/${eventSlug}/update/`;
    },
    request: requests.post
  },
  eventSuggestEdit: {
    url: ({ eventSlug }) => {
      return `${BASE_URL}/events/${eventSlug}/suggest-edit/`;
    },
    request: requests.post
  },
  eventSpeakers: {
    url: ({ eventSlug }) => {
      return `${BASE_URL}/events/${eventSlug}/speaker-invitations/`;
    },
    request: requests.get
  },
  speakerInvitationDetail: {
    url: ({ token }) => {
      return `${BASE_URL}/events/speaker-invitation/${token}/retrieve/`;
    },
    request: requests.get
  },
  acceptSpeakerInvitation: {
    url: ({ token }) => {
      return `${BASE_URL}/events/invitation-response/${token}/`;
    },
    request: requests.post
  },
  dismissNotifications: {
    url: `${BASE_URL}/messaging/notifications/dismiss/`,
    request: requests.post
  },
  CTAsList: {
    url: BASE_URL + '/resources/call-to-actions/list/',
    request: requests.get
  },
  investorList: {
    url: BASE_URL + '/vendors/investors/list/',
    request: requests.get
  },
  investorDetail: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/investors/${slug}/`;
    },
    request: requests.get
  },
  investorInvestmentList: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/investors/${slug}/investments/`;
    },
    request: requests.get
  },
  investorToggleFollow: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/investors/${slug}/toggle-follow/`;
    },
    request: requests.get
  },
  getHolisticsToken: {
    url: BASE_URL + '/common/holistics-token/',
    request: requests.post
  },
  fetchTenderPartner: {
    url: ({ slug }) => {
      return `${BASE_URL}/tender-partners/${slug}/`;
    },
    request: requests.get
  },
  fetchTenderPartnerTenders: {
    url: ({ slug }) => {
      return `${BASE_URL}/tender-partners/list/`;
    },
    request: requests.get
  },
  fetchRatings: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/company/${slug}/feedback/`;
    },
    request: requests.get
  },
  updateRating: {
    url: ({ slug }) => {
      return `${BASE_URL}/vendors/company/${slug}/receive-feedback/`;
    },
    request: requests.post
  },
  trackVendorDetailImpression: {
    url: ({ slug }) => {
      return `${BASE_URL}/tracking/vendor-detail-impressions/${slug}/create/`;
    },
    request: requests.post
  },
  trackVendorImpressions: {
    url: `${BASE_URL}/tracking/vendor-impressions/create/`,
    request: requests.post
  },
  linkAnonymousRatingsToUser: {
    url: `${BASE_URL}/vendors/update-user-feedback/`,
    request: requests.post
  },
  vendorBadgeCreate: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/badge/create/`,
    request: requests.post
  },
  vendorListWithNotes: {
    url: `${BASE_URL}/vendors/with-notes/list/`,
    request: requests.get
  },
  vendorNoteCreate: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/notes/`,
    request: requests.post
  },
  vendorNoteList: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/notes/`,
    request: requests.get
  },
  vendorNoteUpdate: {
    url: ({ slug, noteId }) => `${BASE_URL}/vendors/${slug}/notes/${noteId}/`,
    request: requests.post
  },
  vendorNoteDelete: {
    url: ({ slug, noteId }) => `${BASE_URL}/vendors/${slug}/notes/${noteId}/`,
    request: requests.delete
  },
  vendorNoteShare: {
    url: `${BASE_URL}/vendors/share-notes/`,
    request: requests.post
  },
  vendorNoteSharedWithList: {
    url: ({ slug }) => `${BASE_URL}/vendors/${slug}/notes/shared-with/`,
    request: requests.get
  },
  vendorNoteAttachmentSign: {
    url: ({ slug }) => `${BASE_URL}/vendors/sign-vendor-note-attachment-file/`,
    request: requests.post
  },
  fetchVendorImpressions: {
    url: ({ slug }) =>
      `${BASE_URL}/tracking/vendor-detail-impressions/${slug}/list/`,
    request: requests.get
  },
  fetchTenderResponseNotes: {
    url: ({ tenderResponseId }) => {
      return `${BASE_URL}/users/tenders/tender-response/${tenderResponseId}/notes/`;
    },
    request: requests.get
  },
  createTenderResponseNote: {
    url: ({ tenderResponseId }) => {
      return `${BASE_URL}/users/tenders/tender-response/${tenderResponseId}/notes/create/`;
    },
    request: requests.post
  },
  editTenderResponseNote: {
    url: ({ tenderResponseNoteId }) => {
      return `${BASE_URL}/users/tenders/tender-response/notes/${tenderResponseNoteId}/edit/`;
    },
    request: requests.post
  },
  signTenderResponseNoteFile: {
    url: `${BASE_URL}/users/sign-tender-response-note-file/`,
    request: requests.post
  },
  fetchPositions: {
    url: `${BASE_URL}/users/position/list/`,
    request: requests.get
  },
  newsList: {
    url: BASE_URL + '/news/list/',
    request: requests.get
  },
  newsItemToggle: {
    url: ({ newsItemId }) => {
      return `${BASE_URL}/news/hide/${newsItemId}/`;
    },
    request: requests.post
  },
  newsLanguageList: {
    url: BASE_URL + '/news/languages/list/',
    request: requests.get
  },
  addProptechNews: {
    url: `${BASE_URL}/news/create-custom-proptech-news/`,
    request: requests.post
  },
  addVendorNews: {
    url: ({ companySlug }) => {
      return `${BASE_URL}/vendors/${companySlug}/create-custom-news/`;
    },
    request: requests.post
  },
  bookATour: {
    url: BASE_URL + '/common/book-a-tour/',
    request: requests.post
  },
  createScrapeRequest: {
    url: `${BASE_URL}/scraper/create-request/`,
    request: requests.post
  },
  scrapeRequestDetail: {
    url: ({ scrapeRequestId }) => `${BASE_URL}/scraper/${scrapeRequestId}/`,
    request: requests.get
  },
  toggleConnection: {
    url: ({ userToConnectId }) =>
      `${BASE_URL}/users/${userToConnectId}/connect/`,
    request: requests.get
  },
  fetchNotifications: {
    url: `${BASE_URL}/notifications/list/`,
    request: requests.get
  },
  markNotificationsAsSeen: {
    url: `${BASE_URL}/notifications/mark-seen/`,
    request: requests.post
  },
  experienceList: {
    url: ({ influencerSlug }) =>
      `${BASE_URL}/users/${influencerSlug}/experience/`,
    request: requests.get
  },
  qualificationsList: {
    url: ({ influencerSlug }) =>
      `${BASE_URL}/users/${influencerSlug}/qualifications/`,
    request: requests.get
  },
  experienceDelete: {
    url: ({ experienceId }) =>
      `${BASE_URL}/users/experience/${experienceId}/edit/`,
    request: requests.delete
  },
  qualificationDelete: {
    url: ({ qualificationId }) =>
      `${BASE_URL}/users/qualification/${qualificationId}/edit/`,
    request: requests.delete
  },
  experienceEdit: {
    url: ({ experienceId }) =>
      `${BASE_URL}/users/experience/${experienceId}/edit/`,
    request: requests.post
  },
  qualificationEdit: {
    url: ({ qualificationId }) =>
      `${BASE_URL}/users/qualification/${qualificationId}/edit/`,
    request: requests.post
  },
  experienceCreate: {
    url: `${BASE_URL}/users/experience/create/`,
    request: requests.post
  },
  qualificationCreate: {
    url: `${BASE_URL}/users/qualification/create/`,
    request: requests.post
  },
  jobCreate: {
    url: `${BASE_URL}/jobs/`,
    request: requests.post
  },
  jobDetail: {
    url: ({ jobSlug }) => `${BASE_URL}/jobs/${jobSlug}/`,
    request: requests.get
  },
  jobEdit: {
    url: ({ jobSlug }) => `${BASE_URL}/jobs/${jobSlug}/`,
    request: requests.put
  },
  jobSignDocuments: {
    url: `${BASE_URL}/jobs/job-applications/documents/sign/`,
    request: requests.post
  },
  jobApplicationCreate: {
    url: ({ jobSlug }) => `${BASE_URL}/jobs/${jobSlug}/job-applications/`,
    request: requests.post
  },
  jobApplicationList: {
    url: ({ jobSlug }) => `${BASE_URL}/jobs/${jobSlug}/job-applications/`,
    request: requests.get
  },
  jobFeaturedList: {
    url: `${BASE_URL}/jobs/featured/`,
    request: requests.get
  },
  jobClose: {
    url: ({ jobSlug }) => `${BASE_URL}/jobs/${jobSlug}/close/`,
    request: requests.post
  },
  jobList: {
    url: `${BASE_URL}/jobs/`,
    request: requests.get
  },
  predefinedSearchDetail: {
    url: ({ predefinedSearchSlug }) =>
      `${BASE_URL}/seo/predefined-searches/${predefinedSearchSlug}/`,
    request: requests.get
  },
  caseStudiesList: {
    url: `${BASE_URL}/case-studies/`,
    request: requests.get
  },
  caseStudiesFeaturedList: {
    url: `${BASE_URL}/case-studies/featured/`,
    request: requests.get
  },
  vendorProfileVideo: {
    url: ({ identifier }) => `${BASE_URL}/vendors/profile-video/${identifier}/`,
    request: requests.get
  },
  fetchAd: {
    url: `${BASE_URL}/ads/featured/`,
    request: requests.get
  },
  trackAdClickImpression: {
    url: ({ adId }) =>
      `${BASE_URL}/tracking/ad-click-impression/${adId}/create/`,
    request: requests.post
  },
  signExperienceLogo: {
    url: `${BASE_URL}/users/sign-experience-logo/`,
    request: requests.post
  },
  signQualificationLogo: {
    url: `${BASE_URL}/users/sign-qualification-logo/`,
    request: requests.post
  },
  generateSSOAuthorizationToken: {
    url: `${BASE_URL}/connect-sso/generate-user-token/`,
    request: requests.post
  },
  CPDListForUser: {
    url: `${BASE_URL}/cpds/user-list/`,
    request: requests.get
  }
};
