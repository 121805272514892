import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'AvailableTendersSection/List/';

export const FETCH_TENDERS = `${actionPrefix}FETCH_TENDERS`;
export const SUCCESS_FETCH_TENDERS = `${actionPrefix}SUCCESS_FETCH_TENDERS`;
export const FAIL_FETCH_TENDERS = `${actionPrefix}FAIL_FETCH_TENDERS`;

export const fetchTenders = () => ({
  type: FETCH_TENDERS
});

const successFetchTenders = payload => ({
  type: SUCCESS_FETCH_TENDERS,
  payload
});

const failFetchTenders = payload => ({
  type: FAIL_FETCH_TENDERS,
  payload
});

export const listTendersReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TENDERS:
      state['tenders'] = [];
      state['isFetching'] = true;
      break;

    case SUCCESS_FETCH_TENDERS:
      state['isFetching'] = false;
      state['tenders'] = action.payload.results;
      break;

    default:
      return state;
  }
};

function* fetchTendersWorker(action) {
  const response = yield makeRequest(api.tendersList, {
    requestData: {
      params: {
        limit: 3,
        offset: 0,
        is_open: true
      }
    }
  });

  if (response.success) yield put(successFetchTenders(response.data));
  else yield put(failFetchTenders(response.errors));
}

export function* tendersWatcher() {
  yield takeLatest(FETCH_TENDERS, fetchTendersWorker);
}
