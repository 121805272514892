import _ from 'lodash';

import { injectScriptBySource } from 'shared/utils/injectScript';
import {
  ORGANIZATION_SCHEMA_CODE,
  WEBSITE_SCHEMA_CODE,
  WEBSITE_SCHEMA_IDENTIFIER,
  ORGANIZATION_SCHEMA_IDENTIFIER
} from './constants';

export const addOrganizationSchema = () => {
  const data = {
    identifier: ORGANIZATION_SCHEMA_IDENTIFIER,
    clearElement: true,
    injectionTarget: 'head',
    type: 'application/ld+json',
    source: ORGANIZATION_SCHEMA_CODE
  };

  addSchema(data);
};

export const removeOrganizationSchema = () => {
  const el = document.getElementById(ORGANIZATION_SCHEMA_IDENTIFIER);

  if (!_.isNil(el)) {
    el.innerHTML = null;
  }
};

export const addWebsiteWideSchema = () => {
  const data = {
    identifier: WEBSITE_SCHEMA_IDENTIFIER,
    clearElement: true,
    injectionTarget: 'head',
    type: 'application/ld+json',
    source: WEBSITE_SCHEMA_CODE
  };

  addSchema(data);
};

const addSchema = data => {
  if (JSON.parse(process.env.REACT_APP_ADD_STRUCTURED_DATA_SCHEMA)) {
    injectScriptBySource(data);
  }
};
