import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { api, makeRequest } from 'shared/sdk';
import { SERVICE_PROVIDER_DETAIL_URL } from 'global-constants';
import { substituteUrl } from 'shared/utils/substituteUrl';

const actionPrefix = 'ConfirmServiceProviderClaimRequest/';

const REQUEST_CONFIRM_CLAIM_REQUEST = `${actionPrefix}REQUEST_CONFIRM_CLAIM_REQUEST`;
const SUCCESS_CONFIRM_CLAIM_REQUEST = `${actionPrefix}SUCCESS_CONFIRM_CLAIM_REQUEST`;
const FAIL_CONFIRM_CLAIM_REQUEST = `${actionPrefix}FAIL_CONFIRM_CLAIM_REQUEST`;

export const requestConfirmClaimRequest = claimRequestId => ({
  type: REQUEST_CONFIRM_CLAIM_REQUEST,
  payload: { claimRequestId }
});

const successConfirmClaimRequest = data => ({
  type: SUCCESS_CONFIRM_CLAIM_REQUEST,
  payload: data
});

const failConfirmClaimRequest = errors => ({
  type: FAIL_CONFIRM_CLAIM_REQUEST,
  payload: errors
});

const initialState = {
  loading: true,
  failed: false,
  errors: null
};

export const confirmServiceProviderClaimRequestReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FAIL_CONFIRM_CLAIM_REQUEST:
      return {
        ...state,
        loading: false,
        failed: true,
        errors: action.payload
      };
    default:
      return state;
  }
};

function* requestConfirmServiceProviderClaimRequestWorker(action) {
  const response = yield makeRequest(api.confirmServiceProviderClaimRequest, {
    lookupData: { claimRequestIdentifier: action.payload.claimRequestId }
  });

  if (response.success) {
    yield* [
      put(successConfirmClaimRequest(response.data)),
      put(
        push(
          substituteUrl(SERVICE_PROVIDER_DETAIL_URL, {
            serviceProviderSlug: response.data.service_provider_slug
          })
        )
      )
    ];
  } else yield put(failConfirmClaimRequest(response.errors));
}

export function* confirmServiceProviderClaimRequestSaga() {
  yield takeLatest(
    REQUEST_CONFIRM_CLAIM_REQUEST,
    requestConfirmServiceProviderClaimRequestWorker
  );
}
