import _ from 'lodash';
import { takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { prepareUserData, filterAdditionalResults } from './utils';

const actionPrefix = 'MentionsTextArea/';

const FETCH_USERS = `${actionPrefix}FETCH_USERS`;

export const fetchUsers = ({ query, callback, additionalResults }) => ({
  type: FETCH_USERS,
  payload: { query, callback, additionalResults }
});

function* fetchUsersWorker(action) {
  const { query: search, callback, additionalResults } = action.payload;

  // here we mock an entry so that we can display a loader INSIDE the dropdown
  callback([{ isLoading: true, display: '', id: '-1' }]);

  const response = yield makeRequest(api.usersList, {
    requestData: { params: { search, limit: 10 } }
  });

  const filteredAdditionalResults = filterAdditionalResults({
    search,
    additionalResults
  });

  const data = prepareUserData({
    results: _.uniqBy(
      [...filteredAdditionalResults, ...response.data.results],
      'slug'
    )
  });

  callback(data);
}

export function* mentionsTextAreaSaga() {
  yield takeLatest(FETCH_USERS, fetchUsersWorker);
}

export * from './utils';
