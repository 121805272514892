import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { makeRequest } from 'shared/sdk';

const actionPrefix = 'Home/Companies/';

const FETCH_FEATURED_COMPANIES = `${actionPrefix}FETCH_FEATURED_COMPANIES`;
const SUCCESS_FETCH_FEATURED_COMPANIES = `${actionPrefix}SUCCESS_FETCH_FEATURED_COMPANIES`;

export const fetchFeaturedCompanies = ({ url }) => ({
  type: FETCH_FEATURED_COMPANIES,
  payload: {
    url
  }
});

export const successFetchFeaturedCompanies = ({ results }) => ({
  type: SUCCESS_FETCH_FEATURED_COMPANIES,
  payload: { results }
});

export const companiesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_FEATURED_COMPANIES:
      state['fetchingCompanies'] = true;
      break;

    case SUCCESS_FETCH_FEATURED_COMPANIES:
      state['companies'] = action.payload.results;
      state['fetchingCompanies'] = false;
      break;

    default:
      return state;
  }
};

function* fetchFeaturedCompaniesWorker(action) {
  const { url } = action.payload;

  const params = { limit: 4 };

  const response = yield makeRequest(url, {
    requestData: { params }
  });

  if (response.success) {
    let results = _.get(response, 'data.results'); // one if the APIs we call is not a ListAPI.

    if (_.isNil(results)) {
      results = response.data;
    }

    yield put(successFetchFeaturedCompanies({ results }));
  }
}

export function* featuredCompaniesWatcher() {
  yield takeLatest(FETCH_FEATURED_COMPANIES, fetchFeaturedCompaniesWorker);
}
