import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { addToList, removeAtIndex } from 'shared/utils/reducerUtils';

import { swapQuestions } from './utils';

const actionPrefix = 'EditCompany/';

const SUBMIT_QUESTIONS = `${actionPrefix}SUBMIT_QUESTION`;
const SUCCESS_SUBMIT_QUESTIONS = `${actionPrefix}SUCCESS_SUBMIT_QUESTIONS`;
const FAIL_SUBMIT_QUESTIONS = `${actionPrefix}FAIL_SUBMIT_QUESTIONS`;

const ADD_QUESTION = `${actionPrefix}ADD_QUESTION`;
const MOVE_QUESTION_UP = `${actionPrefix}MOVE_QUESTION_UP`;
const MOVE_QUESTION_DOWN = `${actionPrefix}MOVE_QUESTION_DOWN`;
const DELETE_QUESTION = `${actionPrefix}DELETE_QUESTION`;

export const submitQuestions = (slug, payload, extraActions = []) => ({
  type: SUBMIT_QUESTIONS,
  slug,
  payload,
  extraActions
});

const successSubmitQuestion = () => ({
  type: SUCCESS_SUBMIT_QUESTIONS
});

const failSubmitQuestion = () => ({
  type: FAIL_SUBMIT_QUESTIONS
});

export const addQuestion = question => ({
  type: ADD_QUESTION,
  payload: question
});

export const moveQuestionUp = questionIndex => ({
  type: MOVE_QUESTION_UP,
  payload: { questionIndex }
});

export const moveQuestionDown = questionIndex => ({
  type: MOVE_QUESTION_DOWN,
  payload: { questionIndex }
});

export const deleteQuestion = questionIndex => ({
  type: DELETE_QUESTION,
  payload: { questionIndex }
});

export const questionsReducer = (newState, action) => {
  switch (action.type) {
    case MOVE_QUESTION_DOWN:
      newState.questions = swapQuestions(
        newState.questions,
        action.payload.questionIndex,
        action.payload.questionIndex + 1
      );

      break;

    case MOVE_QUESTION_UP:
      newState.questions = swapQuestions(
        newState.questions,
        action.payload.questionIndex,
        action.payload.questionIndex - 1
      );

      break;

    case DELETE_QUESTION:
      newState.questions = removeAtIndex(
        newState.questions,
        action.payload.questionIndex
      );

      break;

    case ADD_QUESTION:
      newState.questions = addToList([action.payload], newState.questions);

      break;

    case SUBMIT_QUESTIONS:
      newState.isUpdatingQuestions = true;

      break;

    case SUCCESS_SUBMIT_QUESTIONS:
      newState.isUpdatingQuestions = false;

      break;

    case FAIL_SUBMIT_QUESTIONS:
      newState.isUpdatingQuestions = false;

      break;

    default:
      break;
  }
};

function* submitQuestionWorker(action) {
  const { slug, payload, extraActions } = action;

  const response = yield makeRequest(api.updateCompanyQuestions, {
    lookupData: { slug },
    requestBody: payload
  });

  if (response.success) {
    yield put(successSubmitQuestion(response.data));
    yield* extraActions.map(action => put(action()));
  } else yield put(failSubmitQuestion(response.errors));
}

export function* questionsSaga() {
  yield takeLatest(SUBMIT_QUESTIONS, submitQuestionWorker);
}
