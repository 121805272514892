import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { openModal } from 'shared/components/Modals/reducer';
import { MODALS } from 'global-constants';

const actionPrefix = 'CompanyDetail/';

const REQUEST_DEMO = `${actionPrefix}REQUEST_DEMO`;
const SUCCESS_REQUEST_DEMO = `${actionPrefix}SUCCESS_REQUEST_DEMO`;
const FAIL_REQUEST_DEMO = `${actionPrefix}FAIL_REQUEST_DEMO`;

export const requestDemo = vendor => ({
  type: REQUEST_DEMO,
  payload: { vendor }
});

const successRequestDemo = () => ({
  type: SUCCESS_REQUEST_DEMO
});

const failRequestDemo = errors => ({
  type: FAIL_REQUEST_DEMO,
  payload: errors
});

export const requestDemoReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_REQUEST_DEMO:
      state.company = { ...state.company, demo_already_requested: true };
      break;

    default:
      break;
  }
};

function* requestDemoWorker(action) {
  const response = yield makeRequest(api.requestDemo, {
    lookupData: { vendorSlug: action.payload.vendor }
  });

  if (response.success) {
    yield* [
      put(successRequestDemo()),
      put(openModal({ modalName: MODALS.SUCCESS_REQUEST_DEMO }))
    ];
  } else yield put(failRequestDemo(response.errors));
}

export function* requestDemoSaga() {
  yield takeLatest(REQUEST_DEMO, requestDemoWorker);
}
