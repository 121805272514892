import { companyReducer, companySaga } from './company';
import { createSaga } from './create';
import { detailReducer, detailSaga } from './detail';
import { editReducer, editSaga } from './edit';

const actionPrefix = 'Job/Form/';

const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const resetState = () => ({
  type: RESET_STATE
});

const initialState = {
  company: null,
  isFetchingFormData: false,
  job: null,
  isClosing: false
};

export const jobFormReducer = (state, action) => {
  let newState = Object.assign({}, state);

  if (action.type === RESET_STATE) {
    newState = initialState;
  }

  companyReducer(newState, action);
  detailReducer(newState, action);
  editReducer(newState, action);

  return newState;
};

export const jobFormSaga = [companySaga, createSaga, detailSaga, editSaga];

export * from './company';
export * from './create';
export * from './detail';
export * from './edit';
