import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'Dashboard/Comments/';

const COMMENT_POST = `${actionPrefix}COMMENT_POST`;
const SUCCESS_COMMENT_POST = `${actionPrefix}SUCCESS_COMMENT_POST`;
const FAIL_COMMENT_POST = `${actionPrefix}FAIL_COMMENT_POST`;

const FETCH_COMMENTS = `${actionPrefix}FETCH_COMMENTS`;
const SUCCESS_FETCH_COMMENTS = `${actionPrefix}SUCCESS_FETCH_COMMENTS`;
const FAIL_FETCH_COMMENTS = `${actionPrefix}FAIL_FETCH_COMMENTS`;

const FETCH_NEXT_COMMENTS = `${actionPrefix}FETCH_NEXT_COMMENTS`;
const SUCCESS_FETCH_NEXT_COMMENTS = `${actionPrefix}SUCCESS_FETCH_NEXT_COMMENTS`;
const FAIL_FETCH_NEXT_COMMENTS = `${actionPrefix}FAIL_FETCH_NEXT_COMMENTS`;

export const fetchComments = ({ postId }) => ({
  type: FETCH_COMMENTS,
  payload: { postId }
});

const successFetchComments = ({ data, postId }) => ({
  type: SUCCESS_FETCH_COMMENTS,
  payload: { data, postId }
});

const failFetchComments = errors => ({
  type: FAIL_FETCH_COMMENTS,
  payload: { errors }
});

export const fetchNextComments = ({ postId, commentsNextUrl }) => ({
  type: FETCH_NEXT_COMMENTS,
  payload: { postId, commentsNextUrl }
});

const successFetchNextComments = ({ data, postId }) => ({
  type: SUCCESS_FETCH_NEXT_COMMENTS,
  payload: { data, postId }
});

const failFetchNextComments = errors => ({
  type: FAIL_FETCH_NEXT_COMMENTS,
  payload: { errors }
});

export const commentPost = ({ content, postId, mentionedUsers }) => ({
  type: COMMENT_POST,
  payload: { content, postId, mentionedUsers }
});

const successCommentPost = ({ data, postId }) => ({
  type: SUCCESS_COMMENT_POST,
  payload: { data, postId }
});

const failCommentPost = errors => ({
  type: FAIL_COMMENT_POST,
  payload: { errors }
});

export const commentsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COMMENTS:
    case SUCCESS_FETCH_NEXT_COMMENTS:
      state['posts'] = _.map(
        state.posts,
        post =>
          post.id === action.payload.postId
            ? {
                ...post,
                commentsNextUrl: action.payload.data.next,
                comments: addToList(
                  _.get(post, 'comments', []),
                  action.payload.data.results
                )
              }
            : post
      );
      break;

    case SUCCESS_COMMENT_POST:
      state['posts'] = _.map(
        state.posts,
        post =>
          post.id === action.payload.postId
            ? {
                ...post,
                comments: addToList([action.payload.data], post.comments),
                number_of_comments: post.number_of_comments + 1
              }
            : post
      );
      break;

    default:
      return state;
  }

  return state;
};

function* fetchCommentsWorker(action) {
  const { postId } = action.payload;

  const response = yield makeRequest(api.fetchPostComments, {
    lookupData: { postId }
  });

  if (response.success) {
    yield put(successFetchComments({ data: response.data, postId }));
  } else {
    yield put(failFetchComments(response));
  }
}

function* fetchNextCommentsWorker(action) {
  const { postId, commentsNextUrl } = action.payload;

  const response = yield makeRequest(api.genericGet, {
    lookupData: commentsNextUrl
  });

  if (response.success) {
    yield put(successFetchNextComments({ data: response.data, postId }));
  } else {
    yield put(failFetchNextComments(response));
  }
}

function* commentPostWorker(action) {
  const {
    postId,
    content,
    mentionedUsers: mentioned_users_ids
  } = action.payload;

  const response = yield makeRequest(api.commentPost, {
    lookupData: { postId },
    requestBody: { content, mentioned_users_ids }
  });

  if (response.success) {
    yield put(successCommentPost({ data: response.data, postId }));
  } else {
    yield put(failCommentPost(response));
  }
}

export function* commentsSaga() {
  yield takeLatest(COMMENT_POST, commentPostWorker);
  yield takeLatest(FETCH_COMMENTS, fetchCommentsWorker);
  yield takeLatest(FETCH_NEXT_COMMENTS, fetchNextCommentsWorker);
}
