import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Investors/';

export const FETCH_FEATURED_INVESTORS = `${actionPrefix}FETCH_FEATURED_INVESTORS`;
export const SUCCESS_FETCH_FEATURED_INVESTORS = `${actionPrefix}SUCCESS_FETCH_FEATURED_INVESTORS`;
export const FAIL_FETCH_FEATURED_INVESTORS = `${actionPrefix}FAIL_FETCH_FEATURED_INVESTORS`;

export const fetchFeaturedInvestors = payload => ({
  type: FETCH_FEATURED_INVESTORS,
  payload
});

const successFetchFeaturedInvestors = payload => ({
  type: SUCCESS_FETCH_FEATURED_INVESTORS,
  payload
});

const failFetchFeaturedInvestors = payload => ({
  type: FAIL_FETCH_FEATURED_INVESTORS,
  payload
});

export const featuredInvestorsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_FEATURED_INVESTORS:
      state['featuredInvestors'] = [];
      state['isFetchingFeaturedInvestors'] = true;
      break;
    case SUCCESS_FETCH_FEATURED_INVESTORS:
      state['featuredInvestors'] = action.payload.results;
      state['isFetchingFeaturedInvestors'] = false;
      break;

    default:
      return state;
  }
};

function* fetchFeaturedInvestorsWorker(action) {
  const params = { ...action.payload, limit: 4, offset: 0 };

  const response = yield makeRequest(api.investorList, {
    requestData: {
      params
    }
  });

  if (response.success) yield put(successFetchFeaturedInvestors(response.data));
  else yield put(failFetchFeaturedInvestors(response.errors));
}

export function* featuredInvestorsWatcher() {
  yield takeLatest(FETCH_FEATURED_INVESTORS, fetchFeaturedInvestorsWorker);
}
