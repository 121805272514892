import _ from 'lodash';
import { makeRequest, unconfiguredCall, api } from 'shared/sdk';

export const getS3SignRequest = (file, sdk) => {
  return makeRequest(sdk, {
    requestBody: {
      filename: file.name,
      file_type: file.type
    }
  });
};

export const getS3UploadRequest = (file, signatureData) => {
  const postData = new FormData();

  _.each(signatureData.fields, (field, fieldName) =>
    postData.append(fieldName, field)
  );

  postData.append('file', file);

  return unconfiguredCall(api.genericPost, postData, {}, signatureData.url);
};

export const getMultipleUploadRequests = (files, signatures) => {
  return _.zipWith(files, signatures, (file, signatureData) => {
    return getS3UploadRequest(file, signatureData);
  });
};
