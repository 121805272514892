import { put, takeLatest } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';

import { makeRequest } from 'shared/sdk';
import { closeModal } from 'shared/components/Modals/reducer';
import { MODALS } from 'global-constants';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'Companies/Detail/';

const REQUEST_CLAIM_COMPANY = `${actionPrefix}REQUEST_CLAIM_COMPANY`;
const SUCCESS_CLAIM_COMPANY = `${actionPrefix}SUCCESS_CLAIM_COMPANY`;
const FAIL_CLAIM_COMPANY = `${actionPrefix}FAIL_CLAIM_COMPANY`;

export const requestClaimCompany = (slug, data, companyType) => ({
  type: REQUEST_CLAIM_COMPANY,
  payload: { slug, data, companyType }
});

const successClaimCompany = data => ({
  type: SUCCESS_CLAIM_COMPANY,
  payload: data
});

const failClaimCompany = data => ({
  type: FAIL_CLAIM_COMPANY,
  payload: data
});

function* claimCompanyWorker(action) {
  const { slug, data, companyType } = action.payload;

  const response = yield makeRequest(companyType.api, {
    lookupData: { slug },
    requestBody: data
  });

  if (response.success) {
    const notificationContent =
      "Thanks, a verification email is on it's way to you.";

    yield* [
      put(successClaimCompany(response.data)),
      put(closeModal(MODALS.CLAIM_COMPANY)),
      put(openNotificationBar(notificationContent))
    ];
  } else
    yield* [
      put(failClaimCompany(response.errors)),
      put(stopSubmit('claimCompanyForm', response.errors))
    ];
}

export function* claimCompanyWatcher() {
  yield takeLatest(REQUEST_CLAIM_COMPANY, claimCompanyWorker);
}
