import _ from 'lodash';

export const getFiles = state => {
  return _.get(state, 'pages.jobs.detail.apply.files', []);
};

export const getJobSlug = state => {
  return _.get(state, 'pages.jobs.detail.apply.jobSlug', null);
};

export const getCloseModal = state => {
  return _.get(state, 'pages.jobs.detail.apply.modal.closeModal', null);
};
