export const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}`;
export const FE_URL = `${window.location.protocol}//${window.location.host}`;
export const REFRESH_TOKEN_INTERVAL = 10 * 60 * 1000;

export const STYLEGUIDE_URL = '/styleguide';

export const UNISSU_EVENTS_URL = 'https://events.unissu.com';

// Sentry
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const SENTRY_RELEASE_VERSION =
  process.env.REACT_APP_SENTRY_RELEASE_VERSION;
export const SENTRY_DSN =
  'https://2179c577dd434d2494701a9ab4db7a31@o508194.ingest.sentry.io/5607650';

export const UNISCORE_REPORT_URL =
  'https://reports.unissu.com/all-uniscore-reports-top-20-proptech-companies-ranked/';
export const CHERRY_PICK_URL =
  'https://reports.unissu.com/cherry-pick-people-report/';
export const DATA_SALES_URL = 'https://meetings.hubspot.com/james889';
export const BASE_TYPEFORM_URL = `https://unissu.typeform.com/to/:responseFormId?user_id=:userId`;
export const CASE_STUDY_CREATE_URL = 'https://unissu.typeform.com/to/kvd21Z';
export const TENDER_SUBSCRIBE_URL =
  'https://reports.unissu.com/proptech-procurement-3000-sales-leads/';

export const DASHBOARD_URL = '/dashboard';
export const DASHBOARD_POST_DETAIL_URL = '/dashboard/:postId';
export const COMPANIES_URL = '/proptech-companies';
export const COMPANY_DETAIL_URL = '/proptech-company/:companySlug';
export const SERVICE_PROVIDER_DETAIL_URL =
  '/service-provider/:serviceProviderSlug';
export const SOLUTIONS_URL = '/proptech-solutions';
export const SOLUTION_DETAIL_URL = '/proptech-solutions/:solutionSlug';
export const EDIT_COMPANY_URL = '/company/:companySlug/edit';
export const CREATE_COMPANY_URL = '/create-company/';
export const EDIT_PRODUCT_URL = '/:companySlug/products/:productId/edit';
export const RESOURCES_URL = '/proptech-resources';
export const RESOURCE_DETAIL_URL = '/proptech-resources/:resourceSlug';
export const NEWS_URL = '/proptech-news';
export const CONNECT_VIDEOS_URL = '/connect-videos';
export const CONNECT_VIDEO_DETAIL_URL = '/connect-videos/:connectVideoSlug';
export const CONNECT_VIDEO_ALTERNATIVE_URL = '/connect/:connectVideoSlug';
export const CONNECT_SPEAKER_STATS_URL = '/connect-speaker-stats';
export const CONNECT_VIDEO_STATS_URL = '/connect-video-stats';
export const CONNECT_LOGIN_URL = '/connect-sso/login';
export const CONNECT_SIGNUP_URL = '/connect-sso/signup';
export const CASE_STUDIES_URL = '/property-proptech-collaboration-case-studies';
export const HOME_URL = '/';
export const PROPERTY_JOURNEY_URL = '/property-journey';
export const PROPTECH_JOURNEY_URL = '/proptech-journey';
export const LINKEDIN_LOGIN_URL = '/login-linkedin';
export const STATIC_PAGE_URL = '/:pageSlug';
export const EDIT_PROFILE_URL = '/profile/edit';
export const EVENTS_URL = '/proptech-events';
export const EVENT_DETAIL_URL = '/proptech-events/:eventSlug';
export const INFLUENCERS_URL = '/proptech-influencers';
export const INFLUENCER_PREVIEW_URL = '/proptech-influencers/:influencerSlug';
export const PAGE_NOT_FOUND_URL = '/404';
export const KNOWLEDGEBASE_URL = '/knowledge-base';

export const ACCEPT_INVITATION_URL = '/accept-invitation/:invitationIdentifier';
export const ACCEPT_SERVICE_PROVIDER_INVITATION_URL =
  '/service-providers/accept-invitation/:invitationIdentifier';
export const ACCEPT_INVESTOR_SERVICE_PROVIDER_INVITATION_URL =
  '/investors/accept-invitation/:invitationIdentifier';
export const CONFIRM_CLAIM_REQUEST_URL =
  '/confirm-claim/:claimRequestIdentifier';
export const CONFIRM_SERVICE_PROVIDER_CLAIM_REQUEST_URL =
  '/service-provider/claim/:claimRequestIdentifier';
export const CONFIRM_INVESTOR_CLAIM_REQUEST_URL =
  '/proptech-investors/confirm-claim/:claimRequestIdentifier';
export const RESET_PASSWORD_URL = '/reset-password/:token';
export const GLOBAL_SEARCH_URL = '/search';
export const ANALYTICS_URL = '/analytics';
export const POST_EVENT_URL = '/post-event';
export const CREATE_RESOURCE_PROPOSAL_URL = '/propose-resource';
export const PREMIUM_FEATURES_URL = '/premium-features';
export const MESSAGING_URL = '/messaging';
export const ENTERPRISE_PRODUCTS_URL = '/enterprise-products';
export const SERVICE_PROVIDERS_URL = '/service-providers';
export const CREATE_SERVICE_PROVIDER_URL = '/create-service-provider/';
export const EDIT_SERVICE_PROVIDER_URL =
  '/service-provider/:serviceProviderSlug/edit';
export const SEND_PUSH_NOTIFICATION_URL = '/send-notification';
export const TENDER_OVERVIEW_URL = '/proptech-procurement-service/';
export const CREATE_TENDER_URL = '/create-proptech-procurement/:activeTab/';
export const EDIT_TENDER_URL = '/project/:tenderId/edit/';
export const RESPOND_TO_TENDER_URL = '/project/respond/:responseFormId/';
export const CLOSED_TENDER_DETAIL_URL = '/project/closed/:tenderId/';
export const TENDER_URL = '/project/:tenderId';
export const TENDERS_LIST_URL = '/proptech-procurement/:activeTab/';
export const EDIT_EVENT_URL = '/proptech-events/:eventSlug/edit';
export const ACCEPT_SPEAKER_INVITATION_URL =
  '/speakers/accept-invitation/:eventSlug';

export const INFLUENCER_ID_TO_SLUG_REDIRECT_URL = '/user/:influencerId/';

export const TENDER_RESPONSE_URL = '/project-response/:tenderResponseId';

export const TENDER_PARTNER_CREATE_TENDER_URL = `/create/`;
export const TENDER_PARTNER_RESPOND_TO_TENDER_URL = `/respond/:responseFormId/`;
export const TENDER_PARTNER_AVAILABLE_TENDERS_URL = `/:activeTab/`;

export const INVESTORS_URL = '/proptech-investors/';
export const INVESTOR_DETAIL_URL = '/proptech-investors/:investorSlug/';

export const LINKEDIN_REDIRECT_URI = `${FE_URL}${LINKEDIN_LOGIN_URL}`;
export const LINKEDIN_CLIENT_ID = `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`;
export const LINKEDIN_STATE = `${process.env.REACT_APP_LINKEDIN_STATE}`;
export const LINKEDIN_URL = 'https://www.linkedin.com/oauth/v2/authorization';

export const JWT_EXPIRATION_DELTA_HOURS = 24 * 7;

export const PROCESS_LOGIN_QUERY_PARAM = 'login';
export const PROCESS_SIGN_UP_QUERY_PARAM = 'sign-up';
export const PROCESS_LOGOUT_QUERY_PARAM = 'logout';

export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const INFLUENCER_FAQ_URL = '/influencer-faq';
export const NOTIFICATIONS_URL = '/notifications';

export const CREATE_JOB_URL = '/create-a-job/';
export const EDIT_JOB_URL = '/edit-a-job/:jobSlug/';
export const JOB_DETAIL_URL = '/proptech-jobs/:jobSlug/';
export const JOB_LIST_URL = `/proptech-jobs/`;

export const COMPANY_PREDEFINED_SEARCH_URL =
  '/proptech-companies/:predefinedSearchSlug/';

export const MODALS = {
  SIGNUP: 'signup',
  PRODUCT: 'product',
  LOGIN: 'login',
  SUCCESS_SUBMIT_COMPANY: 'successSubmitCompany',
  SUBMIT_ARTICLE: 'submitArticle',
  SUCCESS_SUBMIT_ARTICLE: 'successSubmitArticle',
  INVITE_TEAM_MEMBERS: 'inviteTeamMembers',
  CLAIM_COMPANY: 'claimCompany',
  FORGOT_PASSWORD_MODAL: 'forgotPassword',
  SUGGEST_COMPANY_EDIT: 'suggestCompanyEdit',
  CHANGE_PLAN: 'changePlan',
  SUCCESS_CHANGE_PLAN: 'successChangePlan',
  SUCCESS_SUBMIT_EVENT: 'successSubmitEvent',
  POST_SIGNUP: 'postSignUp',
  EVENT_ATTENDEES: 'eventAttendees',
  DELETE_CONVERSATION_CONFIRMATION: 'deleteConversationConfirmation',
  BLOCK_USER_CONFIRMATION_FROM_MESSAGING: 'blockUserConfirmationFromMessaging',
  SUCCESS_REQUEST_DEMO: 'successRequestDemo',
  SEND_MESSAGES_MODAL: 'sendMessagesModal',
  DELETE_TENDER_MODAL: 'deleteTenderModal',
  TENDER_CLAIM_VENDOR_MODAL: 'tenderClaimVendorModal',
  SUCCESS_CREATE_TENDER_MODAL: 'successCreateTenderModal',
  SUCCESS_CREATE_TENDER_RESPONSE_MODAL: 'successCreateTenderResponseModal',
  PROFILE_STEPS_INFO_MODAL: 'profileStepsInfoModal',
  LEAVE_COMPANY_MODAL: 'leaveCompanyModal',
  SUGGEST_EVENT_EDIT: 'suggestEventEdit',
  NOTIFICATION_PERMISSION_PROMPT: 'notificationPermissionPrompt',
  PROFILE_VISITS: 'profileVisits'
};
export const NEWS_ITEM_HIDE_BUTTON_STATES = {
  CAN_HIDE: 1,
  UPGRADE_BUTTON: 2,
  HIDDEN: 3
};

export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const HOLISTICS = {
  vendorStatistics: 'vendor-statistics',
  userAverageTimeFollowersAndAppearance:
    'user-average-time-followers-and-appearance',
  userProfileVisitsAndTimeOnPage: 'user-profile-visits-and-time-on-page',
  analyticsHubForInvestorAdvisor: 'analytics-hub-investor-advisor',
  analyticsHubForProptech: 'analytics-hub-proptech',
  analyticsHubForProperty: 'analytics-hub-property',
  connectSpeakerStats: 'connect-speaker-stats',
  connectVideoStats: 'connect-video-stats'
};

export const M3U8_CONTENT_TYPE = 'application/x-mpegURL';
