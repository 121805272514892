import { sectorsSaga, sectorsReducer } from './sectors';
import { countriesSaga, countriesReducer } from './countries';
import { joinCompanyReducer, joinCompanySaga } from './joinCompany';
import { registerSaga } from './register';
import { patchUserSaga, patchUserReducer } from './patchUser';
import { vendorsReducer, vendorsSaga } from './vendors';
import { BASE_STEPS, REGISTER, manageStepsReducer } from './manageSteps';
import { validateCredentialsWatcher } from './validateCredentials';

const DELETE_STATE = 'Signup/DELETE_STATE';

export const deleteState = () => {
  return {
    type: DELETE_STATE
  };
};

const initialState = {
  steps: BASE_STEPS,
  currentStep: REGISTER,
  countries: [],
  sectors: [],
  detectedCompany: null,
  vendors: [],
  workEmail: null,
  isOpenManualSearch: false,
  joinTeamError: false
};

export const onboardingReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) {
    return initialState;
  }

  manageStepsReducer(newState, action);
  countriesReducer(newState, action);
  sectorsReducer(newState, action);
  joinCompanyReducer(newState, action);
  vendorsReducer(newState, action);
  patchUserReducer(newState, action);

  return newState;
};

export const onboardingSagas = [
  countriesSaga,
  registerSaga,
  sectorsSaga,
  patchUserSaga,
  joinCompanySaga,
  vendorsSaga,
  validateCredentialsWatcher
];

export * from './register';
export * from './patchUser';
export * from './sectors';
export * from './countries';
export * from './manageSteps';
export * from './joinCompany';
export * from './vendors';
export * from './validateCredentials';
