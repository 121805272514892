import _ from 'lodash';

import { put, takeLatest, select } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { uploadFile } from 'shared/utils/fileUpload';
import { addToList } from 'shared/utils/reducerUtils';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

import { getInitialPresentationUrl } from './selectors';

const actionPrefix = 'EditProfile/ConnectVideos/';

const FETCH_CONNECT_VIDEOS = `${actionPrefix}FETCH_CONNECT_VIDEOS`;
const SUCCESS_FETCH_CONNECT_VIDEOS = `${actionPrefix}SUCCESS_FETCH_CONNECT_VIDEOS`;

const SUBMIT_CONNECT_TALK_DETAILS = `${actionPrefix}CONNECT_TALK_DETAILS`;
const SUCCESS_SUBMIT_CONNECT_TALK_DETAILS = `${actionPrefix}SUCCESS_SUBMIT_CONNECT_TALK_DETAILS`;
const FAIL_SUBMIT_CONNECT_TALK_DETAILS = `${actionPrefix}FAIL_SUBMIT_CONNECT_TALK_DETAILS`;

const FETCH_CONNECT_TALK_DETAILS = `${actionPrefix}FETCH_CONNECT_TALK_DETAILS`;
const SUCCESS_FETCH_CONNECT_TALK_DETAILS = `${actionPrefix}SUCCESS_FETCH_CONNECT_TALK_DETAILS`;

const RESET_CONNECT_TALK_DETAILS = `${actionPrefix}RESET_CONNECT_TALK_DETAILS`;

export const fetchConnectVideos = ({ influencerId, nextUrl }) => ({
  type: FETCH_CONNECT_VIDEOS,
  payload: { influencerId, nextUrl }
});

const successFetchConnectVideos = ({ data: { results, next, count } }) => ({
  type: SUCCESS_FETCH_CONNECT_VIDEOS,
  payload: { results, next, count }
});

export const submitConnectTalkDetails = ({ data, formName }) => ({
  type: SUBMIT_CONNECT_TALK_DETAILS,
  payload: { data },
  formName
});

const successSubmitConnectTalkDetails = ({ data }) => ({
  type: SUCCESS_SUBMIT_CONNECT_TALK_DETAILS,
  payload: { data }
});

const failSubmitConnectTalkDetails = ({ errors }) => ({
  type: FAIL_SUBMIT_CONNECT_TALK_DETAILS,
  payload: { errors }
});

export const fetchConnectTalkDetails = () => ({
  type: FETCH_CONNECT_TALK_DETAILS
});

const successFetchConnectTalkDetails = ({ data }) => ({
  type: SUCCESS_FETCH_CONNECT_TALK_DETAILS,
  payload: { data }
});

export const resetConnectTalkDetails = () => ({
  type: RESET_CONNECT_TALK_DETAILS
});

export const connectVideosReducer = (state, action) => {
  switch (action.type) {
    case FETCH_CONNECT_VIDEOS:
      state['isFetchingConnectVideos'] = true;
      break;

    case SUCCESS_FETCH_CONNECT_VIDEOS:
      state['connectVideos'] = addToList(
        state.connectVideos,
        action.payload.results
      );
      state['connectVideosCount'] = action.payload.count;
      state['connectVideosNextUrl'] = action.payload.next;
      state['isFetchingConnectVideos'] = false;
      state['fetchedInitialConnectVideos'] = true;
      break;

    case SUBMIT_CONNECT_TALK_DETAILS:
      state['isSubmittingConnectTalkDetailsForm'] = true;
      break;

    case SUCCESS_SUBMIT_CONNECT_TALK_DETAILS:
      state['connectTalkDetails'] = {
        ...state.connectTalkDetails,
        ...action.payload.data
      };
      state['isSubmittingConnectTalkDetailsForm'] = false;
      break;

    case FAIL_SUBMIT_CONNECT_TALK_DETAILS:
      state['isSubmittingConnectTalkDetailsForm'] = false;
      break;

    case FETCH_CONNECT_TALK_DETAILS:
      state['isFetchingConnectTalkDetails'] = true;
      break;

    case SUCCESS_FETCH_CONNECT_TALK_DETAILS:
      state['isFetchingConnectTalkDetails'] = false;
      state['connectTalkDetails'] = action.payload.data;
      break;

    case RESET_CONNECT_TALK_DETAILS:
      state['connectTalkDetails'] = null;
      state['isFetchingConnectTalkDetails'] = true;
      state['isSubmittingConnectTalkDetailsForm'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchConnectVideosWorker(action) {
  const { influencerId, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    const params = { author: influencerId, limit: 5 };

    response = yield makeRequest(api.connectVideoList, {
      requestData: { params }
    });
  }

  if (response.success) {
    yield put(successFetchConnectVideos(response));
  }
}

function* submitConnectTalkDetailsWorker(action) {
  let { data: formData } = action.payload;

  const { presentation, presentation_status: presentationStatus } = formData;

  if (presentationStatus === 'Ready') {
    let presentationUrl = yield select(getInitialPresentationUrl);

    if (presentationUrl !== presentation && !_.isNil(presentation)) {
      // only upload the file if it's a new one
      presentationUrl = yield uploadFile(
        presentation,
        api.connectTalkDetailsSignPresentation
      );
    }

    formData = Object.assign(formData, { presentation: presentationUrl });
  } else {
    // remove presentation if status is not 'ready'
    formData = _.omit(formData, 'presentation');
  }

  const response = yield makeRequest(api.connectTalkDetailsUpdate, {
    requestBody: formData
  });

  if (response.success) {
    yield* [
      put(successSubmitConnectTalkDetails(response)),
      put(
        openNotificationBar(
          'Connect talk details have been updated successfully!'
        )
      )
    ];
  } else {
    yield put(failSubmitConnectTalkDetails(response));
    return response.errors;
  }
}

function* fetchConnectTalkDetailsWorker(action) {
  const response = yield makeRequest(api.connectTalkDetailsDetail);

  if (response.success) {
    yield put(successFetchConnectTalkDetails(response));
  }
}

export function* connectVideosWatcher() {
  yield takeLatest(FETCH_CONNECT_VIDEOS, fetchConnectVideosWorker);
  yield takeLatest(
    SUBMIT_CONNECT_TALK_DETAILS,
    formSubmitWorker(submitConnectTalkDetailsWorker)
  );
  yield takeLatest(FETCH_CONNECT_TALK_DETAILS, fetchConnectTalkDetailsWorker);
}
