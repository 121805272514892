import _ from 'lodash';

export const getFilters = (state, pageName) => {
  return _.get(state.filters[pageName], 'filters', []);
};

export const isLoggedUser = state => {
  return !_.isNil(state.me.user);
};

export const getUserPlan = state => {
  return _.get(state.me, 'user.plan_subscription.plan_type', null);
};
