import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

const actionPrefix = 'ServiceProvider/Similar/';

export const FETCH_SIMILAR_SERVICE_PROVIDERS = `${actionPrefix}FETCH_SIMILAR_SERVICE_PROVIDERS`;
const SUCCESS_FETCH_SIMILAR_SERVICE_PROVIDERS = `${actionPrefix}SUCCESS_FETCH_SIMILAR_SERVICE_PROVIDERS`;
const FAIL_FETCH_SIMILAR_SERVICE_PROVIDERS = `${actionPrefix}FAIL_FETCH_SIMILAR_SERVICE_PROVIDERS`;

export const fetchSimilarServiceProviders = slug => ({
  type: FETCH_SIMILAR_SERVICE_PROVIDERS,
  payload: {
    slug
  }
});

export const successFetchSimilarServiceProviders = payload => ({
  type: SUCCESS_FETCH_SIMILAR_SERVICE_PROVIDERS,
  payload
});

export const failFetchSimilarServiceProviders = errors => ({
  type: FAIL_FETCH_SIMILAR_SERVICE_PROVIDERS,
  errors
});

export const similarServiceProvidersReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SIMILAR_SERVICE_PROVIDERS:
      state['similarServiceProviders'] = action.payload.results;
      break;
    default:
      return state;
  }
};

function* fetchSimilarServiceProvidersWorker(action) {
  const { slug } = action.payload;

  const response = yield makeRequest(api.similarServiceProviders, {
    lookupData: { slug }
  });

  if (response.data)
    yield put(successFetchSimilarServiceProviders(response.data));
  else yield put(failFetchSimilarServiceProviders(response.errors));
}

export function* similarServiceProvidersSaga() {
  yield takeLatest(
    FETCH_SIMILAR_SERVICE_PROVIDERS,
    fetchSimilarServiceProvidersWorker
  );
}
