import { combineReducers } from 'redux';

import { postEventSaga } from './Create/ducks';
import {
  eventReducer as eventDetailReducer,
  eventSagas as eventDetailSagas
} from './Detail/ducks';
import { eventsReducer, eventsSaga } from './List/ducks';
import { eventFormReducer, eventFormSaga } from './Form/ducks';
import { eventEditReducer, eventEditSaga } from './Edit/ducks';

export const eventReducer = combineReducers({
  list: eventsReducer,
  detail: eventDetailReducer,
  form: eventFormReducer,
  edit: eventEditReducer
});

export const eventSagas = [
  ...postEventSaga,
  ...eventDetailSagas,
  ...eventsSaga,
  ...eventFormSaga,
  ...eventEditSaga
];
