const actionPrefix = 'Websockets/';

const USER_CAME_ONLINE = `${actionPrefix}USER_CAME_ONLINE`;
const USER_WENT_OFFLINE = `${actionPrefix}USER_WENT_OFFLINE`;

export const userCameOnline = ({ message }) => ({
  type: USER_CAME_ONLINE,
  payload: { message }
});

export const userWentOffline = ({ message }) => ({
  type: USER_WENT_OFFLINE,
  payload: { message }
});

const initialState = {};

export const websocketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CAME_ONLINE:
      console.log(action.payload.message);
      break;

    case USER_WENT_OFFLINE:
      console.log(action.payload.message);
      break;

    default:
      break;
  }

  return initialState;
};
