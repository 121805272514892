import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'Home/';

const FETCH_INITIAL_RESOURCES = `${actionPrefix}FETCH_INITIAL_RESOURCES`;
const SUCCESS_FETCH_INITIAL_RESOURCES = `${actionPrefix}SUCCESS_FETCH_INITIAL_RESOURCES`;

export const fetchInitialResources = () => ({
  type: FETCH_INITIAL_RESOURCES
});

export const successFetchInitialResources = payload => ({
  type: SUCCESS_FETCH_INITIAL_RESOURCES,
  payload
});

export const resourcesReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_INITIAL_RESOURCES:
      state['fetchingResources'] = false;
      state['resources'] = action.payload.results;
      break;

    case FETCH_INITIAL_RESOURCES:
      state['fetchingResources'] = true;
      break;

    default:
      return state;
  }
};

function* fetchInitialResourcesWorker(action) {
  const params = { limit: 6 };

  const response = yield makeRequest(api.resourceList, {
    requestData: { params }
  });
  if (response.success) {
    yield put(successFetchInitialResources(response.data));
  }
}

export function* resourcesSaga() {
  yield takeLatest(FETCH_INITIAL_RESOURCES, fetchInitialResourcesWorker);
}
