import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'EditCompany/';

const UPDATE_COMPANY_CONTACT = `${actionPrefix}UPDATE_COMPANY_CONTACT`;
const FAIL_UPDATE_COMPANY_CONTACT = `${actionPrefix}FAIL_UPDATE_COMPANY_CONTACT`;
const SUCCESS_UPDATE_COMPANY_CONTACT = `${actionPrefix}SUCCESS_UPDATE_COMPANY_CONTACT`;

export const updateCompanyContact = (
  formName,
  slug,
  payload,
  extraActions = []
) => ({
  type: UPDATE_COMPANY_CONTACT,
  formName,
  slug,
  payload,
  extraActions
});

const failUpdateCompanyContact = payload => ({
  type: FAIL_UPDATE_COMPANY_CONTACT,
  payload
});

const successUpdateCompanyContact = payload => ({
  type: SUCCESS_UPDATE_COMPANY_CONTACT,
  payload
});

function* updateCompanyContactWorker(action) {
  const response = yield makeRequest(api.updateCompanyContact, {
    lookupData: { slug: action.slug },
    requestBody: action.payload
  });

  if (response.success) {
    yield put(successUpdateCompanyContact(response.data));
    yield* action.extraActions.map(action => put(action()));
  } else {
    yield put(failUpdateCompanyContact(response.errors));

    return response.errors;
  }
}

export function* updateContactSaga() {
  yield takeLatest(
    UPDATE_COMPANY_CONTACT,
    formSubmitWorker(updateCompanyContactWorker)
  );
}
