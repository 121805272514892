import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'GlobalSearch/';
const FETCH_INVESTORS = `${actionPrefix}FETCH_INVESTORS`;
const SUCCESS_FETCH_INVESTORS = `${actionPrefix}SUCCESS_FETCH_INVESTORS`;
const FAIL_FETCH_INVESTORS = `${actionPrefix}FAIL_FETCH_INVESTORS`;

export const fetchInvestors = args => ({
  type: FETCH_INVESTORS,
  payload: args
});

export const successFetchInvestors = payload => ({
  type: SUCCESS_FETCH_INVESTORS,
  payload
});

export const failFetchInvestors = payload => ({
  type: FAIL_FETCH_INVESTORS,
  payload
});

export const investorsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INVESTORS:
      state['fetchingInvestors'] = true;
      break;

    case SUCCESS_FETCH_INVESTORS:
      state['investors'] = action.payload.results;
      state['investorsCount'] = action.payload.count;

      state['fetchingInvestors'] = false;
      break;

    default:
      return state;
  }
};

function* fetchInvestorsWorker(action) {
  const response = yield makeRequest(api.investorList, {
    requestData: { params: { limit: 4, ...action.payload } }
  });

  if (response.success) yield put(successFetchInvestors(response.data));
  else yield put(failFetchInvestors(response.errors));
}

export function* investorsSaga() {
  yield takeLatest(FETCH_INVESTORS, fetchInvestorsWorker);
}
