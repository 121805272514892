import { listInvestorsReducer, investorsWatcher } from './list';
import {
  featuredInvestorsReducer,
  featuredInvestorsWatcher
} from './featuredInvestors';

const DELETE_STATE = 'Investors/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  investors: [],
  nextUrl: null,
  isFetching: true,
  featuredInvestors: [],
  investorCount: 0,
  isFetchingFeaturedInvestors: false
};

export const investorsReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  listInvestorsReducer(initialState, newState, action);
  featuredInvestorsReducer(newState, action);

  return newState;
};

export const investorsSaga = [investorsWatcher, featuredInvestorsWatcher];
export * from './list';
export * from './featuredInvestors';
