import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { buildFilters } from 'shared/utils/filters';

const actionPrefix = 'CaseStudies/';

const RESET_STATE = `${actionPrefix}RESET_STATE`;

const FETCH_CASE_STUDIES = `${actionPrefix}FETCH_CASE_STUDIES`;
const SUCCESS_FETCH_CASE_STUDIES = `${actionPrefix}SUCCESS_FETCH_CASE_STUDIES`;

const FETCH_FEATURED_CASE_STUDIES = `${actionPrefix}FETCH_FEATURED_CASE_STUDIES`;
const SUCCESS_FETCH_FEATURED_CASE_STUDIES = `${actionPrefix}SUCCESS_FETCH_FEATURED_CASE_STUDIES`;

const PAGE_SIZE = 10;

export const resetState = () => ({
  type: RESET_STATE
});

export const fetchCaseStudies = ({ page, filters }) => ({
  type: FETCH_CASE_STUDIES,
  payload: { page, filters }
});

const successFetchCaseStudies = ({ data: { count, results } }) => ({
  type: SUCCESS_FETCH_CASE_STUDIES,
  payload: { count, results }
});

export const fetchFeaturedCaseStudies = () => ({
  type: FETCH_FEATURED_CASE_STUDIES
});

const successFetchFeaturedCaseStudies = ({ data }) => ({
  type: SUCCESS_FETCH_FEATURED_CASE_STUDIES,
  payload: { data }
});

const initialState = {
  caseStudies: [],
  isFetchingCaseStudies: false,
  featuredCaseStudies: [],
  isFetchingFeaturedCaseStudies: false,
  caseStudyPageCount: 0
};

export const caseStudiesReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case RESET_STATE:
      newState = initialState;
      break;

    case FETCH_CASE_STUDIES:
      newState['isFetchingCaseStudies'] = true;
      break;

    case SUCCESS_FETCH_CASE_STUDIES:
      newState['isFetchingCaseStudies'] = false;
      newState['caseStudies'] = action.payload.results;
      newState['caseStudyPageCount'] = Math.ceil(
        action.payload.count / PAGE_SIZE
      );
      break;

    case FETCH_FEATURED_CASE_STUDIES:
      newState['isFetchingFeaturedCaseStudies'] = true;
      break;

    case SUCCESS_FETCH_FEATURED_CASE_STUDIES:
      newState['featuredCaseStudies'] = action.payload.data;
      newState['isFetchingFeaturedCaseStudies'] = false;
      break;

    default:
      break;
  }

  return newState;
};

function* fetchCaseStudiesWorker(action) {
  const { page, filters } = action.payload;

  const response = yield makeRequest(api.caseStudiesList, {
    requestData: {
      params: {
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        ...buildFilters(filters)
      }
    }
  });

  if (response.success) {
    yield put(successFetchCaseStudies(response));
  }
}

function* fetchFeaturedCaseStudiesWorker(action) {
  const response = yield makeRequest(api.caseStudiesFeaturedList);

  if (response.success) {
    yield put(successFetchFeaturedCaseStudies(response));
  }
}

export function* caseStudiesSaga() {
  yield takeLatest(FETCH_CASE_STUDIES, fetchCaseStudiesWorker);
  yield takeLatest(FETCH_FEATURED_CASE_STUDIES, fetchFeaturedCaseStudiesWorker);
}
