import { combineReducers } from 'redux';

import {
  listServiceProvidersReducer,
  serviceProvidersSagas
} from './List/ducks';
import {
  editServiceProviderReducer,
  editServiceProviderSagas
} from './Edit/ducks';
import {
  detailServiceProvidersReducer,
  serviceProviderSagas as detailServiceProviderSagas
} from './Detail/ducks';
import {
  confirmServiceProviderClaimRequestReducer,
  confirmServiceProviderClaimRequestSaga
} from './ClaimRequest/Confirm/ducks';
import {
  acceptServiceProviderInvitationReducer,
  acceptServiceProviderInvitationSaga
} from './AcceptInvitation/ducks';

import { createServiceProviderSaga } from './Create/ducks';

export const serviceProviderReducer = combineReducers({
  edit: editServiceProviderReducer,
  list: listServiceProvidersReducer,
  detail: detailServiceProvidersReducer,
  acceptInvitation: acceptServiceProviderInvitationReducer,
  claimRequest: combineReducers({
    confirm: confirmServiceProviderClaimRequestReducer
  })
});

export const serviceProviderSagas = [
  ...serviceProvidersSagas,
  createServiceProviderSaga,
  ...editServiceProviderSagas,
  ...detailServiceProviderSagas,
  acceptServiceProviderInvitationSaga,
  confirmServiceProviderClaimRequestSaga
];
