import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'SendNotification/';

const REQUEST_SEND_NOTIFICATION = `${actionPrefix}/REQUEST_SEND_NOTIFICATION`;
const SUCCESS_SEND_NOTIFICATION = `${actionPrefix}/SUCCESS_SEND_NOTIFICATION`;
const FAIL_SEND_NOTIFICATION = `${actionPrefix}/FAIL_SEND_NOTIFICATION`;

export const requestSendNotification = (formName, payload, extra) => ({
  type: REQUEST_SEND_NOTIFICATION,
  formName,
  payload,

  extra
});

export const successSendNotification = payload => ({
  type: SUCCESS_SEND_NOTIFICATION,
  payload
});

export const failSendNotification = payload => ({
  type: FAIL_SEND_NOTIFICATION,
  payload
});

function* sendNotificationWorker(action) {
  const response = yield makeRequest(api.sendNotification, {
    requestBody: action.payload
  });

  if (response.success)
    yield* [
      put(successSendNotification(response.data)),
      ...action.extra.map(action => put(action))
    ];
  else yield put(failSendNotification);

  return response.errors;
}

export function* notificationWatcher() {
  yield takeLatest(
    REQUEST_SEND_NOTIFICATION,
    formSubmitWorker(sendNotificationWorker)
  );
}
