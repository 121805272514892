import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404, makeRequest } from 'shared/sdk';

import { getFilterValues } from './utils';

const actionPrefix = 'Company/PredefinedSearch/';

const RESET_STATE = `${actionPrefix}RESET_STATE`;

const FETCH_PREDEFINED_SEARCH = `${actionPrefix}FETCH_PREDEFINED_SEARCH`;
const SUCCESS_FETCH_PREDEFINED_SEARCH = `${actionPrefix}SUCCESS_FETCH_PREDEFINED_SEARCH`;

const FETCH_COMPANIES = `${actionPrefix}FETCH_COMPANIES`;
const SUCCESS_FETCH_COMPANIES = `${actionPrefix}SUCCESS_FETCH_COMPANIES`;

const FETCH_FEATURED_COMPANIES = `${actionPrefix}FETCH_FEATURED_COMPANIES`;
const SUCCESS_FETCH_FEATURED_COMPANIES = `${actionPrefix}SUCCESS_FETCH_FEATURED_COMPANIES`;

const SET_FILTER_VALUES = `${actionPrefix}SET_FILTER_VALUES`;

export const resetState = () => ({
  type: RESET_STATE
});

export const fetchPredefinedSearch = ({ predefinedSearchSlug }) => ({
  type: FETCH_PREDEFINED_SEARCH,
  payload: { predefinedSearchSlug }
});

const successFetchPredefinedSearch = ({ data }) => ({
  type: SUCCESS_FETCH_PREDEFINED_SEARCH,
  payload: { data }
});

export const fetchCompanies = ({ filters, nextUrl }) => ({
  type: FETCH_COMPANIES,
  payload: { filters, nextUrl }
});

const successFetchCompanies = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_COMPANIES,
  payload: { results, next }
});

const fetchFeaturedCompanies = ({ filters }) => ({
  type: FETCH_FEATURED_COMPANIES,
  payload: { filters }
});

const successFetchFeaturedCompanies = ({ data }) => ({
  type: SUCCESS_FETCH_FEATURED_COMPANIES,
  payload: { data }
});

const setFilterValues = ({ filters }) => ({
  type: SET_FILTER_VALUES,
  payload: filters
});

const initialState = {
  isFetchingPredefinedSearch: false,
  sector: null,
  solution: null,
  country: null,

  isFetchingCompanies: true,
  companies: [],
  companiesNextUrl: null,

  isFetchingFeaturedCompanies: true,
  featuredCompanies: [],

  filterValues: {}
};

export const companyPredefinedSearchReducer = (
  state = initialState,
  action
) => {
  let newState = Object.assign({}, state);

  switch (action.type) {
    case RESET_STATE:
      newState = initialState;
      break;

    case FETCH_PREDEFINED_SEARCH:
      newState['isFetchingPredefinedSearch'] = true;
      break;

    case SUCCESS_FETCH_PREDEFINED_SEARCH:
      newState = {
        ...newState,
        ...action.payload.data,
        isFetchingPredefinedSearch: false
      };
      break;

    case SUCCESS_FETCH_COMPANIES:
      newState['isFetchingCompanies'] = false;
      newState['companies'] = [
        ...newState.companies,
        ...action.payload.results
      ];
      newState['companiesNextUrl'] = action.payload.next;
      break;

    case SUCCESS_FETCH_FEATURED_COMPANIES:
      newState['isFetchingFeaturedCompanies'] = false;
      newState['featuredCompanies'] = action.payload.data;
      break;

    case SET_FILTER_VALUES:
      newState['filterValues'] = action.payload;
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchPredefinedSearchWorker(action) {
  const { predefinedSearchSlug } = action.payload;

  const response = yield getResponseOr404(api.predefinedSearchDetail, {
    lookupData: { predefinedSearchSlug }
  });

  if (response.success) {
    const filters = getFilterValues(response.data);

    yield* [
      put(setFilterValues({ filters })),
      put(successFetchPredefinedSearch(response)),
      put(fetchCompanies({ filters })),
      put(fetchFeaturedCompanies({ filters }))
    ];
  }
}

function* fetchCompaniesWorker(action) {
  const { filters, nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.companiesList, {
      requestData: { params: { ...filters, limit: 10 } }
    });
  } else {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  }

  if (response.success) {
    yield put(successFetchCompanies(response));
  }
}

function* fetchFeaturedCompaniesWorker(action) {
  const { filters } = action.payload;

  const response = yield makeRequest(api.featuredCompaniesList, {
    requestData: { params: { ...filters, limit: 2 } }
  });

  if (response.success) {
    yield put(successFetchFeaturedCompanies(response));
  }
}

export function* companyPredefinedSearchSaga() {
  yield takeLatest(FETCH_PREDEFINED_SEARCH, fetchPredefinedSearchWorker);
  yield takeLatest(FETCH_COMPANIES, fetchCompaniesWorker);
  yield takeLatest(FETCH_FEATURED_COMPANIES, fetchFeaturedCompaniesWorker);
}
