import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'TenderResponse/Team/';

const FETCH_TENDER_RESPONSE_TEAM = `${actionPrefix}FETCH_TENDER_RESPONSE_TEAM`;
const SUCCESS_FETCH_TENDER_RESPONSE_TEAM = `${actionPrefix}SUCCESS_FETCH_TENDER_RESPONSE_TEAM`;

export const fetchTenderResponseTeam = ({ tenderResponseId, nextUrl }) => ({
  type: FETCH_TENDER_RESPONSE_TEAM,
  payload: { tenderResponseId, nextUrl }
});

const successFetchTenderResponseTeam = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_TENDER_RESPONSE_TEAM,
  payload: { results, next }
});

export const teamReducer = (state, action) => {
  switch (action.type) {
    case FETCH_TENDER_RESPONSE_TEAM:
      state['team']['isFetching'] = true;
      break;

    case SUCCESS_FETCH_TENDER_RESPONSE_TEAM:
      state['team']['isFetching'] = false;
      state['team']['nextUrl'] = action.payload.next;
      state['team']['members'] = addToList(
        state.team.members,
        action.payload.results
      );
      break;

    default:
      break;
  }
};

function* fetchTenderResponseTeamWorker(action) {
  const { nextUrl, tenderResponseId } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.tenderResponseTeamList, {
      lookupData: { tenderResponseId },
      requestData: { params: { limit: 10 } }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  }

  if (response.success) {
    yield put(successFetchTenderResponseTeam(response));
  }
}

export function* teamSaga() {
  yield takeLatest(FETCH_TENDER_RESPONSE_TEAM, fetchTenderResponseTeamWorker);
}
