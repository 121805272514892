import { put, takeEvery } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'filters/';
const FETCH_SOLUTIONS = `${actionPrefix}FETCH_SOLUTIONS`;
const SUCCESS_FETCH_SOLUTIONS = `${actionPrefix}SUCCESS_FETCH_SOLUTIONS`;
const FAIL_FETCH_SOLUTIONS = `${actionPrefix}FAIL_FETCH_SOLUTIONS`;

export const fetchSolutions = (filterName, solutionType) => ({
  type: FETCH_SOLUTIONS,
  payload: {
    filterName,
    solutionType
  }
});

const successFetchSolutions = (filterName, data) => ({
  type: SUCCESS_FETCH_SOLUTIONS,
  payload: data,
  filterName
});

const failFetchSolutions = payload => ({
  type: FAIL_FETCH_SOLUTIONS,
  payload
});

export const solutionsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SOLUTIONS:
      state.filterChoices[action.filterName] = action.payload;
      break;
    default:
      return state;
  }
  return state;
};

function* fetchSolutionsWorker(action) {
  const { filterName, solutionType } = action.payload;

  const filter = { type: solutionType };

  const response = yield makeRequest(api.solutionsListAll, {
    requestData: { params: filter }
  });

  if (response.success)
    yield put(successFetchSolutions(filterName, response.data));
  else yield put(failFetchSolutions(response.errors));
}

export function* solutionsWatcher() {
  yield takeEvery(FETCH_SOLUTIONS, fetchSolutionsWorker);
}
