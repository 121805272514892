import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'VendorCount/';

export const FETCH_VENDOR_COUNT = `${actionPrefix}FETCH_VENDOR_COUNT`;
export const FAIL_FETCH_VENDOR_COUNT = `${actionPrefix}FAIL_FETCH_VENDOR_COUNT`;
export const SUCCESS_FETCH_VENDOR_COUNT = `${actionPrefix}SUCCESS_FETCH_VENDOR_COUNT`;

export const fetchVendorCount = (extraActions = []) => {
  return {
    type: FETCH_VENDOR_COUNT,
    extraActions
  };
};

export const successFetchVendorCount = data => {
  return {
    type: SUCCESS_FETCH_VENDOR_COUNT,
    data
  };
};

export const failFetchVendorCount = error => {
  return {
    type: FAIL_FETCH_VENDOR_COUNT,
    error
  };
};

const initialState = {
  vendorCount: 6800
};

export const fetchVendorCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_VENDOR_COUNT:
      return {
        vendorCount: action.data.count
      };

    default:
      return state;
  }
};

function* vendorCountWorker(action) {
  const params = { limit: 1 };

  const response = yield makeRequest(api.companiesList, {
    requestData: { params }
  });

  if (response.success) {
    yield put(successFetchVendorCount(response.data));
  } else yield put(failFetchVendorCount(response.errors));
}

export function* vendorCountSagas() {
  yield takeLatest(FETCH_VENDOR_COUNT, vendorCountWorker);
}
