import { detailReducer, detailSaga } from './detail';
import { commentRepliesReducer, commentRepliesSaga } from './commentReplies';
import { commentsReducer, commentsSaga } from './comments';
import { sponsorsReducer, sponsorsSaga } from './sponsors';

const actionPrefix = 'ConnectVideo/Detail/';

const DELETE_STATE = `${actionPrefix}DELETE_STATE`;

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  CTAs: [],
  connectVideo: {},
  isFetching: true,
  isFetchingComments: false,
  comments: [],
  commentsNextUrl: null,
  sponsors: {},
  isFetchingSponsors: false,
  isFetchingCTAs: false
};

export const connectVideoDetailReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) {
    return initialState;
  }

  detailReducer(newState, action);
  commentRepliesReducer(newState, action);
  commentsReducer(newState, action);
  sponsorsReducer(newState, action);

  return newState;
};

export const connectVideoDetailSagas = [
  detailSaga,
  commentRepliesSaga,
  commentsSaga,
  sponsorsSaga
];

export * from './detail';
export * from './comments';
export * from './commentReplies';
export * from './sponsors';
