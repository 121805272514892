import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';
import { buildFiltersFromObj } from 'shared/utils/filters';

const actionPrefix = 'filters/';
const FETCH_COUNTRIES = `${actionPrefix}FETCH_COUNTRIES`;
const SUCCESS_FETCH_COUNTRIES = `${actionPrefix}SUCCESS_FETCH_COUNTRIES`;
const FAIL_FETCH_COUNTRIES = `${actionPrefix}FAIL_FETCH_COUNTRIES`;

export const fetchCountries = filters => ({
  type: FETCH_COUNTRIES,
  payload: { filters }
});

export const successFetchCountries = countries => ({
  type: SUCCESS_FETCH_COUNTRIES,
  payload: {
    countries
  }
});

export const failFetchCountries = errors => ({
  type: FAIL_FETCH_COUNTRIES,
  payload: {
    errors
  }
});

export const countriesReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COUNTRIES:
      newState.filterChoices['countries'] = action.payload.countries;
      break;
    case FAIL_FETCH_COUNTRIES:
      newState['errors'] = action.payload.errors;
      break;

    default:
      return newState;
  }
};

function* fetchCountriesWorker(action) {
  const filters = buildFiltersFromObj(action.payload.filters);

  const response = yield makeRequest(api.countriesList, {
    requestData: {
      params: {
        limit: 500,
        offset: 0,
        ...filters
      }
    }
  });

  if (response.success) yield put(successFetchCountries(response.data.results));
  else yield put(failFetchCountries(response.errors));
}

export function* countriesWatcher() {
  yield takeLatest(FETCH_COUNTRIES, fetchCountriesWorker);
}
