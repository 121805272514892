import { combineReducers } from 'redux';

import { editTenderReducer, editTenderSaga } from './Edit/ducks';
import {
  tenderReducer as tenderDetailReducer,
  tenderSaga as tenderDetailSaga
} from './Detail/ducks';
import { tendersSaga, tendersReducer } from './List/ducks';
import { createTenderSaga, createTenderReducer } from './Create/ducks';

import { createTenderResponseSaga } from './CreateResponse/ducks';
import {
  closedTenderReducer,
  closedTenderSaga
} from './ClosedTenderDetail/ducks';

export const tenderReducer = combineReducers({
  edit: editTenderReducer,
  detail: tenderDetailReducer,
  list: tendersReducer,
  create: createTenderReducer,
  closed: closedTenderReducer
});

export const tenderSagas = [
  editTenderSaga,
  tenderDetailSaga,
  createTenderResponseSaga,
  closedTenderSaga,
  ...tendersSaga,
  ...createTenderSaga
];
