import _ from 'lodash';
import { put, takeLatest, select } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { getInfluencerOrderingByMostInfluentialInThePastWeek } from './selectors';

const actionPrefix = 'Home/Influencers/';

const FETCH_INFLUENCERS = `${actionPrefix}FETCH_INFLUENCERS`;
const SUCCESS_FETCH_INFLUENCERS = `${actionPrefix}SUCCESS_FETCH_INFLUENCERS`;

export const fetchInfluencers = () => ({
  type: FETCH_INFLUENCERS
});

const successFetchInfluencers = ({ data: { results } }) => ({
  type: SUCCESS_FETCH_INFLUENCERS,
  payload: { results }
});

export const influencersReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INFLUENCERS:
      state['fetchingInfluencers'] = true;
      break;

    case SUCCESS_FETCH_INFLUENCERS:
      state['fetchingInfluencers'] = false;
      state['influencers'] = action.payload.results;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchInfluencersWorker(action) {
  const ordering = yield select(
    getInfluencerOrderingByMostInfluentialInThePastWeek
  );

  const params = { limit: 3, ordering: _.get(ordering, 'value') };

  const response = yield makeRequest(api.influencersList, {
    requestData: { params }
  });

  if (response.success) {
    yield put(successFetchInfluencers(response));
  }
}

export function* influencersSaga() {
  yield takeLatest(FETCH_INFLUENCERS, fetchInfluencersWorker);
}
