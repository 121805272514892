import _ from 'lodash';

const isEmptyFilterValue = value => {
  if (_.isArray(value)) {
    return _.isEmpty(value);
  }

  return _.isNil(value) || value === '';
};

const getFilterValue = value => {
  if (_.isArray(value)) {
    return value.join(',');
  }

  return value;
};

export const buildFilters = filters => {
  const params = {};
  if (_.isEmpty(filters)) {
    return params;
  }

  _.forEach(filters, filter => {
    if (!isEmptyFilterValue(filter.value)) {
      params[filter.name] = getFilterValue(filter.value);
    }
  });

  return params;
};

export const buildFiltersFromObj = filters => {
  const params = {};
  if (_.isEmpty(filters)) {
    return params;
  }

  Object.keys(filters).forEach(key => {
    const value = filters[key].value;
    if (!isEmptyFilterValue(value)) {
      params[key] = getFilterValue(value);
    }
  });
  return params;
};
