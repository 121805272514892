import { normalizeCompanyContactFields } from 'shared/utils/company';
import {
  normalizeRenderedSelect as normalize,
  normalizeRenderedSelectValues as normalizeValues
} from 'shared/forms/RenderSelect';

export const getCompanyData = state => {
  let {
    companyHeadlinesData,
    companyLocationData,
    companyContactData
  } = state.pages.company.create;

  const headlinesData = {
    ...companyHeadlinesData,
    industry: normalize(companyHeadlinesData.industry, false),
    ownership: normalize(companyHeadlinesData.ownership, false),
    year_founded: normalize(companyHeadlinesData.year_founded, false),
    employees: normalize(companyHeadlinesData.employees, false)
  };

  const locationData = {
    ...companyLocationData,
    billing_city: normalize(companyLocationData.billing_city, false),
    billing_country: normalize(companyLocationData.billing_country, false),
    operating_markets: normalize(companyHeadlinesData.operating_markets, true)
  };

  companyContactData = normalizeCompanyContactFields(companyContactData);

  return {
    ...headlinesData,
    ...locationData,
    ...companyContactData
  };
};

export const getProductData = state => {
  const { companyProductsData } = state.pages.company.create;

  const productsData = companyProductsData.map(item => ({
    ...item,
    ...normalizeValues(item, [
      'sectors',
      'solutions',
      'available_countries',
      'business_type',
      'product_stage',
      'property_lifecycle_stage',
      'user_count'
    ])
  }));

  return productsData;
};
