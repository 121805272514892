import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { buildFilters } from 'shared/utils/filters';
import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'ServiceProviders/List/';
export const FETCH_SERVICE_PROVIDERS = `${actionPrefix}FETCH_SERVICE_PROVIDERS`;
export const SUCCESS_FETCH_SERVICE_PROVIDERS = `${actionPrefix}SUCCESS_FETCH_SERVICE_PROVIDERS`;
export const FAIL_FETCH_SERVICE_PROVIDERS = `${actionPrefix}FAIL_FETCH_SERVICE_PROVIDERS`;

export const SUCCESS_FETCH_NEXT_SERVICE_PROVIDERS = `${actionPrefix}SUCCESS_FETCH_NEXT_SERVICE_PROVIDERS`;
export const FETCH_NEXT_SERVICE_PROVIDERS = `${actionPrefix}FETCH_NEXT_SERVICE_PROVIDERS`;

export const fetchServiceProviders = (filters = []) => ({
  type: FETCH_SERVICE_PROVIDERS,
  payload: {
    filters
  }
});

export const successFetchServiceProviders = payload => ({
  type: SUCCESS_FETCH_SERVICE_PROVIDERS,
  payload
});

export const failFetchServiceProviders = payload => ({
  type: FAIL_FETCH_SERVICE_PROVIDERS,
  payload
});

export const fetchNextServiceProviders = url => ({
  type: FETCH_NEXT_SERVICE_PROVIDERS,
  url
});

export const successFetchNextServiceProviders = payload => ({
  type: SUCCESS_FETCH_NEXT_SERVICE_PROVIDERS,
  payload
});

export const serviceProvidersReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_NEXT_SERVICE_PROVIDERS:
      state['serviceProviders'] = addToList(
        state.serviceProviders,
        action.payload.results
      );
      state['nextUrl'] = action.payload.next;
      state['isFetching'] = false;
      break;
    case FETCH_SERVICE_PROVIDERS:
    case FETCH_NEXT_SERVICE_PROVIDERS:
      state['isFetching'] = true;
      break;
    case SUCCESS_FETCH_SERVICE_PROVIDERS:
      state['serviceProviders'] = action.payload.results;
      state['serviceProviderCount'] = action.payload.count;
      state['nextUrl'] = action.payload.next;
      state['isFetching'] = false;
      break;

    default:
      return state;
  }
};

function* fetchServiceProvidersWorker(action) {
  const params = buildFilters(action.payload.filters);

  const response = yield makeRequest(api.serviceProvidersList, {
    requestData: { params }
  });

  if (response.success) yield put(successFetchServiceProviders(response.data));
  else yield put(failFetchServiceProviders(response.errors));
}

function* fetchNextServiceProvidersWorker(action) {
  const response = yield makeRequest(api.genericGet, {
    lookupData: action.url
  });

  if (response.success)
    yield put(successFetchNextServiceProviders(response.data));
  else yield put(failFetchServiceProviders(response.errors));
}

export function* serviceProvidersWatcher() {
  yield takeLatest(FETCH_SERVICE_PROVIDERS, fetchServiceProvidersWorker);
  yield takeLatest(
    FETCH_NEXT_SERVICE_PROVIDERS,
    fetchNextServiceProvidersWorker
  );
}
