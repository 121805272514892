import { getResourceReducer, getResourceSaga } from './getResource';
import { commentsReducer, commentsSaga } from './comments';

const DELETE_STATE = 'Resource/Detail/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  resource: {},
  nextUrl: null,
  comments: [],
  commentsNextUrl: null,
  isFetchingComments: false
};

export const resourceDetailReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  getResourceReducer(newState, action);
  commentsReducer(newState, action);

  return newState;
};

export const resourceDetailSagas = [getResourceSaga, commentsSaga];

export * from './getResource';
export * from './comments';
