import { PROGRESS_TABS, progressBarReducer } from './progressBar';
import { headlinesReducer, headlinesWatcher } from './headlines';
import { productsReducer } from './products';
import { locationReducer, locationWatcher } from './location';
import { contactReducer, contactWatcher } from './contact';

const DELETE_STATE = 'CreateCompany/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  progressTabs: [
    PROGRESS_TABS.HEADLINES,
    PROGRESS_TABS.PRODUCTS,
    PROGRESS_TABS.LOCATION,
    PROGRESS_TABS.CONTACT
  ],
  activeProgressTab: PROGRESS_TABS.HEADLINES.numeration,
  vendorImages: [],
  sectorChoices: [],
  solutionChoices: [],
  countryChoices: [],
  companyHeadlinesData: {},
  companyProductsData: [],
  companyLocationData: {},
  companyContactData: {},
  editingProductIndex: null
};

export const createCompanyReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === DELETE_STATE) newState = initialState;

  progressBarReducer(newState, action);
  headlinesReducer(newState, action);
  productsReducer(newState, action);
  locationReducer(newState, action);
  contactReducer(newState, action);

  return newState;
};

export const createCompanySaga = [
  headlinesWatcher,
  contactWatcher,
  locationWatcher
];

export * from './progressBar';
export * from './headlines';
export * from './products';
export * from './location';
export * from './contact';
