import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'CityField/';

const FETCH_CITIES = `${actionPrefix}FETCH_CITIES`;
const SUCCESS_FETCH_CITIES = `${actionPrefix}SUCCESS_FETCH_CITIES`;

export const fetchCities = ({ name = '' } = {}) => ({
  type: FETCH_CITIES,
  payload: { name }
});

const successFetchCities = ({ data: { results } }) => ({
  type: SUCCESS_FETCH_CITIES,
  payload: { results }
});

const initialState = {
  options: []
};

export const cityFieldReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case SUCCESS_FETCH_CITIES:
      newState['options'] = action.payload.results.map(({ id, name }) => ({
        value: id,
        label: name
      }));
      break;

    default:
      return newState;
  }

  return newState;
};

function* fetchCitiesWorker(action) {
  const { name } = action.payload;

  const response = yield makeRequest(api.cityList, {
    requestData: {
      params: {
        limit: 100,
        offset: 0,
        name
      }
    }
  });

  if (response.success) {
    yield put(successFetchCities(response));
  }
}

export function* cityFieldSaga() {
  yield takeLatest(FETCH_CITIES, fetchCitiesWorker);
}
