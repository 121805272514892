import React from 'react';
import classnames from 'classnames';
import preloader_image from 'assets/images/spinner_logo.svg';
import styles from './styles.module.scss';

const Loader = props => {
  return (
    <div className={classnames(styles.generalLoaderWrapper, props.className)}>
      <div className={classnames(styles.loader)} />
      <img src={preloader_image} alt="loader" className={styles.loaderImage} />
    </div>
  );
};

export default Loader;
