import _ from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

import {
  multipleFileUploadWorkerFactory,
  asGenericUploadHook
} from 'shared/utils/fileUpload';
import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';

import { getCreateOrUpdateNoteActionPayload } from './selectors';

const actionPrefix = 'TenderResponse/Notes/';

const FETCH_NOTES = `${actionPrefix}FETCH_NOTES`;
const SUCCESS_FETCH_NOTES = `${actionPrefix}SUCCESS_FETCH_NOTES`;

const SUCCESS_CREATE_NOTE = `${actionPrefix}SUCCESS_CREATE_NOTE`;
const FAIL_CREATE_NOTE = `${actionPrefix}FAIL_CREATE_NOTE`;

const SUCCESS_EDIT_NOTE = `${actionPrefix}SUCCESS_EDIT_NOTE`;
const FAIL_EDIT_NOTE = `${actionPrefix}FAIL_EDIT_NOTE`;

const BEGIN_NOTE_SUBMISSION = `${actionPrefix}BEGIN_NOTE_SUBMISSION`;
const SUCCESS_BEGIN_NOTE_SUBMISSION = `${actionPrefix}SUCCESS_BEGIN_NOTE_SUBMISSION`;
const FAIL_BEGIN_NOTE_SUBMISSION = `${actionPrefix}FAIL_BEGIN_NOTE_SUBMISSION`;

export const beginNoteSubmission = ({
  tenderResponseId,
  tenderResponseNoteId,
  content,
  extraActions,
  files,
  oldFiles //  we need those for the edit
}) => ({
  type: BEGIN_NOTE_SUBMISSION,
  payload: {
    tenderResponseId,
    tenderResponseNoteId,
    content,
    extraActions,
    files,
    oldFiles
  }
});

const successBeginNoteSubmission = payload => ({
  type: SUCCESS_BEGIN_NOTE_SUBMISSION,
  payload
});

const failBeginNoteSubmission = errors => ({
  type: FAIL_BEGIN_NOTE_SUBMISSION,
  payload: { errors }
});

export const fetchNotes = ({ tenderResponseId, nextUrl }) => ({
  type: FETCH_NOTES,
  payload: { tenderResponseId, nextUrl }
});

const successFetchNotes = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_NOTES,
  payload: { results, next }
});

const successCreateNote = ({ data }) => ({
  type: SUCCESS_CREATE_NOTE,
  payload: { data }
});

const successEditNote = ({ data }) => ({
  type: SUCCESS_EDIT_NOTE,
  payload: { data }
});

export const notesReducer = (state, action) => {
  switch (action.type) {
    case BEGIN_NOTE_SUBMISSION:
      state['notes']['isModifyingNote'] = true;
      state['notes']['createOrUpdateActionPayload'] = action.payload;
      break;

    case SUCCESS_BEGIN_NOTE_SUBMISSION:
      state['notes']['createOrUpdateActionPayload']['files'] = _.map(
        _.get(state, 'notes.createOrUpdateActionPayload.files', []),
        ({ name }, index) => ({
          name,
          path: action.payload.files[index]
        })
      );
      break;

    case FAIL_BEGIN_NOTE_SUBMISSION:
      state['notes']['isModifyingNote'] = false;
      state['notes']['errors'] = action.payload.errors;
      break;

    case FAIL_CREATE_NOTE:
    case FAIL_EDIT_NOTE:
      state['notes']['createOrUpdateActionPayload'] = {};
      state['notes']['isModifyingNote'] = false;
      break;

    case SUCCESS_CREATE_NOTE:
      state['notes']['createOrUpdateActionPayload'] = {};
      state['notes']['isModifyingNote'] = false;
      state['notes']['notes'] = addToList(
        [action.payload.data],
        state['notes']['notes']
      );
      break;

    case SUCCESS_EDIT_NOTE:
      state['notes']['createOrUpdateActionPayload'] = {};
      state['notes']['isModifyingNote'] = false;
      state['notes']['notes'] = updateByIdentifier({
        collection: state['notes']['notes'],
        identifierField: 'id',
        identifierValue: action.payload.data.id,
        newItem: action.payload.data
      });

      break;

    case FETCH_NOTES:
      state['notes']['isFetching'] = true;
      break;

    case SUCCESS_FETCH_NOTES:
      state['notes']['nextUrl'] = action.payload.next;
      state['notes']['notes'] = addToList(
        state['notes']['notes'],
        action.payload.results
      );
      state['notes']['isFetching'] = false;
      break;

    default:
      break;
  }
};

function* fetchNotesWorker(action) {
  const { tenderResponseId, nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.fetchTenderResponseNotes, {
      lookupData: { tenderResponseId }
    });
  } else {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  }

  if (response.success) {
    yield put(successFetchNotes(response));
  }
}

function* createOrUpdateNoteWorker(action) {
  const {
    tenderResponseId,
    tenderResponseNoteId,
    content,
    extraActions,
    files = [],
    oldFiles = []
  } = yield select(getCreateOrUpdateNoteActionPayload);

  // here we combine the old files we haven't deleted and the new files
  const attachments = [...files, ...oldFiles];

  if (_.isNil(tenderResponseNoteId)) {
    // if a note id is not present - we're creating a note
    const response = yield makeRequest(api.createTenderResponseNote, {
      lookupData: { tenderResponseId },
      requestBody: { content, attachments }
    });

    if (response.success) {
      yield put(successCreateNote(response));
    } else {
      yield put(
        openNotificationBar(
          'Something went wrong. Please try again later.',
          null,
          true
        )
      );
    }
  } else {
    // otherwise we're updating an old one
    const response = yield makeRequest(api.editTenderResponseNote, {
      lookupData: { tenderResponseNoteId },
      requestBody: { content, attachments }
    });

    if (response.success) {
      yield put(successEditNote(response));
    } else {
      yield put(
        openNotificationBar(
          'Something went wrong. Please try again later.',
          null,
          true
        )
      );
    }
  }

  extraActions.map(extraAction => extraAction());
}

export function* notesSaga() {
  yield takeLatest(FETCH_NOTES, fetchNotesWorker);
  yield takeLatest(
    BEGIN_NOTE_SUBMISSION,
    multipleFileUploadWorkerFactory(
      api.signTenderResponseNoteFile,
      asGenericUploadHook(successBeginNoteSubmission),
      asGenericUploadHook(failBeginNoteSubmission),
      true
    )
  );
  yield takeLatest(SUCCESS_BEGIN_NOTE_SUBMISSION, createOrUpdateNoteWorker);
}
