import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { api, makeRequest } from 'shared/sdk';
import { INVESTOR_DETAIL_URL } from 'global-constants';
import { substituteUrl } from 'shared/utils/substituteUrl';

const actionPrefix = 'InvestorConfirmClaimRequest/';

const REQUEST_CONFIRM_CLAIM_REQUEST = `${actionPrefix}REQUEST_CONFIRM_CLAIM_REQUEST`;
const SUCCESS_CONFIRM_CLAIM_REQUEST = `${actionPrefix}SUCCESS_CONFIRM_CLAIM_REQUEST`;
const FAIL_CONFIRM_CLAIM_REQUEST = `${actionPrefix}FAIL_CONFIRM_CLAIM_REQUEST`;

export const requestConfirmClaimRequest = claimRequestId => ({
  type: REQUEST_CONFIRM_CLAIM_REQUEST,
  payload: { claimRequestId }
});

const successConfirmClaimRequest = data => ({
  type: SUCCESS_CONFIRM_CLAIM_REQUEST,
  payload: data
});

const failConfirmClaimRequest = errors => ({
  type: FAIL_CONFIRM_CLAIM_REQUEST,
  payload: errors
});

const initialState = {
  loading: true,
  errors: []
};

export const investorConfirmClaimRequestReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FAIL_CONFIRM_CLAIM_REQUEST:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    default:
      return state;
  }
};

function* requestConfirmClaimRequestWorker(action) {
  const response = yield makeRequest(api.confirmInvestorClaimRequest, {
    lookupData: { claimRequestIdentifier: action.payload.claimRequestId }
  });

  if (response.success) {
    yield* [
      put(successConfirmClaimRequest(response.data)),
      put(
        push(
          substituteUrl(INVESTOR_DETAIL_URL, {
            investorSlug: response.data.investor_slug
          })
        )
      )
    ];
  } else yield put(failConfirmClaimRequest(response.errors));
}

export function* investorConfirmClaimRequestSaga() {
  yield takeLatest(
    REQUEST_CONFIRM_CLAIM_REQUEST,
    requestConfirmClaimRequestWorker
  );
}
