import _ from 'lodash';
import { teamReducer, teamSaga } from './team';
import { detailReducer, detailSaga } from './detail';
import { applyReducer, applySaga } from './apply';

const actionPrefix = 'Job/Detail/';

const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const resetState = () => ({ type: RESET_STATE });

const initialState = {
  team: {
    preview: [],
    isFetchingPreview: false,
    team: [],
    isFetchingTeam: false,
    hasMoreThanPreview: false,
    teamNextUrl: null
  },
  job: null,
  isFetchingJob: true,
  apply: {
    files: [],
    jobSlug: null,
    isApplying: false,
    modal: {
      closeModal: () => {},
      errors: []
    }
  }
};

export const jobDetailReducer = (state = _.cloneDeep(initialState), action) => {
  let newState = _.cloneDeep(state);

  if (action.type === RESET_STATE) {
    newState = _.cloneDeep(initialState);
  }

  applyReducer(newState, action);
  teamReducer(newState, action);
  detailReducer(newState, action);

  return newState;
};

export const jobDetailSaga = [teamSaga, detailSaga, applySaga];

export * from './team';
export * from './detail';
export * from './apply';
