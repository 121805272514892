import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'ConnectVideo/Detail/Sponsors/';

const FETCH_SPONSORS = `${actionPrefix}FETCH_SPONSORS`;
const SUCCESS_FETCH_SPONSORS = `${actionPrefix}SUCCESS_FETCH_SPONSORS`;
const FAIL_FETCH_SPONSORS = `${actionPrefix}FAIL_FETCH_SPONSORS`;

export const fetchSponsors = ({ connectVideoSlug }) => ({
  type: FETCH_SPONSORS,
  payload: { connectVideoSlug }
});

const successFetchSponsors = ({ data }) => ({
  type: SUCCESS_FETCH_SPONSORS,
  payload: { data }
});

const failFetchSponsors = ({ errors }) => ({
  type: FAIL_FETCH_SPONSORS,
  payload: { errors }
});

export const sponsorsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_SPONSORS:
      state['isFetchingSponsors'] = true;
      break;

    case SUCCESS_FETCH_SPONSORS:
      state['isFetchingSponsors'] = false;
      state['sponsors'] = action.payload.data;
      break;

    default:
      return state;
  }
};

function* fetchSponsorsWorker(action) {
  const { connectVideoSlug } = action.payload;

  const response = yield getResponseOr404(api.connectVideoSponsorsList, {
    lookupData: { connectVideoSlug }
  });

  if (response.success) {
    yield put(successFetchSponsors(response));
  } else {
    yield put(failFetchSponsors(response));
  }
}

export function* sponsorsSaga() {
  yield takeLatest(FETCH_SPONSORS, fetchSponsorsWorker);
}
