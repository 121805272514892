import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { addAttendeeUtil, removeAttendeeUtil } from './utils';

const actionPrefix = 'Attendees/';
export const FETCH_ATTENDEES = `${actionPrefix}FETCH_ATTENDEES`;
export const SUCCESS_FETCH_ATTENDEES = `${actionPrefix}SUCCESS_FETCH_ATTENDEES`;
export const FAIL_FETCH_ATTENDEES = `${actionPrefix}FAIL_FETCH_ATTENDEES`;

export const FETCH_PREVIEW_ATTENDEES = `${actionPrefix}FETCH_PREVIEW_ATTENDEES`;
export const SUCCESS_FETCH_PREVIEW_ATTENDEES = `${actionPrefix}SUCCESS_FETCH_PREVIEW_ATTENDEES`;
export const FAIL_FETCH_PREVIEW_ATTENDEES = `${actionPrefix}FAIL_FETCH_PREVIEW_ATTENDEES`;

const ADD_ATTENDEE = `${actionPrefix}ADD_ATTENDEE`;
const SUCCESS_ADD_ATTENDEE = `${actionPrefix}SUCCESS_ADD_ATTENDEE`;
const FAIL_ADD_ATTENDEE = `${actionPrefix}FAIL_ADD_ATTENDEE`;

const REMOVE_ATTENDEE = `${actionPrefix}REMOVE_ATTENDEE`;
const SUCCESS_REMOVE_ATTENDEE = `${actionPrefix}SUCCESS_REMOVE_ATTENDEE`;
const FAIL_REMOVE_ATTENDEE = `${actionPrefix}FAIL_REMOVE_ATTENDEE`;

const DELETE_STATE = `${actionPrefix}DELETE_STATE`;

export const FETCH_SPEAKERS = `${actionPrefix}FETCH_SPEAKERS`;

export const fetchAttendees = (eventSlug, limit, offset) => ({
  type: FETCH_ATTENDEES,
  payload: {
    eventSlug,
    limit,
    offset
  }
});

export const fetchSpeakers = (eventSlug, limit, offset) => ({
  type: FETCH_SPEAKERS,
  payload: {
    eventSlug,
    limit,
    offset
  }
});

export const successFetchInfluencers = data => ({
  type: SUCCESS_FETCH_ATTENDEES,
  payload: {
    data
  }
});

export const failFetchInfluencers = errors => ({
  type: FAIL_FETCH_ATTENDEES,
  payload: {
    errors
  }
});

export const fetchPreviewAttendees = (eventSlug, limit, offset) => ({
  type: FETCH_PREVIEW_ATTENDEES,
  payload: {
    eventSlug,
    limit,
    offset
  }
});

export const successFetchPreviewAttendees = data => ({
  type: SUCCESS_FETCH_PREVIEW_ATTENDEES,
  payload: {
    data
  }
});

export const failFetchPreviewAttendees = errors => ({
  type: FAIL_FETCH_PREVIEW_ATTENDEES,
  payload: {
    errors
  }
});

export const addAttendee = eventSlug => ({
  type: ADD_ATTENDEE,
  payload: {
    eventSlug
  }
});

const successAddAttendee = data => ({
  type: SUCCESS_ADD_ATTENDEE,
  payload: {
    data
  }
});

const failAddAttendee = errors => ({
  type: FAIL_ADD_ATTENDEE,
  payload: {
    errors
  }
});

export const removeAttendee = eventSlug => ({
  type: REMOVE_ATTENDEE,
  payload: {
    eventSlug
  }
});

const successRemoveAttendee = data => ({
  type: SUCCESS_REMOVE_ATTENDEE,
  payload: {
    data
  }
});

const failRemoveAttendee = errors => ({
  type: FAIL_REMOVE_ATTENDEE,
  payload: {
    errors
  }
});

export const deleteModalState = () => ({
  type: DELETE_STATE
});

export const attendeeReducer = (state, action) => {
  switch (action.type) {
    case FETCH_ATTENDEES:
      state.performingFetchAttendeesOperation = true;
      break;
    case SUCCESS_FETCH_ATTENDEES:
      state['attendees'] = { ...action.payload.data };
      state.performingFetchAttendeesOperation = false;
      break;
    case SUCCESS_FETCH_PREVIEW_ATTENDEES:
      state['previewAttendees'] = { ...action.payload.data };
      break;
    case FAIL_FETCH_ATTENDEES:
    case FAIL_FETCH_PREVIEW_ATTENDEES:
      state['errors'] = { ...action.payload.errors };
      break;
    case ADD_ATTENDEE:
    case REMOVE_ATTENDEE:
      state.performingAddOrRemoveAttendeeOperation = true;
      break;
    case SUCCESS_ADD_ATTENDEE:
      state = addAttendeeUtil(state);
      break;
    case SUCCESS_REMOVE_ATTENDEE:
      state = removeAttendeeUtil(state);
      break;
    case DELETE_STATE:
      state.attendees = [];

      break;

    default:
      return state;
  }
};

export function* fetchAttendeesWorker(action) {
  const response = yield makeRequest(api.eventAttendees, {
    lookupData: { eventSlug: action.payload.eventSlug },
    requestData: {
      params: { limit: action.payload.limit, offset: action.payload.offset }
    }
  });

  if (response.success) yield put(successFetchInfluencers(response.data));
  else yield put(failFetchInfluencers(response.errors));
}

export function* fetchSpeakersWorker(action) {
  const response = yield makeRequest(api.eventSpeakers, {
    lookupData: { eventSlug: action.payload.eventSlug },
    requestData: {
      params: { limit: action.payload.limit, offset: action.payload.offset }
    }
  });

  if (response.success) {
    yield put(successFetchInfluencers(response.data));
  } else yield put(failFetchInfluencers(response.errors));
}

export function* fetchPreviewAttendeesWorker(action) {
  const response = yield makeRequest(api.eventAttendees, {
    lookupData: { eventSlug: action.payload.eventSlug },
    requestData: {
      params: { limit: action.payload.limit, offset: action.payload.offset }
    }
  });

  if (response.success) yield put(successFetchPreviewAttendees(response.data));
  else yield put(failFetchPreviewAttendees(response.errors));
}

export function* addAttendeeWorker(action) {
  const response = yield makeRequest(api.addAttendee, {
    lookupData: { eventSlug: action.payload.eventSlug }
  });

  if (response.success) yield put(successAddAttendee(response.data));
  else yield put(failAddAttendee(response.errors));
}

export function* removeAttendeeWorker(action) {
  const response = yield makeRequest(api.removeAttendee, {
    lookupData: { eventSlug: action.payload.eventSlug }
  });

  if (response.success) yield put(successRemoveAttendee(response.data));
  else yield put(failRemoveAttendee(response.errors));
}

export function* attendeeWatchers() {
  yield takeLatest(ADD_ATTENDEE, addAttendeeWorker);
  yield takeLatest(REMOVE_ATTENDEE, removeAttendeeWorker);
  yield takeLatest(FETCH_ATTENDEES, fetchAttendeesWorker);
  yield takeLatest(FETCH_PREVIEW_ATTENDEES, fetchPreviewAttendeesWorker);
  yield takeLatest(FETCH_SPEAKERS, fetchSpeakersWorker);
}
