import { put, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';

import { api, makeRequest } from 'shared/sdk';
import { getCurrentConversationId } from 'pages/Messaging/ducks/messages/selectors';
import { getConversationsWithUpdatedLatestMessage } from './utils';

const actionPrefix = 'Messaging/';
const FETCH_AND_GROUP_UNSEEN_MESSAGES = `${actionPrefix}FETCH_AND_GROUP_UNSEEN_MESSAGES`;
const SUCCESS_FETCH_UNSEEN_MESSAGES = `${actionPrefix}SUCCESS_FETCH_UNSEEN_MESSAGES`;

export const fetchAndGroupUnseenMessages = ({ message, conversation_id }) => ({
  type: FETCH_AND_GROUP_UNSEEN_MESSAGES,
  payload: { message, conversation_id }
});

const successFetchUnseenMessages = payload => ({
  type: SUCCESS_FETCH_UNSEEN_MESSAGES,
  payload
});

export const notificationsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_UNSEEN_MESSAGES:
      const { results, count } = action.payload;

      state.globalNotifier = {
        ...state.globalNotifier,
        unseenMessageCount: count,
        unseenMessages: [...results]
      };

      const grouped = _.groupBy(results, 'conversation_id');
      state.conversations = getConversationsWithUpdatedLatestMessage(
        state,
        grouped
      );
      break;

    default:
      break;
  }
};

function* fetchAndGroupUnseenMessagesWorker(action) {
  let currentConversationId = yield select(getCurrentConversationId);

  if (
    currentConversationId &&
    currentConversationId === action.payload.conversation_id
  ) {
    return;
  }

  let response = yield makeRequest(api.unseenMessageList);

  if (response.success) {
    yield put(successFetchUnseenMessages(response.data));
  }
}

export function* notificationsWatcher() {
  yield takeLatest(
    FETCH_AND_GROUP_UNSEEN_MESSAGES,
    fetchAndGroupUnseenMessagesWorker
  );
}
