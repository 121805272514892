import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

const actionPrefix = 'filters/';
const FETCH_SECTORS = `${actionPrefix}FETCH_SECTORS`;
const SUCCESS_FETCH_SECTORS = `${actionPrefix}SUCCESS_FETCH_SECTORS`;
const FAIL_FETCH_SECTORS = `${actionPrefix}FAIL_FETCH_SECTORS`;

export const fetchSectors = () => ({
  type: FETCH_SECTORS
});

const successFetchSectors = sectors => ({
  type: SUCCESS_FETCH_SECTORS,
  payload: {
    sectors
  }
});

const failFetchSectors = errors => ({
  type: FAIL_FETCH_SECTORS,
  payload: {
    errors
  }
});

export const sectorsReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SECTORS:
      newState.filterChoices['sectors'] = action.payload.sectors;
      break;
    case FAIL_FETCH_SECTORS:
      newState['errors'] = action.payload.errors;
      break;

    default:
      return newState;
  }
};

function* fetchSectorsWorker() {
  const response = yield makeRequest(api.sectorList);
  if (response.success) yield put(successFetchSectors(response.data.results));
  else yield put(failFetchSectors(response.errors));
}

export function* sectorsWatcher() {
  yield takeLatest(FETCH_SECTORS, fetchSectorsWorker);
}
