import { listTendersReducer, tendersWatcher } from './list';

const DELETE_STATE = 'Tenders/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  tenders: [],
  isFetching: true
};

export const tendersReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  listTendersReducer(newState, action);

  return newState;
};

export const tendersSaga = [tendersWatcher];
export * from './list';
