import companyReducer, { companySaga } from './company';
import productsReducer, { productSaga } from './products';
import { suggestEditReducer, suggestEditSaga } from './suggestEdit';

import {
  similarCompaniesReducer,
  similarCompaniesSaga
} from './similarCompanies';
import { requestDeckSaga } from './requestDeck';
import { requestDemoReducer, requestDemoSaga } from './requestDemo';
import { teamMembersReducer, teamMembersWatcher } from './teamMembers';
import {
  updateFundraisingReducer,
  updateFundraisingSaga
} from './updateFundraising';
import { newsReducer, newsSaga } from './news';
import { profileVisitsReducer, profileVisitsSaga } from './profileVisits';
import { jobsReducer, jobsSaga } from './jobs';

const DELETE_STATE = 'Companies/Detail/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  company: {},
  products: [],
  fundings: [],
  countries: [],
  isFetching: true,
  invitationErrors: null,
  fetchingProduct: false,
  similarCompanies: [],
  fetchingTeamMembers: false,
  team: [],
  isEditFundraisingOpen: false,
  ratings: [],
  isFetchingRatings: false,
  news: [],
  newsCount: 0,
  featuredNews: [],
  isFetchingNews: false,
  isFetchingInitialProfileVisits: false,
  isFetchingModalProfileVisits: false,
  profileVisitsNextPageUrl: null,
  profileVisits: [],
  modalProfileVisits: [],
  isPerformingFollowOperation: false,

  openJobs: [],
  closedJobs: [],
  isFetchingOpenJobs: false,
  isFetchingClosedJobs: false,
  openJobsNextUrl: null,
  closedJobsNextUrl: null
};

export const detailCompaniesReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === DELETE_STATE) newState = initialState;

  companyReducer(newState, action);
  productsReducer(newState, action);
  suggestEditReducer(newState, action);
  teamMembersReducer(newState, action);
  requestDemoReducer(newState, action);
  similarCompaniesReducer(newState, action);
  updateFundraisingReducer(newState, action);
  newsReducer(newState, action);
  profileVisitsReducer(newState, action);
  jobsReducer(newState, action);

  return newState;
};

export const companySagas = [
  companySaga,
  productSaga,
  suggestEditSaga,
  similarCompaniesSaga,
  teamMembersWatcher,
  requestDemoSaga,
  requestDeckSaga,
  updateFundraisingSaga,
  newsSaga,
  profileVisitsSaga,
  jobsSaga
];

export * from './company/';
export * from './products/';
export * from './suggestEdit';
export * from './similarCompanies';
export * from './teamMembers';
export * from './requestDemo';
export * from './requestDeck';
export * from './updateFundraising';
export * from './news';
export * from './profileVisits';
export * from './jobs';
