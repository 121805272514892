import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'GlobalSearch/';

const FETCH_INFLUENCERS = `${actionPrefix}FETCH_INFLUENCERS`;
const SUCCESS_FETCH_INFLUENCERS = `${actionPrefix}SUCCESS_FETCH_INFLUENCERS`;
const FAIL_FETCH_INFLUENCERS = `${actionPrefix}FAIL_FETCH_INFLUENCERS`;

export const fetchInfluencers = filters => ({
  type: FETCH_INFLUENCERS,
  payload: { filters }
});

export const successFetchInfluencers = payload => ({
  type: SUCCESS_FETCH_INFLUENCERS,
  payload
});

export const failFetchInfluencers = payload => ({
  type: FAIL_FETCH_INFLUENCERS,
  payload
});

export const influencersReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INFLUENCERS:
      state['fetchingInfluencers'] = true;
      break;

    case SUCCESS_FETCH_INFLUENCERS:
      state['influencers'] = action.payload.results;
      state['influencersCount'] = action.payload.count;
      state['fetchingInfluencers'] = false;
      break;
    default:
      return state;
  }
};

function* fetchInfluencersWorker(action) {
  const response = yield makeRequest(api.usersList, {
    requestData: { params: { limit: 6, ...action.payload.filters } }
  });

  if (response.success) yield put(successFetchInfluencers(response.data));
  else yield put(failFetchInfluencers(response.errors));
}

export function* influencersSaga() {
  yield takeLatest(FETCH_INFLUENCERS, fetchInfluencersWorker);
}
