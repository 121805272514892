import { put, takeLatest } from 'redux-saga/effects';

import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { api, makeRequest } from 'shared/sdk';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'Company/EditFundraising/';

const TOGGLE_FUNDRAISING_EDIT = `${actionPrefix}TOGGLE_FUNDRAISING_EDIT`;

const UPDATE_FUNDRAISING = `${actionPrefix}UPDATE_FUNDRAISING`;
const SUCCESS_UPDATE_FUNDRAISING = `${actionPrefix}SUCCESS_UPDATE_FUNDRAISING`;
const FAIL_UPDATE_FUNDRAISING = `${actionPrefix}FAIL_UPDATE_FUNDRAISING`;

export const toggleFundraisingEdit = () => ({
  type: TOGGLE_FUNDRAISING_EDIT
});

export const updateFundraising = (companySlug, data, formName) => ({
  type: UPDATE_FUNDRAISING,
  payload: { data },
  formName,
  companySlug
});

const successUpdateFundraising = data => ({
  type: SUCCESS_UPDATE_FUNDRAISING,
  data
});

const failUpdateFundraising = errors => ({
  type: FAIL_UPDATE_FUNDRAISING,
  payload: { errors }
});

export const updateFundraisingReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_UPDATE_FUNDRAISING:
      const fundraisingData = action.data;
      newState['company'] = {
        ...newState['company'],
        ...fundraisingData
      };
      newState['isEditFundraisingOpen'] = false;
      break;
    case TOGGLE_FUNDRAISING_EDIT:
      newState['isEditFundraisingOpen'] = !newState['isEditFundraisingOpen'];
      break;
    default:
      break;
  }
};

function* updateFundraisingWorker(action) {
  const { data } = action.payload;

  const response = yield makeRequest(api.updateFundraising, {
    lookupData: { slug: action.companySlug },
    requestBody: data
  });

  if (response.success) {
    const notificationContent = 'Fundraising was updated successfully';

    yield* [
      put(successUpdateFundraising(response.data)),
      put(openNotificationBar(notificationContent))
    ];
  } else {
    yield put(failUpdateFundraising(response.errors));
    return response.errors;
  }
}

export function* updateFundraisingSaga() {
  yield takeLatest(
    UPDATE_FUNDRAISING,
    formSubmitWorker(updateFundraisingWorker)
  );
}
