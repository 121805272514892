import { combineReducers } from 'redux';

import { investorsReducer, investorsSaga } from './List/ducks';
import { investorDetailReducer, investorDetailSagas } from './Detail/ducks';
import {
  investorConfirmClaimRequestReducer,
  investorConfirmClaimRequestSaga
} from './ClaimRequest/Confirm/ducks';

export const investorReducer = combineReducers({
  list: investorsReducer,
  detail: investorDetailReducer,
  claimRequest: investorConfirmClaimRequestReducer
});

export const investorSagas = [
  ...investorsSaga,
  ...investorDetailSagas,
  investorConfirmClaimRequestSaga
];
