import _ from 'lodash';

import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'Influencer/Detail/Connections/';

const FETCH_CONNECTIONS = `${actionPrefix}FETCH_CONNECTIONS`;
const SUCCESS_FETCH_CONNECTIONS = `${actionPrefix}SUCCESS_FETCH_CONNECTIONS`;

const PAGE_SIZE = 12;
const CONNECTED_WITH = 'connected_with';

export const fetchConnections = ({ influencerId, nextUrl } = {}) => ({
  type: FETCH_CONNECTIONS,
  payload: { influencerId, nextUrl }
});

const successFetchConnections = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_CONNECTIONS,
  payload: { results, next }
});

export const connectionsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_CONNECTIONS:
      state['isFetchingConnections'] = true;
      break;

    case SUCCESS_FETCH_CONNECTIONS:
      state['connections'] = addToList(
        state.connections,
        action.payload.results
      );
      state['connectionsNextUrl'] = action.payload.next;
      state['isFetchingConnections'] = false;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchConnectionsWorker(action) {
  const { influencerId, nextUrl } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  } else {
    const params = { limit: PAGE_SIZE, [CONNECTED_WITH]: influencerId };

    response = yield makeRequest(api.usersList, {
      requestData: { params }
    });
  }

  if (response.success) {
    yield put(successFetchConnections(response));
  }
}

export function* connectionsSaga() {
  yield takeLatest(FETCH_CONNECTIONS, fetchConnectionsWorker);
}
