import { put, takeLatest } from 'redux-saga/effects';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'SendMassMessage/';

const SEND_MASS_MESSAGES = `${actionPrefix}SEND_MASS_MESSAGES`;
const SUCCESS_SEND_MASS_MESSAGES = `${actionPrefix}SUCCESS_SEND_MASS_MESSAGES`;
const FAIL_SEND_MASS_MESSAGES = `${actionPrefix}FAIL_SEND_MASS_MESSAGES`;

export const sendMessages = (formName, data, extraActions = []) => ({
  type: SEND_MASS_MESSAGES,
  formName,
  payload: { data },
  extraActions
});

const successSendMessages = data => ({
  type: SUCCESS_SEND_MASS_MESSAGES,
  payload: data
});

const failSendMessages = data => ({
  type: FAIL_SEND_MASS_MESSAGES,
  payload: data
});

function* sendMessagesWorker(action) {
  const response = yield makeRequest(api.sendMessages, {
    requestBody: action.payload.data
  });

  if (response.success) {
    yield put(successSendMessages(response.data));
    yield* action.extraActions.map(action => action());
  } else {
    yield* [put(failSendMessages(response.errors))];
    return response.errors;
  }
}

export function* sendMessagesSaga() {
  yield takeLatest(SEND_MASS_MESSAGES, formSubmitWorker(sendMessagesWorker));
}
