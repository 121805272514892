import { resourceListReducer, resourceListSaga } from './list';
const DELETE_STATE = 'Companies/Detail/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

export const SOLUTIONS = 'solution';
export const INFLUENCERS = 'author';
export const ARTICLES = 'resource_type';
export const SEARCH = 'search';

const initialState = {
  resources: [],
  nextUrl: null,
  fetchingResources: true,
  premiumResources: []
};

export const resourcesReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  resourceListReducer(initialState, newState, action);

  return newState;
};

export const resourcesSaga = [resourceListSaga];

export * from './list';
