import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { buildFiltersFromObj } from 'shared/utils/filters';

const actionPrefix = 'filters/';
const FETCH_AUTHORS = `${actionPrefix}FETCH_AUTHORS`;
const SUCCESS_FETCH_AUTHORS = `${actionPrefix}SUCCESS_FETCH_AUTHORS`;
const FAIL_FETCH_AUTHORS = `${actionPrefix}FAIL_FETCH_AUTHORS`;

export const fetchAuthors = (filters = {}) => ({
  type: FETCH_AUTHORS,
  payload: { filters }
});

const successFetchAuthors = ({ results, count, next }) => ({
  type: SUCCESS_FETCH_AUTHORS,
  payload: {
    results,
    count,
    next
  }
});

const failFetchAuthors = payload => ({
  type: FAIL_FETCH_AUTHORS,
  payload
});

export const authorsReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_AUTHORS:
      state.filterChoices['authors'] = action.payload.results;
      break;
    default:
      return state;
  }
  return state;
};

function* fetchAuthorWorker(action) {
  const filters = action.payload.filters;

  const response = yield makeRequest(api.authorList, {
    requestData: { params: buildFiltersFromObj(filters) }
  });

  if (response.success) yield put(successFetchAuthors(response.data));
  else yield put(failFetchAuthors(response.errors));
}

export function* authorsWatcher() {
  yield takeLatest(FETCH_AUTHORS, fetchAuthorWorker);
}
