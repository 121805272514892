import React from 'react';

import { addWebsiteWideSchema } from 'shared/utils/structuredData';

class ScriptInjector extends React.Component {
  componentDidMount() {
    addWebsiteWideSchema();
  }

  render() {
    const { children, ...propsToPass } = this.props;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, propsToPass)
    );

    return <React.Fragment>{childrenWithProps}</React.Fragment>;
  }
}

export default ScriptInjector;
