import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'EditCompany/';

const FETCH_COUNTRIES = `${actionPrefix}FETCH_COUNTRIES`;
const SUCCESS_FETCH_COUNTRIES = `${actionPrefix}SUCCESS_FETCH_COUNTRIES`;
const FAIL_FETCH_COUNTRIES = `${actionPrefix}FAIL_FETCH_COUNTRIES`;

const UPDATE_COMPANY_LOCATION = `${actionPrefix}UPDATE_COMPANY_LOCATION`;
const FAIL_UPDATE_COMPANY_LOCATION = `${actionPrefix}FAIL_UPDATE_COMPANY_LOCATION`;
const SUCCESS_UPDATE_COMPANY_LOCATION = `${actionPrefix}SUCCESS_UPDATE_COMPANY_LOCATION`;

export const updateCompanyLocation = (
  formName,
  slug,
  payload,
  extraActions = []
) => ({
  type: UPDATE_COMPANY_LOCATION,
  formName,
  slug,
  payload,
  extraActions
});

const failUpdateCompanyLocation = payload => ({
  type: FAIL_UPDATE_COMPANY_LOCATION,
  payload
});

const successUpdateCompanyLocation = payload => ({
  type: SUCCESS_UPDATE_COMPANY_LOCATION,
  payload
});

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES
});

const failFetchCountries = payload => ({
  type: FAIL_FETCH_COUNTRIES,
  payload
});

const successFetchCountries = payload => ({
  type: SUCCESS_FETCH_COUNTRIES,
  payload
});

export const updateLocationReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COUNTRIES:
      newState['countries'] = action.payload.results;
      break;

    default:
      break;
  }
};

function* updateCompanyLocationWorker(action) {
  const response = yield makeRequest(api.updateCompanyLocation, {
    lookupData: { slug: action.slug },
    requestBody: action.payload
  });

  if (response.success) {
    yield put(successUpdateCompanyLocation(response.data));
    yield* action.extraActions.map(action => put(action()));
  } else {
    yield put(failUpdateCompanyLocation(response.errors));

    return response.errors;
  }
}

function* fetchCountriesWorker(action) {
  const response = yield makeRequest(api.countriesList, {
    requestData: {
      params: {
        limit: 500,
        offset: 0,
        ordering: 1
      }
    }
  });

  if (response.success) yield put(successFetchCountries(response.data));
  else yield put(failFetchCountries(response.errors));
}

export function* updateLocationSaga() {
  yield takeLatest(FETCH_COUNTRIES, fetchCountriesWorker);
  yield takeLatest(
    UPDATE_COMPANY_LOCATION,
    formSubmitWorker(updateCompanyLocationWorker)
  );
}
