import { put, takeLatest, select } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

import { getServiceProviderCaseStudyData } from './selectors';
const actionPrefix = 'EditServiceProvider/';

const UPDATE_CASE_STUDY = `${actionPrefix}UPDATE_CASE_STUDY`;
const FAIL_UPDATE_CASE_STUDY = `${actionPrefix}FAIL_UPDATE_CASE_STUDY`;
const SUCCESS_UPDATE_CASE_STUDY = `${actionPrefix}SUCCESS_UPDATE_CASE_STUDY`;

export const updateCaseStudy = (formName, slug) => ({
  type: UPDATE_CASE_STUDY,
  formName,
  slug
});

const failUpdateCaseStudy = payload => ({
  type: FAIL_UPDATE_CASE_STUDY,
  payload
});

const successUpdateCaseStudy = payload => ({
  type: SUCCESS_UPDATE_CASE_STUDY,
  payload
});

function* updateCaseStudyWorker(action) {
  const serviceProviderCaseStudyData = yield select(
    getServiceProviderCaseStudyData
  );
  const response = yield makeRequest(api.updateServiceProviderCaseStudy, {
    lookupData: { slug: action.slug },
    requestBody: serviceProviderCaseStudyData
  });

  if (response.success) {
    yield put(successUpdateCaseStudy(response.data));
    yield put(openNotificationBar('Case study data was updated successfully'));
  } else {
    yield put(failUpdateCaseStudy(response.errors));

    return response.errors;
  }
}

export function* updateCaseStudySaga() {
  yield takeLatest(UPDATE_CASE_STUDY, formSubmitWorker(updateCaseStudyWorker));
}
