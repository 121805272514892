import React, { useEffect } from 'react';

import cx from 'classnames';

import { enableBodyScroll, disableBodyScroll } from 'shared/utils/common';

import styles from './styles.module.scss';

const ModalWithGreyHeader = ({
  header,
  children,
  footer,
  className,
  closeModal,
  contentClassName,
  blurredBackdrop = false
}) => {
  useEffect(() => {
    disableBodyScroll();

    return enableBodyScroll;
  }, []);

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.blurredBackdrop]: blurredBackdrop
      })}
      onClick={closeModal}>
      <div
        className={cx(styles.container, className)}
        onClick={e => e.stopPropagation()}>
        <div className={styles.close} onClick={closeModal}>
          close
        </div>
        <div className={styles.header}>{header}</div>
        <div className={cx(styles.content, contentClassName)}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>
  );
};

export default ModalWithGreyHeader;
