import _ from 'lodash';
import { parseQueryString } from 'shared/utils/url';

const NEXT_KEY = 'next';

export const buildUrlFromNextRedirect = url => {
  const queries = url.split('?');

  if (queries.length > 2) {
    const filteredNextQueries = queries.filter(q => q.includes(NEXT_KEY));

    if (filteredNextQueries.length) {
      const nextQuery = filteredNextQueries[0];

      const nextUrl = _.get(parseQueryString(nextQuery), NEXT_KEY, '');

      return (
        nextUrl +
        '?' +
        queries.filter(q => !_.isEmpty(q) && !q.includes(NEXT_KEY)).join('&')
      );
    }
  }

  const params = parseQueryString(url);
  const originalUrl = _.get(params, NEXT_KEY, '');

  return `${originalUrl}`;
};

export const getNextSearch = url => {
  return `${NEXT_KEY}=${url}`;
};
