import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'TenderPartner/Fetch/';

const FETCH_TENDER_PARTNER = `${actionPrefix}FETCH_TENDER_PARTNER`;
const SUCCESS_FETCH_TENDER_PARTNER = `${actionPrefix}SUCCESS_FETCH_TENDER_PARTNER`;
const FAIL_FETCH_TENDER_PARTNER = `${actionPrefix}FAIL_FETCH_TENDER_PARTNER`;

export const fetchTenderPartner = ({ tenderPartnerSlug }) => ({
  type: FETCH_TENDER_PARTNER,
  payload: { tenderPartnerSlug }
});

export const successFetchTenderPartner = ({ data }) => ({
  type: SUCCESS_FETCH_TENDER_PARTNER,
  payload: { data }
});

export const failFetchTenderPartner = ({ errors }) => ({
  type: FAIL_FETCH_TENDER_PARTNER,
  payload: { errors }
});

const initialState = {
  isFetching: true,
  tenderPartner: {}
};

export const fetchTenderPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TENDER_PARTNER:
      state['isFetching'] = true;
      state['tenderPartner'] = {};
      break;

    case SUCCESS_FETCH_TENDER_PARTNER:
      state['isFetching'] = false;
      state['tenderPartner'] = action.payload.data;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchTenderPartnerWorker(action) {
  const { tenderPartnerSlug } = action.payload;

  const response = yield getResponseOr404(api.fetchTenderPartner, {
    lookupData: { slug: tenderPartnerSlug }
  });

  if (response.success) {
    yield put(successFetchTenderPartner({ data: response.data }));
  } else {
    yield put(failFetchTenderPartner({ errors: response.errors }));
  }
}

export function* fetchTenderPartnerSaga() {
  yield takeLatest(FETCH_TENDER_PARTNER, fetchTenderPartnerWorker);
}
