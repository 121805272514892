import { takeLatest } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { setServiceProviderImages } from 'shared/components/ServiceProviderForm/ducks';

const actionPrefix = 'ServiceProvider/Edit/';

export const FETCH_SERVICE_PROVIDER = `${actionPrefix}FETCH_SERVICE_PROVIDER`;
export const SUCCESS_FETCH_SERVICE_PROVIDER = `${actionPrefix}SUCCESS_FETCH_SERVICE_PROVIDER`;
export const FAIL_FETCH_SERVICE_PROVIDER = `${actionPrefix}FAIL_FETCH_SERVICE_PROVIDER`;

export const fetchServiceProvider = slug => ({
  type: FETCH_SERVICE_PROVIDER,
  payload: { slug }
});

export const successFetchServiceProvider = data => ({
  type: SUCCESS_FETCH_SERVICE_PROVIDER,
  payload: {
    data
  }
});

export const failFetchServiceProvider = errors => ({
  type: FAIL_FETCH_SERVICE_PROVIDER,
  payload: {
    errors
  }
});

export const serviceProviderReducer = (state, action) => {
  switch (action.type) {
    case FETCH_SERVICE_PROVIDER:
      state['loadingServiceProvider'] = true;
      break;
    case SUCCESS_FETCH_SERVICE_PROVIDER:
      state['serviceProvider'] = action.payload.data;
      state['loadingServiceProvider'] = false;
      break;

    default:
      return state;
  }
};

export function* fetchServiceProviderWorker(action) {
  const response = yield makeRequest(api.serviceProviderDetail, {
    lookupData: action.payload
  });

  if (response.success) {
    yield put(successFetchServiceProvider(response.data));

    if (response.data.images.length > 0)
      yield put(setServiceProviderImages(response.data.images));
  } else yield put(failFetchServiceProvider(response.errors));
}

export function* serviceProviderSaga() {
  yield takeLatest(FETCH_SERVICE_PROVIDER, fetchServiceProviderWorker);
}
