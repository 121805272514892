import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { buildFiltersFromObj } from 'shared/utils/filters';

const actionPrefix = 'TenderPartner/FetchTenders/';

const FETCH_TENDER_PARTNER_TENDERS = `${actionPrefix}FETCH_TENDER_PARTNER`;
const SUCCESS_FETCH_TENDER_PARTNER_TENDERS = `${actionPrefix}SUCCESS_FETCH_TENDER_PARTNER`;
const FAIL_FETCH_TENDER_PARTNER_TENDERS = `${actionPrefix}FAIL_FETCH_TENDER_PARTNER`;

export const fetchTenderPartnerTenders = ({ filters }) => ({
  type: FETCH_TENDER_PARTNER_TENDERS,
  payload: { filters }
});

export const successFetchTenderPartnerTenders = ({ data }) => ({
  type: SUCCESS_FETCH_TENDER_PARTNER_TENDERS,
  payload: { data }
});

export const failFetchTenderPartnerTenders = ({ errors }) => ({
  type: FAIL_FETCH_TENDER_PARTNER_TENDERS,
  payload: { errors }
});

const initialState = {
  tenders: [],
  isFetching: false
};

export const fetchTenderPartnerTendersReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_TENDER_PARTNER_TENDERS:
      state['isFetching'] = true;
      state['tenders'] = [];
      break;

    case SUCCESS_FETCH_TENDER_PARTNER_TENDERS:
      state['isFetching'] = false;
      state['tenders'] = action.payload.data.results;
      break;

    default:
      return state;
  }

  return state;
};

function* fetchTenderPartnerTendersWorker(action) {
  const { filters } = action.payload;

  const response = yield makeRequest(api.tendersList, {
    requestData: {
      params: buildFiltersFromObj(filters)
    }
  });

  if (response.success) {
    yield put(successFetchTenderPartnerTenders({ data: response.data }));
  } else {
    yield put(failFetchTenderPartnerTenders({ errors: response.errors }));
  }
}

export function* fetchTenderPartnerTendersSaga() {
  yield takeLatest(
    FETCH_TENDER_PARTNER_TENDERS,
    fetchTenderPartnerTendersWorker
  );
}
