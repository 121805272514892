import _ from 'lodash';
import axios from 'axios';

const getCallQuery = (url, lookupData) =>
  url instanceof Function ? url(lookupData) : url;

const get = (url, { lookupData = {}, requestData = {} } = {}) => {
  if (_.isNil(lookupData) || _.isNil(requestData)) {
    throw new Error(
      'Do not pass null values for `lookupData` and `requestData`, use empty objects instead'
    );
  }
  const callQuery = getCallQuery(url, lookupData);

  return axios.get(callQuery, requestData);
};

const _delete = (url, { lookupData = {}, requestData = {} } = {}) => {
  if (_.isNil(lookupData) || _.isNil(requestData)) {
    throw new Error(
      'Do not pass null values for `lookupData` and `requestData`, use empty objects instead'
    );
  }

  const callQuery = getCallQuery(url, lookupData);

  return axios.delete(callQuery, requestData);
};

const post = (
  url,
  { lookupData = {}, requestBody = {}, requestData = {} } = {}
) => {
  if (_.isNil(lookupData) || _.isNil(requestData) || _.isNil(requestBody)) {
    throw new Error(
      'Do not pass null values for `lookupData`, `requestData` and `requestBody`, use empty objects instead'
    );
  }
  const callQuery = getCallQuery(url, lookupData);

  return axios.post(callQuery, requestBody, requestData);
};

const put = (
  url,
  { lookupData = {}, requestBody = {}, requestData = {} } = {}
) => {
  if (_.isNil(lookupData) || _.isNil(requestData) || _.isNil(requestBody)) {
    throw new Error(
      'Do not pass null values for `lookupData`, `requestData` and `requestBody`, use empty objects instead'
    );
  }
  const callQuery = getCallQuery(url, lookupData);

  return axios.put(callQuery, requestBody, requestData);
};

const requests = {
  put,
  get,
  post,
  delete: _delete
};

export default requests;
