import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Influencer/Detail/FetchInfluencer/';

const FETCH_INFLUENCER = `${actionPrefix}FETCH_INFLUENCER`;
const SUCCESS_FETCH_INFLUENCER = `${actionPrefix}SUCCESS_FETCH_INFLUENCER`;

export const fetchInfluencer = ({ slug, extraActions }) => ({
  type: FETCH_INFLUENCER,
  payload: {
    slug,
    extraActions
  }
});

const successFetchInfluencer = ({ data }) => ({
  type: SUCCESS_FETCH_INFLUENCER,
  payload: {
    data
  }
});

export const fetchInfluencerReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INFLUENCER:
      state['isFetchingInfluencer'] = true;
      break;

    case SUCCESS_FETCH_INFLUENCER:
      state['influencer'] = action.payload.data;
      state['isFetchingInfluencer'] = false;
      break;

    default:
      break;
  }
};

function* fetchInfluencerWorker(action) {
  const { slug, extraActions } = action.payload;

  const response = yield getResponseOr404(api.influencerDetail, {
    lookupData: { slug }
  });

  if (response.success) {
    yield put(successFetchInfluencer(response));
    extraActions.map(action => action({ influencer: response.data }));
  }
}

export function* fetchInfluencerSaga() {
  yield takeLatest(FETCH_INFLUENCER, fetchInfluencerWorker);
}
