import { put, select } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

import { buildFilters } from 'shared/utils/filters';
import * as selectors from './selectors';
import { getComputedRank } from './utils';

const actionPrefix = 'Influencers/';
const FETCH_INFLUENCERS = `${actionPrefix}FETCH_INFLUENCERS`;
const SUCCESS_FETCH_INFLUENCERS = `${actionPrefix}SUCCESS_FETCH_INFLUENCERS`;
const FAIL_FETCH_INFLUENCERS = `${actionPrefix}FAIL_FETCH_INFLUENCERS`;

const FETCH_NEXT_INFLUENCERS = `${actionPrefix}FETCH_NEXT_INFLUENCERS`;
const SUCCESS_FETCH_NEXT_INFLUENCERS = `${actionPrefix}SUCCESS_FETCH_NEXT_INFLUENCERS`;
const FAIL_FETCH_NEXT_INFLUENCERS = `${actionPrefix}FAIL_FETCH_NEXT_INFLUENCERS`;

export const fetchInfluencers = (filters = []) => ({
  type: FETCH_INFLUENCERS,
  payload: {
    filters
  }
});

const successFetchInfluencers = ({ next, results, count }) => ({
  type: SUCCESS_FETCH_INFLUENCERS,
  payload: {
    next,
    results,
    count
  }
});

const failFetchInfluencers = errors => ({
  type: FAIL_FETCH_INFLUENCERS,
  payload: {
    errors
  }
});

const successFetchNextInfluencers = ({ next, results, count }) => ({
  type: SUCCESS_FETCH_NEXT_INFLUENCERS,
  payload: {
    next,
    results,
    count
  }
});

const failFetchNextInfluencers = errors => ({
  type: FAIL_FETCH_NEXT_INFLUENCERS,
  payload: {
    errors
  }
});

export const fetchNextInfluencers = () => ({
  type: FETCH_NEXT_INFLUENCERS
});

export const influencersListReducer = (initialState, newState, action) => {
  switch (action.type) {
    case FETCH_INFLUENCERS:
    case FETCH_NEXT_INFLUENCERS:
      newState['isFetching'] = true;
      break;

    case SUCCESS_FETCH_INFLUENCERS:
      newState['influencers'] = action.payload.results.map((item, index) => ({
        ...item,
        computed_rank: getComputedRank(index, action.payload.results),
        performingFollowOperation: false
      }));
      newState['nextPageUrl'] = action.payload.next;
      newState['influencersCount'] = action.payload.count;
      newState['isFetching'] = false;
      break;

    case SUCCESS_FETCH_NEXT_INFLUENCERS:
      newState['influencers'] = [
        ...newState['influencers'],
        ...action.payload.results.map((item, index) => ({
          ...item,
          computed_rank: getComputedRank(
            index,
            action.payload.results,
            newState['influencers']
          ),
          performingFollowOperation: false
        }))
      ];
      newState['isFetching'] = false;
      newState['nextPageUrl'] = action.payload.next;
      break;

    case FAIL_FETCH_INFLUENCERS:
      newState['errors'] = action.payload.errors;
      break;

    default:
      return newState;
  }
};

function* fetchInfluencersWorker(action) {
  const filters = action.payload.filters;
  const response = yield makeRequest(api.influencersList, {
    requestData: { params: buildFilters(filters) }
  });

  if (response.success) yield put(successFetchInfluencers(response.data));
  else yield put(failFetchInfluencers(response.errors));
}

function* fetchNextInfluencersWorker(action) {
  const nextPageUrl = yield select(selectors.getNextPageUrl);
  const response = yield makeRequest(api.genericGet, {
    lookupData: nextPageUrl
  });

  if (response.success) yield put(successFetchNextInfluencers(response.data));
  else yield put(failFetchNextInfluencers(response.errors));
}

export function* influencersListWatcher() {
  yield takeLatest(FETCH_INFLUENCERS, fetchInfluencersWorker);
  yield takeLatest(FETCH_NEXT_INFLUENCERS, fetchNextInfluencersWorker);
}
