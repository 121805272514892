import { push } from 'react-router-redux';
import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404, makeRequest } from 'shared/sdk';

import { injectScriptByUrl } from 'shared/utils/injectScript';

import { PREMIUM_FEATURES_URL } from 'global-constants';

const actionPrefix = 'ResourceDetail/';

const LIST_CTAS = `${actionPrefix}LIST_CTAS`;
const SUCCESS_LIST_CTAS = `${actionPrefix}SUCCESS_LIST_CTAS`;

const FETCH_RESOURCE_DETAIL = `${actionPrefix}FETCH_RESOURCE_DETAIL`;
const SUCCESS_FETCH_RESOURCE_DETAIL = `${actionPrefix}SUCCESS_FETCH_RESOURCE_DETAIL`;
const FAIL_FETCH_RESOURCE_DETAIL = `${actionPrefix}FAIL_FETCH_RESOURCE_DETAIL`;

const TWITTER_EMBED_URL = process.env.REACT_APP_TWITTER_EMBED_URL;
const TWITTER_EMBED_IDENTIFIER = 'twitterScript';

export const listCTAs = () => ({
  type: LIST_CTAS
});

const successListCTAs = payload => ({
  type: SUCCESS_LIST_CTAS,
  payload
});

export const fetchResourceDetail = ({ slug, extraActions = [] }) => ({
  type: FETCH_RESOURCE_DETAIL,
  payload: {
    slug,
    extraActions
  }
});

const successFetchResourceDetail = payload => ({
  type: SUCCESS_FETCH_RESOURCE_DETAIL,
  payload
});

const failFetchResourceDetail = payload => ({
  type: FAIL_FETCH_RESOURCE_DETAIL,
  payload
});

export const getResourceReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_RESOURCE_DETAIL:
      newState['resource'] = Object.assign(action.payload, { followed: false });
      newState['isFetching'] = false;
      break;

    case SUCCESS_LIST_CTAS:
      newState['CTAs'] = action.payload;
      break;

    default:
      break;
  }
};

function* fetchResourceWorker(action) {
  const response = yield getResponseOr404(api.resourceDetail, {
    lookupData: { slug: action.payload.slug }
  });

  if (response.success) {
    yield put(successFetchResourceDetail(response.data));

    const data = {
      url: TWITTER_EMBED_URL,
      identifier: TWITTER_EMBED_IDENTIFIER
    };
    injectScriptByUrl(data);

    yield* action.payload.extraActions.map(extraAction => extraAction());
  } else {
    let actions = [put(failFetchResourceDetail(response.errors))];

    if (
      response.statusCode === 403 &&
      response.errors.detail ===
        'You do not have permission to perform this action.'
    )
      actions.push(put(push(PREMIUM_FEATURES_URL)));

    yield* [actions];
  }
}

function* fetchCTAs(action) {
  const response = yield makeRequest(api.CTAsList);

  if (response.success) yield put(successListCTAs(response.data));
}

export function* getResourceSaga() {
  yield takeLatest(FETCH_RESOURCE_DETAIL, fetchResourceWorker);
  yield takeLatest(LIST_CTAS, fetchCTAs);
}
