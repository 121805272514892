import { takeLatest } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'EventDetail/';

const FOLLOW_COMPANY = `${actionPrefix}FOLLOW_COMPANY`;
const SUCCESS_FOLLOW_COMPANY = `${actionPrefix}SUCCESS_FOLLOW_COMPANY`;
const FAIL_FOLLOW_COMPANY = `${actionPrefix}FAIL_FOLLOW_COMPANY`;

const UNFOLLOW_COMPANY = `${actionPrefix}UNFOLLOW_COMPANY`;
const SUCCESS_UNFOLLOW_COMPANY = `${actionPrefix}SUCCESS_UNFOLLOW_COMPANY`;
const FAIL_UNFOLLOW_COMPANY = `${actionPrefix}FAIL_UNFOLLOW_COMPANY`;

export const followCompany = slug => ({
  type: FOLLOW_COMPANY,
  payload: { slug }
});

const successFollowCompany = data => ({
  type: SUCCESS_FOLLOW_COMPANY,
  payload: {
    data
  }
});

const failFollowCompany = errors => ({
  type: FAIL_FOLLOW_COMPANY,
  payload: {
    errors
  }
});

export const unfollowCompany = slug => ({
  type: UNFOLLOW_COMPANY,
  payload: { slug }
});

const successUnfollowCompany = data => ({
  type: SUCCESS_UNFOLLOW_COMPANY,
  payload: {
    data
  }
});

const failUnfollowCompany = errors => ({
  type: FAIL_UNFOLLOW_COMPANY,
  payload: {
    errors
  }
});

export const companyReducer = (state, action) => {
  switch (action.type) {
    case FOLLOW_COMPANY:
      state.performingFollowHostVendorOperation = true;
      break;
    case UNFOLLOW_COMPANY:
      state.performingFollowHostVendorOperation = true;
      break;

    case SUCCESS_FOLLOW_COMPANY:
      state['event'] = { ...state.event, is_host_vendor_followed: true };
      state.performingFollowHostVendorOperation = false;
      break;

    case SUCCESS_UNFOLLOW_COMPANY:
      state['event'] = { ...state.event, is_host_vendor_followed: false };
      state.performingFollowHostVendorOperation = false;
      break;

    default:
      return state;
  }
};

export function* followCompanyWorker(action) {
  const response = yield makeRequest(api.followCompany, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFollowCompany(response.data));
  else yield put(failFollowCompany(response.errors));
}

export function* unfollowCompanyWorker(action) {
  const response = yield makeRequest(api.unfollowCompany, {
    lookupData: action.payload
  });

  if (response.success) yield put(successUnfollowCompany(response.data));
  else yield put(failUnfollowCompany(response.errors));
}
export function* companyWatchers() {
  yield takeLatest(FOLLOW_COMPANY, followCompanyWorker);
  yield takeLatest(UNFOLLOW_COMPANY, unfollowCompanyWorker);
}
