import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { prependCommentReplies } from 'shared/utils/comments';
import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';

const actionPrefix = 'Dashboard/CommentReplies/';

const CREATE_COMMENT_REPLY = `${actionPrefix}CREATE_COMMENT_REPLY`;
const SUCCESS_CREATE_COMMENT_REPLY = `${actionPrefix}SUCCESS_CREATE_COMMENT_REPLY`;

const FETCH_COMMENT_REPLIES = `${actionPrefix}FETCH_COMMENT_REPLIES`;
const SUCCESS_FETCH_COMMENT_REPLIES = `${actionPrefix}SUCCESS_FETCH_COMMENT_REPLIES`;
const FAIL_FETCH_COMMENT_REPLIES = `${actionPrefix}FAIL_FETCH_COMMENT_REPLIES`;

export const createCommentReply = ({
  content,
  commentId,
  postId,
  mentionedUsers
}) => ({
  type: CREATE_COMMENT_REPLY,
  payload: { content, commentId, postId, mentionedUsers }
});

const successCreateCommentReply = ({ data, commentId, postId }) => ({
  type: SUCCESS_CREATE_COMMENT_REPLY,
  payload: { data, commentId, postId }
});

export const fetchCommentReplies = ({ commentId, url, postId }) => ({
  type: FETCH_COMMENT_REPLIES,
  payload: { commentId, url, postId }
});

const successFetchCommentReplies = ({
  commentId,
  results,
  nextUrl,
  postId
}) => ({
  type: SUCCESS_FETCH_COMMENT_REPLIES,
  payload: { commentId, results, nextUrl, postId }
});

const failFetchCommentReplies = ({ errors }) => ({
  type: FAIL_FETCH_COMMENT_REPLIES,
  payload: { errors }
});

export const commentRepliesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_COMMENT_REPLIES:
      state['posts'] = state.posts.map(
        post =>
          post.id === action.payload.postId
            ? {
                ...post,
                comments: updateByIdentifier({
                  collection: post.comments,
                  identifierField: 'id',
                  identifierValue: action.payload.commentId,
                  newItem: { isFetchingReplies: true, repliesNextUrl: null }
                })
              }
            : post
      );
      break;

    case SUCCESS_FETCH_COMMENT_REPLIES:
      state['posts'] = state.posts.map(
        post =>
          post.id === action.payload.postId
            ? {
                ...post,
                comments: post.comments.map(
                  comment =>
                    comment.id === action.payload.commentId
                      ? {
                          // we want the newest one to be on the bottom
                          ...comment,
                          isFetchingReplies: false,
                          repliesNextUrl: action.payload.nextUrl,
                          replies: prependCommentReplies({
                            oldReplies: _.get(comment, 'replies', []),
                            newReplies: action.payload.results
                          })
                        }
                      : comment
                )
              }
            : post
      );
      break;

    case SUCCESS_CREATE_COMMENT_REPLY:
      state['posts'] = state.posts.map(
        post =>
          post.id === action.payload.postId
            ? {
                ...post,
                number_of_comments: post.number_of_comments + 1,
                comments: post.comments.map(
                  comment =>
                    comment.id === action.payload.commentId
                      ? {
                          ...comment,
                          reply_count: _.get(comment, 'reply_count', 0) + 1,
                          replies: addToList(
                            _.get(comment, 'replies', []),
                            action.payload.data
                          )
                        }
                      : comment
                )
              }
            : post
      );
      break;

    default:
      return state;
  }

  return state;
};

function* createCommentReplyWorker(action) {
  const {
    commentId,
    content,
    postId,
    mentionedUsers: mentioned_users_ids
  } = action.payload;

  const response = yield makeRequest(api.replyToCommentPost, {
    lookupData: { commentId },
    requestBody: { content, mentioned_users_ids }
  });

  if (response.success) {
    yield put(
      successCreateCommentReply({ data: response.data, commentId, postId })
    );
  }
}

function* fetchCommentRepliesWorker(action) {
  const { commentId, url, postId } = action.payload;

  let response;

  if (!_.isNil(url)) {
    response = yield makeRequest(api.genericGet, {
      lookupData: url
    });
  } else {
    response = yield makeRequest(api.fetchCommentReplies, {
      lookupData: { commentId }
    });
  }

  if (response.success) {
    yield put(
      successFetchCommentReplies({
        postId,
        commentId,
        results: response.data.results,
        nextUrl: response.data.next
      })
    );
  } else {
    yield put(failFetchCommentReplies(response));
  }
}

export function* commentRepliesSaga() {
  yield takeLatest(CREATE_COMMENT_REPLY, createCommentReplyWorker);
  yield takeLatest(FETCH_COMMENT_REPLIES, fetchCommentRepliesWorker);
}
