import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'EditProfile/';

const PATCH_USER_PREFERENCES = `${actionPrefix}PATCH_USER_PREFERENCES`;
const SUCCESS_PATCH_USER_PREFERENCES = `${actionPrefix}SUCCESS_PATCH_USER_PREFERENCES`;
const FAIL_PATCH_USER_PREFERENCES = `${actionPrefix}FAIL_PATCH_USER_PREFERENCES`;

const FETCH_USER_PREFERENCES = `${actionPrefix}FETCH_USER_PREFERENCES`;
const SUCCESS_FETCH_USER_PREFERENCES = `${actionPrefix}SUCCESS_FETCH_USER_PREFERENCES`;

export const patchUserPreferences = payload => ({
  type: PATCH_USER_PREFERENCES,
  payload
});

const successPatchUserPreferences = payload => ({
  type: SUCCESS_PATCH_USER_PREFERENCES,
  payload
});

const failPatchUserPreferences = payload => ({
  type: FAIL_PATCH_USER_PREFERENCES,
  payload
});

export const fetchUserPreferences = () => ({
  type: FETCH_USER_PREFERENCES
});

const successFetchUserPreferences = ({ data }) => ({
  type: SUCCESS_FETCH_USER_PREFERENCES,
  payload: data
});

export const preferencesReducer = (newState, action) => {
  switch (action.type) {
    case PATCH_USER_PREFERENCES:
      newState['patchingPreferences'] = true;
      break;

    case SUCCESS_PATCH_USER_PREFERENCES:
      newState['patchingPreferences'] = false;
      break;

    case FAIL_PATCH_USER_PREFERENCES:
      newState['patchingPreferences'] = false;
      break;

    case SUCCESS_FETCH_USER_PREFERENCES:
      newState['userPreferences'] = action.payload;
      break;

    default:
      return newState;
  }
};

function* patchUserPreferencesWorker(action) {
  const response = yield makeRequest(api.updateUserPreferences, {
    requestBody: action.payload
  });

  if (response.success)
    yield* [
      put(fetchUserPreferences()),
      put(successPatchUserPreferences(response.data)),
      put(openNotificationBar('Preferences updated successfully!'))
    ];
  else yield put(failPatchUserPreferences(response.errors));
}

function* fetchUserPreferencesWorker(action) {
  const response = yield makeRequest(api.userPreferencesDetail);

  if (response.success) {
    yield put(successFetchUserPreferences(response));
  }
}

export function* userPreferencesWatcher() {
  yield takeLatest(PATCH_USER_PREFERENCES, patchUserPreferencesWorker);
  yield takeLatest(FETCH_USER_PREFERENCES, fetchUserPreferencesWorker);
}
