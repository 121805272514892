import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { buildFilters } from 'shared/utils/filters';

const actionPrefix = 'Influencers';
export const FETCH_COMPANIES = `${actionPrefix}FETCH_COMPANIES`;
export const SUCCESS_FETCH_COMPANIES = `${actionPrefix}SUCCESS_FETCH_COMPANIES`;
export const FAIL_FETCH_COMPANIES = `${actionPrefix}FAIL_FETCH_COMPANIES`;

export const fetchCompanies = (filters = {}) => ({
  type: FETCH_COMPANIES,
  payload: {
    filters
  }
});

export const successFetchCompanies = payload => ({
  type: SUCCESS_FETCH_COMPANIES,
  payload
});

export const failFetchCompanies = payload => ({
  type: FAIL_FETCH_COMPANIES,
  payload
});

export const companiesReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COMPANIES:
      state['companies'] = action.payload.results;
      break;

    default:
      return state;
  }
};

function* fetchCompaniesWorker(action) {
  const filters = action.payload.filters;
  const response = yield makeRequest(api.companiesList, {
    requestData: { params: buildFilters(filters) }
  });

  if (response.success) yield put(successFetchCompanies(response.data));
  else yield put(failFetchCompanies(response.errors));
}

export function* companiesWatcher() {
  yield takeLatest(FETCH_COMPANIES, fetchCompaniesWorker);
}
