import _ from 'lodash';

export const prependCommentReplies = ({ oldReplies, newReplies }) =>
  _.sortedUniqBy(_.sortBy([...newReplies, ...oldReplies], 'id'), 'id'); // old are the ones in the store, not the ones that are actually older.

export const getCommentersAndRepliers = ({ comments, author }) => {
  const commenters = _.map(comments, comment => comment.user);
  const repliers = _.flattenDeep(
    _.map(comments, comment => _.map(comment.replies, reply => reply.user))
  );

  if (!_.isNil(author)) {
    return _.uniqBy([author, ...commenters, ...repliers], 'slug');
  }

  return _.uniqBy([...commenters, ...repliers], 'slug');
};
