import { constantsSaga } from './constants';

export const fetchInitialsSagas = [constantsSaga, meSagas];
import { put, takeLatest, delay } from 'redux-saga/effects';

import { REFRESH_TOKEN_INTERVAL } from 'global-constants';

import { setAuthorization } from 'shared/utils/setAuthorization';
import { api, makeRequest } from 'shared/sdk';
import { attemptUserIDSet } from 'shared/utils/GA';
import { setSentryUser } from 'shared/utils/sentry';
import { PusherManager } from 'shared/utils/pusher';
import { assignOneSignalExternalID } from 'shared/components/PushProvider/pushUtils';

const actionPrefix = 'Me/';

export const FETCH_ME = `${actionPrefix}FETCH_ME`;
export const FAIL_FETCH_ME = `${actionPrefix}FAIL_FETCH_ME`;
export const SUCCESS_FETCH_ME = `${actionPrefix}SUCCESS_FETCH_ME`;
export const REFRESH_TOKEN = `${actionPrefix}REFRESH_TOKEN`;
export const REFRESH_TOKEN_SUCCESS = `${actionPrefix}REFRESH_TOKEN_SUCCESS`;
export const REFRESH_TOKEN_FAIL = `${actionPrefix}REFRESH_TOKEN_FAIL`;

export const TOGGLE_USER_IDENTIFIED = `${actionPrefix}TOGGLE_USER_IDENTIFIED`;

export const fetchMe = (extraActions = []) => ({
  type: FETCH_ME,
  extraActions
});

export const successFetchMe = data => ({
  type: SUCCESS_FETCH_ME,
  data
});

export const failFetchMe = error => ({
  type: FAIL_FETCH_ME,
  error
});

export const refreshToken = oldToken => ({
  type: REFRESH_TOKEN,
  oldToken
});

export const refreshTokenSuccess = () => ({
  type: REFRESH_TOKEN_SUCCESS
});

export const refreshTokenFail = error => ({
  type: REFRESH_TOKEN_FAIL,
  error
});

export const toggleUserIdentified = () => ({
  type: TOGGLE_USER_IDENTIFIED
});

const initialState = {
  user: null,
  loading: false,
  identified: false,
  initialCallMade: false
};

export const fetchMeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ME:
      return {
        ...state,
        loading: true
      };

    case SUCCESS_FETCH_ME:
      return {
        ...state,
        loading: false,
        user: action.data,
        initialCallMade: true
      };

    case FAIL_FETCH_ME:
      return {
        ...state,
        loading: false,
        initialCallMade: true
      };

    case TOGGLE_USER_IDENTIFIED:
      return { ...state, identified: !state.identified };

    default:
      return state;
  }
};

function* meWorker(action) {
  const response = yield makeRequest(api.getMe);

  if (response.success) {
    yield put(successFetchMe(response.data));

    yield* action.extraActions.map(extraAction => put(extraAction()));

    setSentryUser({ userData: response.data });
    attemptUserIDSet(response.data);
    assignOneSignalExternalID(response.data);

    let token = response.data.pusher_user.token;
    PusherManager.subscribeToUserSpecificChannel(token);
    PusherManager.subscribeToGeneralChannel();

    yield put(refreshToken('token'));
  } else {
    yield put(failFetchMe());
  }
}

function* refreshTokenWorker(action) {
  while (true) {
    yield delay(REFRESH_TOKEN_INTERVAL);

    const response = yield makeRequest(api.refreshToken, {
      requestBody: { token: localStorage.getItem('token') }
    });

    if (response.success) {
      setAuthorization(response.data.token);

      yield put(refreshTokenSuccess());
    } else {
      yield put(refreshTokenFail());
    }
  }
}

export function* meSagas() {
  yield takeLatest(FETCH_ME, meWorker);
  yield takeLatest(REFRESH_TOKEN, refreshTokenWorker);
}

export default refreshTokenWorker;

export * from './constants';
