import { listTendersReducer, tendersWatcher } from './list';
import { createTenderWatcher } from './create';
import {
  downloadTenderResponseExportReducer,
  downloadTenderResponseExportWatcher
} from './export';
import { makeIntroReducer, makeIntroWatcher } from './makeIntro';

const DELETE_STATE = 'Tenders/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  tenders: [],
  nextUrl: null,
  isFetching: true,
  isFetchingExport: false,
  isMakingIntro: false
};

export const createTenderReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  makeIntroReducer(newState, action);
  listTendersReducer(newState, action);
  downloadTenderResponseExportReducer(newState, action);

  return newState;
};

export const createTenderSaga = [
  tendersWatcher,
  makeIntroWatcher,
  createTenderWatcher,
  downloadTenderResponseExportWatcher
];
export * from './list';
export * from './create';
export * from './export';
export * from './makeIntro';
