import { combineReducers } from 'redux';

import { jobDetailReducer, jobDetailSaga } from './Detail/ducks';
import { jobFormReducer, jobFormSaga } from './Form/ducks';
import { jobListReducer, jobListSaga } from './List/ducks';

export const jobReducer = combineReducers({
  detail: jobDetailReducer,
  form: jobFormReducer,
  list: jobListReducer
});

export const jobSaga = [...jobDetailSaga, ...jobFormSaga, jobListSaga];
