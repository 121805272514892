import { combineReducers } from 'redux';

import { resourceDetailReducer, resourceDetailSagas } from './Detail/ducks';
import { resourceProposalReducer, resourceProposalSagas } from './Create/ducks';
import {
  resourcesReducer,
  resourcesSaga as resourceListSagas
} from './List/ducks';

export const resourceReducer = combineReducers({
  list: resourcesReducer,
  detail: resourceDetailReducer,
  create: resourceProposalReducer
});

export const resourceSagas = [
  ...resourceDetailSagas,
  ...resourceProposalSagas,
  ...resourceListSagas
];
