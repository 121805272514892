import { serviceProviderReducer, serviceProviderSaga } from './serviceProvider';
import { updateHeadlinesReducer, updateHeadlinesSaga } from './updateHeadlines';
import { updateLocationSaga } from './updateLocation';
import { updateContactSaga } from './updateContact';
import { updateCaseStudySaga } from './updateCasestudy';

const DELETE_STATE = 'EditServiceProvider/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  loadingServiceProvider: true,
  serviceProvider: {},
  serviceProviderImages: []
};

export const editServiceProviderReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === DELETE_STATE) newState = initialState;

  serviceProviderReducer(newState, action);
  updateHeadlinesReducer(newState, action);

  return newState;
};

export const editServiceProviderSagas = [
  serviceProviderSaga,
  updateHeadlinesSaga,
  updateLocationSaga,
  updateContactSaga,
  updateCaseStudySaga
];

export * from './serviceProvider/';
export * from './updateHeadlines';
export * from './updateLocation';
export * from './updateContact';
export * from './updateCasestudy';
