import { detailReducer, detailSaga } from './detail';
import { followReducer, followSaga } from './follow';
import { investmentReducer, investmentSaga } from './investment';
import { teamMembersReducer, teamMembersSaga } from './teamMembers';
import {
  similarInvestorsReducer,
  similarInvestorsSaga
} from './similarInvestors';

const DELETE_STATE = 'Companies/Detail/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  investor: {},
  investments: {},
  isFetching: true,
  investmentCount: null,
  isFetchingInvestments: true,
  isPerformingFollowOperation: false,
  team: [],
  isFetchingTeam: false,
  teamMembersNext: null,
  similarInvestors: [],
  isFetchingSimilarInvestors: false
};

export const investorDetailReducer = (state = initialState, action) => {
  let newState = { ...state };

  if (action.type === DELETE_STATE) newState = initialState;

  detailReducer(newState, action);
  followReducer(newState, action);
  investmentReducer(newState, action);
  teamMembersReducer(newState, action);
  similarInvestorsReducer(newState, action);

  return newState;
};

export const investorDetailSagas = [
  detailSaga,
  followSaga,
  investmentSaga,
  teamMembersSaga,
  similarInvestorsSaga
];

export * from './detail/';
export * from './follow/';
export * from './investment/';
export * from './teamMembers/';
export * from './similarInvestors/';
