import { takeLatest, put } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'TenderResponseStatusForm/';
export const CHANGE_TENDER_RESPONSE_STATUS = `${actionPrefix}CHANGE_TENDER_RESPONSE_STATUS`;
export const SUCCESS_CHANGE_TENDER_RESPONSE_STATUS = `${actionPrefix}SUCCESS_CHANGE_TENDER_RESPONSE_STATUS`;
export const FAIL_CHANGE_TENDER_RESPONSE_STATUS = `${actionPrefix}FAIL_CHANGE_TENDER_RESPONSE_STATUS`;

export const changeTenderResponseStatus = (
  id,
  statusObj,
  extraActions = []
) => ({
  type: CHANGE_TENDER_RESPONSE_STATUS,
  payload: { id, statusObj },
  extraActions
});

export function* changeTenderResponseStatusWorker(action) {
  const { id, statusObj } = action.payload;
  const response = yield makeRequest(api.updateTenderResponseStatus, {
    lookupData: { tenderResponseId: id },
    requestBody: { status: statusObj.value }
  });

  if (response.success) {
    yield* action.extraActions.map(extraAction =>
      put(extraAction(action.payload.statusObj))
    );
  } else return response.errors;
}

function* changeTenderResponseStatusSaga() {
  yield takeLatest(
    CHANGE_TENDER_RESPONSE_STATUS,
    changeTenderResponseStatusWorker
  );
}

export default changeTenderResponseStatusSaga;
