// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const requestPermission = () => {
  return new Promise((resolve, reject) => {
    const requestPermissionResult = window.Notification.requestPermission(
      result => resolve(result)
    );

    if (requestPermissionResult) requestPermissionResult.then(resolve, reject);
  }).then(permission => {
    if (permission !== 'granted')
      console.log('User has denied notification permissions');
  });
};

export default function register() {
  if (
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window
  ) {
    // The URL constructor is available in all browsers that support SW.
    console.log('Starting service worker register');

    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);

    if (publicUrl.origin !== window.location.origin) {
      console.log(`${publicUrl} differs from ${window.location.origin}`);
      console.log('This might cause CORS policy issues');
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      // return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${window.location.origin}/custom-service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://goo.gl/SC7cgQ'
          );
        });
      } else {
        console.log('Non-dev env detected. Registering service worker');
        // Is not local host. Just register service worker
        registerValidSW(swUrl);
      }
    });
  }
}

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const savePushSubscriptionDetails = subscription => {
  const subscriptionDeserialized = JSON.parse(JSON.stringify(subscription));

  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/messaging/push-subscriptions/create/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        endpoint: subscriptionDeserialized.endpoint,
        p256dh: subscriptionDeserialized.keys.p256dh,
        auth: subscriptionDeserialized.keys.auth
      })
    }
  ).then(response => {
    return response.json();
  });
};

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      console.log('Subscribing: ', process.env.REACT_APP_VAPID_PUBLIC_KEY);

      const subscriptionOptions = {
        userVisibleOnly: true, // must always be provided because the devs decided so
        applicationServerKey: urlB64ToUint8Array(
          `${process.env.REACT_APP_VAPID_PUBLIC_KEY}`
        )
      };

      return registration.pushManager.subscribe(subscriptionOptions);
    })
    .then(pushSubscription => {
      console.log('Permission allowed, saving info');
      savePushSubscriptionDetails(pushSubscription);
      return pushSubscription;
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
