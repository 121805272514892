import { put, takeLatest } from 'redux-saga/effects';

import { api, getResponseOr404, makeRequest } from 'shared/sdk';

const actionPrefix = 'ConnectVideo/Detail/';

const LIST_CTAS = `${actionPrefix}LIST_CTAS`;
const SUCCESS_LIST_CTAS = `${actionPrefix}SUCCESS_LIST_CTAS`;

const FETCH_CONNECT_VIDEO = `${actionPrefix}FETCH_CONNECT_VIDEO`;
const SUCCESS_FETCH_CONNECT_VIDEO = `${actionPrefix}SUCCESS_FETCH_CONNECT_VIDEO`;
const FAIL_FETCH_CONNECT_VIDEO = `${actionPrefix}FAIL_FETCH_CONNECT_VIDEO`;

export const listCTAs = () => ({
  type: LIST_CTAS
});

const successListCTAs = payload => ({
  type: SUCCESS_LIST_CTAS,
  payload
});

export const fetchConnectVideo = ({ connectVideoSlug }) => ({
  type: FETCH_CONNECT_VIDEO,
  payload: { connectVideoSlug }
});

const successFetchConnectVideo = ({ data }) => ({
  type: SUCCESS_FETCH_CONNECT_VIDEO,
  payload: { data }
});

const failFetchConnectVideo = ({ errors }) => ({
  type: FAIL_FETCH_CONNECT_VIDEO,
  payload: { errors }
});

export const detailReducer = (state, action) => {
  switch (action.type) {
    case FETCH_CONNECT_VIDEO:
      state['isFetching'] = true;
      break;

    case LIST_CTAS:
      state['isFetchingCTAs'] = true;
      break;

    case SUCCESS_FETCH_CONNECT_VIDEO:
      state['isFetching'] = false;
      state['connectVideo'] = action.payload.data;
      break;

    case SUCCESS_LIST_CTAS:
      state['CTAs'] = action.payload;
      state['isFetchingCTAs'] = false;
      break;

    default:
      return state;
  }
};

function* fetchCTAsWorker(action) {
  const response = yield makeRequest(api.CTAsList);

  if (response.success) yield put(successListCTAs(response.data));
}

function* fetchConnectVideoWorker(action) {
  const { connectVideoSlug } = action.payload;

  const response = yield getResponseOr404(api.connectVideoDetail, {
    lookupData: { connectVideoSlug }
  });

  if (response.success) {
    yield put(successFetchConnectVideo(response));
  } else {
    yield put(failFetchConnectVideo(response));
  }
}

export function* detailSaga() {
  yield takeLatest(FETCH_CONNECT_VIDEO, fetchConnectVideoWorker);
  yield takeLatest(LIST_CTAS, fetchCTAsWorker);
}
