import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import WithPush from 'shared/HOCs/WithPush';

import { closeNotificationBar } from './ducks';

import styles from './styles.module.scss';

class NotificationBar extends React.Component {
  render() {
    const { isOpen, content, redirectUrl, isWarning } = this.props;
    return (
      <div
        className={classnames(
          styles.notificationBarContainer,
          { [styles.hidden]: !isOpen },
          { [styles.warning]: isWarning }
        )}>
        <div
          className={styles.content}
          onClick={() => redirectUrl && this.props.push(redirectUrl)}>
          {content}
        </div>
        <div className={styles.close} onClick={this.props.closeNotificationBar}>
          <i className="material-icons">clear</i>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isOpen, content, redirectUrl, isWarning } = state.notificationBar;

  return { isOpen, content, redirectUrl, isWarning };
};
const mapDispatchToProps = { closeNotificationBar };

export default WithPush(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotificationBar)
);
