const actionPrefix = 'EditCompany/';

export const EDIT_COMPANY_TABS = {
  HEADLINES: 0,
  PRODUCTS: 1,
  QNA: 2,
  LOCATION: 3,
  CONTACT: 4
};

const SET_ACTIVE_TAB = `${actionPrefix}SET_ACTIVE_TAB`;

export const setActiveTab = tab => ({
  type: SET_ACTIVE_TAB,
  payload: {
    tab
  }
});

export const tabManagementReducer = (newState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      newState['activeTab'] = action.payload.tab;
      break;

    default:
      break;
  }
};
