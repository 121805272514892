import { getFormValues } from 'redux-form';

import { normalizeCompanyContactFields } from 'shared/utils/company';

import { SERVICE_PROVIDER_CONTACT_FORM } from 'shared/components/ServiceProviderForm/ducks';

export const getServiceProviderContactData = state => {
  let formContactData = getFormValues(SERVICE_PROVIDER_CONTACT_FORM)(state);

  formContactData = normalizeCompanyContactFields(formContactData);

  return formContactData;
};
