import { companiesReducer, companiesWatcher } from './list';
import {
  previewCompaniesReducer,
  previewCompaniesWatcher
} from './previewCompanies';

const DELETE_STATE = 'Companies/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  companies: [],
  nextUrl: null,
  isFetching: true,
  previewCompanies: [],
  preferentialCompanies: [],
  all_companies: [],
  isFetchingPreferentialCompany: true
};

export const listCompaniesReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  companiesReducer(initialState, newState, action);
  previewCompaniesReducer(newState, action);

  return newState;
};

export const companiesSagas = [companiesWatcher, previewCompaniesWatcher];

export * from './list';
export * from './previewCompanies';
