import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'ShareTenderModal/';

const SHARE_TENDER = `${actionPrefix}SHARE_TENDER`;
const SUCCESS_SHARE_TENDER = `${actionPrefix}SUCCESS_SHARE_TENDER`;
const FAIL_SHARE_TENDER = `${actionPrefix}FAIL_SHARE_TENDER`;

const RESET_ERRORS = `${actionPrefix}RESET_ERRORS`;

export const shareTender = ({ tenderId, emails, closeModal }) => ({
  type: SHARE_TENDER,
  payload: { tenderId, emails, closeModal }
});

const successShareTender = () => ({
  type: SUCCESS_SHARE_TENDER
});

const failShareTender = errors => ({
  type: FAIL_SHARE_TENDER,
  payload: { errors }
});

export const resetErrors = () => ({
  type: RESET_ERRORS
});

const initialState = {
  errors: [],
  isSubmitting: false
};

export const shareTenderModalReducer = (state = initialState, action) => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case SHARE_TENDER:
      newState['isSubmitting'] = true;
      break;

    case SUCCESS_SHARE_TENDER:
      newState['isSubmitting'] = false;
      break;

    case FAIL_SHARE_TENDER:
      newState['isSubmitting'] = false;
      newState['errors'] = action.payload.errors;
      break;

    case RESET_ERRORS:
      newState['errors'] = [];
      break;

    default:
      return newState;
  }

  return newState;
};

function* shareTenderWorker(action) {
  const { tenderId, emails, closeModal } = action.payload;

  const response = yield makeRequest(api.tenderCollaboratorAdd, {
    lookupData: { tenderId },
    requestBody: { emails }
  });

  if (response.success) {
    yield* [
      put(successShareTender()),
      put(
        openNotificationBar(
          "Thanks for sharing. We've let your collaborator know they've been invited."
        )
      )
    ];

    return closeModal();
  } else {
    const nonEmailErrors = _.omit(response.errors, 'emails');
    const emailErrors = _.pick(response.errors, 'emails');

    let action;

    if (!_.isEmpty(nonEmailErrors)) {
      closeModal();

      action = put(
        openNotificationBar(
          'Something went wrong. Please try again later.',
          null,
          true
        )
      );
    } else {
      action = put(failShareTender(_.get(emailErrors, 'emails', [])));
    }

    yield action;
  }
}

export function* shareTenderModalSaga() {
  yield takeLatest(SHARE_TENDER, shareTenderWorker);
}
