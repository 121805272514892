import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest, getResponseOr404 } from 'shared/sdk';

import { REQUIRED_FUNDRAISING_FIELDS } from 'pages/Company/Detail/FundraisingDetails/constants';

const actionPrefix = 'Company/Detail/';

const FETCH_COMPANY = `${actionPrefix}FETCH_COMPANY`;
const SUCCESS_FETCH_COMPANY = `${actionPrefix}SUCCESS_FETCH_COMPANY`;
const FAIL_FETCH_COMPANY = `${actionPrefix}FAIL_FETCH_COMPANY`;

const FOLLOW_COMPANY = `${actionPrefix}FOLLOW_COMPANY`;
const SUCCESS_FOLLOW_COMPANY = `${actionPrefix}SUCCESS_FOLLOW_COMPANY`;
const FAIL_FOLLOW_COMPANY = `${actionPrefix}FAIL_FOLLOW_COMPANY`;

const UNFOLLOW_COMPANY = `${actionPrefix}UNFOLLOW_COMPANY`;
const SUCCESS_UNFOLLOW_COMPANY = `${actionPrefix}SUCCESS_UNFOLLOW_COMPANY`;
const FAIL_UNFOLLOW_COMPANY = `${actionPrefix}FAIL_UNFOLLOW_COMPANY`;

const TOGGLE_PRODUCT = `${actionPrefix}TOGGLE_PRODUCT`;

const FETCH_FUNDRAISING_COMPANIES_COUNT = `${actionPrefix}FETCH_FUNDRAISING_COMPANIES_COUNT`;
const SUCCESS_FETCH_FUNDRAISING_COMPANIES_COUNT = `${actionPrefix}SUCCESS_FETCH_FUNDRAISING_COMPANIES_COUNT`;

export const fetchCompany = slug => ({
  type: FETCH_COMPANY,
  payload: { slug }
});

export const successFetchCompany = data => ({
  type: SUCCESS_FETCH_COMPANY,
  payload: {
    data
  }
});

export const failFetchCompany = errors => ({
  type: FAIL_FETCH_COMPANY,
  payload: {
    errors
  }
});

export const followCompany = slug => ({
  type: FOLLOW_COMPANY,
  payload: { slug }
});

const successFollowCompany = data => ({
  type: SUCCESS_FOLLOW_COMPANY,
  payload: {
    data
  }
});

const failFollowCompany = errors => ({
  type: FAIL_FOLLOW_COMPANY,
  payload: {
    errors
  }
});

export const unfollowCompany = slug => ({
  type: UNFOLLOW_COMPANY,
  payload: { slug }
});

const successUnfollowCompany = data => ({
  type: SUCCESS_UNFOLLOW_COMPANY,
  payload: {
    data
  }
});

const failUnfollowCompany = errors => ({
  type: FAIL_UNFOLLOW_COMPANY,
  payload: {
    errors
  }
});

export const toggleProduct = id => ({
  type: TOGGLE_PRODUCT,
  payload: { id }
});

const fetchFundraisingCompaniesCount = () => ({
  type: FETCH_FUNDRAISING_COMPANIES_COUNT
});

const successFetchFundraisingCompaniesCount = count => ({
  type: SUCCESS_FETCH_FUNDRAISING_COMPANIES_COUNT,
  payload: { count }
});

const companyReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_COMPANY:
      const company = action.payload.data;
      state['company'] = { ...action.payload.data };
      if (company.products)
        state['products'] = company.products.map((product, index) => ({
          ...product,
          isOpen: index === 0 ? true : false
        }));
      if (company.funding_stages)
        state['fundings'] = company.funding_stages.map((funding, index) => ({
          ...funding,
          isOpen: index === 0 ? true : false
        }));

      state['isFetching'] = false;
      state['performingFollowOperation'] = false;
      break;

    case FAIL_FETCH_COMPANY:
      state['isFetching'] = false;
      break;

    case FOLLOW_COMPANY:
      state['performingFollowOperation'] = true;
      break;

    case UNFOLLOW_COMPANY:
      state['performingFollowOperation'] = true;
      break;

    case SUCCESS_FOLLOW_COMPANY:
      state['company'] = { ...state.company, followed: true };
      state['performingFollowOperation'] = false;
      break;

    case SUCCESS_UNFOLLOW_COMPANY:
      state['company'] = { ...state.company, followed: false };
      state['performingFollowOperation'] = false;
      break;

    case TOGGLE_PRODUCT:
      const { id } = action.payload;
      state['products'] = state.products.map(
        product =>
          product.id === id ? { ...product, isOpen: !product.isOpen } : product
      );
      break;

    case SUCCESS_FETCH_FUNDRAISING_COMPANIES_COUNT:
      state['company'] = {
        ...state.company,
        fundraising_companies_count: action.payload.count
      };
      break;
    default:
      return state;
  }
};

function* fetchCompanyWorker(action) {
  const response = yield getResponseOr404(api.companiesDetail, {
    lookupData: action.payload
  });

  if (response.success) {
    let actions = [() => put(successFetchCompany(response.data))];

    const is_raising_funds =
      response.data.is_raising_funds &&
      _.every(
        REQUIRED_FUNDRAISING_FIELDS,
        field => !_.isNil(_.get(response.data, field))
      );

    if (!is_raising_funds)
      actions.push(() => put(fetchFundraisingCompaniesCount()));

    if (response.data) yield* [...actions.map(action => action())];
  } else yield put(failFetchCompany(response.errors));
}

export function* followCompanyWorker(action) {
  const response = yield makeRequest(api.followCompany, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFollowCompany(response.data));
  else yield put(failFollowCompany(response.errors));
}

export function* unfollowCompanyWorker(action) {
  const response = yield makeRequest(api.unfollowCompany, {
    lookupData: action.payload
  });

  if (response.success) yield put(successUnfollowCompany(response.data));
  else yield put(failUnfollowCompany(response.errors));
}

function* fetchFundraisingCompaniesCountWorker(action) {
  const response = yield makeRequest(api.companiesList, {
    requestData: { params: { is_raising_funds: true } }
  });

  if (response.success)
    yield put(successFetchFundraisingCompaniesCount(response.data.count));
}

export function* companySaga() {
  yield takeLatest(FETCH_COMPANY, fetchCompanyWorker);
  yield takeLatest(FOLLOW_COMPANY, followCompanyWorker);
  yield takeLatest(UNFOLLOW_COMPANY, unfollowCompanyWorker);
  yield takeLatest(
    FETCH_FUNDRAISING_COMPANIES_COUNT,
    fetchFundraisingCompaniesCountWorker
  );
}

export default companyReducer;
