import _ from 'lodash';
import Pusher from 'pusher-js';

import store from 'config/store';

import { fetchMe } from 'shared/HOCs/FetchInitials/ducks';
import { newPostIsPresent } from 'pages/Dashboard/ducks';
import { fetchAndGroupUnseenMessages } from 'pages/Messaging/ducks';
import { fetchUploadedVendorProfileVideo } from 'pages/Company/Edit/ducks';

import { userCameOnline, userWentOffline } from './ducks';

// TODO: Use constants instead of magic strings. BE needs to implement them first and provide them here.
class PusherManagerClass {
  constructor() {
    this.pusherClient = null;
    this.generalChannel = null;
    this.userSpecificChannel = null;
  }

  initPusherClient() {
    this.pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      authEndpoint: `${process.env.REACT_APP_BACKEND_URL}/pusher/authenticate/`,
      auth: {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      }
    });
  }

  disconnect() {
    if (_.isNil(this.pusherClient)) {
      return;
    }

    this.pusherClient.disconnect();
    this.pusherClient = null;
  }

  getPusherClient() {
    if (_.isNil(this.pusherClient)) {
      this.initPusherClient();
    }

    return this.pusherClient;
  }

  subscribeToUserSpecificChannel(userToken) {
    if (!_.isNil(this.userSpecificChannel)) {
      return;
    }

    const pusherClient = this.getPusherClient();
    // TODO: Format this more gracefully. Use a constant as well.
    this.userSpecificChannel = pusherClient.subscribe(
      'private-user-channel-' + userToken
    );

    this.userSpecificChannel.bind('new-conversation-message', data => {
      store.dispatch(fetchAndGroupUnseenMessages(data));
    });
    this.userSpecificChannel.bind('new-notification', data => {
      store.dispatch(fetchMe());
    });
    this.userSpecificChannel.bind(
      'vendor-profile-video-upload-finished',
      data => {
        const { identifier } = data;

        store.dispatch(fetchUploadedVendorProfileVideo({ identifier }));
      }
    );
  }

  subscribeToGeneralChannel() {
    if (!_.isNil(this.generalChannel)) {
      return;
    }

    const pusherClient = this.getPusherClient();
    this.generalChannel = pusherClient.subscribe('private-general-channel');

    this.generalChannel.bind('user-came-online', data => {
      store.dispatch(userCameOnline(data));
    });
    this.generalChannel.bind('user-went-offline', data => {
      store.dispatch(userWentOffline(data));
    });
    this.generalChannel.bind('new-post', data => {
      store.dispatch(newPostIsPresent(data));
    });
  }
}

export const PusherManager = new PusherManagerClass();
