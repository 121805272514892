import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { prependCommentReplies } from 'shared/utils/comments';
import { addToList, updateByIdentifier } from 'shared/utils/reducerUtils';

const actionPrefix = 'ConnectVideo/Detail/CommentReplies';

const CREATE_COMMENT_REPLY = `${actionPrefix}CREATE_COMMENT_REPLY`;
const SUCCESS_CREATE_COMMENT_REPLY = `${actionPrefix}SUCCESS_CREATE_COMMENT_REPLY`;
const FAIL_CREATE_COMMENT_REPLY = `${actionPrefix}FAIL_CREATE_COMMENT_REPLY`;

const FETCH_COMMENT_REPLIES = `${actionPrefix}FETCH_COMMENT_REPLIES`;
const SUCCESS_FETCH_COMMENT_REPLIES = `${actionPrefix}SUCCESS_FETCH_COMMENT_REPLIES`;
const FAIL_FETCH_COMMENT_REPLIES = `${actionPrefix}FAIL_FETCH_COMMENT_REPLIES`;

export const createCommentReply = ({ content, mentions, commentId }) => ({
  type: CREATE_COMMENT_REPLY,
  payload: { content, mentions, commentId }
});

const successCreateCommentReply = ({ data, commentId }) => ({
  type: SUCCESS_CREATE_COMMENT_REPLY,
  payload: { data, commentId }
});

const failCreateCommentReply = ({ errors }) => ({
  type: FAIL_CREATE_COMMENT_REPLY,
  errors
});

export const fetchCommentReplies = ({ commentId, url }) => ({
  type: FETCH_COMMENT_REPLIES,
  payload: { commentId, url }
});

const successFetchCommentReplies = ({ commentId, results, nextUrl }) => ({
  type: SUCCESS_FETCH_COMMENT_REPLIES,
  payload: { commentId, results, nextUrl }
});

const failFetchCommentReplies = ({ errors }) => ({
  type: FAIL_FETCH_COMMENT_REPLIES,
  errors
});

export const commentRepliesReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_CREATE_COMMENT_REPLY:
      state['comments'] = state['comments'].map(
        comment =>
          comment.id === action.payload.commentId
            ? {
                ...comment,
                reply_count: _.get(comment, 'reply_count', 0) + 1,
                replies: addToList(
                  _.get(comment, 'replies', []),
                  action.payload.data
                )
              }
            : comment
      );
      break;

    case FETCH_COMMENT_REPLIES:
      state['comments'] = updateByIdentifier({
        collection: state['comments'],
        identifierField: 'id',
        identifierValue: action.payload.commentId,
        newItem: { isFetchingReplies: true, repliesNextUrl: null }
      });
      break;

    case SUCCESS_FETCH_COMMENT_REPLIES:
      state['comments'] = state['comments'].map(
        comment =>
          comment.id === action.payload.commentId
            ? {
                ...comment,
                isFetchingReplies: false,
                repliesNextUrl: action.payload.nextUrl,
                replies: prependCommentReplies({
                  oldReplies: _.get(comment, 'replies', []),
                  newReplies: action.payload.results
                })
              }
            : comment
      );
      break;

    default:
      return state;
  }
};

function* createCommentReplyWorker(action) {
  const { commentId, content, mentions } = action.payload;

  const response = yield makeRequest(api.replyToCommentPost, {
    lookupData: { commentId },
    requestBody: { content, mentioned_users_ids: mentions }
  });

  if (response.success) {
    yield put(successCreateCommentReply({ data: response.data, commentId }));
  } else {
    yield put(failCreateCommentReply(response));
  }
}

function* fetchCommentRepliesWorker(action) {
  const { commentId, url } = action.payload;

  let response;

  if (!_.isNil(url)) {
    response = yield makeRequest(api.genericGet, {
      lookupData: url
    });
  } else {
    response = yield makeRequest(api.fetchCommentReplies, {
      lookupData: { commentId },
      requestData: { params: { limit: 5 } }
    });
  }

  if (response.success) {
    yield put(
      successFetchCommentReplies({
        commentId,
        results: response.data.results,
        nextUrl: response.data.next
      })
    );
  } else {
    yield put(failFetchCommentReplies(response));
  }
}

export function* commentRepliesSaga() {
  yield takeLatest(CREATE_COMMENT_REPLY, createCommentReplyWorker);
  yield takeLatest(FETCH_COMMENT_REPLIES, fetchCommentRepliesWorker);
}
