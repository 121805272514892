import { put, takeLatest } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

const actionPrefix = 'CreateTender/';

const CREATE_TENDER = `${actionPrefix}CREATE_TENDER`;
const SUCCESS_CREATE_TENDER = `${actionPrefix}SUCCESS_CREATE_TENDER`;
const FAIL_CREATE_TENDER = `${actionPrefix}FAIL_CREATE_TENDER`;

export const createTender = (formName, data, extraActions) => ({
  type: CREATE_TENDER,
  formName,
  payload: { data },
  extraActions
});

const successCreateTender = payload => ({
  type: SUCCESS_CREATE_TENDER,
  payload
});

const failCreateTender = payload => ({
  type: FAIL_CREATE_TENDER,
  payload
});

function* createTenderWorker(action) {
  let { data } = action.payload;

  const createResponse = yield makeRequest(api.createTender, {
    requestBody: data
  });

  if (createResponse.success)
    yield* [
      put(successCreateTender(createResponse.data)),
      ...action.extraActions.map(action => put(action()))
    ];
  else {
    yield put(failCreateTender(createResponse.errors));

    return createResponse.errors;
  }
}

export function* createTenderWatcher() {
  yield takeLatest(CREATE_TENDER, formSubmitWorker(createTenderWorker));
}
