import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';
import { openNotificationBar } from 'shared/components/NotificationBar/ducks';

const actionPrefix = 'Companies/Detail/News/';

const FETCH_NEWS = `${actionPrefix}FETCH_NEWS`;
const SUCCESS_FETCH_NEWS = `${actionPrefix}SUCCESS_FETCH_NEWS`;
const FAIL_FETCH_NEWS = `${actionPrefix}FAIL_FETCH_NEWS`;

const FETCH_FEATURED_NEWS = `${actionPrefix}FETCH_FEATURED_NEWS`;
const SUCCESS_FETCH_FEATURED_NEWS = `${actionPrefix}SUCCESS_FETCH_FEATURED_NEWS`;
const FAIL_FETCH_FEATURED_NEWS = `${actionPrefix}FAIL_FETCH_FEATURED_NEWS`;

const TOGGLE_NEWS_ITEM = `${actionPrefix}TOGGLE_NEWS_ITEM`;
const SUCCESS_TOGGLE_NEWS_ITEM = `${actionPrefix}SUCCESS_TOGGLE_NEWS_ITEM`;
const FAIL_TOGGLE_NEWS_ITEM = `${actionPrefix}FAIL_TOGGLE_NEWS_ITEM`;

const ADD_NEWS = `${actionPrefix}ADD_NEWS`;

export const fetchNews = ({ slug, offset, limit, ordering }) => ({
  type: FETCH_NEWS,
  payload: {
    slug,
    offset,
    limit,
    ordering
  }
});

const successFetchNews = payload => ({
  type: SUCCESS_FETCH_NEWS,
  payload
});

const failFetchNews = errors => ({
  type: FAIL_FETCH_NEWS,
  errors
});

export const fetchFeaturedNews = ({ slug, offset, limit, ordering }) => ({
  type: FETCH_FEATURED_NEWS,
  payload: {
    slug,
    offset,
    limit,
    ordering
  }
});

const successFetchFeaturedNews = payload => ({
  type: SUCCESS_FETCH_FEATURED_NEWS,
  payload
});

const failFetchFeaturedNews = errors => ({
  type: FAIL_FETCH_FEATURED_NEWS,
  errors
});

export const toggleNewsItem = ({ newsItemId }) => ({
  type: TOGGLE_NEWS_ITEM,
  payload: {
    newsItemId
  }
});

const successToggleNewsItem = ({ newsItemId, hidden }) => ({
  type: SUCCESS_TOGGLE_NEWS_ITEM,
  payload: {
    newsItemId,
    hidden
  }
});

const failToggleNewsItem = errors => ({
  type: FAIL_TOGGLE_NEWS_ITEM,
  payload: {
    errors
  }
});

export const addNewsItem = ({ data, formName, extraActions, companySlug }) => ({
  type: ADD_NEWS,
  formName,
  payload: {
    data,
    companySlug,
    extraActions
  }
});

export const newsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_NEWS:
    case FETCH_FEATURED_NEWS:
      state['isFetchingNews'] = true;
      break;

    case SUCCESS_FETCH_NEWS:
      state['news'] = action.payload.results;
      state['newsCount'] = action.payload.count;
      state['isFetchingNews'] = false;
      break;

    case SUCCESS_FETCH_FEATURED_NEWS:
      state['featuredNews'] = action.payload.results;
      state['isFetchingNews'] = false;
      break;

    case SUCCESS_TOGGLE_NEWS_ITEM:
      state['news'] = state['news'].filter(
        newsItem => newsItem.id !== action.payload.newsItemId
      );
      state['featuredNews'] = state['featuredNews'].filter(
        newsItem => newsItem.id !== action.payload.newsItemId
      );
      break;

    default:
      return state;
  }
};

function* fetchNewsWorker(action) {
  const { slug, limit, offset, ordering } = action.payload;

  const response = yield makeRequest(api.companyNewsList, {
    lookupData: { slug },
    requestData: { params: { limit, offset, ordering } }
  });

  if (response.data) {
    yield put(successFetchNews(response.data));
  } else {
    yield put(failFetchNews(response.errors));
  }
}

function* fetchFeaturedNewsWorker(action) {
  const { slug, limit, offset, ordering } = action.payload;

  const response = yield makeRequest(api.companyNewsList, {
    lookupData: { slug },
    requestData: { params: { limit, offset, ordering } }
  });

  if (response.data) {
    yield put(successFetchFeaturedNews(response.data));
  } else {
    yield put(failFetchFeaturedNews(response.errors));
  }
}

function* toggleNewsItemWorker(action) {
  const { newsItemId } = action.payload;

  const response = yield makeRequest(api.companyNewsItemToggle, {
    lookupData: { newsItemId }
  });

  if (response.success) {
    yield put(successToggleNewsItem({ newsItemId, ...response.data }));
  } else {
    yield put(failToggleNewsItem(response.errors));
  }
}

function* addNewsItemWorker(action) {
  const { data, companySlug, extraActions } = action.payload;

  const response = yield makeRequest(api.addVendorNews, {
    lookupData: { companySlug },
    requestBody: data
  });

  if (response.success) {
    const notificationContent = 'Article will be available shortly.';

    extraActions.map(action => action());
    yield put(openNotificationBar(notificationContent));
  } else {
    return response.errors;
  }
}

export function* newsSaga() {
  yield takeLatest(FETCH_NEWS, fetchNewsWorker);
  yield takeLatest(FETCH_FEATURED_NEWS, fetchFeaturedNewsWorker);
  yield takeLatest(TOGGLE_NEWS_ITEM, toggleNewsItemWorker);
  yield takeLatest(ADD_NEWS, formSubmitWorker(addNewsItemWorker));
}
