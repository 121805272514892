import { listReducer, listSaga } from './list';
import { markAsSeenSaga } from './markAsSeen';

const actionPrefix = 'Notifications/';

const DELETE_STATE = `${actionPrefix}/DELETE_STATE`;

const initialState = {
  notifications: [],
  notificationsNextUrl: null,
  isFetchingNotifications: false
};

export const deleteState = () => ({
  type: DELETE_STATE
});

export const notificationReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) {
    return initialState;
  }

  listReducer(newState, action);

  return newState;
};

export const notificationSagas = [listSaga, markAsSeenSaga];

export * from './list';
export * from './markAsSeen';
