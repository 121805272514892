import {
  influencersListReducer,
  influencersListWatcher
} from './influencersList';
import {
  influencersPreviewReducer,
  influencersPreviewWatcher
} from './influencersPreview';
import { companiesReducer, companiesWatcher } from './company';
const DELETE_STATE = 'Influencers/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

export const CONNECTED = 'connected';
export const YOUR_TEAM = 'vendor';
export const LIMIT = 'limit';
export const ORDERING = 'ordering';

const initialState = {
  influencers: [],
  nextPageUrl: null,
  influencersPreview: [],
  isFetchingInfluencersPreview: false,
  isFetching: true,
  influencersCount: 0
};

export const influencersReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  influencersListReducer(initialState, newState, action);
  influencersPreviewReducer(newState, action);
  companiesReducer(newState, action);

  return newState;
};

export const influencersSagas = [
  influencersListWatcher,
  influencersPreviewWatcher,
  companiesWatcher
];

export * from './influencersList';
export * from './influencersPreview';
export * from './company';
