import { updateHeadlinesReducer, updateHeadlinesSaga } from './updateHeadlines';
import { tabManagementReducer, EDIT_COMPANY_TABS } from './tabManagement';
import { updateLocationReducer, updateLocationSaga } from './updateLocation';
import { updateContactSaga } from './updateContact';
import { questionsReducer, questionsSaga } from './questions';
import { productsReducer, productsWatcher } from './products';

export const actionPrefix = 'EditCompany/UpdateHeadlines/';

const DELETE_STATE = `${actionPrefix}/DELETE_STATE`;

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  company: {},
  questions: [],
  activeTab: EDIT_COMPANY_TABS.HEADLINES,
  countries: [],
  sectorChoices: [],
  solutionChoices: [],
  isUpdatingQuestions: false,
  creatingProduct: false,
  companyImages: [],
  profileVideo: null
};

export const editCompanyReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  if (action.type === DELETE_STATE) newState = initialState;

  updateHeadlinesReducer(newState, action);
  tabManagementReducer(newState, action);
  updateLocationReducer(newState, action);
  questionsReducer(newState, action);
  productsReducer(newState, action);

  return newState;
};

export const editCompanySagas = [
  updateHeadlinesSaga,
  updateLocationSaga,
  updateContactSaga,
  questionsSaga,
  productsWatcher
];

export * from './updateHeadlines';
export * from './tabManagement';
export * from './updateLocation';
export * from './updateContact';
export * from './questions';
export * from './products';
