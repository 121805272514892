import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { uploadFile } from 'shared/utils/fileUpload';
import { fetchMe } from 'shared/HOCs/FetchInitials/ducks';
import { nextStep } from '../';

const actionPrefix = 'Signup/';
const PATCH_USER = `${actionPrefix}PATCH_USER`;
const SUCCESS_PATCH_USER = `${actionPrefix}SUCCESS_PATCH_USER`;
const FAIL_PATCH_USER = `${actionPrefix}FAIL_PATCH_USER`;

const UPDATE_PROFILE_AND_PREFERECES = `${actionPrefix}UPDATE_PROFILE_AND_PREFERECES`;
const SUCCESS_UPDATE_PROFILE_AND_PREFERECES = `${actionPrefix}SUCCESS_UPDATE_PROFILE_AND_PREFERECES`;
const FAIL_UPDATE_PROFILE_AND_PREFERECES = `${actionPrefix}FAIL_UPDATE_PROFILE_AND_PREFERECES`;

export const patchUser = ({
  user_type = null,
  sectors = [],
  country = null
}) => {
  return {
    type: PATCH_USER,
    payload: {
      user_type,
      sectors,
      country
    }
  };
};

export const patchUserSectors = ({ sectors = [] }) => ({
  type: PATCH_USER,
  payload: {
    sectors
  }
});

export const patchUserCountry = ({ country = null }) => ({
  type: PATCH_USER,
  payload: {
    sectors: [],
    country
  }
});

export const patchUserLifecycle = ({ lifecycle = [] }) => ({
  type: PATCH_USER,
  payload: {
    lifecycle
  }
});

export const patchUserServiceProviders = ({ service_areas = [] }) => ({
  type: PATCH_USER,
  payload: { service_areas }
});

const successPatchUser = payload => {
  return {
    type: SUCCESS_PATCH_USER,
    payload
  };
};

const failPatchUser = errors => {
  return {
    type: FAIL_PATCH_USER,
    payload: {
      errors
    }
  };
};

export const updateProfile = (formName, data) => ({
  type: UPDATE_PROFILE_AND_PREFERECES,
  formName,
  payload: data
});

const successUpdateProfile = data => ({
  type: SUCCESS_UPDATE_PROFILE_AND_PREFERECES,
  payload: data
});

const failUpdateProfile = errors => ({
  type: FAIL_UPDATE_PROFILE_AND_PREFERECES,
  payload: {
    errors
  }
});

export const patchUserReducer = (newState, action) => {
  switch (action.type) {
    case SUCCESS_PATCH_USER:
      newState['workEmail'] = action.payload.email;
      break;

    default:
      return newState;
  }
};

function* patchUserWorker(action) {
  const response = yield makeRequest(api.userPatch, {
    requestBody: action.payload
  });

  if (response.success)
    yield* [
      put(successPatchUser(response.data)),
      put(nextStep()),
      put(fetchMe())
    ];
  else yield put(failPatchUser(response.data));
}

export function* uploadAvatarS3(avatar) {
  const avatarUrl = yield uploadFile(avatar, api.signUserAvatar);

  return avatarUrl;
}

function* fetchUpdateProfileWorker(action) {
  let data = action.payload;

  const avatarArr = _.get(action.payload, 'profile.avatar', null);

  if (!_.isNil(avatarArr) && Array.isArray(avatarArr)) {
    delete data.profile.avatar;

    const s3avatar = yield uploadAvatarS3(avatarArr[0]);

    data = { ...data, profile: { ...data.profile, avatar: s3avatar } };
  }

  const response = yield makeRequest(api.userProfileAndPreferencesUpdate, {
    requestBody: data
  });

  if (response.success)
    yield* [put(successUpdateProfile()), put(nextStep()), put(fetchMe())];
  else {
    yield put(failUpdateProfile(response.errors));
    return response.errors;
  }
}

export function* patchUserSaga() {
  yield takeLatest(PATCH_USER, patchUserWorker);
  yield takeLatest(
    UPDATE_PROFILE_AND_PREFERECES,
    formSubmitWorker(fetchUpdateProfileWorker)
  );
}
