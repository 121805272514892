import _ from 'lodash';

import { detailReducer, detailSaga } from './detail';
import { notesReducer, notesSaga } from './notes';
import { sharingReducer, sharingSaga } from './sharing';
import { teamReducer, teamSaga } from './team';

const RESET_STATE = 'TenderResponse/RESET_STATE';

export const resetState = () => ({ type: RESET_STATE });

const initialState = {
  isFetching: true,
  tenderResponse: null,
  isFetchingTenderResponseNotes: false,
  tenderCollaborators: {
    collaborators: [],
    isFetching: true,
    nextUrl: null
  },
  notes: {
    errors: [],
    notes: [],
    isFetching: true,
    nextUrl: null,
    isModifyingNote: false,
    createOrUpdateActionPayload: {}
  },
  team: {
    members: [],
    isFetching: true,
    nextUrl: null
  }
};

export const tenderResponseReducer = (
  state = _.cloneDeep(initialState),
  action
) => {
  let newState = _.cloneDeep(state);

  if (action.type === RESET_STATE) {
    newState = _.cloneDeep(initialState);
  }

  teamReducer(newState, action);
  notesReducer(newState, action);
  detailReducer(newState, action);
  sharingReducer(newState, action);

  return newState;
};

export const tenderResponseSaga = [
  teamSaga,
  notesSaga,
  detailSaga,
  sharingSaga
];

export * from './detail';
export * from './notes';
export * from './sharing';
export * from './team';
