import { takeLatest } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'ServiceProvider/Detail/';

export const FETCH_SERVICE_PROVIDER = `${actionPrefix}FETCH_SERVICE_PROVIDER`;
export const SUCCESS_FETCH_SERVICE_PROVIDER = `${actionPrefix}SUCCESS_FETCH_SERVICE_PROVIDER`;
export const FAIL_FETCH_SERVICE_PROVIDER = `${actionPrefix}FAIL_FETCH_SERVICE_PROVIDER`;

export const fetchServiceProvider = serviceProviderSlug => ({
  type: FETCH_SERVICE_PROVIDER,
  payload: { slug: serviceProviderSlug }
});

export const successFetchServiceProvider = data => ({
  type: SUCCESS_FETCH_SERVICE_PROVIDER,
  payload: {
    data
  }
});

export const failFetchServiceProvider = errors => ({
  type: FAIL_FETCH_SERVICE_PROVIDER,
  payload: {
    errors
  }
});

export const serviceProviderReducer = (state, action) => {
  switch (action.type) {
    case SUCCESS_FETCH_SERVICE_PROVIDER:
      state['serviceProvider'] = action.payload.data;
      state['isFetching'] = false;
      break;

    default:
      return state;
  }
};

export function* fetchServiceProviderWorker(action) {
  const response = yield getResponseOr404(api.serviceProviderDetail, {
    lookupData: action.payload
  });

  if (response.success) yield put(successFetchServiceProvider(response.data));
  else yield put(failFetchServiceProvider(response.errors));
}

export function* serviceProviderSaga() {
  yield takeLatest(FETCH_SERVICE_PROVIDER, fetchServiceProviderWorker);
}
