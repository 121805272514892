import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';
import { addToList } from 'shared/utils/reducerUtils';

const actionPrefix = 'EditProfile/';

const FETCH_USER_RESOURCES = `${actionPrefix}FETCH_USER_RESOURCES`;
const SUCCESS_FETCH_USER_RESOURCES = `${actionPrefix}SUCCESS_FETCH_USER_RESOURCES`;
const FAIL_FETCH_USER_RESOURCES = `${actionPrefix}FAIL_FETCH_USER_RESOURCES`;

export const fetchUserResources = ({ userId, nextUrl }) => ({
  type: FETCH_USER_RESOURCES,
  payload: {
    author: userId,
    nextUrl
  }
});

const successFetchUserResources = payload => ({
  type: SUCCESS_FETCH_USER_RESOURCES,
  payload
});

const failFetchUserResources = payload => ({
  type: FAIL_FETCH_USER_RESOURCES,
  payload
});

export const resourcesReducer = (newState, action) => {
  switch (action.type) {
    case FETCH_USER_RESOURCES:
      newState['isFetchingResources'] = true;
      break;

    case SUCCESS_FETCH_USER_RESOURCES:
      newState['isFetchingResources'] = false;
      newState['resources'] = addToList(
        newState.resources,
        action.payload.results
      );
      newState['fetchedInitialResources'] = true;
      newState['resourceCount'] = action.payload.count;
      newState['resourcesNext'] = action.payload.next;
      break;

    default:
      return newState;
  }
};

function* fetchUserResourcesWorker(action) {
  const { nextUrl, author } = action.payload;

  let response;

  if (!_.isNil(nextUrl)) {
    response = yield makeRequest(api.genericGet, {
      lookupData: nextUrl
    });
  } else {
    const params = { author, limit: 5 };

    response = yield makeRequest(api.resourceList, {
      requestData: { params }
    });
  }

  if (response.success) {
    yield put(successFetchUserResources(response.data));
  } else {
    yield put(failFetchUserResources(response.errors));
  }
}

export function* resourcesWatcher() {
  yield takeLatest(FETCH_USER_RESOURCES, fetchUserResourcesWorker);
}
