import { getFormValues } from 'redux-form';

import { normalizeUrl } from 'shared/utils/url';
import { normalizeRenderedSelect as normalize } from 'shared/forms/RenderSelect';

import { SERVICE_PROVIDER_HEADLINES_FORM } from 'shared/components/ServiceProviderForm/ducks';

export const getServiceProviderHeadlinesData = state => {
  const formHeadlinesData = getFormValues(SERVICE_PROVIDER_HEADLINES_FORM)(
    state
  );
  const serviceProviderImages =
    state.shared.serviceProviderForm.serviceProviderImages;

  const headlinesData = {
    ...formHeadlinesData,
    images: serviceProviderImages,
    service_types: normalize(formHeadlinesData.service_types, true),
    sectors: normalize(formHeadlinesData.sectors, true),
    clients: normalize(formHeadlinesData.clients, true),
    website: normalizeUrl({ url: formHeadlinesData.website })
  };

  return {
    ...headlinesData
  };
};
