import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { uploadFile } from 'shared/utils/fileUpload';
import { formSubmitWorker } from 'shared/utils/formSubmitWorkerFactory';

import { api, makeRequest } from 'shared/sdk';

const actionPrefix = 'PostEvent/';

const CREATE_EVENT_PROPOSAL = `${actionPrefix}CREATE_EVENT_PROPOSAL`;
const SUCCESS_CREATE_EVENT_PROPOSAL = `${actionPrefix}SUCCESS_CREATE_EVENT_PROPOSAL`;
const FAIL_CREATE_EVENT_PROPOSAL = `${actionPrefix}FAIL_CREATE_EVENT_PROPOSAL`;

export const createEventProposal = (formName, data, extraActions = []) => ({
  type: CREATE_EVENT_PROPOSAL,
  formName,
  payload: data,
  extraActions
});

const successCreateEventProposal = data => ({
  type: SUCCESS_CREATE_EVENT_PROPOSAL,
  payload: data
});

const failCreateEventProposal = data => ({
  type: FAIL_CREATE_EVENT_PROPOSAL,
  payload: data
});

function* proposeEventWorker(action) {
  const eventData = action.payload;

  let data = eventData;

  const imageArr = _.get(eventData, 'image', null);

  if (!_.isNil(imageArr)) {
    const image = imageArr[0];

    const imageUrl = yield uploadFile(image, api.signEventImage);

    data = Object.assign({}, data, { image: imageUrl });
  }

  const response = yield makeRequest(api.createEventProposal, {
    requestBody: data
  });

  if (response.success) {
    yield put(successCreateEventProposal(response.data));
    yield* action.extraActions.map(action => put(action()));
  } else {
    yield* [put(failCreateEventProposal(response.errors))];
    const errors = {
      ...response.errors,
      start_date: response.errors.starting_at,
      end_date: response.errors.ending_at
    };
    return errors;
  }
}

export function* proposeEventWatcher() {
  yield takeLatest(CREATE_EVENT_PROPOSAL, formSubmitWorker(proposeEventWorker));
}
