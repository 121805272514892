import _ from 'lodash';

const OPEN_MODAL = 'modals/OPEN_MODAL';
const CLOSE_MODAL = 'modals/CLOSE_MODAL';
const STORE_DATA_IN_MODAL = 'modals/STORE_DATA_IN_MODAL';

export const openModal = ({ modalName, data }) => ({
  type: OPEN_MODAL,
  payload: {
    modalName,
    data
  }
});

export const closeModal = modalName => ({
  type: CLOSE_MODAL,
  payload: {
    modalName
  }
});

export const storeDataInModal = (modalName, data) => ({
  type: STORE_DATA_IN_MODAL,
  payload: {
    modalName,
    data
  }
});

export const selectModal = (state, modalName) => state.modal[modalName];

export const isModalOpen = (state, modalName) =>
  _.get(state, `modal.${modalName}.isOpen`, false);

const initialState = {};

export const modalReducer = (state = initialState, action) => {
  const modalName = _.get(action, 'payload.modalName');

  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        [modalName]: {
          isOpen: true,
          data: action.payload.data || {}
        }
      };

    case CLOSE_MODAL:
      return {
        ...state,
        [modalName]: {
          isOpen: false,
          data: {}
        }
      };
    case STORE_DATA_IN_MODAL:
      return {
        ...state,
        [modalName]: {
          ...state[modalName],
          data: {
            ...state[modalName].data,
            ...action.payload.data
          }
        }
      };
    default:
      return state;
  }
};
