import { put } from 'redux-saga/effects';
import { api, makeRequest } from 'shared/sdk';
import { takeLatest } from 'redux-saga/effects';

const actionPrefix = 'Companies/Detail/';

export const FETCH_PRODUCT = `${actionPrefix}FETCH_PRODUCT`;
const SUCCESS_FETCH_PRODUCT = `${actionPrefix}SUCCESS_FETCH_PRODUCT`;
const FAIL_FETCH_PRODUCT = `${actionPrefix}FAIL_FETCH_PRODUCT`;

export const fetchProduct = id => ({
  type: FETCH_PRODUCT,
  payload: {
    id
  }
});

export const successFetchProduct = payload => ({
  type: SUCCESS_FETCH_PRODUCT,
  payload
});

export const failFetchProduct = errors => ({
  type: FAIL_FETCH_PRODUCT,
  errors
});

const productsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_PRODUCT:
      state['fetchingProduct'] = true;
      break;

    case SUCCESS_FETCH_PRODUCT:
      state['product'] = { ...action.payload };
      state['fetchingProduct'] = false;
      break;
    default:
      return state;
  }
};

function* fetchProductWorker(action) {
  const response = yield makeRequest(api.productsDetail, {
    lookupData: action.payload
  });

  if (response.data) yield put(successFetchProduct(response.data));
  else yield put(failFetchProduct(response.errors));
}

export function* productSaga() {
  yield takeLatest(FETCH_PRODUCT, fetchProductWorker);
}

export default productsReducer;
