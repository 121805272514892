import _ from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';

import { api, makeRequest } from 'shared/sdk';

import { addToList } from 'shared/utils/reducerUtils';

import { markAsSeen } from 'pages/Notifications/ducks';

const actionPrefix = 'Notifications/List/';

const FETCH_NOTIFICATIONS = `${actionPrefix}FETCH_NOTIFICATIONS`;
const SUCCESS_FETCH_NOTIFICATIONS = `${actionPrefix}SUCCESS_FETCH_NOTIFICATIONS`;

export const fetchNotifications = ({ nextUrl }) => ({
  type: FETCH_NOTIFICATIONS,
  payload: { nextUrl }
});

const successFetchNotifications = ({ data: { results, next } }) => ({
  type: SUCCESS_FETCH_NOTIFICATIONS,
  payload: { results, next }
});

export const listReducer = (state, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      state['isFetchingNotifications'] = true;
      break;

    case SUCCESS_FETCH_NOTIFICATIONS:
      state['isFetchingNotifications'] = false;
      state['notificationsNextUrl'] = action.payload.next;
      state['notifications'] = addToList(
        state['notifications'],
        action.payload.results
      );
      break;

    default:
      return state;
  }

  return state;
};

function* fetchNotificationsWorker(action) {
  const { nextUrl } = action.payload;

  let response;

  if (_.isNil(nextUrl)) {
    response = yield makeRequest(api.fetchNotifications);
  } else {
    response = yield makeRequest(api.genericGet, { lookupData: nextUrl });
  }

  if (response.success) {
    const resultIds = response.data.results.map(result => result.id);

    yield* [
      put(successFetchNotifications(response)),
      put(markAsSeen({ ids: resultIds }))
    ];
  }
}

export function* listSaga() {
  yield takeLatest(FETCH_NOTIFICATIONS, fetchNotificationsWorker);
}
