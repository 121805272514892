import { sectorsReducer, sectorsWatcher } from './sectors';
import { countriesReducer, countriesWatcher } from './countries';
import { usersReducer, usersWatcher } from './users';

const initialState = {
  sectors: [],
  countries: [],
  users: []
};

export const eventFormReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  sectorsReducer(newState, action);
  countriesReducer(newState, action);
  usersReducer(newState, action);

  return newState;
};

export const eventFormSaga = [sectorsWatcher, countriesWatcher, usersWatcher];

export * from './sectors';
export * from './countries';
export * from './users';
