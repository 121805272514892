import ReactGA from 'react-ga';
import _ from 'lodash';

const EVENTS = {
  REGISTRATION: {
    category: 'registration',
    action: 'nonlinkedin_registration_submission'
  },
  LINKEDIN_REGISTRATION: {
    category: 'registration',
    action: 'linkedin_registration_submission'
  },
  CLICK_ON_FEATURED_COMPANY_FROM_HOME_PAGE: {
    category: 'Home / Featured Company',
    action: 'Click'
  },
  SET_INDEX_OF_CLICKED_FEATURED_COMPANY: {
    category: 'Home / Featured Company',
    action: 'Open company in position'
  },
  UPGRADE_TO_PREMIUM_PLAN: {
    category: 'Billing',
    action: 'Upgrade to Premium plan'
  },
  CLICK_CTA_SIGNUP: {
    category: 'CTAs',
    action: 'Click Singup button'
  },
  CLICK_NEWS_TAB: {
    category: 'News tab',
    action: 'Click news tab'
  },
  CLICK_INTERESTED_NEWS: {
    category: 'News tab',
    action: 'Click interested'
  },
  CLICK_NOT_INTERESTED_NEWS: {
    category: 'News tab',
    action: 'Click not interested'
  }
};

export const GAInitialize = () => {
  ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, { titleCase: false });
};

export const GAPageView = location => {
  ReactGA.pageview(location.pathname + location.search);
};

export const GARegister = () => {
  window.ga(
    'send',
    'event',
    EVENTS.REGISTRATION.category,
    EVENTS.REGISTRATION.action
  );
};

export const GARegisterLinkedIn = () => {
  window.ga(
    'send',
    'event',
    EVENTS.LINKEDIN_REGISTRATION.category,
    EVENTS.LINKEDIN_REGISTRATION.action
  );
};

export const GAClickOnFeaturedCompanyFromHome = companySlug => {
  const data = {
    ...EVENTS.CLICK_ON_FEATURED_COMPANY_FROM_HOME_PAGE,
    label: companySlug
  };
  ReactGA.event(data);
};

export const GACSetIndexOfClickedCompany = index => {
  const data = {
    ...EVENTS.SET_INDEX_OF_CLICKED_FEATURED_COMPANY,
    value: index
  };
  ReactGA.event(data);
};

export const GAUpgradeToPremiumPlan = userId => {
  const data = { ...EVENTS.UPGRADE_TO_PREMIUM_PLAN, value: userId };
  ReactGA.event(data);
};

export const GAClickCTASignup = ctaId => {
  const data = { ...EVENTS.CLICK_CTA_SIGNUP, value: ctaId };
  ReactGA.event(data);
};

export const attemptUserIDSet = user => {
  if (!_.isNil(user) && !_.isEmpty(user)) {
    window.ga('set', 'userId', user.id.toString());
  }
};

export const GAClickNewsTab = () => {
  const data = { ...EVENTS.CLICK_NEWS_TAB };
  ReactGA.event(data);
};
