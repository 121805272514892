import _ from 'lodash';

import { detailReducer, detailWatcher } from './detail';
import { companyReducer, companyWatchers } from './vendor';
import { attendeeReducer, attendeeWatchers } from './attendees';

const DELETE_STATE = 'Event/Detail/DELETE_STATE';

export const deleteState = () => ({ type: DELETE_STATE });

const initialState = {
  event: {},
  performingFollowHostVendorOperation: false,
  performingAddAttendeeOperation: false,
  attendees: {},
  previewAttendees: {}
};

export const eventReducer = (state = _.cloneDeep(initialState), action) => {
  let newState = _.cloneDeep(state);

  if (action.type === DELETE_STATE) {
    newState = _.cloneDeep(initialState);
  }

  detailReducer(newState, action);
  companyReducer(newState, action);
  attendeeReducer(newState, action);

  return newState;
};

export const eventSagas = [detailWatcher, companyWatchers, attendeeWatchers];

export * from './detail';
export * from './vendor';
export * from './attendees';
