import _ from 'lodash';

import queryString from 'query-string';

export const generateQueryString = params => {
  return Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    })
    .join('&');
};

export const parseQueryString = searchParams => {
  return queryString.parse(searchParams);
};

export const stringifyParams = filters => queryString.stringify(filters);

export const normalizeUrl = ({ url, protocol = 'http' }) => {
  if (!_.startsWith(url, 'http') && !_.isEmpty(url)) {
    url = `${protocol}://${url}`;
  }

  return url;
};
