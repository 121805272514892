import { takeLatest } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';

import { api, getResponseOr404 } from 'shared/sdk';

const actionPrefix = 'Investor/Detail/Similar/';

export const FETCH_SIMILAR_INVESTORS = `${actionPrefix}FETCH_SIMILAR_INVESTORS`;
export const SUCCESS_FETCH_SIMILAR_INVESTORS = `${actionPrefix}SUCCESS_FETCH_SIMILAR_INVESTORS`;
export const FAIL_FETCH_SIMILAR_INVESTORS = `${actionPrefix}FAIL_FETCH_SIMILAR_INVESTORS`;

export const fetchSimilarInvestors = slug => ({
  type: FETCH_SIMILAR_INVESTORS,
  payload: { slug }
});

const successFetchSimilarInvestors = data => ({
  type: SUCCESS_FETCH_SIMILAR_INVESTORS,
  payload: {
    data
  }
});

const failFetchSimilarInvestors = errors => ({
  type: FAIL_FETCH_SIMILAR_INVESTORS,
  payload: {
    errors
  }
});

export const similarInvestorsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_SIMILAR_INVESTORS:
      state['isFetchingSimilarInvestors'] = true;
      break;

    case SUCCESS_FETCH_SIMILAR_INVESTORS:
      state['similarInvestors'] = action.payload.data.results;
      state['isFetchingSimilarInvestors'] = false;
      break;

    case FAIL_FETCH_SIMILAR_INVESTORS:
      state['isFetchingSimilarInvestors'] = false;
      break;

    default:
      return state;
  }
};

export function* fetchSimilarInvestorsWorker(action) {
  const response = yield getResponseOr404(api.similarInvestors, {
    lookupData: action.payload
  });

  if (response.success) {
    yield put(successFetchSimilarInvestors(response.data));
  } else yield put(failFetchSimilarInvestors(response.errors));
}

export function* similarInvestorsSaga() {
  yield takeLatest(FETCH_SIMILAR_INVESTORS, fetchSimilarInvestorsWorker);
}
