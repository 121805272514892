import { takeLatest } from 'redux-saga/effects';

import { api } from 'shared/sdk';
import { uploadFile } from 'shared/utils/fileUpload';

const actionPrefix = 'QuillEditor/';

const START_IMAGE_UPLOAD = `${actionPrefix}START_IMAGE_UPLOAD`;

export const triggerUpload = (file, quillInstance) => ({
  type: START_IMAGE_UPLOAD,
  payload: { file, quillInstance }
});

function* uploadEditorImageWorker(action) {
  const { file, quillInstance } = action.payload;

  const imageUrl = yield uploadFile(file, api.signResourceProposalFile);

  const range = quillInstance.getSelection();
  quillInstance.insertEmbed(range.index, 'image', imageUrl);
}

export function* quillSaga() {
  yield takeLatest(START_IMAGE_UPLOAD, uploadEditorImageWorker);
}
