import { fetchInfluencerReducer, fetchInfluencerSaga } from './fetchInfluencer';
import { connectVideosReducer, connectVideosSaga } from './connectVideos';
import { resourcesReducer, resourcesSaga } from './resources';
import { connectionsReducer, connectionsSaga } from './connections';
import { experienceReducer, experienceSaga } from './experience';
import { qualificationsReducer, qualificationsSaga } from './qualifications';

const actionPrefix = 'Influencer/Detail/';
const RESET_STATE = `${actionPrefix}RESET_STATE`;

export const resetState = () => ({
  type: RESET_STATE
});

const initialState = {
  influencer: null,
  isFetchingInfluencer: true,
  connectVideos: [],
  connectVideosNextUrl: null,
  isFetchingConnectVideos: false,
  isFetchingResources: false,
  resources: [],
  resourcesNextUrl: null,
  connections: [],
  isFetchingConnections: false,
  connectionsNextUrl: null,
  experience: [],
  qualifications: [],
  isFetchingExperience: false,
  isFetchingQualifications: false,
  experienceNextUrl: null,
  qualificationsNextUrl: null
};

export const influencerReducer = (state = initialState, action) => {
  let newState = { ...state };

  fetchInfluencerReducer(newState, action);
  connectVideosReducer(newState, action);
  resourcesReducer(newState, action);
  connectionsReducer(newState, action);
  experienceReducer(newState, action);
  qualificationsReducer(newState, action);

  if (action.type === RESET_STATE) {
    newState = initialState;
  }

  return newState;
};

export const influencerSagas = [
  fetchInfluencerSaga,
  connectVideosSaga,
  resourcesSaga,
  connectionsSaga,
  experienceSaga,
  qualificationsSaga
];

export * from './fetchInfluencer';
export * from './connectVideos';
export * from './resources';
export * from './connections';
export * from './experience';
export * from './qualifications';
